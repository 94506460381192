angular
  .module('workflow')

  .directive('projectForm', function() {
    return {
      restrict: 'E',
      templateUrl: 'custom/workflow/views/partials/projectForm.html',
      controller: 'ProjectCtrl',
      link: function(scope, elem, attrs) {
        scope.wizard = attrs.wizard && attrs.wizard.length > 0;

        scope.setFilesNumber = files => {
          if (Array.isArray(files)) scope.filesNumber = files.length;
        };
      }
    };
  });
