angular
  .module('quote')

  .factory('QuoteAskApprovalRequest', [
    'Crud',

    function(Crud) {
      'use strict';

      class QuoteAskApprovalRequest extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/quote-ask-approval-request/:id', {
            id: '@id'
          });
        }
      }

      return QuoteAskApprovalRequest;
    }
  ]);
