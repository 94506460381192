angular
  .module('company')

  .directive('selectPayment', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        payment: '='
      },
      template:
        '<select ng-model="payment" ng-options="p.value for p in payments track by p._id">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'PaymentCrud',
        function($scope, $controller, PaymentCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new PaymentCrud('-list'),
            packageName: 'payments',
            dataName: 'payments',
            filters: {}
          });

          $scope.loadList();
        }
      ]
    };
  });
