angular
  .module('task')

  .factory('TaskStartCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class TaskStartCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/start-task' + '/:id', { id: '@id' });
        }
      }
      return TaskStartCrud;
    }
  ]);
