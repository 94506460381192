'use strict';
angular
  .module('library')

  .factory('ValueLang', function() {
    return function(w, key, lang) {
      if (!w) return;
      if (!w[key]) {
        w[key] = [{ lang: lang }];
      }

      for (var i = 0; i < w[key].length; i += 1) {
        if (w[key][i].lang === lang) {
          return w[key][i];
        }
      }

      if (Array.isArray(w[key])) {
        const size = w[key].push({ lang: lang });
        return w[key][size - 1];
      }

      return {
        value: w[key][lang]
      };
    };
  });
