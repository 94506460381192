angular
  .module('contributor')

  .directive('contributorHeadquarterPreview', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      scope: {
        headquarter: '=ngModel',
        showActive: '@',
        showLegal: '@'
      },
      templateUrl:
        'custom/contributor/views/partials/contributorHeadquarterPreview.html'
    };
  });
