angular
  .module('company')

  .factory('ZohoCompanySync', [
    'Crud',

    function(Crud) {
      'use strict';

      class ZohoCompanySync extends Crud {
        constructor(id) {
          super(null, '@@apiUrl' + '/accounts-sync/' + id, {});
        }
      }

      return ZohoCompanySync;
    }
  ]);
