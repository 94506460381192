angular
  .module('customer', ['pb.core', 'components', 'company'])

  .run([
    '$filter',
    'Module',
    function($filter, Module) {
      var module = new Module();

      module.push({
        menu: {
          name: 'customer',
          title: $filter('translate')('customers.CUSTOMERS'),
          icon: 'zmdi zmdi-accounts',
          link: 'root.app.simple.customers'
        }
      });
    }
  ]);
