angular
  .module('demo')

  .controller('DemoListCtrl', [
    '$scope',
    '$controller',
    'DemoCrud',

    function($scope, $controller, DemoCrud) {
      $controller('PaginatedListCtrl', {
        $scope: $scope,
        crud: new DemoCrud(),
        dataName: 'items',
        filters: {}
      });
    }
  ]);
