/* global bootstrap-sweetalert */

angular
  .module('pb.base')

  .factory('Confirm', [
    '$q',
    '$rootScope',
    '$filter',
    'Dialog',

    function($q, $rootScope, $filter, Dialog) {
      'use strict';

      /**
       * Confirm class
       * @constructor
       */
      class Confirm extends Dialog {
        constructor(confirmButtonText) {
          super();
          this.confirmButtonText = confirmButtonText;
        }
        /**
         * Show a notification message
         * @returns {promise}
         */
        show() {
          var deferred = $q.defer();
          var notification = this._argumentsOrDefault(arguments);

          if (notification.type === 'danger') {
            notification.type = 'error';
          }

          swal(
            {
              title: notification.title || '',
              text: notification.message || '',
              type: notification.type,
              showCancelButton: true,
              confirmButtonText:
                this.confirmButtonText || $filter('translate')('CONFIRM'),
              cancelButtonText: $filter('translate')('CANCEL'),
              closeOnConfirm: true
            },
            function(isConfirm) {
              isConfirm ? deferred.resolve() : deferred.reject();
            }
          );

          return deferred.promise;
        }
      }

      return Confirm;
    }
  ]);
