angular
  .module('pb.base')

  .factory('OpenPdf', [
    '$window',
    'GetToken',

    function($window, GetToken) {
      return function(url) {
        // Creazione url
        var url = url + '?access_token=' + GetToken();
        // Perform request in a new tab
        $window.open(url);
      };
    }
  ]);
