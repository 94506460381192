angular
  .module('contributor')

  .directive('contributorBilling', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        billing: '=ngModel'
      },
      templateUrl: 'custom/contributor/views/partials/contributorBilling.html',
      controller: [
        '$scope',
        '$filter',
        function($scope, $filter) {
          $scope.$watch('billing.tax_code', function(value) {
            if (value) {
              $scope.showRDA =
                value === $filter('translate')('tribute.tribute_1040');
              $scope.billing.rda = 20;
            }
          });
        }
      ]
    };
  });
