angular
  .module('library')

  .filter('multipleTags', function() {
    return function(input) {
      var result = '';

      if (input.length > 0) {
        for (var i = 0; i < input.length; i++) {
          if (i > 0 && i < input.length) {
            result += ', ';
          }

          result += input[i].text;
        }
      }

      return result;
    };
  });
