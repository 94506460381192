angular
  .module('contributor')

  .directive('toolsNetwork', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        tools: '=ngModel'
      },
      templateUrl: 'custom/contributor/views/partials/toolsNetwork.html',
      controller: [
        '$scope',
        function($scope) {
          $scope.softwares = [
            'Adobe Acrobat (full version)',
            'Adobe Framemaker',
            'Ms-Office',
            'Practicount',
            'SDL Multiterm',
            'SDL Studio Groupshare',
            'SDL Trados Studio',
            'Tegest',
            'Xtract'
          ];

          $scope.softwares = $scope.softwares.map(function(
            element,
            index,
            array
          ) {
            if (!(index % 2)) {
              return array[index / 2];
            } else {
              return array[Math.floor(array.length / 2) + Math.ceil(index / 2)];
            }
          });

          $scope.softwares[$scope.softwares.length - 1]
            ? null
            : $scope.softwares.pop();

          $scope.checkSoftware = function(software) {
            if (!$scope.tools) {
              return false;
            }

            $scope.tools.softwares = $scope.tools.softwares || [];
            return $scope.tools.softwares.indexOf(software) >= 0;
          };

          $scope.toggleSoftware = function(software) {
            if (!$scope.tools) {
              return;
            }

            var index = $scope.tools.softwares.indexOf(software);
            if (index < 0) {
              $scope.tools.softwares.push(software);
            } else {
              $scope.tools.softwares.splice(index, 1);
            }
          };
        }
      ]
    };
  });
