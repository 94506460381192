angular
  .module('workflow')

  .directive('previewService', function() {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'custom/workflow/views/partials/previewService.html',
      controller: 'ServiceCtrl'
    };
  });
