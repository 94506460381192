angular
  .module('pb.user')

  .factory('UserListCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class UserListCrud extends Crud {
        constructor() {
          super('allusers', '@@apiUrl' + '/allusers');
        }
      }

      return UserListCrud;
    }
  ]);
