angular
  .module('signup')

  .directive('newCompanyForm', [
    '$filter',
    function($filter) {
      return {
        restrict: 'E',
        replace: true,
        require: '^ngModel',
        scope: {
          company: '=ngModel'
        },
        templateUrl: 'custom/signup/views/partials/newCompanyForm.html'
      };
    }
  ]);
