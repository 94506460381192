angular
  .module('workflow')

  .factory('CostsSummarySrv', [
    function() {
      const fee = parseFloat('@@fixed_pm_fee') || 39.95;
      let indirect_ratio = parseFloat('@@default_indirect_costs_ratio') || 0.6;
      let source,
        target,
        company,
        batches = [],
        incompleteList;

      const setSource = function(s) {
        source = s;
      };

      const setTarget = function(t) {
        target = t;
      };

      const setCompany = function(c) {
        company = c;
      };

      const setBatches = function(b) {
        batches = b;
      };

      const setRatio = ratio => {
        return (indirect_ratio = ratio);
      };

      const totalQuantities = function(ranges) {
        let total = 0;
        ranges &&
          ranges.forEach(function(range) {
            if (range.quantity) {
              total += range.quantity;
            }
          });
        return total;
      };

      const pricePerUnit = function(prices, ranges) {
        if (prices && prices.length) {
          if (prices.length === 1) {
            return prices[0];
          } else if (prices.length > 1) {
            return (
              Math.round(
                (calcPrice(prices, ranges) / totalQuantities(ranges)) * 100
              ) / 100 || null
            );
          }
        }
      };

      const calcPrice = function(prices = [], ranges, defaultPrices, task) {
        if (prices.length === 1) {
          return prices[0];
        } else if (prices.length > 1) {
          let price = null;
          ranges &&
            ranges.forEach(function(range) {
              const defaultAmountPerUnit =
                defaultPrices && defaultPrices[range.price_range - 1];
              const amountPerUnit =
                prices[range.price_range - 1] || defaultAmountPerUnit;
              if (range.price_range && amountPerUnit && range.quantity) {
                price += amountPerUnit * range.quantity;
              } else if (
                task &&
                range.price_range &&
                range.quantity &&
                !amountPerUnit
              ) {
                incompleteList = task;
              }
            });
          return typeof price === 'number' ? +price.toFixed(3) : price;
        } else {
          return null;
        }
      };

      const findUnitByTask = task => {
        return (
          task.unit &&
          task.units &&
          task.units.find(unit => unit._id === task.unit._id)
        );
      };

      const findCustomCompanyList = task => {
        return (
          company &&
          company.lists &&
          company.lists.find(function(list) {
            const foundUom =
              list.units &&
              list.units.find(function(unit) {
                return unit._id === (task && task.unit && task.unit._id);
              });
            return foundUom && !list.task.old && list.task.task_id === task._id;
          })
        );
      };

      const findCustomCompanyListByUnit = (task = {}, customList = {}) => {
        const foundUnit =
          customList.units &&
          customList.units.find(unit => {
            return unit._id === task.unit._id;
          });
        const foundCombo =
          findMatchingCombo(foundUnit && foundUnit.combos) || {};
        return foundCombo.list;
      };

      const searchPrice = function(task) {
        // first of all, see if current task has a custom price.
        if (task.amount || task.amount === 0) {
          return task.amount;
        }
        // then, see if current task has a custom price list. If yes, use that. If not, check if customer has a custom price list.
        const defaultUnit = findUnitByTask(task) || {};
        const defaultCombo = findMatchingCombo(defaultUnit.combos) || {};
        const defaultPrices =
          (defaultCombo.list && defaultCombo.list.prices) || [];

        const customListForTask =
          task && task.unit && task.unit.list && task.unit.list.customTask;

        if (customListForTask) {
          return calcPrice(
            task.unit.list.prices,
            task.trados,
            defaultPrices,
            task
          );
        }
        // search for a current (not old) custom price list that has the same unit of m. and task _id as the the task in batch
        const customCompanyList = findCustomCompanyList(task);
        const customCompanyListByUnit = findCustomCompanyListByUnit(
          task,
          customCompanyList
        );
        const customCompanyPrices =
          customCompanyListByUnit && customCompanyListByUnit.prices;
        // if found, use price from found list
        if (customCompanyPrices) {
          return calcPrice(
            customCompanyPrices,
            task.trados,
            defaultPrices,
            task
          );
        }
        // else, use default price for task. If task has no default price, return null
        else {
          const foundUnit = findUnitByTask(task);
          if (foundUnit) {
            const foundCombo = findMatchingCombo(foundUnit.combos) || {};
            const prices = (foundCombo.list && foundCombo.list.prices) || [];
            return calcPrice(prices, task.trados, null, task);
          } else {
            return null;
          }
        }
      };

      const pricePerTask = function(task) {
        return searchPrice(task) * task.quantity;
      };

      const totalPrice = function() {
        let price = 0;
        let currentTask = null;
        incompleteList = null;

        if (
          batches.some(batch => {
            return batch.tasks.some(task => {
              let { quantity, unitPrice } = task || {};
              quantity = quantity || 0;
              unitPrice =
                typeof unitPrice === 'number' ? unitPrice : searchPrice(task);
              const taskPrice = unitPrice * quantity;
              price += taskPrice || 0;
              currentTask = task;

              return !unitPrice && unitPrice !== 0;
            });
          })
        ) {
          return { error: currentTask };
        } else {
          return { ok: price, incompleteList: incompleteList };
        }
      };

      const findMatchingComboByUnit = (combos = [], unit) => {
        return combos.find(function(combo) {
          if (!combo || !combo.uom || !combo.uom._id) {
            return false;
          } else if (
            combo.source &&
            combo.source._id &&
            combo.target &&
            combo.target._id
          ) {
            return (
              combo.source._id === source._id &&
              combo.target._id === target._id &&
              combo.uom._id === unit._id
            );
          } else if (combo.target && combo.target._id) {
            return (
              combo.target._id === target._id && combo.uom._id === unit._id
            );
          } else {
            return combo.uom._id === unit._id;
          }
        });
      };

      const findMatchingCombo = (combos = []) => {
        return combos.find(function(combo) {
          if (
            combo.source &&
            combo.source._id &&
            combo.target &&
            combo.target._id
          ) {
            return (
              combo.source._id === (source && source._id) &&
              combo.target._id === target._id
            );
          } else if (combo.target && combo.target._id) {
            return combo.target._id === target._id;
          } else {
            return true;
          }
        });
      };

      const findMatchingService = (services = [], task_id) => {
        return services.find(function(service) {
          const foundCombo = findMatchingCombo(service.combos);
          return foundCombo && task_id === service.task._id;
        });
      };

      const taskContributorCost = function(
        contributors,
        ranges,
        quantity,
        task_id
      ) {
        let price = 0;
        let foundCombo, foundService;
        contributors &&
          contributors.forEach(function(contributor) {
            // If the contributor already has a price, use it
            if (contributor.price) {
              return (price += contributor.price);
            }
            // else compute it
            foundService =
              findMatchingService(contributor.servicesList, task_id) || {};
            foundCombo = findMatchingCombo(foundService.combos) || {};
            price +=
              (foundService &&
                foundCombo.price * quantity * (totalQuantities(ranges) || 1)) ||
              0;
          });
        return price;
      };

      const totContributorCost = function(rangesName) {
        // rangesName is usually "trados"
        let total = 0;
        batches.forEach(function(batch) {
          batch.tasks.forEach(function(task) {
            total +=
              typeof task.contributorCost === 'number'
                ? task.contributorCost
                : taskContributorCost(
                    task.contributors,
                    task[rangesName],
                    task.quantity,
                    task._id
                  );
          });
        });
        return total;
      };

      const getFee = function() {
        return fee;
      };

      const getRatio = function() {
        return indirect_ratio;
      };

      return {
        findCustomCompanyList,
        findCustomCompanyListByUnit,
        searchPrice,
        setSource,
        setTarget,
        setCompany,
        setBatches,
        setRatio,
        pricePerTask,
        totalPrice,
        totContributorCost,
        taskContributorCost,
        pricePerUnit,
        getFee,
        getRatio,
        findMatchingCombo,
        findMatchingService,
        totalQuantities,
        findMatchingComboByUnit
      };
    }
  ]);
