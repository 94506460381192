angular
  .module('workflow')

  .directive('selectOfferTemplate', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        saved: '<',
        selected: '&'
      },
      templateUrl: 'custom/workflow/views/partials/selectOfferTemplate.html',
      controller: [
        '$scope',
        '$controller',
        '$modal',
        '$timeout',
        'PdfCrudUndoable',

        function($scope, $controller, $modal, $timeout, PdfCrudUndoable) {
          $controller('DataTableCtrl', {
            $scope: $scope,
            crud: new PdfCrudUndoable($scope),
            packageName: 'pdfTemplate',
            tableParams: 'pdfTemplateList',
            filters: {},
            parser: null
          });

          $scope.selectTemplate = template => {
            deselectAllBut(template);
            $scope.selected({ template });
          };

          $timeout(() => {
            const unlisten = $scope.$watch(
              'pdfTemplateList',
              ({ data }) => {
                const { saved } = $scope;
                if (saved || !data || !data.length) return;
                const template = findSelected();
                $scope.selected({ template });
                sortByActive();
                unlisten();
              },
              true
            );
          }, 500);

          const unlistenTemplateSaved = $scope.$watch(
            'saved+pdfTemplateList.data',
            () => {
              if (!($scope.saved && $scope.pdfTemplateList.data.length)) return;
              const index = $scope.pdfTemplateList.data.findIndex(
                t => t._id == $scope.saved._id
              );
              if (index > -1) $scope.pdfTemplateList.data.splice(index, 1);
              $scope.pdfTemplateList.data.push($scope.saved);
              $scope.selectTemplate($scope.saved);
              sortByActive();
              unlistenTemplateSaved();
            }
          );

          function findSelected() {
            return $scope.pdfTemplateList.data.find(t => t.is_default);
          }

          function deselectAllBut(template) {
            $scope.pdfTemplateList.data.map(t => {
              if (t._id != template._id) {
                t.is_default = false;
                return t;
              }
              return t;
            });
          }

          $scope.modify = template => {
            openModal(template);
          };

          function openModal(template) {
            const modalInstance = $modal.open({
              animation: true,
              templateUrl: 'custom/library/views/pdfForm.html',
              controller: 'selectOfferTemplateModalCtrl',
              size: 'lg',
              resolve: {
                template: () => JSON.parse(JSON.stringify(template))
              }
            });
            modalInstance.result.then(res => {
              const index = $scope.pdfTemplateList.data.findIndex(
                t => t._id == res.template._id
              );
              if (index >= 0) $scope.pdfTemplateList.data[index] = res.template;
              $scope.selectTemplate(findSelected());
            });
          }

          function sortByActive() {
            $scope.pdfTemplateList.data.sort(
              (a, b) => b.is_default - a.is_default
            );
          }
        }
      ]
    };
  })

  .controller('selectOfferTemplateModalCtrl', [
    '$scope',
    '$modalInstance',
    'template',
    function($scope, $modalInstance, template) {
      $scope.pdf = template;
      $scope.save = () => $modalInstance.close({ template: $scope.pdf });
    }
  ]);
