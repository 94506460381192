'use strict';

/**
 * DO NOT ADD HERE DEPENDENCIES OF CUSTOM PACKAGES, BUT IN CONFIG FILE (config/packages.json)
 */

//customModules is replaced by Gulp
angular.module('app', ['pb.base', 'templates'].concat(customModules));

angular.element(document).ready(function() {
  //Fixing facebook bug with redirect
  if (window.location.hash === '#_=_') window.location.hash = '#!';

  //Then init the app
  angular.bootstrap(document, ['app'], {
    strictDi: true
  });
});
