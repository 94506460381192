angular
  .module('pb.user')

  .directive('userImage', [
    '$filter',
    '$controller',

    function($filter, $controller) {
      'use strict';

      return {
        restrict: 'E',
        replace: true,
        scope: {
          user: '=ngModel',
          save: '&'
        },
        templateUrl: 'modules/user/views/partials/userImage.html',
        controller: [
          '$scope',
          '$controller',
          '$filter',
          '$timeout',
          'Confirm',
          'Dialog',
          'ErrorsManager',
          'FilesCrud',
          'FileDeleter',
          'FileDownloader',
          function(
            $scope,
            $controller,
            $filter,
            $timeout,
            Confirm,
            Dialog,
            ErrorsManager,
            FilesCrud,
            FileDeleter,
            FileDownloader
          ) {
            let fileImg;
            let crud = new FilesCrud();
            let confirm = new Confirm();
            let dialog = new Dialog();

            $scope.filePath = '';
            $scope.userPhotoUrl = '';

            const getFile = parentPath => {
              crud
                .query({ parentPath: parentPath })
                .then(response => {
                  fileImg = response.length > 0 ? response[0] : {};
                  return fileImg.key;
                })
                .then(downloadAttachment);
            };

            const downloadAttachment = key => {
              if (!key) {
                return;
              }

              FileDownloader.download(key)
                // .then(url => $scope.userPhotoUrl = url + '&ts=' + Date.now());
                .then(url => ($scope.userPhotoUrl = url));
            };

            $scope.$watch('user', newValue => {
              if (newValue && newValue._id) {
                $scope.filePath = 'profiles/' + newValue._id;
                getFile($scope.filePath);
              }
            });

            $scope.$on('onFileUploaded', (event, file) => {
              if (file && file.key) {
                fileImg = file;
                downloadAttachment(file.key);
              }
            });

            $scope.delete = () => {
              confirm
                .info($filter('translate')('contributors.DELETE_FILE_MESSAGE'))
                .then(() => {
                  return FileDeleter.delete(fileImg);
                })
                .then(() => ($scope.userPhotoUrl = ''))
                .catch(err => {
                  Sentry.captureException(err);
                  $timeout(() => {
                    dialog.error(ErrorsManager.getErrorsAsString(err));
                  }, 100);
                });
            };
          }
        ]
      };
    }
  ]);
