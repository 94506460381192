angular
  .module('contributor')

  .controller('ContributorHeaderCtrl', [
    '$scope',
    '$filter',
    'Confirm',

    function($scope, $filter, Confirm) {
      const confirm = new Confirm();

      $scope.selectAllContributors = () => {
        $scope.$parent.$broadcast('selectAllContributors');
      };

      $scope.sendUpdateProfileRequests = () => {
        $scope.$parent.$broadcast('sendUpdateProfileRequests');
      };

      $scope.download = () => {
        confirm.info($filter('translate')('DOWNLOAD_REQUEST')).then(() => {
          $scope.$parent.$broadcast('downloadContributors');
        });
      };

      $scope.downloadSpecializations = () => {
        confirm.info($filter('translate')('DOWNLOAD_REQUEST')).then(() => {
          $scope.$parent.$broadcast('downloadSpecializations');
        });
      };

      $scope.downloadSpecializationsTotals = () => {
        confirm.info($filter('translate')('DOWNLOAD_REQUEST')).then(() => {
          $scope.$parent.$broadcast('downloadSpecializationsTotals');
        });
      };

      $scope.addContributorsToTask = () => {
        $scope.$parent.$broadcast('addContributorsToTask');
      };

      $scope.export = () => {
        confirm.info($filter('translate')('DOWNLOAD_REQUEST')).then(() => {
          $scope.$parent.$broadcast('exportContributorsToJson');
        });
      };

      $scope.$on('multiContributorsSelect', (ev, multiContributorsSelect) => {
        $scope.multiContributorsSelect = multiContributorsSelect;
      });
    }
  ]);
