angular
  .module('task')

  .directive('selectContributorProjectManager', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        selected: '=',
        contributor: '<'
      },
      template:
        '<select ng-model="selected" ng-options="pm as (pm.first_name + \' \' + pm.last_name) for pm in projectManagers track by pm._id"></select>',
      controller: [
        '$scope',
        'ContributorProjectManagerCrud',
        'LoggedUser',
        function($scope, ContributorProjectManagerCrud, LoggedUser) {
          const params = {};
          const loadProjectManagers = () =>
            new ContributorProjectManagerCrud()
              .get(params)
              .then(res => ($scope.projectManagers = res))
              .catch(() => ($scope.projectManagers = []));

          if (LoggedUser.isPM()) {
            const unwatch = $scope.$watch('contributor', contributor => {
              if (!contributor) return;
              const { _id } = contributor;
              params.contributor = _id;
              loadProjectManagers();
              unwatch();
            });
          } else loadProjectManagers();

          $scope.$on('resetFilters', () => {
            loadProjectManagers();
          });
        }
      ]
    };
  });
