angular
  .module('pb.core.form')

  .factory('PasswordStrength', function() {
    var getStrength = function(password) {
      var strength = 0;

      if (!password || password.length <= 3) {
        return 0;
      }

      strength += /[a-z]/.test(password) ? 1 : 0;
      strength += /[A-Z]/.test(password) ? 1 : 0;
      strength += /\d/.test(password) ? 1 : 0;

      if (strength > 1) {
        strength += password.length >= 8 ? 1 : 0;
        strength += password.length >= 16 ? 1 : 0;
      }

      if (strength > 4) {
        strength = 4;
      }

      //Strength percentage
      return strength * 25;
    };

    return {
      getStrength: getStrength
    };
  });
