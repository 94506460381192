angular
  .module('company')

  .directive('selectPaymentConditions', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        payment: '=',
        lang: '<',
        onChange: '&'
      },
      template:
        '<select ng-model="payment" ng-options="p.value for p in payments track by p._id" ng-change="save()">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        '$timeout',
        'PaymentCrud',
        function($scope, $controller, $timeout, PaymentCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new PaymentCrud('-list'),
            packageName: 'payments',
            dataName: 'payments',
            filters: {}
          });

          const { lang } = $scope;
          $scope.loadList({ lang });

          $scope.save = () =>
            $timeout(() => $scope.onChange && $scope.onChange());
        }
      ]
    };
  });
