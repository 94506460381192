angular
  .module('signup')

  .controller('SignupStepCtrl', [
    '$scope',
    '$filter',
    '$timeout',
    '$anchorScroll',
    'Dialog',
    'FormValidator',

    function($scope, $filter, $timeout, $anchorScroll, Dialog, FormValidator) {
      var dialog = new Dialog(),
        validator;

      //Scroll to top
      $anchorScroll(0);

      $timeout(function() {
        validator = new FormValidator($scope.signupForm);
      });

      $scope.signup = $scope.$parent.signup || {};

      $scope.back = function() {
        $scope.$parent.back();
      };

      $scope.next = function() {
        if (!validator.validate()) {
          dialog.error(
            $filter('translate')('crud.VALIDATION_ERROR') +
              '\n' +
              $filter('translate')('crud.VALIDATION_ERROR_VERIFY')
          );
          return;
        }

        $scope.$parent.next();
      };

      $scope.goTo = function(step) {
        if (!validator.validate()) {
          dialog.error(
            $filter('translate')('crud.VALIDATION_ERROR') +
              '\n' +
              $filter('translate')('crud.VALIDATION_ERROR_VERIFY')
          );
          return;
        }

        $scope.$parent.goTo(step);
      };

      $scope.noFilesError = function() {
        dialog.error($filter('translate')('signup.NO_CV_ERR'));
      };
    }
  ]);
