angular
  .module('workflow')

  .config([
    '$stateProvider',

    function($stateProvider) {
      var path = 'custom/workflow/views/';

      $stateProvider

        .state('root.app.simple.workflow-wizard', {
          url: '/new-workflow/:id',
          views: {
            header: {
              templateUrl: path + 'header.html'
            },
            content: {
              templateUrl: path + 'wizard.html',
              controller: 'WorkflowWizardCtrl'
            }
          },
          permissions: {
            resources: { quotes: ['create'] }
          }
        })

        .state('root.app.simple.workflow-wizard.step1', {
          url: '/step1',
          templateUrl: path + 'step1.html',
          controller: 'WorkflowWizardStepCtrl'
        })

        .state('root.app.simple.workflow-wizard.step2', {
          url: '/step2',
          templateUrl: path + 'step2.html',
          controller: 'WorkflowWizardStepCtrl'
        })

        .state('root.app.simple.workflow-wizard.step3', {
          url: '/step3/:projectCode',
          templateUrl: path + 'step3.html',
          controller: 'WorkflowWizardStepCtrl'
        })

        .state('root.app.simple.workflow-wizard.step4', {
          url: '/step4',
          templateUrl: path + 'step4.html',
          controller: 'WorkflowWizardStepCtrl'
        })

        .state('root.app.simple.workflow-wizard.step5', {
          url: '/step5',
          templateUrl: path + 'step5.html',
          controller: 'WorkflowWizardStepCtrl'
        });
    }
  ]);
