angular
  .module('pb.base')

  .directive('selectFormat', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        formats: '='
      },
      template: `<select chosen="{'placeholder_text_single': ('service_detail.SELECT_SINGLE_FORMAT' | translate)}"
                                         ng-model="format" ng-options="i for i in items"></select>`,
      controller: [
        '$scope',
        '$controller',
        'FormatsCrud',
        function($scope, $controller, FormatsCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new FormatsCrud('-list'),
            packageName: 'format',
            dataName: 'items',
            filters: {}
          });

          $scope.loadList();
        }
      ]
    };
  });
