angular
  .module('pb.file')

  .service('FileDownloader', [
    'PbDownloader',
    'S3Downloader',

    function(PbDownloader, S3Downloader) {
      // let uploaderType = '@@file_upload';
      // let downloader = uploaderType === 'S3' ? S3Downloader : PbDownloader;

      this.download = file => {
        // return downloader.download(file);
        return S3Downloader.download(file);
      };
    }
  ]);
