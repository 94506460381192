angular
  .module('workflow')

  .directive('workflowCheckIso', [
    function() {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          iso17100: '=',
          company: '='
        },
        templateUrl: 'custom/workflow/views/partials/workflowCheckIso.html',
        controller: [
          '$scope',
          function($scope) {
            $scope.$watch('company', company => {
              if (!company) return;

              const { settings } = company;
              const { iso17100 = false } = settings || {};
              if (typeof $scope.iso17100 === 'undefined') {
                $scope.iso17100 = iso17100;
              }
            });
          }
        ]
      };
    }
  ]);
