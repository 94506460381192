angular
  .module('pb.core.user')

  .directive('pbCanEdit', [
    '$compile',

    function($compile) {
      return {
        restrict: 'A',
        priority: 1000,
        terminal: true,
        link: function(scope, element, attrs) {
          element.attr('pb-can-create', attrs.pbCanEdit);
          element.attr('pb-can-update', attrs.pbCanEdit);
          //remove the attribute to avoid infinite loop
          element.removeAttr('pb-can-edit');

          $compile(element)(scope);
        }
      };
    }
  ]);
