angular
  .module('pb.base')

  .config([
    '$stateProvider',
    '$urlRouterProvider',
    '$locationProvider',

    function($stateProvider, $urlRouterProvider, $locationProvider) {
      var path = 'modules/base/views/';

      $locationProvider.html5Mode(true);
      $urlRouterProvider.otherwise('404');

      //////////////////////////
      // State Configurations //
      //////////////////////////

      $stateProvider

        .state('root', {
          url: '',
          template: '<div ui-view></div>',
          ncyBreadcrumb: {
            label: "{{::( 'HOME' | translate | uppercase )}}"
          }
        })

        //////////////
        //  Login   //
        //////////////

        .state('root.login', {
          url: '/login',
          templateUrl: path + 'pages/login.html',
          controller: 'LoginCtrl',
          data: {
            bodyClasses: 'login-content'
          },
          resolve: {
            root: [
              'BootstrapNoAuth',
              function(BootstrapNoAuth) {
                return BootstrapNoAuth();
              }
            ]
          }
        })

        .state('root.loginMfa', {
          url: '/login-mfa',
          templateUrl: path + 'pages/login-mfa.html',
          controller: 'LoginMfaCtrl',
          data: {
            bodyClasses: 'login-content'
          },
          params: {
            email: null
          },
          resolve: {
            root: [
              'BootstrapNoAuth',
              function(BootstrapNoAuth) {
                return BootstrapNoAuth();
              }
            ]
          }
        })

        //            .state('root.signup', {
        //              url: '/signup',
        //              templateUrl: path + 'pages/signup.html',
        //              controller: 'SignupCtrl',
        //              data: {
        //                bodyClasses: 'login-content'
        //              },
        //              resolve: {
        //                root: ['BootstrapNoAuth', function(BootstrapNoAuth) {
        //                  return BootstrapNoAuth();
        //                }]
        //              }
        //            })

        //            .state('root.forgotpassword', {
        //              url: '/forgot-password',
        //              templateUrl: path + 'pages/forgotpassword.html',
        //              controller: 'ForgotCtrl',
        //              data: {
        //                bodyClasses: 'login-content'
        //              },
        //              resolve: {
        //                root: ['BootstrapNoAuth', function(BootstrapNoAuth) {
        //                  return BootstrapNoAuth();
        //                }]
        //              }
        //            })

        //////////////
        //  Errors  //
        //////////////

        .state('root.0', {
          url: '/0',
          templateUrl: path + 'errors/0.html',
          controller: 'ErrorsCtrl',
          data: {
            bodyClasses: 'four-zero-content'
          },
          resolve: {
            root: [
              'BootstrapNoAuth',
              function(BootstrapNoAuth) {
                return BootstrapNoAuth();
              }
            ]
          }
        })

        .state('root.403', {
          url: '/403',
          templateUrl: path + 'errors/403.html',
          controller: 'ErrorsCtrl',
          data: {
            bodyClasses: 'four-zero-content'
          },
          resolve: {
            root: [
              'BootstrapNoAuth',
              function(BootstrapNoAuth) {
                return BootstrapNoAuth();
              }
            ]
          }
        })

        .state('root.404', {
          url: '/404',
          templateUrl: path + 'errors/404.html',
          controller: 'ErrorsCtrl',
          data: {
            bodyClasses: 'four-zero-content'
          },
          resolve: {
            root: [
              'BootstrapNoAuth',
              function(BootstrapNoAuth) {
                return BootstrapNoAuth();
              }
            ]
          }
        })

        .state('root.500', {
          url: '/500',
          templateUrl: path + 'errors/500.html',
          controller: 'ErrorsCtrl',
          data: {
            bodyClasses: 'four-zero-content'
          },
          resolve: {
            root: [
              'BootstrapNoAuth',
              function(BootstrapNoAuth) {
                return BootstrapNoAuth();
              }
            ]
          }
        })

        ///////////////////////////////////
        //   Wizard pages (not active)   //
        ///////////////////////////////////

        .state('root.wizard', {
          url: '/wizard',
          templateUrl: path + 'app.html',
          resolve: {
            root: [
              'BootstrapWizardService',
              function(BootstrapWizardService) {
                return BootstrapWizardService();
              }
            ]
          }
        })

        .state('root.wizard.simple', {
          url: '/p',
          templateUrl: path + 'base-12.html'
        })

        .state('root.wizard.base', {
          url: '/page',
          templateUrl: path + 'base-4-8.html'
        })

        ////////////////////////////////////
        // Base pages (logged and active) //
        ////////////////////////////////////

        .state('root.app', {
          abstract: true,
          url: '/app',
          templateUrl: path + 'app.html',
          ncyBreadcrumb: {
            skip: true
          },
          resolve: {
            root: [
              'BootstrapService',
              function(BootstrapService) {
                return BootstrapService();
              }
            ]
          }
        })

        .state('root.app.info', {
          url: '/info',
          template: '<info></info>'
        })

        .state('root.app.simple', {
          url: '/p',
          templateUrl: path + 'base-12.html',
          ncyBreadcrumb: {
            skip: true
          }
        })

        .state('root.app.base', {
          url: '/page',
          templateUrl: path + 'base-4-8.html'
        })

        .state('root.app.sla', {
          url: '/sla',
          template: '<sla></sla>'
        });
    }
  ])

  .config([
    '$httpProvider',
    function($httpProvider) {
      $httpProvider.defaults.useXDomain = true;
      delete $httpProvider.defaults.headers.common['X-Requested-With'];
    }
  ]);
