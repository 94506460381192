angular
  .module('customer')

  .factory('ZohoCustomerBulkSync', [
    'Crud',

    function(Crud) {
      'use strict';

      class ZohoCustomerBulkSync extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/contacts-bulk-sync', {});
        }
      }

      return ZohoCustomerBulkSync;
    }
  ]);
