angular
  .module('signup')

  .directive('selectUom', [
    '$filter',
    function($filter) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          uom: '='
        },
        template:
          '<select ng-model="uomSelected" ng-options="u.value for u in uoms track by u._id" ng-change="uomChanged()">' +
          '<option value="">-</option>' +
          '</select>',
        controller: [
          '$scope',
          '$controller',
          'UnitCrud',
          function($scope, $controller, UnitCrud) {
            $controller('ListCtrl', {
              $scope: $scope,
              crud: new UnitCrud('-list'),
              packageName: 'contributor',
              dataName: 'uoms',
              filters: {}
            });

            $scope.loadList();

            $scope.uomChanged = () => {
              $scope.uom = $scope.uomSelected && {
                _id: $scope.uomSelected._id,
                code: $scope.uomSelected.code,
                value: $scope.uomSelected.value,
                lc: $scope.uomSelected.lc
              };
            };

            $scope.$watch('uom', uom => ($scope.uomSelected = uom));
          }
        ]
      };
    }
  ]);
