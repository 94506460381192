angular.module('leads').controller('LeadsCtrl', [
  '$scope',
  'GetToken',
  'GetUTypeToken',
  'Dialog',
  'Confirm',
  function($scope, GetToken, GetUTypeToken, Dialog, Confirm, ErrorsManager) {
    $scope.apiUrl = '@@apiUrl';
    $scope.token = GetToken();
    $scope.xutype = GetUTypeToken();
    $scope.dialog = new Dialog();
    $scope.confirm = new Confirm();
  }
]);
