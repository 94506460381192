angular
  .module('contributor')

  .factory('SoftwareCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class SoftwareCrud extends Crud {
        constructor(list) {
          super(
            'software' + (list || ''),
            '@@apiUrl' + '/softwares' + (list || '') + '/:id',
            { id: '@id' },
            {
              query: {
                method: 'GET',
                isArray: true
              }
            }
          );
        }
      }

      return SoftwareCrud;
    }
  ]);
