angular
  .module('workflow')

  .factory('WorkflowISO17100', [
    'TaskCrud',
    'UserLang',
    function(TaskCrud, UserLang) {
      const getTranslate = (translations, userLang) => {
        const translate = translations.find(({ lang }) => userLang === lang);
        return (translate && translate.value) || '';
      };

      return function(workflow) {
        const lang = UserLang.get();
        const { iso17100 = false, projects = [] } = workflow;

        if (!iso17100) return Promise.resolve();

        const crud = new TaskCrud();
        const params = { lang };

        return crud
          .query(params)
          .then(tasks => tasks.find(({ code }) => code === '@@task_code_17100'))
          .then(revisionTask => {
            if (!revisionTask) {
              return;
            }

            const name = getTranslate(revisionTask.name, lang);
            const description = getTranslate(revisionTask.description, lang);
            const abbreviation = getTranslate(revisionTask.abbreviation, lang);

            projects.forEach(project => {
              const { batches = [], services = [] } = project;

              const translationService = services.find(({ service = {} }) => {
                return service.code === '@@translation_service_code';
              });
              if (!translationService) return;

              const alreadyHasRevisionTask = (batches || []).some(batch => {
                const { tasks } = batch;
                return (tasks || []).some(task => {
                  const { code } = task || {};
                  return code === '@@task_code_17100';
                });
              });
              if (alreadyHasRevisionTask) return;

              const { service = {} } = translationService;
              const { _id: service_id, value: service_name } = service;

              const task = {
                ...revisionTask,
                service_id,
                service_name,
                name,
                description,
                abbreviation
              };

              batches.push({ tasks: [task] });
            });
          })
          .catch(error => Sentry.captureException(error));
      };
    }
  ]);
