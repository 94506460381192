angular
  .module('workflow')

  .factory('WorkflowOrigins', [
    '$filter',

    function($filter) {
      'use strict';

      var origins = [
        {
          code: '1',
          value: 'DOOR24'
        },
        {
          code: '2',
          value: $filter('translate')('contacts.EMAIL')
        },
        {
          code: '3',
          value: $filter('translate')('workflow.VOICE')
        }
      ];

      return {
        origins: origins,
        teg: origins[0]
      };
    }
  ]);
