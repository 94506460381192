angular
  .module('quote')

  .config([
    '$stateProvider',

    function($stateProvider) {
      var path = 'custom/workflow/views/';

      $stateProvider

        .state('root.app.simple.quotes', {
          url: '/quotes',
          views: {
            content: {
              templateUrl: path + 'search.html',
              controller: 'WorkflowListCtrl'
            }
          },
          ncyBreadcrumb: {
            label: '{{::( breadCrumbQuotes | uppercase )}}'
          },
          permissions: {
            resources: { quotes: ['read'] }
          }
        })

        .state('root.app.simple.quote', {
          url: '/quote/:id',
          views: {
            content: {
              templateUrl: path + 'edit.html',
              controller: 'WorkflowEditCtrl'
            }
          },
          ncyBreadcrumb: {
            parent: 'root.app.simple.quotes',
            label: '{{ breadCrumbQuote | uppercase }}'
          },
          permissions: {
            resources: { quotes: ['read'] }
          }
        })

        .state('root.app.simple.quote.about', {
          url: '/about',
          templateUrl: path + 'tab-about.html',
          controller: 'WorkflowTabCtrl',
          ncyBreadcrumb: {
            label: '{{::( breadCrumbAbout | uppercase )}}'
          },
          permissions: {
            resources: { quotes: ['read'] }
          }
        })

        .state('root.app.simple.quote.projects', {
          url: '/projects',
          abstract: true,
          template: '<div ui-view></div>',
          controller: 'WorkflowTabCtrl',
          ncyBreadcrumb: {
            label: '{{::( breadCrumbProjects | uppercase )}}'
          },
          permissions: {
            resources: { quotes: ['read'] }
          }
        })

        .state('root.app.simple.quote.projects.list', {
          url: '/list',
          templateUrl: path + 'projects-list.html',
          ncyBreadcrumb: {
            label: '{{::( breadCrumbProjects | uppercase )}}'
          },
          permissions: {
            resources: { quotes: ['read'] }
          }
        })

        .state('root.app.simple.quote.projects.detail', {
          url: '/:projectCode',
          abstract: true,
          templateUrl: path + 'project-container.html',
          controller: 'WorkflowProjectDetailCtrl',
          ncyBreadcrumb: {
            parent: 'root.app.simple.quote.projects.list',
            label: '{{::( breadCrumbProject | uppercase )}}'
          },
          permissions: {
            resources: { quotes: ['read'] }
          }
        })

        .state('root.app.simple.quote.projects.detail.data', {
          url: '/data',
          template: '<project-form></project-form>',
          ncyBreadcrumb: {
            parent: 'root.app.simple.quote.projects.list',
            label: '{{::( breadCrumbProject | uppercase )}}'
          },
          permissions: {
            resources: { quotes: ['read'] }
          }
        })

        .state('root.app.simple.quote.projects.detail.tasks', {
          url: '/tasks',
          params: {
            newContributors: null,
            batch: null,
            task: null
          },
          templateUrl: path + 'tab-task.html',
          ncyBreadcrumb: {
            parent: 'root.app.simple.quote.projects.list',
            label: '{{::( breadCrumbProject | uppercase )}}'
          },
          permissions: {
            resources: { quotes: ['read'] }
          }
        })

        .state('root.app.simple.quote.projects.detail.task-detail', {
          url: '/task-detail/:batchIndex/:taskIndex',
          params: {
            batchIndex: null,
            taskIndex: null
          },
          templateUrl: path + 'task-detail.html',
          controller: 'TaskDetailCtrl',
          ncyBreadcrumb: {
            parent: 'root.app.simple.quote.projects.list',
            label: '{{::( breadCrumbProject | uppercase )}}'
          },
          permissions: {
            resources: { quotes: ['read'] }
          }
        })

        .state('root.app.simple.quote.projects.detail.service-create', {
          url: '/service-create',
          templateUrl: path + 'tab-service.html',
          ncyBreadcrumb: {
            parent: 'root.app.simple.quote.projects.detail.data',
            label: '{{::( breadCrumbCreateSrv | uppercase )}}'
          },
          permissions: {
            resources: { quotes: ['read'] }
          }
        })

        .state('root.app.simple.quote.projects.detail.service-detail', {
          url: '/:serviceCode',
          templateUrl: path + 'tab-service.html',
          controller: 'WorkflowServiceDetailCtrl',
          ncyBreadcrumb: {
            parent: 'root.app.simple.quote.projects.detail.data',
            label: '{{::( breadCrumbEditSrv | uppercase )}}'
          },
          permissions: {
            resources: { quotes: ['read'] }
          }
        })

        .state('root.app.simple.quote.billing', {
          url: '/billing',
          templateUrl: path + 'tab-billing.html',
          controller: 'WorkflowTabCtrl',
          ncyBreadcrumb: {
            label: '{{::( breadCrumbBilling | uppercase )}}'
          },
          permissions: {
            resources: { quotes: ['read'] }
          }
        })

        .state('root.app.simple.quote.admin', {
          url: '/admin',
          templateUrl: path + 'tab-doc-admin.html',
          controller: 'WorkflowTabCtrl',
          ncyBreadcrumb: {
            label: '{{::( breadCrumbAdmin | uppercase )}}'
          },
          permissions: {
            resources: { quotes: ['read'] }
          }
        })

        .state('root.app.simple.quote.offer', {
          url: '/offer',
          templateUrl: path + 'tab-offer.html',
          controller: 'WorkflowTabCtrl',
          ncyBreadcrumb: {
            label: '{{::( breadCrumbOffer | uppercase )}}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        })

        .state('root.app.simple.quote.additionalFiles', {
          url: '/additionalFiles',
          templateUrl: path + 'tab-additionals-files.html',
          controller: 'WorkflowTabCtrl',
          ncyBreadcrumb: {
            label: '{{::( breadCrumbAdditionalFiles | uppercase )}}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        });
    }
  ]);
