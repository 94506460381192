angular.module('company').factory('UsedContributorsCrud', [
  'Crud',
  function(Crud) {
    return class UsedContributors extends Crud {
      constructor() {
        super(
          'used-contributors',
          '@@apiUrl' + '/used-contributors',
          {},
          {
            query: {
              method: 'GET',
              isArray: true
            }
          }
        );
      }
    };
  }
]);
