angular.module('pb.base').directive('price', [
  '$filter',
  function($filter) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, ngModelController) {
        ngModelController.$parsers.push(function(data) {
          //convert data from view format to model format
          data = $filter('comma2decimal')(data);
          return data;
        });

        ngModelController.$formatters.push(function(data) {
          //convert data from model format to view format
          data = $filter('decimal2comma')(data);
          return data;
        });
      }
    };
  }
]);
