angular
  .module('company')

  .directive('companySummary', [
    '$filter',
    function($filter) {
      'use strict';

      return {
        restrict: 'E',
        replace: true,
        scope: true,
        templateUrl: 'custom/company/views/partials/companySummary.html',
        controller: [
          '$scope',
          'CompanyStore',
          'LoggedUser',
          function($scope, CompanyStore, LoggedUser) {
            $scope.active = false;
            $scope.company_name = '';
            $scope.billingCompany = false;
            $scope.expired = '';
            $scope.showExpired = LoggedUser.isPM();

            CompanyStore.listenTo(function(company) {
              $scope.active = company ? !!company.active : false;
              $scope.company_name = company ? company.company_name : '';
              $scope.billingCompany = company ? company.billingCompany : false;
              $scope.expired = company ? company.expired : 0;
              $scope.lastZohoSync = company && company.lastZohoSync;
            });

            CompanyStore.addListener('legal_address', function(headquarter) {
              $scope.headquarter = headquarter;
            });
          }
        ]
      };
    }
  ]);
