angular
  .module('leads')

  .config([
    '$stateProvider',

    function($stateProvider) {
      $stateProvider.state('root.app.simple.leads', {
        url: '/leads',
        views: {
          content: {
            template:
              '<leads api-url="apiUrl" token="token" xutype="xutype" dialog="dialog" confirm="confirm"/>',
            controller: 'LeadsCtrl'
          }
        },
        permissions: {
          resources: { leads: ['read'] }
        }
      });
    }
  ]);
