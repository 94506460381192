angular
  .module('pb.core.crud')

  .controller('DeleteBaseCtrl', [
    'Dialog',
    'ErrorsManager',
    '$q',
    '$timeout',
    '$filter',
    '$rootScope',
    '$scope',
    'crud',
    'packageName',
    'dataName',

    function(
      Dialog,
      ErrorsManager,
      $q,
      $timeout,
      $filter,
      $rootScope,
      $scope,
      crud,
      packageName,
      dataName
    ) {
      var dialog = new Dialog();

      packageName = packageName || 'data';
      dataName = dataName || 'data';
      $scope[dataName] = $scope[dataName] || {};

      $scope.enableSuccessNotifications =
        typeof $scope.enableSuccessNotifications === 'boolean'
          ? $scope.enableSuccessNotifications
          : true;
      $scope.enableErrorNotifications =
        typeof $scope.enableErrorNotifications === 'boolean'
          ? $scope.enableErrorNotifications
          : true;

      //Delete document from server
      $scope.processRemoving = function() {
        return crud.delete($scope[dataName]).then(
          function(response) {
            $rootScope.$broadcast('reloadList.' + packageName);

            if ($scope.enableSuccessNotifications) {
              $timeout(function() {
                dialog.success(
                  $filter('translate')('crud.DELETED'),
                  $filter('translate')('crud.DELETED_INFO')
                );
              }, 100);
            }
            return response;
          },
          function(err) {
            if ($scope.enableErrorNotifications) {
              $timeout(function() {
                dialog.error(ErrorsManager.getErrorsAsString(err));
              }, 100);
            }
            return $q.reject(err);
          }
        );
      };
    }
  ]);
