angular
  .module('contributor')

  .directive('servicesFilter', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      scope: {
        services: '=ngModel',
        type: '@',
        title: '@'
      },
      templateUrl: 'custom/contributor/views/partials/servicesFilter.html',
      controller: [
        '$scope',
        '$filter',
        'Dialog',
        function($scope, $filter, Dialog) {
          $scope.services = $scope.services || [];

          var alreadyInsert = function() {
            $scope.services = $scope.services || [];

            for (var i = 0; i < $scope.services.length; i += 1) {
              if ($scope.services[i].task._id === $scope.selectedTask._id) {
                return true;
              }
            }

            return false;
          };

          $scope.add = function() {
            var service;

            if (!$scope.selectedTask) {
              new Dialog().warning(
                $filter('translate')('contributors.SELECT_TASK_MESSAGE')
              );
              return;
            }

            if (alreadyInsert()) {
              new Dialog().warning(
                $filter('translate')('contributors.TASK_ALREADY_INSERT')
              );
              return;
            }

            service = {
              task: $scope.selectedTask,
              combo: {}
            };

            $scope.services.push(service);
            $scope.selectedTask = null;
          };

          $scope.delete = function(index) {
            $scope.services.splice(index, 1);
          };
        }
      ]
    };
  });
