angular
  .module('signup')

  .controller('SignupWizardCtrl', [
    '$scope',
    '$state',
    '$controller',
    '$filter',
    '$translate',
    '$window',
    'ProfileSignupCrud',
    'SignupModel',
    'Confirm',
    'PbWizard',

    function(
      $scope,
      $state,
      $controller,
      $filter,
      $translate,
      $window,
      ProfileSignupCrud,
      SignupModel,
      Confirm,
      PbWizard
    ) {
      var wizard, stepsUrls;
      var confirm = new Confirm();

      $state.go('root.wizard.simple.signup-customer.step1');

      $controller('EditBaseCtrl', {
        $scope: $scope,
        crud: new ProfileSignupCrud(),
        object: SignupModel,
        packageName: 'signup',
        dataName: 'signup',
        formName: null
      });

      $scope.remove = new Function();

      //Wizard
      $scope.steps = 5;
      stepsUrls = [1, 2, 3, 4, 5].map(step => {
        return '^.step' + step;
      });

      function setParams(data) {
        var d = data || {};
        $scope.current = d.step;
        $scope.completed = d.completed;
      }

      function onChangeStep(data) {
        setParams(data);
        $state.go(data.currentStep);
      }

      function onStartFinish(done) {
        confirm.warning($filter('translate')('signup.SEND_REQUEST')).then(done);
      }

      function onFinish(data) {
        setParams(data);
        $scope.signup.profile = $scope.signup.profile || {};
        $scope.signup.profile.complete = true;

        $scope.processSaving().then(function(response) {
          if (response && response._id) {
            $state.go('^.^.^.signup-end');
          }
        });
      }

      wizard = new PbWizard({
        stepsUrls: stepsUrls,
        onChangeStep: onChangeStep,
        onStartFinish: onStartFinish,
        onFinish: onFinish
      });
      //End wizard

      $scope.next = function() {
        wizard.next();
      };

      $scope.back = function() {
        wizard.previous();
      };

      $scope.goTo = function(step) {
        wizard.goTo(step);
      };

      $scope.$on('onGoToStep', function(e, step) {
        if ($scope.signup.company && $scope.signup.company._id && step === 3) {
          return;
        } else {
          wizard.goTo(step);
        }
      });

      $scope.$watch('signup.profile.lang', function(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          $translate.use(newValue);
          localStorage.setItem('@@localStoragePrefix' + 'lang', newValue);
          $window.location.reload();
        }
      });
    }
  ]);
