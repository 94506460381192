angular
  .module('customer')

  .directive('customerBasicInfo', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        customer: '=ngModel',
        save: '&',
        undo: '&'
      },
      templateUrl: 'custom/customer/views/partials/customerBasicInfo.html',
      controller: [
        '$scope',
        'LoggedUser',
        function($scope, LoggedUser) {
          const { lang: currentLang } = LoggedUser.getData() || {};

          $scope.translateTitle = (names = []) => {
            return (names.find(({ lang }) => lang === currentLang) || {}).value;
          };

          $scope.isPM = LoggedUser.isPM();
        }
      ]
    };
  });
