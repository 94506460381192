angular
  .module('pb.file')

  .directive('fileInput', [
    '$timeout',
    function($timeout) {
      return {
        restrict: 'E',
        templateUrl: 'modules/file/views/partials/fileInput.html',
        scope: {
          key: '=',
          path: '<',
          fileName: '@',
          uniqueFileName: '@'
        },
        controller: 'filesController'
      };
    }
  ]);
