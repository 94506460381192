angular
  .module('task', ['pb.core', 'components', 'cfp.loadingBar'])

  .run([
    '$filter',
    'Module',
    function($filter, Module) {
      var module = new Module();

      module.push({
        menu: {
          name: 'contributor_tasks',
          title: $filter('translate')('task.TASKS'),
          icon: 'zmdi zmdi-view-headline',
          link: 'root.app.simple.tasks'
        }
      });
    }
  ]);
