angular
  .module('components')

  .directive('selectCity', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        city: '=',
        district: '=',
        country: '='
      },
      template: [
        '<div class="fg-line" ng-class="showSelect ? \'select\' : \'\'">',
        '<select ng-show="showSelect" class="form-control" name="city" ng-model="city" ng-required="required" required-msg="{{::(\'VALIDATION.REQUIRED\' | translate)}}">',
        '<option value="">-</option>',
        '<option ng-repeat="c in cities track by $index" value="{{c}}">{{c}}</option>',
        '</select>',
        '<input ng-hide="showSelect" type="text" class="form-control input-sm" name="city" ng-model="city" placeholder="{{::(\'form.CITY\' | translate)}}" ng-required="required" ng-maxlength="100" required-msg="{{::(\'VALIDATION.REQUIRED\' | translate)}}" maxlength-msg="{{::(\'VALIDATION.MAX_LENGTH\' | translate:\'{length: 100}\')}}">',
        '</div>'
      ].join(''),
      controller: [
        '$scope',
        '$controller',
        'ItalianCityCrud',
        function($scope, $controller, ItalianCityCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new ItalianCityCrud(),
            packageName: 'cities',
            dataName: 'cities',
            filters: {}
          });
        }
      ],
      link: function(scope, element, attrs) {
        var firstLoading = true;

        if (typeof attrs.required !== 'undefined') {
          scope.required = true;
        }

        scope.showSelect = false;
        scope.cities = [];

        scope.$watch('district', function(newValue, oldValue) {
          if (!firstLoading && (!newValue || newValue === oldValue)) {
            return;
          }

          if (!firstLoading) {
            scope.city = '';
          }

          firstLoading = false;

          if (scope.country && scope.country.code === 'IT') {
            scope.showSelect = true;
            scope
              .loadList({ country_code: scope.country.code })
              .then(function(response) {
                scope.cities = response;
              });
          } else {
            scope.showSelect = false;
            scope.cities = [];
          }
        });
      }
    };
  });
