angular
  .module('quote', ['pb.core', 'components', 'workflow'])

  .run([
    '$filter',
    'Module',
    function($filter, Module) {
      var module = new Module();

      module.push({
        menu: {
          name: 'quotes',
          title: $filter('translate')('quote.QUOTES'),
          icon: 'zmdi zmdi-shopping-cart',
          link: 'root.app.simple.quotes'
        }
      });
    }
  ]);
