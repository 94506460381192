angular
  .module('contributor')

  .directive('contributorBilingualServices', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        contributor: '=ngModel'
      },
      templateUrl:
        'custom/contributor/views/partials/contributorBilingualServices.html'
    };
  });
