angular
  .module('pb.base')

  .controller('SystemCtrl', [
    '$window',
    '$rootScope',
    '$filter',
    'Toast',

    function($window, $rootScope, $filter, Toast) {
      var toast = new Toast();

      $rootScope.system = {
        isMobile: false,
        isMacOs: /Macintosh/i.test(navigator.userAgent),
        online: navigator.onLine
      };

      //Detact Mobile Browser
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        angular.element('html').addClass('ismobile');
        $rootScope.system.isMobile = true;
      }

      //Offline event
      $window.addEventListener(
        'offline',
        function() {
          $rootScope.$apply(function() {
            $rootScope.system.online = false;
          });

          $rootScope.$broadcast('navigator.offline');
          toast.warning(
            $filter('translate')('header.OFFLINE') +
              '. ' +
              $filter('translate')('header.OFFLINE_INFO')
          );
        },
        false
      );

      //Online event
      $window.addEventListener(
        'online',
        function() {
          $rootScope.$apply(function() {
            $rootScope.system.online = true;
          });

          $rootScope.$broadcast('navigator.online');
          toast.success($filter('translate')('header.ONLINE') + '!');
        },
        false
      );
    }
  ]);
