angular
  .module('pb.core.crud')

  .factory('CrudUndoable', [
    '$rootScope',
    '$window',
    '$timeout',
    '$state',
    'Crud',

    function($rootScope, $window, $timeout, $state, Crud) {
      'use strict';

      /**
       * CrudUndoable class
       */
      class CrudUndoable extends Crud {
        constructor($scope, cacheKey, url, params, actions, cacheParams) {
          super(cacheKey, url, params, actions, cacheParams);

          this.data = this.copy = {};
          this.memToState = null;
          this.enGo = false;

          $scope.$on(
            '$stateChangeStart',
            (event, toState, toParams, fromState) => {
              const { name: toStateName = '' } = toState;
              const { name: fromStateName = '' } = fromState;

              const toTaskDetail =
                toStateName.endsWith('.projects.detail.task-detail') &&
                fromStateName.endsWith('.projects.detail.tasks');

              const fromTaskDetail =
                fromStateName.endsWith('.projects.detail.task-detail') &&
                toStateName.endsWith('.projects.detail.tasks');

              const toWizardStep5 =
                fromStateName.endsWith('.step4') &&
                toStateName.endsWith('.step5');

              if (toTaskDetail || fromTaskDetail || toWizardStep5) return;

              if (!angular.equals(this.data, this.copy)) {
                if ('@@debugMode' == 'true') {
                  console.info('The objects are different!');
                  console.info(JSON.stringify(this.data));
                  console.info(JSON.stringify(this.copy));
                }

                this.enGo = false;
                this.memToState = toState.name;
                event.preventDefault();
                $rootScope.$broadcast('saveObjectRequest');
              } else {
                this.memToState = null;
              }
            }
          );
        }

        /**
         * Go to next state
         */
        go() {
          if (this.enGo && this.memToState) {
            $state.go(this.memToState);
            this.memToState = null;
          }
        }

        /**
         * Stop state transition
         */
        stopTransition() {
          this.enGo = false;
          this.memToState = null;
        }

        /**
         * Set data
         * @param data
         * @returns {CrudUndoable}
         */
        set(data) {
          $timeout(() => {
            this.data = data;
            this.copy = angular.copy(data);
          }, 1);

          return this;
        }

        /**
         * Save a copy of data
         * @param newData
         * @returns {{}|*}
         */
        saveCopy(newData) {
          this.data = this.copy = angular.copy(newData);
          this.enGo = true;
          this.go();
          return this.data;
        }

        /**
         * Undo to previous saving
         * @returns {{}|*}
         */
        undo() {
          this.data = angular.copy(this.copy);
          this.enGo = true;
          this.go();
          return this.data;
        }

        get(params) {
          return super.get(params).then(response => {
            this.set(response);
            return response;
          });
        }

        save(data) {
          return super.save(data).then(response => {
            this.saveCopy(response);
            return response;
          });
        }

        update(data) {
          return super.update(data).then(response => {
            this.saveCopy(response);
            return response;
          });
        }

        delete(data) {
          return super.delete(data).then(response => {
            this.saveCopy(null);
            return response;
          });
        }
      }

      return CrudUndoable;
    }
  ]);
