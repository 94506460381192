angular
  .module('pb.base')

  .controller('AppCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    '$controller',
    '$timeout',
    '$filter',
    'Dialog',

    function(
      $rootScope,
      $scope,
      $state,
      $controller,
      $timeout,
      $filter,
      Dialog
    ) {
      var dialog = new Dialog();

      $controller('SystemCtrl', {
        $scope: $scope
      });

      $scope.$on('goToLogin', function() {
        $state.go('root.login');
        removeSplash();
      });

      $scope.$on('goToWizard', function() {
        $state.go('root.wizard');
        removeSplash();
      });

      $scope.$on('goToHome', function() {
        //Go to home page
        //Implement this event where you want the home page
      });

      $scope.$on('err0', function() {
        $state.go('root.0');
      });

      $scope.$on('err401', function() {
        if ($state.current.name === 'root.loginMfa') return;
        $state.go('root.login');
      });

      $scope.$on('err403', function() {
        $state.go('root.403');
      });

      $scope.$on('err404', function() {
        //dialog.warning($filter('translate')('error.404_MSG'));
      });

      $scope.$on('err500', function() {
        dialog.error(
          'Oops... ' + $filter('translate')('error.SOMETHING_WRONG') + '.'
        );
      });

      //Set body classes
      $scope.$on('$stateChangeSuccess', function(event, toState) {
        if (
          angular.isDefined(toState.data) &&
          angular.isDefined(toState.data.bodyClasses)
        ) {
          $scope.bodyClasses = toState.data.bodyClasses;
          return;
        }

        $scope.bodyClasses = '';
      });

      // By default Sidbars are hidden in boxed layout and in wide layout only the right sidebar is hidden.
      this.sidebarToggle = {
        left: false,
        right: false
      };

      //Close sidebar on click
      this.sidebarStat = function(event) {
        if (
          !angular
            .element(event.target)
            .parent()
            .hasClass('active')
        ) {
          this.sidebarToggle.left = false;
        }
      };

      $scope.$on('closeSidebarMenu', () => {
        this.sidebarToggle.left = false;
      });

      // By default template has a boxed layout
      // this.layoutType = localStorage.getItem('@@localStoragePrefix' + 'layout-status');

      function removeSplash() {
        $timeout(function() {
          angular.element('#splash').remove();
        }, 500);
      }

      $scope.$on('onBootstrapComplete', function() {
        removeSplash();
      });
    }
  ]);
