angular
  .module('pb.base')

  .directive('selectGender', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        gender: '='
      },
      template:
        '<select ng-model="gender">' +
        '<option value="">-</option>' +
        '<option value="M">{{::(\'gender.MALE\' | translate)}}</option>' +
        '<option value="F">{{::(\'gender.FEMALE\' | translate)}}</option>' +
        '</select>'
    };
  });
