const environment = '@@sentryEnv';
const dsn = '@@sentryDsn';
const shouldInitSentry =
  environment && dsn && !environment.startsWith('@') && !dsn.startsWith('@');

if (shouldInitSentry) {
  Sentry.init({ dsn, environment });
}

angular.module('pb.core', [
  'angular-loading-bar',
  'ngAnimate',
  'localytics.directives',
  'pascalprecht.translate',
  'pb.core.crud',
  'pb.core.form',
  'pb.core.module',
  'pb.core.notifications',
  'pb.core.utilities',
  'pb.core.user',
  'dcbImgFallback',
  'ngTagsInput',
  'ngSanitize',
  'ui.utils.masks',
  'iso-4217-currency-codes'
]);
