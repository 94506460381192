angular
  .module('order')

  .factory('OrderCountersCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class OrderCountersCrud extends Crud {
        constructor() {
          super(
            '',
            '@@apiUrl' + '/orders-counters' + '/:id',
            { id: '@id' },
            {
              query: {
                method: 'GET',
                isArray: false
              }
            }
          );
        }
      }
      return OrderCountersCrud;
    }
  ]);
