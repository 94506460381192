angular
  .module('contributor')

  .directive('contributorNote', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        contributor: '=ngModel',
        hidePrivacy: '<'
      },
      templateUrl: 'custom/contributor/views/partials/contributorNote.html',
      controller: [
        '$scope',
        '$timeout',
        'S3Downloader',
        '$filter',
        'Confirm',
        'ContributorStore',
        function(
          $scope,
          $timeout,
          S3Downloader,
          $filter,
          Confirm,
          ContributorStore
        ) {
          $scope.$on('imageUploaded', function(event, data) {
            $timeout(function() {
              if ($scope.brochure && $scope.brochure.length > 0) {
                $scope.contributor.brochure = $scope.brochure;
              }
            });
          });

          $scope.delete = function() {
            new Confirm()
              .info($filter('translate')('contributors.DELETE_FILE_MESSAGE'))
              .then(function() {
                $scope.contributor.brochure = undefined;
              });
          };

          $scope.download = function() {
            S3Downloader.download($scope.contributor.brochure).then(function(
              url
            ) {
              window.open(url, '_blank');
            });
          };

          const callback = (event, message, options) => {
            const { type, filesNumber } = options;
            if (type !== 'contributor-brochure') return;
            if (!$scope.contributor.lsp) return;

            $scope.contributor.hasCV = filesNumber > 0;
            ContributorStore.emit('save');
          };

          $scope.$on('onFileDeleted', callback);
          $scope.$on('onFilesUploaded', callback);
        }
      ]
    };
  });
