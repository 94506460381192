angular
  .module('pb.core.auth')

  .factory('Auth', [
    '$q',
    'SaveToken',
    'DeleteToken',
    'GetToken',
    'LSPrefix',

    function($q, SaveToken, DeleteToken, GetToken, LSPrefix) {
      /**
       * Login
       * @param crud
       * @param email
       * @param password
       * @returns {Deferred.promise|*}
       */
      function login(crud, email, password) {
        var deferred = $q.defer();

        crud.save({ email: email, password: password }).then(
          function(response) {
            // SaveToken(response);
            deferred.resolve(response);
          },
          function(err) {
            DeleteToken();
            deferred.reject(err);
          }
        );

        return deferred.promise;
      }

      function loginMfa(crud, email, code) {
        const deferred = $q.defer();

        crud.save({ email, code }).then(
          function(response) {
            SaveToken(response);
            deferred.resolve(response);
          },
          function(err) {
            DeleteToken();
            deferred.reject(err);
          }
        );

        return deferred.promise;
      }

      /**
       * Logout
       * @returns {Deferred.promise|*}
       */
      function logout() {
        var deferred = $q.defer();

        DeleteToken();
        deferred.resolve();

        return deferred.promise;
      }

      /**
       * Forgot password
       * @param crud
       * @param email
       * @returns {Deferred.promise|*}
       */
      function forgotPassword(crud, email) {
        var deferred = $q.defer();

        crud.save({ email: email, appId: '@@appId' }).then(
          function(response) {
            DeleteToken();
            deferred.resolve(response);
          },
          function(err) {
            DeleteToken();
            deferred.reject(err);
          }
        );

        return deferred.promise;
      }

      /**
       * Signup
       * @param crud
       * @param email
       * @param password
       * @returns {Deferred.promise|*}
       */
      function signup(crud, email, password) {
        var deferred = $q.defer();

        crud.save({ email: email, password: password }).then(
          function(response) {
            SaveToken(response);
            deferred.resolve(response);
          },
          function(err) {
            DeleteToken();
            deferred.reject(err);
          }
        );

        return deferred.promise;
      }

      /**
       * Check if user is authenticated
       * @returns {*|boolean}
       */
      function isAuthenticated() {
        var token = GetToken();
        return token && token.length > 0;
      }

      /**
       * Get user id from local storage
       * @returns {*|string}
       */
      function getUserId() {
        var lc = localStorage.getItem(LSPrefix + 'user');
        var sc = sessionStorage.getItem(LSPrefix + 'user');
        return lc || sc || null;
      }

      /**
       * Get user email from local storage
       * @returns {*|string}
       */
      function getUserEmail() {
        var lc = localStorage.getItem(LSPrefix + 'email');
        var sc = sessionStorage.getItem(LSPrefix + 'email');
        return lc || sc || null;
      }

      /**
       * Get user token from local storage
       * @returns {*|string}
       */
      function getUserToken() {
        return GetToken();
      }

      return {
        login: login,
        loginMfa,
        logout: logout,
        forgotPassword: forgotPassword,
        signup: signup,
        isAuthenticated: isAuthenticated,
        getUserId: getUserId,
        getUserEmail: getUserEmail,
        getUserToken: getUserToken
      };
    }
  ]);
