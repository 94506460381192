angular
  .module('company')

  .directive('headquarter', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        headquarter: '=ngModel',
        index: '=',
        deleteRequest: '&delete',
        save: '&',
        undo: '&'
      },
      templateUrl: 'custom/company/views/partials/headquarter.html',
      controller: [
        '$scope',
        '$filter',
        'Confirm',
        function($scope, $filter, Confirm) {
          var confirm = new Confirm();

          $scope.delete = function() {
            confirm
              .warning($filter('translate')('dialog.DELETE_ASK'))
              .then(function() {
                $scope.deleteRequest();
              });
          };
        }
      ]
    };
  });
