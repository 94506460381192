angular
  .module('pb.core.crud')

  .controller('EditBaseCtrl', [
    '$controller',
    '$scope',
    'crud',
    'object',
    'packageName',
    'dataName',
    'formName',

    function(
      $controller,
      $scope,
      crud,
      object,
      packageName,
      dataName,
      formName
    ) {
      packageName = packageName || 'data';
      dataName = dataName || 'data';
      $scope[dataName] = object ? new object() : {};

      //Load document from server
      $controller('LoadBaseCtrl', {
        $scope: $scope,
        crud: crud,
        object: object,
        dataName: dataName
      });

      //Create or update document
      $controller('SaveBaseCtrl', {
        $scope: $scope,
        crud: crud,
        packageName: packageName,
        dataName: dataName,
        formName: formName
      });

      //Delete document from server
      $controller('DeleteBaseCtrl', {
        $scope: $scope,
        crud: crud,
        packageName: packageName,
        dataName: dataName
      });

      //Undo modifications
      $scope.undo = function() {
        if (typeof crud.undo === 'function') {
          $scope[dataName] = crud.undo();
        }
      };

      //Stop state transition
      $scope.stopTransition = function() {
        if (typeof crud.stopTransition === 'function') {
          crud.stopTransition();
        }
      };
    }
  ]);
