angular
  .module('contributor')

  .factory('ContributorModel', function() {
    function ContributorModel() {
      //Contributor default model
      return {
        type: 'contributor',
        birth: {
          district: 'EE'
        }
      };
    }

    return ContributorModel;
  });
