angular
  .module('pb.base')

  .filter('comma2decimal', [
    function() {
      // should be altered to suit your needs
      return function(input) {
        var ret = input
          ? input
              .toString()
              .trim()
              .replace(',', '.')
          : null;
        return parseFloat(ret);
      };
    }
  ])

  .filter('decimal2comma', [
    function() {
      // should be altered to suit your needs
      return function(input) {
        var ret = input ? input.toString().replace('.', ',') : null;

        if (ret) {
          var decArr = ret.split(',');
          if (decArr.length > 1) {
            var dec = decArr[1].length;
            if (dec === 1) {
              ret += '0';
            }
          } //this is to show prices like 12,20 and not 12,2
        }
        return ret;
      };
    }
  ]);
