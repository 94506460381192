angular
  .module('pb.user')

  .factory('MeCrud', [
    '$q',
    'MeApiCrud',
    'ResourceCrud',
    'LoggedUser',
    'SaveUTypeToken',

    function($q, MeApiCrud, ResourceCrud, LoggedUser, SaveUTypeToken) {
      'use strict';

      class MeCrud {
        get(params) {
          var deferred = $q.defer(),
            me = new MeApiCrud();

          me.get(params).then(
            function(response) {
              const { _id: id } = response || {};
              Sentry.configureScope(scope => scope.setUser({ id }));

              if (response && response.registered === false) {
                deferred.resolve(response);
                return;
              }

              SaveUTypeToken(response || {});

              var resource = new ResourceCrud();
              var userObj = LoggedUser.set(response);

              resource.get().then(
                function(resources) {
                  userObj.setResources(resources);
                  deferred.resolve(response);
                },
                function(err) {
                  deferred.reject(err);
                }
              );
            },
            function(err) {
              deferred.reject(err);
            }
          );

          return deferred.promise;
        }
      }

      return MeCrud;
    }
  ]);
