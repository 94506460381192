angular
  .module('pb.core.form')

  .factory('FormValidator', function() {
    /**
     * Validator class
     * @param form
     * @constructor
     */
    var FormValidator = function(form) {
      this.form = form;
    };

    /**
     * Validate function
     * @returns {boolean}
     */
    FormValidator.prototype.validate = function() {
      if (this.form && this.form.$valid) {
        return true;
      }

      if ('@@debugMode' == 'true') {
        console.info('The form is invalid!');
        console.info(this.form);
      }

      this.setFormDirty();
      return false;
    };

    /**
     * Set all form's inputs to dirty
     */
    FormValidator.prototype.setFormDirty = function() {
      this._setDirty(this.form);
    };

    /**
     * Set input dirty (recoursive function)
     * @param form
     * @private
     */
    FormValidator.prototype._setDirty = function(form) {
      angular.forEach(
        form,
        function(input, key) {
          if (input && typeof input.$dirty !== 'undefined') {
            input.$dirty = true;

            //Set subforms dirty
            if (
              key !== '$$parentForm' &&
              typeof input.$modelValue === 'undefined'
            ) {
              this._setDirty(input);
            }
          }
        },
        this
      );
    };

    return FormValidator;
  });
