angular
  .module('contributor')

  .directive('selectTasksChosen', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        task: '=',
        type: '<'
      },
      template:
        '<select chosen ng-model="task" ng-options="t.value for t in tasks track by t._id" />',
      controller: [
        '$scope',
        '$controller',
        'TaskCrud',
        function($scope, $controller, TaskCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new TaskCrud('-list'),
            packageName: 'tasks',
            dataName: 'tasks',
            filters: {}
          });

          const getLangs = type => {
            switch (type) {
              case 'bilingual':
                return 2;
              case 'monolingual':
                return 1;
              default:
                return 0;
            }
          };

          $scope.$watch(
            'task',
            task => {
              if (task && !task._id) delete $scope.task;
            },
            true
          );

          const unwatch = $scope.$watch('type', type => {
            $scope.loadList({ lang_required: getLangs(type) }).then(() => {
              $scope.tasks.unshift({
                _id: '',
                value: '-'
              });
            });
            unwatch();
          });
        }
      ]
    };
  });
