angular
  .module('contributor', ['pb.core', 'company', 'components'])

  .run([
    '$filter',
    'Module',
    function($filter, Module) {
      var module = new Module();

      module.push({
        menu: {
          name: 'contributor',
          title: $filter('translate')('contributors.CONTRIBUTORS'),
          icon: 'zmdi zmdi-accounts-outline',
          link: 'root.app.simple.contributors'
        }
      });
    }
  ]);
