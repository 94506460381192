angular
  .module('contributor')

  .controller('ContributorDocCtrl', [
    '$scope',
    'LoggedUser',
    function($scope, LoggedUser) {
      $scope.isPM = LoggedUser.isPM();

      $scope.$watch('contributor.docAccess', docAccess => {
        if (!docAccess) delete $scope.company;
      });

      $scope.addCompany = () => {
        const { company } = $scope;
        if (!company) return console.log('No company selected');

        $scope.contributor.docCompanies = $scope.contributor.docCompanies || [];
        const isCompanyAlreadyAdded = $scope.contributor.docCompanies.some(
          ({ _id }) => _id === company._id
        );
        if (isCompanyAlreadyAdded) return console.log('Company already added');

        $scope.contributor.docCompanies.push({
          _id: company._id,
          company_name: company.company_name
        });
        $scope.contributor.docCompanies.sort((a, b) =>
          a.company_name > b.company_name ? 1 : -1
        );
        $scope.contributor.docCompanies = $scope.contributor.docCompanies.concat(
          []
        );
      };

      $scope.removeCompany = index => {
        console.log('removeCompany', index);
        $scope.contributor.docCompanies.splice(index, 1);
        $scope.contributor.docCompanies = $scope.contributor.docCompanies.concat(
          []
        );
      };
    }
  ]);
