angular
  .module('pb.user')

  .directive('checkboxResource', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      scope: {
        resource: '=ngModel'
      },
      templateUrl: 'modules/user/views/partials/checkboxResource.html',
      link: function(scope) {
        var actions = ['create', 'update', 'delete'];

        function setResource(resource) {
          angular.forEach(actions, function(value) {
            scope[value] = false;
          });

          angular.forEach(resource, function(value) {
            scope[value] = true;
          });
        }

        if (typeof scope.resource === 'object') {
          setResource(scope.resource);
        }

        scope.$watchCollection('resource', function(newValue, oldValue) {
          if (newValue && newValue != oldValue) {
            setResource(newValue);
          }
        });

        scope.change = function(resource) {
          var value = scope[resource],
            index = scope.resource.indexOf(resource);

          if (value && index < 0) {
            scope.resource.push(resource);
          } else if (!value && index >= 0) {
            scope.resource.splice(index, 1);
          }
        };
      }
    };
  });
