angular
  .module('components')

  .component('note', {
    bindings: {
      data: '=',
      label: '<',
      parentSave: '&save',
      undo: '&'
    },
    templateUrl: 'custom/components/views/partials/note.html',
    controller: [
      function() {
        this.save = () => this.parentSave();
        this.replaceNewLine = source =>
          typeof source === 'string' ? source.replace(/\n/g, '<br>') : source;
      }
    ]
  });
