angular
  .module('company')

  .controller('CompanyProjectTemplatesCtrl', [
    '$scope',
    '$controller',
    '$timeout',
    '$state',
    '$filter',
    'CompanyStore',
    'CompanyTemplateCrud',
    'Confirm',
    'Dialog',
    'Company24hUtils',

    function(
      $scope,
      $controller,
      $timeout,
      $state,
      $filter,
      CompanyStore,
      CompanyTemplateCrud,
      Confirm,
      Dialog,
      Company24hUtils
    ) {
      $scope.languagesSummary = Company24hUtils.generateLanguagesSummary;
      $scope.editable = [];
      $scope.notes = [];
      $scope.titles = [];
      let companyId;

      CompanyStore.listenTo((company = {}) => {
        const { _id, workflowTemplates = [] } = company;
        companyId = _id;
        $scope.workflowTemplates = workflowTemplates;
      });

      $scope.delete = (workflow, index) => {
        const confirm = new Confirm();
        const message = $filter('translate')('company.DELETE_TEMPLATE_PROJECT');
        confirm
          .show(message)
          .then(() => {
            const crud = new CompanyTemplateCrud(companyId);
            return crud.delete(workflow);
          })
          .then(() => {
            $scope.workflowTemplates.splice(index, 1);
          });
      };

      $scope.edit = (workflow, index) => {
        $scope.editable[index] = true;
      };

      $scope.save = (workflow, index) => {
        const crud = new CompanyTemplateCrud(companyId);
        const update = {
          _id: workflow._id,
          title: $scope.titles[index],
          notes: $scope.notes[index]
        };
        crud
          .update(update)
          .then(({ error } = {}) => {
            if (error) return Promise.reject(error);
            workflow.title = update.title;
            workflow.templateNotes = update.notes;
            $scope.editable[index] = false;
          })
          .catch(error => new Dialog().error(error));
      };
    }
  ]);
