angular
  .module('library')

  .factory('LibraryNamesModel', function() {
    function LibraryNamesModel() {
      return {
        code: '',
        name: [
          { value: '', lang: 'it' },
          { value: '', lang: 'en' }
        ]
      };
    }

    return LibraryNamesModel;
  });
