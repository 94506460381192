angular
  .module('task')

  .factory('TaskDoneCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class TaskDoneCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/task-done' + '/:id', { id: '@id' });
        }
      }
      return TaskDoneCrud;
    }
  ]);
