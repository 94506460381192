angular
  .module('workflow')

  .controller('WorkflowProjectDetailCtrl', [
    '$scope',
    '$timeout',
    '$state',
    '$filter',
    '$stateParams',
    'WorkflowStore',
    'ProjectStore',
    'UserLang',

    function(
      $scope,
      $timeout,
      $state,
      $filter,
      $stateParams,
      WorkflowStore,
      ProjectStore,
      UserLang
    ) {
      $scope.userLang = UserLang.get();

      var unwatch = $scope.$watch('$parent.workflow.company', function(
        newValue
      ) {
        if (newValue) {
          $scope.company = newValue;
          unwatch();
        }
      });

      $scope.breadCrumbProject = $stateParams.projectCode;

      $scope.title = $stateParams.projectCode;

      WorkflowStore.addProjectStore($stateParams.projectCode);

      ProjectStore.clear().listenTo(function(project) {
        if (
          project.delivery_date &&
          project.delivery_date != '1970-01-01T00:00:00.000Z'
        ) {
          $scope.title +=
            ' - ' +
            $filter('uppercase')($filter('translate')('form.DELIVERY_DATE')) +
            ': ' +
            $filter('date')(project.delivery_date, 'dd/MM/yy');
        }

        $scope.title += project.delivered
          ? ' - ' + $filter('translate')('status.delivered')
          : '';
      });

      $scope.undo = function() {
        $scope.performUndo();

        $timeout(function() {
          $state.go('^.^.list');
        });
      };
    }
  ]);
