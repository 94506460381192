angular
  .module('components')

  .directive('detailTooltip', function() {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      require: '^ngModel',
      scope: {
        list: '=ngModel'
      },
      template:
        '<span tooltip-placement="left" tooltip-html="formatted" tooltip-trigger="mouseenter" ng-transclude></span>',
      controller: [
        '$scope',
        '$sce',
        function($scope, $sce) {
          var MAX_LENGTH = 50;
          $scope.$watch('list', function(newValue) {
            var split = [];
            var joined = '';
            if (newValue) {
              split = newValue.split(' - ');
              split = split.filter(function(element) {
                return element;
              });
              split.forEach(function(line, index, split) {
                if (line.length > MAX_LENGTH) {
                  split[index] = line.substr(0, MAX_LENGTH);
                  split[index] += '.';
                }
              });
              joined = split.join(' - ');
            }
            $scope.formatted = joined
              ? $sce.trustAsHtml(joined.replace(/ - /g, '<br>'))
              : '';
          });
        }
      ]
    };
  });
