angular
  .module('contributor')

  .directive('qualificationDate', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        rating: '=ngModel'
      },
      templateUrl: 'custom/contributor/views/partials/qualificationDate.html',
      controller: [
        '$scope',
        function($scope) {
          $scope.$on('changeQualified', function(e, value) {
            if (value) {
              $scope.rating.qualified_date = new Date();
            }
          });
        }
      ]
    };
  });
