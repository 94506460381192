angular
  .module('pb.base')

  .directive('listBody', function() {
    return {
      restrict: 'E',
      transclude: true,
      template: '<div class="lv-body" ng-transclude></div>',
      scope: false
    };
  });
