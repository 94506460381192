angular
  .module('home')

  .config([
    '$stateProvider',
    '$urlRouterProvider',

    function($stateProvider, $urlRouterProvider) {
      var path = 'custom/home/views/';

      $urlRouterProvider.otherwise('/app/p/home');

      $stateProvider.state('root.app.simple.home', {
        url: '/home',
        views: {
          header: {
            templateUrl: path + 'header.html',
            controller: 'HomeCtrl'
          },
          content: {
            templateUrl: path + 'home.html',
            controller: 'HomeCtrl'
          }
        }
      });

      /*.state('root.app.simple.home.setRange', {
            url: '/setRange',
            templateUrl: path + 'partials/setRange.html',
            controller: 'HomeCtrl'
        });*/
    }
  ]);
