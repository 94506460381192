angular
  .module('workflow')

  .factory('ZohoWorkflowBulkSync', [
    'Crud',

    function(Crud) {
      'use strict';

      class ZohoWorkflowBulkSync extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/deals-bulk-sync', {});
        }
      }

      return ZohoWorkflowBulkSync;
    }
  ]);
