angular
  .module('contributor')

  .directive('contributorRatingsChart', function() {
    return {
      restrict: 'E',
      scope: {
        macrosector: '<',
        ratings: '<',
        title: '@'
      },
      template:
        '<line-chart data-lines="data" data-title="{{title}}" ng-if="show"></line-chart>',
      controller: [
        '$scope',
        function($scope) {
          $scope.show = false;
          $scope.data = [];

          function setData() {
            $scope.show = false;
            $scope.data = [];

            if (!$scope.ratings || !$scope.macrosector) {
              return;
            }

            angular.forEach($scope.ratings, function(rating) {
              if (rating.macrosector._id === $scope.macrosector._id) {
                let votes = [];

                angular.forEach(rating.votes, function(vote, i) {
                  votes.push([i + 1, vote ? vote.vote || 0 : 0]);
                });

                if (votes.length > 1) {
                  $scope.show = true;
                }

                $scope.data.push({
                  label: $scope.macrosector.value,
                  data: votes
                });
              }
            });
          }

          $scope.$watchCollection('ratings', setData);
        }
      ]
    };
  });
