angular
  .module('workflow')

  .factory('WorkflowInvoicedCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class WorkflowInvoicedCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/workflow-invoiced' + '/:id', {
            id: '@id'
          });
        }
      }
      return WorkflowInvoicedCrud;
    }
  ]);
