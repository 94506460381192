angular
  .module('components')

  .controller('filterController', [
    '$scope',
    '$timeout',
    'LSPrefix',
    function($scope, $timeout, LSPrefix) {
      var storageName = LSPrefix + $scope.prefix + '.filters';

      $scope.filtersModel =
        $scope.filtersModel ||
        JSON.parse(sessionStorage.getItem(storageName) || '{}');

      $scope.search = function() {
        sessionStorage.setItem(
          storageName,
          JSON.stringify($scope.filtersModel)
        );
        $scope.performSearch();
        try {
          $scope.notifyFilter({
            show: !!Object.keys($scope.filtersModel).length
          });
        } catch (e) {}
      };

      $scope.clear = function() {
        $scope.reset();
        sessionStorage.removeItem(storageName);
        $timeout(() => $scope.search());
      };
    }
  ]);
