'use strict';

angular
  .module('workflow')

  .factory('TradosRangesAssets', function() {
    const ranges = [
      {
        name: 'PerfectMatch',
        price_range: null,
        quantity: null
      },
      {
        name: 'Context Match',
        price_range: null,
        quantity: null
      },
      {
        name: 'Repetitions',
        trados_names: ['Repetitions', 'Ripetizioni'],
        price_range: null,
        quantity: null
      },
      {
        name: 'Cross-file Repetitions',
        trados_names: ['Cross-file Repetitions', 'Ripetizioni tra file'],
        price_range: null,
        quantity: null
      },
      {
        name: '100%',
        price_range: null,
        quantity: null
      },
      {
        name: '95% - 99%',
        price_range: null,
        quantity: null
      },
      {
        name: '85% - 94%',
        price_range: null,
        quantity: null
      },
      {
        name: '75% - 84%',
        price_range: null,
        quantity: null
      },
      {
        name: '50% - 74%',
        price_range: null,
        quantity: null
      },
      {
        name: 'New',
        trados_names: ['New', 'New/AT', 'Nuovi/AdaptiveMT'],
        price_range: null,
        quantity: null
      }
    ];

    const price_ranges = [1, 2, 3, 4, 5];

    return {
      ranges,
      price_ranges
    };
  });
