angular
  .module('signup')

  .controller('SignupWizardContributorCtrl', [
    '$scope',
    '$state',
    '$controller',
    '$timeout',
    '$filter',
    '$translate',
    '$window',
    'LoggedUser',
    'UserCrud',
    'SignupModelContributor',
    'Confirm',
    'PbWizard',
    'SaveUTypeToken',
    'GetUTypeToken',

    function(
      $scope,
      $state,
      $controller,
      $timeout,
      $filter,
      $translate,
      $window,
      LoggedUser,
      UserCrud,
      SignupModelContributor,
      Confirm,
      PbWizard,
      SaveUTypeToken,
      GetUTypeToken
    ) {
      var wizard, stepsUrls;
      var confirm = new Confirm();
      var user = LoggedUser.get().get();

      var wizardBaseState = 'root.wizard.simple.signup-contributor';

      $controller('EditBaseCtrl', {
        $scope: $scope,
        crud: new UserCrud($scope),
        object: SignupModelContributor,
        packageName: 'signup',
        dataName: 'signup',
        formName: null
      });

      //Wizard
      $scope.steps = 9;
      stepsUrls = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(step => {
        return wizardBaseState + '.step' + step;
      });

      function setParams(data) {
        var d = data || {};

        Object.assign($scope.signup, {
          step: d.step,
          done: d.completed,
          percentage: d.percentage,
          complete: d.finish
        });
      }

      function onNextStep(data) {
        setParams(data);

        $scope.processSaving().then(
          response => {
            if (!GetUTypeToken()) {
              const { xutype } = response || {};
              xutype && SaveUTypeToken(response);
            }

            $state.go(data.currentStep);
          },
          function() {
            wizard.cancel();
          }
        );
      }

      function onPreviousStep(data) {
        setParams(data);
        $state.go(data.currentStep);
      }

      function onStartFinish(done) {
        confirm.warning($filter('translate')('signup.SEND_REQUEST')).then(done);
      }

      function onFinish(data) {
        setParams(data);

        $scope.processSaving().then(function() {
          $state.go('^.^.^.signup-end');
        });
      }

      wizard = new PbWizard({
        stepsUrls: stepsUrls,
        onNextStep: onNextStep,
        onPreviousStep: onPreviousStep,
        onStartFinish: onStartFinish,
        onFinish: onFinish
      });
      //End wizard

      $scope.next = function() {
        wizard.next();
      };

      $scope.back = function() {
        wizard.previous();
      };

      $scope.goTo = function(step) {
        wizard.goTo(step);
      };

      $scope.$on('onGoToStep', function(e, step) {
        if (!$scope.signup.lsp && step === 2) {
          return;
        } else {
          wizard.goTo(step);
        }
      });

      $scope.save = function() {
        $scope.processSaving();
      };

      function goToStep() {
        if (user && user._id) {
          $scope.processLoading({ id: user._id }).then(
            function(response) {
              let doneStep = response ? response.done || 1 : 1;
              let stepToGo = doneStep === 2 ? doneStep - 1 : doneStep;

              wizard.completed = parseInt(doneStep);

              if (stepToGo === 1) {
                $state.go(wizardBaseState + '.step1');
              } else {
                wizard.goTo(stepToGo + 1);
              }
            },
            function() {
              $state.go(wizardBaseState + '.step1');
            }
          );
        } else {
          $state.go(wizardBaseState + '.step1');
        }
      }

      $timeout(goToStep, 100);

      $scope.$watch('signup.lang', function(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          $translate.use(newValue);
          localStorage.setItem('@@localStoragePrefix' + 'lang', newValue);
          $window.location.reload();
        }
      });
    }
  ]);
