angular.module('leads', ['pb.core']).run([
  '$filter',
  'Module',
  function($filter, Module) {
    new Module().push({
      menu: {
        name: 'leads',
        title: $filter('translate')('leads.TITLE'),
        icon: 'zmdi zmdi-assignment-account',
        link: 'root.app.simple.leads'
      }
    });
  }
]);
