angular
  .module('company')

  .controller('CompanyUsersCtrl', [
    '$scope',
    '$controller',
    '$timeout',
    'CompanyStore',
    'CompanyUsersCrud',

    function($scope, $controller, $timeout, CompanyStore, CompanyUsersCrud) {
      //On change listener
      CompanyStore.listenTo(function(data) {
        $scope.queryParams = { id: data._id };
      });

      $controller('DataTableCtrl', {
        $scope: $scope,
        crud: new CompanyUsersCrud(),
        packageName: 'companyUsers',
        tableParams: 'userList',
        filters: {
          sort: {
            last_name: 1,
            first_name: 1
          }
        },
        parser: null
      });
    }
  ]);
