angular
  .module('pb.base')

  .directive('editTableActions', function() {
    return {
      restrict: 'EA',
      require: '^ngModel',
      replace: true,
      templateUrl: 'modules/base/views/partials/editTableActions.html',
      scope: {
        data: '=ngModel',
        deleteItem: '&delete',
        hideDelete: '@'
      },
      link: function(scope, element, attrs) {
        scope.enable = attrs.enable !== 'false';
      }
    };
  });
