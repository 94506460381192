angular
  .module('contributor')

  .factory('ContributorSearchCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class ContributorSearchCrud extends Crud {
        constructor() {
          super(
            'contributors',
            '@@apiUrl' + '/contributors-search',
            {},
            {
              query: {
                method: 'POST',
                isArray: false
              },
              get: {
                method: 'POST',
                isArray: true
              }
            }
          );
        }
      }

      return ContributorSearchCrud;
    }
  ]);
