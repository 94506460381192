angular
  .module('pb.base')

  .factory('BootstrapNoAuth', [
    '$rootScope',

    function($rootScope) {
      return function() {
        $rootScope.$broadcast('onBootstrapComplete');
      };
    }
  ]);
