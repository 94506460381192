angular
  .module('contributor')

  .directive('selectConnection', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        connection: '='
      },
      template:
        '<select ng-model="connection" ng-options="c as (c.name[userLang] || c.value) for c in connections track by c._id">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'ConnectionCrud',
        'UserLang',
        function($scope, $controller, ConnectionCrud, UserLang) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new ConnectionCrud('-list'),
            packageName: 'contributor',
            dataName: 'connections',
            filters: {}
          });

          $scope.userLang = UserLang.get();
          $scope.loadList();
        }
      ]
    };
  });
