angular
  .module('quote')

  .factory('QuoteApproveRequest', [
    'Crud',

    function(Crud) {
      'use strict';

      class QuoteApproveRequest extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/quote-approve-request/:id', { id: '@id' });
        }
      }

      return QuoteApproveRequest;
    }
  ]);
