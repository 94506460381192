angular
  .module('statistics')

  .config([
    '$stateProvider',

    function($stateProvider) {
      $stateProvider.state('root.app.simple.statistics', {
        url: '/statistics',
        views: {
          content: {
            template:
              '<statistics api-url="apiUrl" token="token" xutype="xutype" />',
            controller: 'StatisticsCtrl'
          }
        },
        permissions: {
          resources: { statistics: ['read'] }
        }
      });
    }
  ]);
