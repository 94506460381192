angular
  .module('pb.core.utilities')

  .factory('Utilities', [
    '$filter',
    '$translate',

    function($filter, $translate) {
      function currentYear() {
        return new Date().getFullYear();
      }

      function getDate(datetime, minuteOffset) {
        //Remove time from date (and add an offset in minutes)
        var tempDate = new Date(datetime);
        tempDate = new Date(
          tempDate.getFullYear(),
          tempDate.getMonth(),
          tempDate.getDate()
        );
        tempDate.setMinutes(
          tempDate.getMinutes() -
            tempDate.getTimezoneOffset() +
            (minuteOffset || 0)
        );
        return new Date(tempDate);
      }

      function getDateNoon(datetime) {
        return this.getDate(datetime || Date.now(), 720);
      }

      function getLang() {
        return $translate.proposedLanguage() || $translate.use();
      }

      function sumDays(days, date) {
        var d = date ? new Date(date) : new Date();
        d.setDate(d.getDate() + days);
        return d;
      }

      function sumMonth(months, date) {
        var d = date ? new Date(date) : new Date();
        d.setMonth(d.getMonth() + months);
        return d;
      }

      function getMonthDays(month, year) {
        var i,
          day,
          tempMonth,
          paramMonth,
          arrMonth = [];

        day = new Date(year + '-' + month + '-01');

        paramMonth = parseInt(month);

        for (i = 1; i <= 31; i++) {
          tempMonth = parseInt($filter('date')(day, 'MM'));

          if (i <= 28 || tempMonth == paramMonth) {
            arrMonth.push({});
          }

          day = sumDays(1, day);
        }

        return arrMonth;
      }

      function arrayToJson(array, field) {
        var err = false;
        var temp = {};

        angular.forEach(array, function(value) {
          if (!field || !value[field]) {
            err = true;
          } else if (!err) {
            var val = value[field];
            temp[val] = value;
          }
        });

        return !err ? temp : {};
      }

      function findWhere(array, properties) {
        if (!angular.isArray(array) || array.length == 0) return {};

        var found = false;
        var temp = {};

        angular.forEach(array, function(value) {
          if (!found) {
            angular.forEach(properties, function(prop, key) {
              if (!found && prop === value[key]) {
                found = true;
                temp = value;
              }
            });
          }
        });

        return temp;
      }

      function pad(str, max) {
        str = str.toString();
        return str.length < max ? pad('0' + str, max) : str;
      }

      return {
        currentDate: new Date(),
        currentYear: currentYear,
        getDate: getDate,
        getDateNoon: getDateNoon,
        getLang: getLang,
        sumDays: sumDays,
        sumMonth: sumMonth,
        getMonthDays: getMonthDays,
        arrayToJson: arrayToJson,
        findWhere: findWhere,
        pad: pad
      };
    }
  ]);
