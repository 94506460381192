angular
  .module('home')

  .directive('ordersPieChart', [
    '$controller',
    '$filter',
    'Colors',
    'OrderCountersCrud',
    '$state',
    function($controller, $filter, Colors, OrderCountersCrud, $state) {
      return {
        restrict: 'E',
        scope: false,
        templateUrl: 'custom/home/views/partials/ordersPieChart.html',
        link: function(scope) {
          $controller('LoadBaseCtrl', {
            $scope: scope,
            crud: new OrderCountersCrud(),
            object: null,
            dataName: 'data'
          });

          scope.goToOrders = () => $state.go('^.orders');

          scope.noOrders = true;
          scope.oCounters = [
            {
              data: 1,
              color: '#e3e3e3',
              label: ''
            }
          ];

          scope.processLoading().then(function(response) {
            var processedCounters = [];

            var rawCounters = response && response.counters;

            const colors = Colors.workflow.status;

            var capitalizeFirstLetter = function(string) {
              return string.charAt(0).toUpperCase() + string.slice(1);
            };

            /* Check if there are no orders, translate labels and add proper colors  */
            var statusName;
            for (var key in rawCounters) {
              if (rawCounters[key]) {
                scope.noOrders = false;
              }
              statusName = 'status.' + key;
              processedCounters.push({
                data: rawCounters[key],
                color: colors[key],
                label:
                  capitalizeFirstLetter($filter('translate')(statusName)) +
                  ' (' +
                  rawCounters[key] +
                  ')'
              });
            }

            scope.oCounters = scope.noOrders
              ? scope.oCounters
              : processedCounters;
          });
        }
      };
    }
  ]);
