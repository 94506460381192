angular
  .module('library')

  .controller('LibraryTaskCreateCtrl', [
    '$scope',
    '$controller',
    '$stateParams',
    'TaskCrud',
    'TaskModel',
    '$filter',
    '$state',
    'ValueLang',

    function(
      $scope,
      $controller,
      $stateParams,
      TaskCrud,
      TaskModel,
      $filter,
      $state,
      ValueLang
    ) {
      $controller('SaveBaseCtrl', {
        $scope: $scope,
        crud: new TaskCrud(),
        packageName: 'tasks',
        dataName: 'task',
        formName: 'taskForm'
      });

      $scope.task = new TaskModel();

      $scope.name_it = ValueLang($scope.task, 'name', 'it');
      $scope.name_en = ValueLang($scope.task, 'name', 'en');
      $scope.desc_it = ValueLang($scope.task, 'description', 'it');
      $scope.desc_en = ValueLang($scope.task, 'description', 'en');
      $scope.abbr_it = ValueLang($scope.task, 'abbreviation', 'it');
      $scope.abbr_en = ValueLang($scope.task, 'abbreviation', 'en');

      $scope.save = function() {
        $scope.processSaving().then(function() {
          $state.go('root.app.simple.libraries', { table: 'tasks' });
        });
      };
    }
  ]);
