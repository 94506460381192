angular
  .module('pb.base')

  .directive('chosenCountry', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        country: '='
      },
      template: `<select chosen="{'placeholder_text_single': ('service_detail.SELECT_SINGLE_COUNTRY' | translate)}"
                                   ng-model="country" ng-options="c.value for c in countries track by c._id" />`,
      controller: [
        '$scope',
        '$controller',
        'CountryCrud',
        function($scope, $controller, CountryCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new CountryCrud('-list'),
            packageName: 'countries',
            dataName: 'countries',
            filters: {}
          });

          $scope.loadList();
        }
      ]
    };
  });
