angular
  .module('contributor')

  .factory('ContributorActivation', [
    'Crud',

    function(Crud) {
      'use strict';

      class ContributorActivation extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/activeprofile/:id', { id: '@id' });
        }
      }

      return ContributorActivation;
    }
  ]);
