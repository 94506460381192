angular
  .module('workflow')

  .factory('SendOfferCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class SendOfferCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/send-offer', {}, {});
        }
      }
      return SendOfferCrud;
    }
  ]);
