angular
  .module('components')

  .factory('PdfCrudUndoable', [
    'CrudUndoable',

    function(CrudUndoable) {
      'use strict';

      class PdfCrudUndoable extends CrudUndoable {
        constructor($scope) {
          super(
            $scope,
            null,
            '@@apiUrl' + '/pdf/:id',
            { id: '@id' },
            {
              query: {
                method: 'GET',
                isArray: true
              }
            }
          );
        }
      }

      return PdfCrudUndoable;
    }
  ]);
