angular
  .module('customer')

  .directive('customerBillingCompanies', function() {
    return {
      restrict: 'E',
      scope: {
        companyId: '<'
      },
      template: `<billing-companies ng-model="company" data-save="save()"></billing-companies>`,
      controller: [
        '$scope',
        '$controller',
        'CompanyCrud',
        'CompanyModel',
        function($scope, $controller, CompanyCrud, CompanyModel) {
          $controller('EditBaseCtrl', {
            $scope: $scope,
            crud: new CompanyCrud($scope),
            object: CompanyModel,
            packageName: 'company',
            dataName: 'company',
            formName: 'companyForm'
          });

          $scope.save = () => $scope.processSaving();

          $scope.$watch('companyId', id => {
            id && $scope.processLoading({ id: id });
          });
        }
      ]
    };
  });
