angular.module('workflow').factory('SetTaskPrices', [
  'CostsSummarySrv',

  function(CostsSummarySrv) {
    return task => {
      const { unitPrice, contributors = [], quantity } = task || {};
      task.unitPrice =
        unitPrice || unitPrice === 0
          ? unitPrice
          : CostsSummarySrv.searchPrice(task);
      task.amount = task.unitPrice * quantity;

      if (!contributors.length) return;

      if (contributors.length === 1) {
        task.contributorCost = contributors[0].price;
      } else {
        let contributor = contributors.find(
          ({ task_status }) => task_status === 'confirmed'
        );
        if (!contributor)
          contributor = contributors.find(
            ({ task_status }) => task_status === 'approved'
          );
        if (!contributor)
          contributor = contributors.find(
            ({ task_status }) => task_status === 'waiting'
          );
        if (contributor && contributor.price)
          task.contributorCost = contributor.price;
      }
    };
  }
]);
