angular
  .module('contributor')

  .directive('selectUType', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        type: '='
      },
      template:
        '<select ng-model="type" ng-options="key as value for (key, value) in types">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$filter',
        function($scope, $filter) {
          $scope.types = {
            contributor: $filter('translate')('contributors.utype.CONTRIBUTOR'),
            hostess: $filter('translate')('contributors.utype.HOSTESS'),
            supplier: $filter('translate')('contributors.utype.SUPPLIER'),
            dtp: $filter('translate')('contributors.utype.DTP')
          };
        }
      ]
    };
  });
