angular
  .module('contributor')

  .directive('selectIncome', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        income: '='
      },
      template:
        '<select ng-model="income" ng-options="i as (i.name[userLang] || i.value) for i in incomes track by i._id">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'IncomeCrud',
        'UserLang',
        function($scope, $controller, IncomeCrud, UserLang) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new IncomeCrud('-list'),
            packageName: 'contributor',
            dataName: 'incomes',
            filters: {}
          });

          $scope.loadList();
          $scope.userLang = UserLang.get();
        }
      ]
    };
  });
