angular
  .module('contributor')

  .factory('IncomeCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class IncomeCrud extends Crud {
        constructor(list) {
          super(
            'income' + (list || ''),
            '@@apiUrl' + '/incomes' + (list || '') + '/:id',
            { id: '@id' },
            {
              query: {
                method: 'GET',
                isArray: true
              }
            }
          );
        }
      }

      return IncomeCrud;
    }
  ]);
