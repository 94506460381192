angular
  .module('company')

  .directive('billingCompanies', function() {
    'use strict';
    return {
      restrict: 'E',
      require: '^ngModel',
      scope: {
        company: '=ngModel',
        save: '&',
        readOnly: '<'
      },
      templateUrl: 'custom/company/views/partials/billingCompanies.html',
      controller: [
        '$scope',
        '$modal',
        '$filter',
        'Confirm',
        'Dialog',
        'CompanyCrud',
        'BillingCompanyCreator',
        function(
          $scope,
          $modal,
          $filter,
          Confirm,
          Dialog,
          CompanyCrud,
          BillingCompanyCreator
        ) {
          var modalInstance,
            confirm = new Confirm(),
            dialog = new Dialog(),
            billingCompanyCrud = new CompanyCrud($scope);

          function openModal() {
            $scope.billingComp = {};

            modalInstance = $modal.open({
              animation: true,
              templateUrl:
                'custom/company/views/partials/modalFormBilling.html',
              controller: 'ModalFormBillingCtrl',
              scope: $scope,
              size: 'lg'
            });
          }

          $scope.addBillingAddress = function() {
            openModal();

            modalInstance.result.then(function() {
              BillingCompanyCreator.save(billingCompanyCrud, $scope.billingComp)
                .then(function(result) {
                  if (!result || !result._id) {
                    dialog.error($filter('translate')('ERROR'));
                    return;
                  }

                  $scope.company.billingCompanies =
                    $scope.company.billingCompanies || [];
                  // $scope.company.billingCompanies.push(result._id);
                  $scope.company.billingCompanies.push(result);
                  $scope.save();
                })
                .catch(err => {
                  Sentry.captureException(err);
                  const { errors = [] } = err || {};
                  const { msg } = errors[0] || {};
                  msg && dialog.error(msg);
                });
            });
          };

          $scope.delete = function(index) {
            confirm
              .info($filter('translate')('dialog.DELETE_ASK'))
              .then(function() {
                if ($scope.company && $scope.company.billingCompanies) {
                  $scope.company.billingCompanies.splice(index, 1);
                  $scope.save();
                }
              });
          };
        }
      ]
    };
  });
