angular
  .module('company')

  .directive('selectDocType', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        type: '='
      },
      template:
        '<select ng-model="type" ng-options="t.description for t in types track by $index">' +
        '<option value="">-</option>' +
        '</select>',
      link: function(scope) {
        scope.types = [];
      }
    };
  });
