angular
  .module('company')

  .factory('CompanyUsersCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class CompanyUsersCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/company-profiles/:id', { id: '@id' });
        }
      }

      return CompanyUsersCrud;
    }
  ]);
