angular
  .module('company')

  .directive('companyDocumentalLink', [
    function() {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: true,
        template:
          '<a href="/app/p/docs/c/{{companyId}}/" target="_blank" class="pv-follow-btn btn-alert" ng-transclude></a>',
        controller: [
          '$scope',
          'CompanyStore',
          function($scope, CompanyStore) {
            CompanyStore.listenTo(function(company) {
              $scope.companyId = company ? company._id : null;
            });
          }
        ]
      };
    }
  ]);
