angular
  .module('workflow')

  .directive('servicesList', function() {
    return {
      restrict: 'A',
      scope: {
        list: '=',
        services: '<'
      },
      controller: [
        '$scope',
        function($scope) {
          $scope.$watchCollection('services', function(services) {
            services = services || [];

            $scope.list = services
              .map(function(service) {
                return service && service.service && service.service.value;
              })
              .sort()
              .join(' - ');
          });
        }
      ]
    };
  });
