angular
  .module('pb.base')

  // DEPRECATO: sostituito con link diretto al sito di Landoor.
  // Il contenuto di questo è obsoleto e non va usato.
  .directive('slaIt', [
    function() {
      return {
        restrict: 'E',
        replace: true,
        templateUrl: 'modules/base/views/partials/slaIt.html'
      };
    }
  ]);
