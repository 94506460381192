angular
  .module('company')

  .factory('CompaniesAssociatedCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class CompaniesAssociatedCrud extends Crud {
        constructor() {
          super(
            null,
            '@@apiUrl' + '/companies-associated/:id',
            { id: '@id' },
            {
              get: {
                method: 'GET',
                isArray: true
              }
            }
          );
        }
      }

      return CompaniesAssociatedCrud;
    }
  ]);
