angular
  .module('contributor')

  .controller('SpecializationCtrl', [
    '$scope',
    '$filter',
    'Dialog',
    'UserLang',
    function($scope, $filter, Dialog, UserLang) {
      $scope.specializations = $scope.specializations || [];

      const unwatchSpecializations = $scope.$watch(
        'specializations',
        specializations => {
          if (!Array.isArray(specializations) || !specializations.length)
            return;
          specializations.forEach(specialization => {
            if (specialization) specialization.canCollapse = true;
          });
          unwatchSpecializations();
        }
      );

      $scope.userLang = UserLang.get();

      const alreadyInsert = function() {
        if (!Array.isArray($scope.specializations)) {
          return false;
        }

        for (let i = 0; i < $scope.specializations.length; i += 1) {
          if (
            $scope.specializations[i].macrosector._id ===
            $scope.selectedMacrosector._id
          ) {
            return true;
          }
        }

        return false;
      };

      $scope.add = function() {
        let specialization;

        if (!$scope.selectedMacrosector) {
          new Dialog().warning(
            $filter('translate')('contributors.SELECT_SECTOR_MESSAGE')
          );
          return;
        }

        if (alreadyInsert()) {
          new Dialog().warning(
            $filter('translate')('contributors.SECTOR_ALREADY_INSERT')
          );
          return;
        }

        const rating = $scope.qualified
          ? {
              status: 'approved',
              qualified: true,
              active: true
            }
          : { status: 'potential' };

        specialization = {
          macrosector: $scope.selectedMacrosector,
          sectors: [],
          documents: [],
          rating
        };

        $scope.specializations = $scope.specializations || [];
        $scope.specializations.unshift(specialization);
        $scope.selectedMacrosector = null;
      };

      $scope.delete = function(index) {
        $scope.specializations.splice(index, 1);
      };

      $scope.canCollapse = function(spec) {
        spec.canCollapse = true;
      };
    }
  ]);
