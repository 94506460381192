angular
  .module('workflow')

  .factory('WorkflowModel', function() {
    function WorkflowModel() {
      return {
        quote: {
          status: ''
        },
        order: {
          status: ''
        },
        step: 1,
        billing: {},
        project_manager: '',
        account_manager: '',
        marketing_manager: '',
        company: '',
        customer: '',
        projects: []
      };
    }

    return WorkflowModel;
  });
