angular
  .module('pb.base')

  .directive('selectMultipleFormats', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        formats: '='
      },
      template: `
        <select chosen="{'placeholder_text_multiple': ('service_detail.SELECT_MULTIPLE_FORMAT' | translate)}"
          ng-model="formats" multiple ng-options="i for i in items">
          <option value=""></option>
        </select>
        `,
      controller: [
        '$scope',
        '$controller',
        'FormatsCrud',
        function($scope, $controller, FormatsCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new FormatsCrud(),
            packageName: 'formats',
            dataName: 'items',
            filters: {}
          });

          $scope.loadList();
        }
      ]
    };
  });
