angular
  .module('pb.core.form')

  .directive('passwordVerify', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        passwordVerify: '='
      },
      link: function(scope, element, attrs, ctrl) {
        scope.$watch(
          function() {
            var combined;

            if (scope.passwordVerify || ctrl.$viewValue) {
              combined = scope.passwordVerify + '_' + ctrl.$viewValue;
            }

            return combined;
          },
          function(value) {
            var origin = scope.passwordVerify;

            if (origin !== ctrl.$viewValue) {
              ctrl.$setValidity('passwordVerify', false);
            } else {
              ctrl.$setValidity('passwordVerify', true);
            }

            return ctrl.$viewValue;
          }
        );
      }
    };
  });
