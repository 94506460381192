angular
  .module('company')

  .directive('workflowSelectCustomer', [
    'WorkflowCustomerModel',
    function(WorkflowCustomerModel) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          customer: '=',
          company: '<',
          idOnly: '<'
        },
        template: `<select
             chosen
             placeholder-text-single="::('customers.CUSTOMER' | translate)"
             ng-model="user"
             ng-options="u._id as (u.first_name + ' ' + u.last_name + (u.code ? ' (' + u.code + ')' : '')) for u in companyUser"
             ng-change="setCustomer(user)"
           >
             <option value=""></option>
           </select>`,
        controller: [
          '$scope',
          '$controller',
          'CompanyUsersCrud',
          function($scope, $controller, CompanyUsersCrud) {
            $controller('ListCtrl', {
              $scope: $scope,
              crud: new CompanyUsersCrud(),
              packageName: 'company',
              dataName: 'companyUser',
              filters: {
                page: 1,
                limit: 1000000
              }
            });

            $scope.$watch('company', id =>
              id ? $scope.loadList({ id }) : delete $scope.companyUser
            );

            $scope.$watch('customer', customer => {
              if (customer) {
                $scope.user = $scope.idOnly ? customer : customer._id;
              }
            });

            $scope.setCustomer = customer => {
              const { companyUser = [] } = $scope;
              const found = companyUser.find(
                ({ _id } = {}) => _id === customer
              );
              if (!found) return;
              $scope.customer = $scope.idOnly
                ? found._id
                : WorkflowCustomerModel(found);
            };
          }
        ]
      };
    }
  ]);
