angular
  .module('contributor')

  .directive('specializationsFilter', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      scope: {
        specializations: '=ngModel',
        qualified: '<'
      },
      templateUrl:
        'custom/contributor/views/partials/specializationsFilter.html',
      controller: 'SpecializationCtrl'
    };
  });
