angular
  .module('pb.base')

  .directive('formOptions', [
    '$state',

    function($state) {
      return {
        restrict: 'E',
        require: '^ngModel',
        transclude: true,
        templateUrl: 'modules/base/views/partials/formOptions.html',
        scope: {
          data: '=ngModel',
          resource: '@'
        },
        link: function(scope, element, attrs) {
          scope.add = function() {
            $state.go('^.add', {}, { reload: attrs.reload === 'true' });
          };
        }
      };
    }
  ]);
