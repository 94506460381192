angular
  .module('components')

  .directive('barChart', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        bars: '<',
        title: '@'
      },
      template: [
        '<div class="card">',
        '<div class="card-header ch-alt">',
        "<h2>{{title || 'Bar chart'}}</h2>",
        '</div>',

        '<div class="card-body card-padding-sm">',
        '<div class="flot-chart"></div>',
        '<div class="flc-bar"></div>',
        '</div>',
        '</div>'
      ].join(''),
      controller: [
        '$scope',
        '$element',
        function($scope, $element) {
          var colors = [
            '#8BC34A',
            '#00BCD4',
            '#FF9800',
            '#009788',
            '#FFED18',
            '#F7412D'
          ];

          function refresh(bars) {
            var barData = [];

            if (!Array.isArray(bars)) {
              return;
            }

            bars.forEach(function(bar, i) {
              var data = bar || {};

              barData.push({
                data: data.data || [],
                label: data.label || '',
                bars: {
                  show: typeof data.show === 'boolean' ? data.show : true,
                  barWidth: data.barWidth || 0.08,
                  order: i + 1,
                  lineWidth: data.lineWidth || 0,
                  fillColor: data.fillColor || colors[i % 6]
                }
              });
            });

            /* Let's create the chart */
            $.plot($($element).find('.flot-chart'), barData, {
              grid: {
                borderWidth: 1,
                borderColor: '#eee',
                show: true,
                hoverable: true,
                clickable: true
              },

              yaxis: {
                tickColor: '#eee',
                tickDecimals: 0,
                font: {
                  lineHeight: 13,
                  style: 'normal',
                  color: '#9f9f9f'
                },
                shadowSize: 0
              },

              xaxis: {
                tickColor: '#fff',
                tickDecimals: 0,
                font: {
                  lineHeight: 13,
                  style: 'normal',
                  color: '#9f9f9f'
                },
                shadowSize: 0
              },

              legend: {
                container: '.flc-bar',
                backgroundOpacity: 0.5,
                noColumns: 0,
                backgroundColor: 'white',
                lineWidth: 0
              }
            });
          }

          $scope.$watchCollection('bars', refresh);
        }
      ]
    };
  });
