angular
  .module('company')

  .factory('BillingCompanyCreator', [
    '$q',
    function($q) {
      function save(billingCompanyCrud, billingCompany) {
        return $q(function(resolve, reject) {
          billingCompany.headquarters = [
            {
              legal: true,
              address: billingCompany.address,
              contacts: billingCompany.contacts,
              department: billingCompany.department
            }
          ];

          delete billingCompany.address;
          delete billingCompany.contacts;
          delete billingCompany.department;

          return billingCompanyCrud
            .save(billingCompany)
            .then(function(response) {
              resolve(response);
            })
            .catch(err => {
              Sentry.captureException(err);
              reject(err);
            });
        });
      }

      return {
        save: save
      };
    }
  ]);
