angular
  .module('pb.base')

  .factory('LanguageCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class LanguageCrud extends Crud {
        constructor(list) {
          super(
            'language' + (list || ''),
            '@@apiUrl' + '/langs' + (list || '') + '/:id',
            { id: '@id' },
            {
              query: {
                method: 'GET',
                isArray: true
              }
            }
          );
        }
      }

      return LanguageCrud;
    }
  ]);
