angular
  .module('workflow')

  .factory('BatchTask', function() {
    return function(taskData, serviceData) {
      var task = Object.assign({}, taskData);
      var service = Object.assign({}, serviceData);

      return {
        _id: task._id,
        code: task.code,
        name: task.name,
        units: task.units,
        translate: task.translate,
        lang_required: task.lang_required,
        service_id: service._id,
        service_name: service.name,
        done: false,
        quantity: 1,
        isNew: true,
        params: {
          hamster: false,
          fake: false,
          offer: false,
          invoice: false,
          upload_file: false
        }
      };
    };
  });
