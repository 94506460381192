angular
  .module('pb.core.crud')

  .controller('ListCtrl', [
    '$q',
    'Dialog',
    'ErrorsManager',
    'QueryFilters',
    '$scope',
    'crud',
    'packageName',
    'dataName',
    'filters',

    function(
      $q,
      Dialog,
      ErrorsManager,
      QueryFilters,
      $scope,
      crud,
      packageName,
      dataName,
      filters
    ) {
      var dialog = new Dialog();

      packageName = packageName || 'data';
      dataName = dataName || 'items';
      $scope[dataName] = [];

      function load(customFilters) {
        var flts = customFilters
          ? angular.extend({}, filters, customFilters)
          : filters;
        var query = QueryFilters(flts, $scope.search);

        return crud.query(query).then(
          function(response) {
            $scope[dataName] =
              response && typeof response.results === 'object'
                ? response.results
                : response;
            $scope.$broadcast('listReady');
            return response;
          },
          function(err) {
            err && dialog.error(ErrorsManager.getErrorsAsString(err));
            return $q.reject(err);
          }
        );
      }

      $scope.loadList = function(customFilters) {
        return load(customFilters);
      };

      $scope.$on('reloadList.' + packageName, function() {
        load();
      });
    }
  ]);
