angular
  .module('workflow')

  .factory('ProjectTemplate', [
    '$state',
    '$filter',
    'Confirm',
    'CompanyTemplateCrud',

    function($state, $filter, Confirm, CompanyTemplateCrud) {
      const goToCompany = id => {
        $state.go('^.^.company.projectTemplates', { id });
      };

      const doSaveTemolate = originalWorkflow => {
        const workflow = angular.copy(originalWorkflow);

        const { company = {} } = workflow;
        const { _id: companyId } = company;
        const crud = new CompanyTemplateCrud(companyId);

        crud.save(workflow).then(() => goToCompany(companyId));
      };

      const saveAsTemplate = (workflow, save) => {
        const confirm = new Confirm();
        const confirmMessage = $filter('translate')(
          'workflow.SAVE_AS_TEMPLATE_MSG'
        );
        confirm.show(confirmMessage).then(() => doSaveTemolate(workflow, save));
      };

      return {
        saveAsTemplate
      };
    }
  ]);
