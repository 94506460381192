angular
  .module('customer')

  .directive('contributorsBlacklist', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        save: '&',
        undo: '&'
      },
      templateUrl: 'custom/customer/views/partials/contributorsBlacklist.html',
      controller: [
        '$scope',
        '$timeout',
        '$filter',
        '$state',
        '$stateParams',
        'Dialog',
        'Confirm',
        'CustomerStore',
        function(
          $scope,
          $timeout,
          $filter,
          $state,
          $stateParams,
          Dialog,
          Confirm,
          CustomerStore
        ) {
          var dialog = new Dialog(),
            confirm = new Confirm(),
            toBlacklistId =
              $stateParams.toBlacklist && $stateParams.toBlacklist !== null
                ? $stateParams.toBlacklist._id
                : null;

          function addToBlacklist(toBlacklistId, blacklist) {
            blacklist.contributors = blacklist.contributors || [];
            var alreadyBlacklisted = false;

            alreadyBlacklisted = blacklist.contributors.some(function(
              contributor
            ) {
              return contributor._id === toBlacklistId;
            });

            if (!alreadyBlacklisted) {
              blacklist.contributors.push({
                _id: toBlacklistId
              });

              $timeout(function() {
                CustomerStore.set('blacklist', blacklist);
                $scope.save();
              }, 250);
            }
          }

          CustomerStore.listenTo(function(data) {
            var customer = data || {};
            var blacklist = customer.blacklist || {};

            $scope.customer = customer;

            if (toBlacklistId) {
              addToBlacklist(toBlacklistId, blacklist);
              toBlacklistId = null;
            }
          });

          $scope.delete = function(index) {
            confirm
              .warning($filter('translate')('customers.DEL_CONTR_MSG'))
              .then(function() {
                $scope.customer.blacklist.contributors.splice(index, 1);
                CustomerStore.set('blacklist', $scope.customer.blacklist);

                $timeout(function() {
                  dialog.success(
                    $filter('translate')('customers.DEL_CONTR_SUCC')
                  );
                  $scope.save();
                });
              });
          };

          $scope.searchContributor = function() {
            $state.go('root.app.simple.contributors', {
              fromId: $scope.customer._id,
              fromState: $state.current.name
            });
          };

          $scope.detail = function(id) {
            $state.go('^.^.contributor.about', {
              id: id
            });
          };
        }
      ]
    };
  });
