angular
  .module('pb.base')

  .controller('SignupCtrl', [
    '$scope',
    '$filter',
    'Auth',
    'AuthSignup',
    'Dialog',

    function($scope, $filter, Auth, AuthSignup, Dialog) {
      var dialog = new Dialog();

      $scope.signup = function() {
        Auth.signup(new AuthSignup(), $scope.email, $scope.password).then(
          function() {
            dialog.success(
              $filter('translate')('auth.SIGNUP_REQ_SENT') +
                '. ' +
                $filter('translate')('THANKS')
            );
          },
          function() {
            dialog.error($filter('translate')('auth.SIGNUP_ERROR'));
          }
        );
      };
    }
  ]);
