angular
  .module('company')

  .directive('selectActivityMacrosector', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        sector: '='
      },
      template:
        '<select ng-model="sector" ng-options="s.value for s in sectors track by s._id" />',
      controller: [
        '$scope',
        '$controller',
        'ActivityCrud',
        function($scope, $controller, ActivityCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new ActivityCrud('-list'),
            packageName: 'activity',
            dataName: 'sectors',
            filters: {
              macro: 1
            }
          });

          $scope.loadList();
        }
      ]
    };
  });
