angular
  .module('workflow')

  .factory('WorkflowChangeStatusToBilledCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class WorkflowChangeStatusToBilledCrud extends Crud {
        constructor() {
          super(
            null,
            '@@apiUrl' + '/workflow-change-status-to-billed' + '/:id',
            {
              id: '@id'
            }
          );
        }
      }
      return WorkflowChangeStatusToBilledCrud;
    }
  ]);
