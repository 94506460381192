angular
  .module('customer')

  .controller('CustomerTagCtrl', [
    '$scope',
    'CustomerStore',
    'LoggedUser',

    function($scope, CustomerStore, LoggedUser) {
      //On change listener
      CustomerStore.listenTo(function(data) {
        $scope.customer = data;
      });

      //Emit save action
      $scope.save = function() {
        CustomerStore.emit('save');
      };

      $scope.undo = function() {
        $scope.$parent.performUndo();
      };

      $scope.profileId = LoggedUser.getData()._id;
      $scope.isPM = LoggedUser.isPM();
    }
  ]);
