angular
  .module('pb.base')

  .directive('info', [
    'Utilities',

    function(Utilities) {
      return {
        restrict: 'E',
        replace: true,
        template: `
          <div class="text-center m-t-25 p-t-15 block-header-alt">
              <h3>{{appName}} - Ver {{scopedAppVersion}}</h3>
              <h4>Copyright &copy; {{ currentYear }}</h4>
              <a href="@@termsUrl" target="_blank">{{::('TERMS' | translate)}}</a> | 
              <!-- <a href="#" ui-sref="root.app.sla" target="_blank">{{::('SERVICE_LEVEL_AGREEMENT' | translate)}}</a> | -->
              <a href="@@slaUrl" target="_blank">{{::('SERVICE_LEVEL_AGREEMENT' | translate)}}</a> | 
              <a href="{{appWebsite}}" target="_blank">{{appWebsiteName}}</a>
              <br/><br/>
              {{::('BETA_BANNER_INFO' | translate)}} <b><a href="mailto:help@palmabit.com">{{::('CLICK_HERE' | translate)}}</a></b>
          </div>
        `,
        scope: true,
        link: function(scope, element, attrs) {
          scope.appName = '@@appName';
          scope.scopedAppVersion = '@@version';
          scope.appWebsiteName = '@@appWebsiteName';
          scope.appWebsite = '@@appWebsite';
          scope.currentYear = Utilities.currentYear();
        },
        controller: [
          '$scope',
          'Utilities',
          function($scope, Utilities) {
            $scope.lang = Utilities.getLang();
            $scope.slaLang = ($scope.lang || '').toLowerCase().startsWith('it')
              ? 'IT'
              : 'EN';
          }
        ]
      };
    }
  ]);
