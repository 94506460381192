angular
  .module('contributor')

  .factory('ContributorSendRequest', [
    'Crud',

    function(Crud) {
      'use strict';

      class ContributorSendRequest extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/profile-send-request/:id', { id: '@id' });
        }
      }

      return ContributorSendRequest;
    }
  ]);
