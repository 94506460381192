angular
  .module('company')

  .directive('selectAcquisitionSource', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        source: '='
      },
      template:
        '<select chosen ng-model="source" ng-options="s as s.value for s in acquisitionSources track by s._id">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'AcquisitionSourceCrud',
        function($scope, $controller, AcquisitionSourceCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new AcquisitionSourceCrud(),
            packageName: 'acquisitionSources',
            dataName: 'acquisitionSources',
            filters: {}
          });

          $scope.loadList();
        }
      ]
    };
  });
