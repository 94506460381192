angular
  .module('pb.core.crud', [
    'ngResource',
    'angular-cache',
    'ui.router',
    'ngTable',
    'pb.core.auth',
    'pb.core.form',
    'pb.core.notifications'
  ])

  .config([
    'CacheFactoryProvider',
    function(CacheFactoryProvider) {
      angular.extend(CacheFactoryProvider.defaults, { maxAge: 15 * 60 * 1000 });
    }
  ]);
