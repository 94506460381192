angular
  .module('contributor')

  .controller('ContributorCreateCtrl', [
    '$scope',
    'ContributorModel',

    function($scope, ContributorModel) {
      $scope.contributor = new ContributorModel();
    }
  ]);
