angular
  .module('home')

  .directive('curvedlineChart', [
    '$filter',
    function($filter) {
      return {
        restrict: 'A',
        scope: {
          quotes: '<',
          orders: '<'
        },
        link: function(scope, element) {
          var drawChart = function(quotes, orders) {
            if (quotes && orders) {
              var d1 = [];
              var d2 = [];

              quotes.forEach(function(element) {
                d1.push([element.day, element.counter]);
              });

              orders.forEach(function(element) {
                d2.push([element.day, element.counter]);
              });

              /* Chart Options */

              var options = {
                series: {
                  shadowSize: 0,
                  curvedLines: {
                    //This is a third party plugin to make curved lines //which, by the way, is not working :-(
                    apply: true,
                    active: true,
                    monotonicFit: true
                  },
                  lines: {
                    show: false,
                    lineWidth: 0
                  }
                },
                grid: {
                  borderWidth: 0,
                  labelMargin: 10,
                  hoverable: true,
                  clickable: true,
                  mouseActiveRadius: 6
                },
                xaxis: {
                  tickDecimals: 0,
                  ticks: false
                },

                yaxis: {
                  tickDecimals: 0,
                  ticks: false
                },

                legend: {
                  show: false
                }
              };

              /* Let's create the chart */

              $.plot(
                $(element),
                [
                  {
                    data: d1,
                    lines: { show: true, fill: 0.98 },
                    label: $filter('translate')('quote.QUOTES'),
                    stack: true,
                    color: '#e3e3e3'
                  },
                  {
                    data: d2,
                    lines: { show: true, fill: 0.66 },
                    label: $filter('translate')('order.ORDERS'),
                    stack: true,
                    color: '#f1dd2c'
                  }
                ],
                options
              );

              /* Tooltips for Flot Charts */

              if ($('.flot-chart')[0]) {
                $('.flot-chart').bind('plothover', function(event, pos, item) {
                  if (item) {
                    const index = item.datapoint[0] - 1;
                    var x = quotes[index].date
                        ? $filter('date')(quotes[index].date, 'dd/MM/yy')
                        : '',
                      y = x ? item.datapoint[1].toFixed(0) : '',
                      tooltipString = y
                        ? x + ': ' + y + ' ' + item.series.label
                        : '';
                    $('.flot-tooltip')
                      .html(tooltipString)
                      .css({
                        top: item.pageY + 5,
                        left: item.pageX + 5
                      })
                      .show();
                  } else {
                    $('.flot-tooltip').hide();
                  }
                });

                $(
                  "<div class='flot-tooltip' class='chart-tooltip'></div>"
                ).appendTo('body');
              }
            }
          };
          scope.$watch('quotes', function(newValue) {
            if (
              newValue &&
              newValue.some(function(element) {
                return element.counter;
              })
            ) {
              drawChart(newValue, scope.orders);
            }
          });

          scope.$watch('orders', function(newValue) {
            if (
              newValue &&
              newValue.some(function(element) {
                return element.counter;
              })
            ) {
              drawChart(scope.quotes, newValue);
            }
          });

          var randomData = [];
          for (var i = 0; i < 30; i++) {
            randomData.push({
              day: i,
              counter: Math.floor(Math.random() * 100 + 1)
            });
          }
          drawChart(randomData, []);
        }
      };
    }
  ]);
