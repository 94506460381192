angular
  .module('pb.user')

  .factory('UserModel', function() {
    function UserModel() {
      return {
        __t: 'Agent',
        resources: {
          customers: [],
          docs: ['read', 'create'],
          docsType: ['read'],
          products: []
        }
      };
    }

    return UserModel;
  });
