angular
  .module('pb.base')

  .directive('starRating', function() {
    return {
      restrict: 'EA',
      template:
        '<ul class="star-rating"><li ng-repeat="star in stars track by $index" class="star" ng-class="{filled: star.filled}" ng-click="toggle($index)"><i class="zmdi zmdi-star"></i></li></ul>',
      scope: {
        ratingValue: '=ngModel',
        max: '=?'
      },
      link: function(scope, element, attributes) {
        if (scope.max == undefined) {
          scope.max = 5;
        }

        function updateStars() {
          scope.stars = [];

          for (var i = 0; i < scope.max; i++) {
            scope.stars.push({
              filled: i < scope.ratingValue
            });
          }
        }

        scope.toggle = function(index) {
          scope.ratingValue = index + 1;
        };

        scope.$watch(
          'ratingValue',
          ratingValue => typeof ratingValue !== 'undefined' && updateStars()
        );
      }
    };
  });
