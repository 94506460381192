angular
  .module('company')

  .factory('Company24hCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class Company24hCrud extends Crud {
        constructor(companyId) {
          super(null, '@@apiUrl' + `/company-standard-projects/${companyId}`, {
            id: null
          });
        }
      }

      return Company24hCrud;
    }
  ]);
