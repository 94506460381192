angular
  .module('company')

  .controller('CompanyListCtrl', [
    '$scope',
    '$controller',
    'CompanyCrud',
    '$state',
    '$stateParams',
    '$filter',
    'Confirm',
    'CompanyExport',
    'Dialog',
    'ZohoCompanyBulkSync',
    'ZohoCompanySync',
    function(
      $scope,
      $controller,
      CompanyCrud,
      $state,
      $stateParams,
      $filter,
      Confirm,
      CompanyExport,
      Dialog,
      ZohoCompanyBulkSync,
      ZohoCompanySync
    ) {
      let searchEnabled = false;

      $controller('DataTableCtrl', {
        $scope: $scope,
        crud: new CompanyCrud($scope),
        packageName: 'company',
        tableParams: 'companyList',
        filters: {
          sort: {
            company_name: 1
          }
        },
        parser: null
      });

      $scope.associating = $stateParams.associating;

      $scope.add = function() {
        $state.go('^.create-company');
      };

      $scope.associate = function(id) {
        if ($stateParams.billingId) {
          $state.go('^.company.about', {
            id: id,
            associating: $stateParams.associating,
            billingId: $stateParams.billingId
          });
        } else if ($stateParams.task || $stateParams.allLists) {
          var confirm = new Confirm();
          confirm
            .info($filter('translate')('company.ASSOCIATE_LIST_MSG'))
            .then(function() {
              $state.go('^.company.lists', {
                id: id,
                associating: $stateParams.associating,
                task: $stateParams.task,
                units: $stateParams.units,
                allLists: $stateParams.allLists
              });
            });
        }
      };

      $scope.getLegalHeadquarter = (headquarters = []) => {
        const legalHeadquarter = headquarters.find(e => e.legal == true);
        return {
          city:
            (legalHeadquarter &&
              legalHeadquarter.address &&
              legalHeadquarter.address.city) ||
            '',
          email:
            (legalHeadquarter &&
              legalHeadquarter.contacts &&
              legalHeadquarter.contacts.email) ||
            '',
          phone:
            (legalHeadquarter &&
              legalHeadquarter.contacts &&
              legalHeadquarter.contacts.phone) ||
            ''
        };
      };

      $scope.search = function() {
        $scope.resetPage();
        $scope.reload($scope.filters);
      };

      $scope.$watch(
        'searchName',
        function(newValue) {
          $scope.filters = $scope.filters || {};
          if (newValue && newValue.search) {
            searchEnabled = true;
            $scope.filters.name = newValue.search;
          } else if ($scope.filters.hasOwnProperty('name')) {
            delete $scope.filters.name;
          }

          if (searchEnabled) {
            $scope.search();
          }
        },
        true
      );

      let firstPMChange = true;
      $scope.$watch('filters.project_manager', project_manager => {
        if (firstPMChange) return (firstPMChange = false);
        if (project_manager === undefined) return;
        $scope.filters = $scope.filters || {};
        if (project_manager === null) delete $scope.filters.project_manager;
        $scope.search();
      });

      let firstSMChange = true;
      $scope.$watch('filters.account_manager', account_manager => {
        if (firstSMChange) return (firstSMChange = false);
        if (account_manager === undefined) return;
        $scope.filters = $scope.filters || {};
        if (account_manager === null) delete $scope.filters.account_manager;
        $scope.search();
      });

      $scope.$on('downloadCompanies', () => {
        CompanyExport.download($scope.filters);
      });

      $scope.zohoSync = ({ _id }) => {
        new Confirm().show($filter('translate')('zoho.SYNC_MSG')).then(() => {
          new ZohoCompanySync(_id)
            .save()
            .then(() => $scope.reload($scope.filters))
            .catch(error => {
              Sentry.captureException(error);
              $timeout(
                () =>
                  new Dialog().error($filter('translate')('zoho.SYNC_ERROR')),
                250
              );
            });
        });
      };
    }
  ]);
