angular
  .module('demo')

  .factory('DemoCrud', [
    'Crud',

    function(Crud) {
      class DemoCrud extends Crud {
        constructor() {
          super('demo', '@@apiUrl' + '/api/demos/:id', { id: '@id' });
        }
      }

      return DemoCrud;
    }
  ]);
