angular.module('company').factory('Company24hUtils', [
  function() {
    const generateLanguagesSummary = projects => {
      let summary = '';
      if (!Array.isArray(projects)) return summary;

      return projects
        .map(project => {
          const { services } = project || {};
          if (!Array.isArray(services)) return '';

          return services
            .map(service => {
              const { source, target } = service || {};
              const { value: sourceValue = '' } = source || {};
              if (!sourceValue) return '';

              const targetString =
                Array.isArray(target) &&
                target
                  .map(target => target && target.value)
                  .filter(value => value)
                  .join(', ');

              if (!targetString) return sourceValue;

              return `${sourceValue} → ${targetString}`;
            })
            .filter(value => value)
            .join('<br>');
        })
        .filter(value => value)
        .join('<br>');
    };

    return { generateLanguagesSummary };
  }
]);
