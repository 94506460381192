angular
  .module('pb.core.crud')

  .controller('DataTableCtrl', [
    '$controller',
    '$timeout',
    '$filter',
    'ngTableParams',
    'ngTableEventsChannel',
    '$scope',
    'crud',
    'packageName',
    'tableParams',
    'filters',
    'parser',

    function(
      $controller,
      $timeout,
      $filter,
      ngTableParams,
      ngTableEventsChannel,
      $scope,
      crud,
      packageName,
      tableParams,
      filters,
      parser
    ) {
      var customFilters = {},
        fullList = null;

      $controller('ListCtrl', {
        $scope: $scope,
        crud: crud,
        packageName: packageName,
        dataName: 'items',
        filters: angular.extend({ page: 1 }, filters || {})
      });

      //Memorize params
      function setParams(params) {
        sessionStorage.setItem(
          '@@localStoragePrefix' + 'tableParams.' + packageName,
          JSON.stringify(params)
        );
      }

      //Get params
      function getParams() {
        return (
          JSON.parse(
            sessionStorage.getItem(
              '@@localStoragePrefix' + 'tableParams.' + packageName
            )
          ) || {}
        );
      }

      function getCount() {
        return getParams().count || 100;
      }

      function getFilters() {
        return getParams().filter || {};
      }

      function getPage() {
        return getParams().page || 1;
      }

      function getSorting() {
        return getParams().sorting || $scope.defaultSorting || {};
      }

      function getQueryParams() {
        return getParams().queryParams || {};
      }

      function sliceResults(results, params) {
        return results.slice(
          (params.page() - 1) * params.count(),
          params.page() * params.count()
        );
      }

      $scope.queryParams = angular.extend(
        getQueryParams(),
        $scope.queryParams || {}
      );

      //Set ngTable params
      $scope[tableParams] = new ngTableParams(
        {
          page: $scope.page || getPage(),
          count: $scope.count || getCount(),
          counts: $scope.counts || [10, 25, 50, 100],
          filter: getFilters(),
          sorting: getSorting(),
          orderMulti: false
        },
        {
          paginationMaxBlocks: $scope.paginationMaxBlocks || 5,
          paginationMinBlocks: $scope.paginationMinBlocks || 2,
          total: 0,
          getData: function($defer, params) {
            var sortQuery = {},
              sortParams = {},
              sorting = params.sorting(),
              filter = params.filter();

            var query = angular.extend({}, $scope.queryParams, {
              page: params.page(),
              limit: params.count()
            });

            //Force sorting by a single column
            var sortBy = Object.keys(sorting).slice(-1)[0];

            if (Object.keys(sorting).length > 0) {
              angular.forEach(sorting, function(value, key) {
                if (key === sortBy) {
                  sortQuery[key] = value === 'asc' ? 1 : -1;
                  sortParams[key] = value;
                }
              });

              query.sort = sortQuery;
            }

            if (
              Object.keys(filter).length > 0 ||
              Object.keys(customFilters).length > 0
            ) {
              query.filter = angular.extend({}, filter, customFilters);
            }

            if (Array.isArray(fullList)) {
              $timeout(function() {
                params.total(fullList.length);
                $defer.resolve(
                  sliceResults(
                    sortParams
                      ? $filter('orderBy')(fullList, params.orderBy())
                      : fullList,
                    params
                  )
                );
              }, 10);
            } else {
              if ($scope.skipInitialTableLoading) return $defer.resolve([]);

              //Load data
              $scope.loadList(query).then(function(response) {
                var total, results;

                if (typeof parser === 'function') {
                  response = parser(response);
                }

                if (response && response.results && !Array.isArray(response)) {
                  results = response.results;
                  total = response.total;
                } else if (Array.isArray(response)) {
                  fullList = response;
                  results = sliceResults(
                    sortParams
                      ? $filter('orderBy')(response, params.orderBy())
                      : fullList,
                    params
                  );
                  total = response.length;
                } else {
                  results = [];
                  total = 0;
                }

                $timeout(function() {
                  params.total(total);
                  $defer.resolve(results);
                }, 10);
              });
            }

            //Memorize params
            setParams({
              count: params.count(),
              filter: filter,
              page: params.page(),
              sorting: sortParams,
              queryParams: $scope.queryParams
            });
          }
        }
      );

      $scope.reload = function(filters) {
        delete $scope.skipInitialTableLoading;
        fullList = null;
        customFilters = filters || {};
        $scope[tableParams].reload();
      };

      $scope.resetPage = () => $scope[tableParams].page(1);
    }
  ]);
