angular
  .module('pb.file')

  .factory('S3CreateFolderCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class S3CreateFolderCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/create-folder');
        }
      }

      return S3CreateFolderCrud;
    }
  ]);
