angular
  .module('contributor')

  .directive('contributorHeadquarters', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      scope: {
        headquarters: '=ngModel',
        store: '<',
        processSaving: '&save',
        undo: '&'
      },
      templateUrl:
        'custom/contributor/views/partials/contributorHeadquarters.html',
      controller: 'HeadquartersCtrl'
    };
  });
