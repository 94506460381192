angular
  .module('company')

  .directive('activitySectors', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      replace: true,
      scope: {
        selected: '=ngModel',
        macrosector: '='
      },
      templateUrl: 'custom/company/views/partials/activitySectors.html',
      controller: [
        '$scope',
        '$controller',
        'ActivityCrud',
        function($scope, $controller, ActivityCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new ActivityCrud('-list'),
            packageName: 'activity',
            dataName: 'activities',
            filters: {}
          });
        }
      ],
      link: function(scope) {
        scope.selected = scope.selected || [];
        scope.sectors = [];

        scope.$watch('macrosector', function(newValue, oldValue) {
          if (!newValue) return;

          if (oldValue) {
            const { _id: oldId = '' } = oldValue;
            const { _id: newId = '' } = newValue;
            scope.selected = newId === oldId ? scope.selected : [];
          }

          scope.loadList({ parent: newValue._id }).then(function(response) {
            var sectors = [];

            angular.forEach(response, function(value) {
              sectors.push({
                _id: value._id || null,
                value: value.value || '',
                selected: (scope.selected || []).includes(value._id)
              });
            });

            scope.sectors = sectors;
          });
        });

        scope.toggleSelection = function(id) {
          var idx = scope.selected.indexOf(id);
          idx > -1 ? scope.selected.splice(idx, 1) : scope.selected.push(id);
        };
      }
    };
  });
