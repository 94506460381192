angular
  .module('pb.core.user')

  .factory('LoggedUser', [
    '$rootScope',
    'User',

    function($rootScope, User) {
      function get() {
        return $rootScope.user || new User();
      }

      function set(data) {
        return ($rootScope.user = new User(data));
      }

      function getData() {
        return get().get();
      }

      function isCustomer() {
        return getData() && getData().type === 'customer';
      }

      function isContributor() {
        return getData() && getData().type === 'contributor';
      }

      function isHRM() {
        const { type } = getData() || {};
        return type === 'hrm';
      }

      function isPM() {
        const { type } = getData() || {};
        return ['superadmin', 'pm_s', 'pm_j', 'hrm'].includes(type);
      }

      function isSuperAdmin() {
        const { type } = getData() || {};
        return type === 'superadmin';
      }

      function isComplete() {
        return getData() && getData().complete;
      }

      function isQualified() {
        const { specializations = [] } = getData() || {};
        return specializations.some((spec = {}) => {
          const { rating = {} } = spec;
          return rating.qualified;
        });
      }

      function hasDocAccess() {
        return isPM() || (isContributor() && !!getData().docAccess);
      }

      const getContributorAgreementsVersion = () => {
        const {
          contributorConditionsVersion = 0,
          contributorLegalAgreementVersion = 0
        } = getData() || {};
        return {
          contributorConditionsVersion,
          contributorLegalAgreementVersion
        };
      };

      return {
        get,
        set,
        getData,
        isCustomer,
        isContributor,
        isHRM,
        isPM,
        isSuperAdmin,
        isComplete,
        isQualified,
        hasDocAccess,
        getContributorAgreementsVersion
      };
    }
  ]);
