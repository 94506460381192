angular
  .module('workflow')

  .factory('Project24h', [
    '$state',
    '$filter',
    'Confirm',
    'Company24hCrud',

    function($state, $filter, Confirm, Company24hCrud) {
      const goToCompany = id => {
        $state.go('^.^.company.projects24h', { id });
      };

      const doSave24h = originalWorkflow => {
        const workflow = angular.copy(originalWorkflow);

        const { company = {} } = workflow;
        const { _id: companyId } = company;
        const crud = new Company24hCrud(companyId);

        crud.save(workflow).then(() => goToCompany(companyId));
      };

      const saveAs24h = (workflow, save) => {
        const confirm = new Confirm();
        const confirmMessage = $filter('translate')('workflow.SAVE_AS_24H_MSG');
        confirm.show(confirmMessage).then(() => doSave24h(workflow, save));
      };

      return {
        saveAs24h
      };
    }
  ]);
