angular
  .module('company')

  .directive('servicesForm', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        company: '=ngModel',
        save: '&',
        undo: '&'
      },
      templateUrl: 'custom/company/views/partials/servicesForm.html',
      controller: [
        '$scope',
        '$filter',
        'Dialog',
        'Confirm',
        function($scope, $filter, Dialog, Confirm) {
          $scope.delete = function(id) {
            new Confirm()
              .info(
                $filter('translate')('company.DELETE_CUSTOM_SERVICE_MESSAGE')
              )
              .then(function() {
                for (var i = $scope.company.services.length - 1; i >= 0; i--) {
                  if ($scope.company.services[i].service === id) {
                    $scope.company.services.splice(i, 1);
                    return;
                  }
                }
              });
          };

          function alreadyInsert() {
            if ($scope.company.services) {
              for (var i = 0; i < $scope.company.services.length; i += 1) {
                if (
                  $scope.company.services[i].service ===
                  $scope.selectedService._id
                ) {
                  return true;
                }
              }
            }
            return false;
          }

          $scope.addService = function() {
            if (!$scope.selectedService) {
              new Dialog().warning('Select a service');
              return;
            }

            if (alreadyInsert()) {
              new Dialog().warning(
                $filter('translate')('company.SERVICE_ALREADY_INSERT')
              );
              return;
            }

            $scope.company.services = $scope.company.services || [];

            $scope.company.services.push({
              service: $scope.selectedService._id,
              tasks: []
            });
            $scope.selectedService = null;
          };

          $scope.saveValidate = function() {
            for (var i = 0; i < $scope.company.services.length; i += 1) {
              if (
                !$scope.company.services[i].tasks ||
                $scope.company.services[i].tasks.length === 0
              ) {
                new Dialog().error(
                  $filter('translate')('company.TASKS_CUSTOM_VALIDATION_ERROR')
                );
                return;
              }
            }

            $scope.save();
          };
        }
      ]
    };
  });
