angular
  .module('contributor')

  .directive('selectVat', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        vat: '='
      },
      template:
        '<select ng-model="vat" ng-options="v as (v.name[userLang] || v.value) for v in vats track by v._id">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'VatCrud',
        'UserLang',
        function($scope, $controller, VatCrud, UserLang) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new VatCrud('-list'),
            packageName: 'vats',
            dataName: 'vats',
            filters: {}
          });

          $scope.loadList();
          $scope.userLang = UserLang.get();
        }
      ]
    };
  });
