angular.module('components').value('Colors', {
  workflow: {
    status: {
      draft: '#F9F9F9',
      standby: '#9E9E9E',
      pm_assigned: '#A0522D',
      request_received: '#7CFC00',
      progress: '#4B0082',
      delivering: '#69A5D6',
      delivered: '#FFBF00',
      dataconfirm: '#FFEB3B',
      invoiced: '#6B8E23',
      waiting: '#EE82EE',
      approved: '#4169E1',
      notapproved: 'rgb(178, 1, 1)',
      canceled: 'rgb(178, 1, 1)'
    }
  }
});
