angular
  .module('company')

  .factory('CompanyTemplateCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class CompanyTemplateCrud extends Crud {
        constructor(companyId) {
          super(null, '@@apiUrl' + `/company-template-projects/${companyId}`, {
            id: null
          });
        }
      }

      return CompanyTemplateCrud;
    }
  ]);
