angular
  .module('pb.core.auth')

  .value('LSPrefix', '@@localStoragePrefix')

  .factory('GetToken', [
    'LSPrefix',
    function(LSPrefix) {
      return function() {
        var lcToken = localStorage.getItem(LSPrefix + 'token');
        var scToken = sessionStorage.getItem(LSPrefix + 'token');
        return lcToken || scToken || null;
      };
    }
  ])

  .factory('SaveToken', [
    'LSPrefix',
    function(LSPrefix) {
      return function(response) {
        localStorage.setItem(LSPrefix + 'user', response._id);
        localStorage.setItem(LSPrefix + 'email', response.email);
        return localStorage.setItem(LSPrefix + 'token', response.token);
      };
    }
  ])

  .factory('GetUTypeToken', [
    'LSPrefix',
    function(LSPrefix) {
      return function() {
        return sessionStorage.getItem(LSPrefix + 'xutype');
      };
    }
  ])

  .factory('SaveUTypeToken', [
    'LSPrefix',
    function(LSPrefix) {
      return function(response) {
        return sessionStorage.setItem(LSPrefix + 'xutype', response.xutype);
      };
    }
  ])

  .factory('DeleteToken', [
    'LSPrefix',
    function(LSPrefix) {
      return function() {
        localStorage.removeItem(LSPrefix + 'user');
        localStorage.removeItem(LSPrefix + 'email');
        localStorage.removeItem(LSPrefix + 'xutype');
        localStorage.removeItem(LSPrefix + 'token');

        sessionStorage.removeItem(LSPrefix + 'user');
        sessionStorage.removeItem(LSPrefix + 'email');
        sessionStorage.removeItem(LSPrefix + 'xutype');
        sessionStorage.removeItem(LSPrefix + 'token');
      };
    }
  ])

  .factory('DeleteStorage', [
    'LSPrefix',
    function(LSPrefix) {
      return function() {
        Object.keys(localStorage).forEach(function(key) {
          if (key.indexOf(LSPrefix) > 0) {
            localStorage.removeItem(key);
          }
        });

        Object.keys(sessionStorage).forEach(function(key) {
          if (key.indexOf(LSPrefix) > 0) {
            sessionStorage.removeItem(key);
          }
        });
      };
    }
  ])

  .factory('SetAuthHeader', [
    '$http',
    'GetToken',
    'GetUTypeToken',
    function($http, GetToken, GetUTypeToken) {
      return function() {
        var token = GetToken();
        var uTypeToken = GetUTypeToken();

        if (uTypeToken) {
          $http.defaults.headers.common['xutype'] = uTypeToken
            ? 'Bearer ' + uTypeToken
            : '';
        }

        return ($http.defaults.headers.common['Authorization'] = token
          ? 'Bearer ' + token
          : '');
      };
    }
  ]);
