angular
  .module('pb.file')

  .directive('pbFileUploadButton', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: false,
      template: `
        <div class="row" ng-show="editable">
            <div class="col-md-8">
                <div class="progress m-t-25 m-l-25" ng-if="showProgress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="{{uploadProgress}}" aria-valuemin="0"
                         aria-valuemax="100" ng-style="{ width: uploadProgress + '%' }">
                        <span class="sr-only">{{uploadProgress}}% Complete</span>
                    </div>
                </div>
            </div>
        
            <div class="col-md-4">
                <div class="text-right">
                    <span class="btn btn-danger btn-file btn-icon btn-file m-15"
                    tooltip="{{::('file.UPLOAD_FILES' | translate)}}"
                    tooltip-placement="top"
                    tooltip-popup-delay="500">
                        <i class="zmdi zmdi-cloud-upload m-t-10"></i>
                        <input type="file" multiple="multiple" name="...">
                    </span>
                </div>
            </div>
        </div>
      `
    };
  });
