angular
  .module('pb.base')

  .controller('ModalBaseInstanceCtrl', [
    '$scope',
    '$modalInstance',
    'content',

    function($scope, $modalInstance, content) {
      $scope.modalContent = content || {};

      $scope.ok = function() {
        $modalInstance.close();
      };

      $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
      };
    }
  ]);
