angular
  .module('contributor')

  .directive('dueDiligenceSector', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        sector: '=ngModel',
        name: '@',
        num: '='
      },
      templateUrl: 'custom/contributor/views/partials/dueDiligenceSector.html',
      controller: [
        '$scope',
        '$filter',
        function($scope, $filter) {
          $scope.getNumber = function(num) {
            return new Array(parseInt(num));
          };
        }
      ]
    };
  });
