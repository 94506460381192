angular
  .module('components')

  .directive('selectService', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        company: '<',
        service: '=',
        disabled: '<',
        templateType: '<',
        langRequired: '='
      },
      template:
        '<select chosen ng-model="service" ng-options="s.value for s in services track by s._id" ng-disabled="disabled" ng-change="setLangRequired(service)">' +
        '<option value="">- </option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'LoggedUser',
        'ServiceCrud',
        function($scope, $controller, LoggedUser, ServiceCrud) {
          const { lang, services: companyServices = [] } = $scope.company || {};

          $controller('ListCtrl', {
            $scope: $scope,
            crud: new ServiceCrud('-list'),
            packageName: 'services',
            dataName: 'services',
            filters: {}
          });

          $scope.langRequired = 2;

          function filterCompanyServices() {
            return $scope.services.filter(service => {
              return (
                companyServices.some(
                  companyService => service._id === companyService.service
                ) ||
                ($scope.service && service._id === $scope.service._id)
              );
            });
          }

          $scope.loadList({ lang, fullTasks: true }).then((services = []) => {
            if (
              ($scope.is24h || $scope.templateType === 'standard') &&
              companyServices &&
              companyServices.length
            ) {
              $scope.services = filterCompanyServices();
            }
          });

          $scope.setLangRequired = service => {
            const { tasks } = service || {};
            let langRequired = 0;
            (tasks || []).forEach(({ lang_required }) => {
              if (lang_required > langRequired) langRequired = lang_required;
            });
            $scope.langRequired = langRequired;
          };
        }
      ]
    };
  });
