angular
  .module('admin')

  .controller('AdminListCtrl', [
    '$scope',
    '$controller',
    '$timeout',
    'UserCrud',
    '$state',

    function($scope, $controller, $timeout, UserCrud, $state) {
      $controller('DataTableCtrl', {
        $scope: $scope,
        crud: new UserCrud($scope),
        packageName: 'admin',
        tableParams: 'adminList',
        filters: {
          type: 'superadmin' //TODO default alla prima chiamata
        },
        parser: null
      });

      $scope.role = 'superadmin';

      $scope.$watch('role', function(val, old) {
        if (val !== old) {
          $scope.queryParams.type = val;
          $scope.reload();
        }
      });

      $scope.add = function() {
        $state.go('^.create-admin');
      };
    }
  ]);
