angular
  .module('pb.core.notifications')

  .factory('Toast', [
    '$rootScope',
    'Notification',

    function($rootScope, Notification) {
      'use strict';

      /**
       * Toast class
       * @param params
       * @constructor
       */
      class Toast extends Notification {
        /**
         * Message notification
         * @returns {Notification}
         */
        message() {
          return this.setType('inverse').show.apply(this, arguments);
        }

        /**
         * Broadcast event on notifications change
         * @private
         */
        _broadcast(notification) {
          $rootScope.$broadcast('onToastNotification', notification);
        }
      }

      return Toast;
    }
  ]);
