angular
  .module('pb.core.crud')

  .factory('Store', [
    '$timeout',
    'Dispatcher',
    function($timeout, Dispatcher) {
      'use strict';

      class Store extends Dispatcher {
        constructor(listeners, changeListeners) {
          super(listeners, changeListeners);
          this.event = this.eventCopy = {};
        }

        get(key) {
          return this.event[key];
        }

        set(key, value) {
          this.event[key] = value;
          return this;
        }

        emitChange(e) {
          $timeout(() => {
            this.event = e || this.event;
            this.eventCopy = angular.copy(this.event);
            super.emitChange(this.event);
          }, 1);
        }

        listenTo(listener) {
          if (typeof listener === 'function') {
            listener(this.event);
          }

          return super.listenTo(listener);
        }

        undo() {
          this.event = angular.copy(this.eventCopy);
          super.emitChange(this.event);
        }
      }

      return Store;
    }
  ]);
