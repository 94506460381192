angular
  .module('order')

  .factory('OrderSetInProgressRequest', [
    'Crud',

    function(Crud) {
      'use strict';

      class OrderSetInProgressRequest extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/order-set-in-progress-request/:id', {
            id: '@id'
          });
        }
      }

      return OrderSetInProgressRequest;
    }
  ]);
