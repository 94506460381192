'use strict';

angular
  .module('workflow')

  .factory('XlsxService', [
    'TradosRangesAssets',

    function(TradosRangesAssets) {
      const findRange = jsonSheet => {
        const ranges = angular.copy(TradosRangesAssets.ranges);
        let error = false;

        ranges.forEach(range => {
          const { name: rangeName, trados_names: tradosNames = [] } = range;
          let foundIndex = 0;
          const foundLine = jsonSheet.find(line => {
            return line.some((columnContent, columnIndex) => {
              if (
                columnContent === rangeName ||
                tradosNames.includes(columnContent)
              ) {
                foundIndex = columnIndex;
                return true;
              } else return false;
            });
          });
          if (foundLine) {
            const foundQuantity = foundLine[foundIndex + 2];
            if (!foundQuantity) return (error = true);

            const parsedQuantity = foundQuantity && parseInt(foundQuantity);
            if (isNaN(parsedQuantity)) return (error = true);

            range.quantity = parsedQuantity;
          }
        });

        if (error) return Promise.reject();
        else return Promise.resolve(ranges);
      };

      const getRanges = parsedFile => {
        if (!parsedFile) return Promise.reject();

        const sheetName = parsedFile.SheetNames && parsedFile.SheetNames[0];
        const sheet = parsedFile.Sheets && parsedFile.Sheets[sheetName];
        const jsonSheet = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        return findRange(jsonSheet);
      };

      const readFile = file => {
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
          reader.onload = e => {
            const data = e.target.result;
            let workbook;

            try {
              workbook = XLSX.read(data, { type: 'binary' });
            } catch (error) {
              Sentry.captureException(error);
              return reject(error);
            }

            return resolve(workbook);
          };

          reader.readAsBinaryString(file);
        });
      };

      return {
        getRanges,
        readFile
      };
    }
  ]);
