angular
  .module('signup')

  .config([
    '$stateProvider',
    '$urlRouterProvider',

    function($stateProvider, $urlRouterProvider) {
      var path = 'custom/signup/views/';

      $stateProvider
        .state('root.wizard.waiting', {
          url: '/waiting',
          templateUrl: path + 'waiting.html'
        })

        .state('root.wizard.signup-start', {
          url: '/signup-start',
          controller: 'SignupStartCtrl',
          templateUrl: path + 'step0.html'
        })

        .state('root.wizard.signup-end', {
          url: '/signup-complete',
          templateUrl: path + 'end.html'
        })

        .state('root.wizard.sla', {
          url: '/sla-wizard',
          template: '<sla></sla>'
        })

        // CUSTOMER
        .state('root.wizard.simple.signup-customer', {
          url: '/signup-wizard-customer',
          views: {
            header: {
              templateUrl: path + 'header-customer.html'
            },
            content: {
              templateUrl: path + 'wizard.html'
            }
          }
        })

        .state('root.wizard.simple.signup-customer.step1', {
          url: '/your-data',
          templateUrl: path + 'customer-step1.html',
          controller: 'SignupStepCtrl'
        })

        .state('root.wizard.simple.signup-customer.step2', {
          url: '/company',
          templateUrl: path + 'customer-step2.html',
          controller: 'SignupStepCtrl'
        })

        .state('root.wizard.simple.signup-customer.step3', {
          url: '/new-company',
          templateUrl: path + 'customer-step3.html',
          controller: 'SignupStepCtrl'
        })

        .state('root.wizard.simple.signup-customer.step4', {
          url: '/headquarter',
          templateUrl: path + 'customer-step4.html',
          controller: 'SignupStepCtrl'
        })

        .state('root.wizard.simple.signup-customer.step5', {
          url: '/agreements',
          templateUrl: path + 'customer-step5.html',
          controller: 'SignupStepCtrl'
        })

        // CONTRIBUTOR

        .state('root.wizard.simple.signup-contributor', {
          url: '/signup-wizard-contributor',
          views: {
            header: {
              templateUrl: path + 'header-contributor.html'
            },
            content: {
              templateUrl: path + 'wizard-contributor.html'
            }
          }
        })

        .state('root.wizard.simple.signup-contributor.step1', {
          url: '/your-data',
          templateUrl: path + 'contributor-step1.html',
          controller: 'SignupStepCtrl'
        })

        .state('root.wizard.simple.signup-contributor.step2', {
          url: '/headquarters',
          templateUrl: path + 'contributor-step2.html',
          controller: 'SignupStepCtrl'
        })

        .state('root.wizard.simple.signup-contributor.step3', {
          url: '/services-bilingual',
          templateUrl: path + 'contributor-step3.html',
          controller: 'SignupStepCtrl'
        })

        .state('root.wizard.simple.signup-contributor.step4', {
          url: '/services-monolingual',
          templateUrl: path + 'contributor-step4.html',
          controller: 'SignupStepCtrl'
        })

        .state('root.wizard.simple.signup-contributor.step5', {
          url: '/services-others',
          templateUrl: path + 'contributor-step5.html',
          controller: 'SignupStepCtrl'
        })

        .state('root.wizard.simple.signup-contributor.step6', {
          url: '/specializations',
          templateUrl: path + 'contributor-step6.html',
          controller: 'SignupStepCtrl'
        })

        .state('root.wizard.simple.signup-contributor.step7', {
          url: '/tools',
          templateUrl: path + 'contributor-step7.html',
          controller: 'SignupStepCtrl'
        })

        .state('root.wizard.simple.signup-contributor.step8', {
          url: '/cv',
          templateUrl: path + 'contributor-step8.html',
          controller: 'SignupStepCtrl'
        })

        .state('root.wizard.simple.signup-contributor.step9', {
          url: '/note',
          templateUrl: path + 'contributor-step9.html',
          controller: 'SignupStepCtrl'
        });
    }
  ])

  .run([
    '$rootScope',
    '$state',
    'LoggedUser',
    function($rootScope, $state, LoggedUser) {
      $rootScope.$on('$stateChangeStart', function(
        event,
        toState,
        toParams,
        fromState
      ) {
        var fromStateName, user, registered, complete, done;
        var toStateName = toState ? toState.name || '' : '';

        if (toStateName.indexOf('root.wizard') < 0) {
          return;
        }

        fromStateName = fromState ? fromState.name || '' : '';

        user = LoggedUser.get().get() || {};
        registered = user.registered !== false;
        complete = typeof user.complete === 'boolean' ? user.complete : false;
        done = parseInt(user.done || 0);

        if (toStateName === 'root.wizard') {
          event.preventDefault();

          if (complete === false && done >= 1) {
            $state.go(
              'root.wizard.simple.signup-' +
                (user.type === 'contributor' ? 'contributor' : 'customer')
            );
          } else {
            $state.go('root.wizard.signup-start');
          }

          return;
        }

        function gotoSignup(type) {
          event.preventDefault();
          if (!registered) {
            $state.go('root.wizard.signup-start');
          } else {
            $state.go('root.wizard.simple.signup-' + type);
          }
        }

        if (complete && toStateName !== 'root.wizard.waiting') {
          event.preventDefault();
          $state.go('root.wizard.waiting');
        } else if (
          toStateName.indexOf('root.wizard.simple.signup-customer') >= 0 &&
          user.type === 'contributor'
        ) {
          gotoSignup('contributor');
        } else if (
          toStateName.indexOf('root.wizard.simple.signup-contributor') >= 0 &&
          user.type === 'customer'
        ) {
          gotoSignup('customer');
        }
      });
    }
  ]);
