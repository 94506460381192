angular
  .module('task')

  .factory('TaskProjectCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class TaskProjectCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/task-project', {});
        }
      }
      return TaskProjectCrud;
    }
  ]);
