angular
  .module('pb.base')

  .directive('checkLabelNeg', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      //template: '<div><i class="zmdi zmdi-hc-fw zmdi-{{value === false ? \'check c-green\' : \'close c-red\'}}"></i></div>',
      template:
        "<div><i class=\"zmdi zmdi-hc-fw zmdi-{{!value ? 'check c-green' : 'close c-red'}}\"></i></div>",
      scope: {
        value: '=ngModel'
      }
    };
  });
