angular
  .module('contributor')

  .directive('contributorStatus', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        rating: '=ngModel',
        radiokey: '@'
      },
      templateUrl: 'custom/contributor/views/partials/contributorStatus.html',
      controller: [
        '$scope',
        '$filter',
        function($scope, $filter) {
          $scope.rating = $scope.rating || {};

          $scope.statuses = {
            potential: $filter('translate')('contributors.STATUS_POTENTIAL'),
            approved: $filter('translate')('contributors.STATUS_APPROVED'),
            not_approved: $filter('translate')(
              'contributors.STATUS_NOT_APPROVED'
            )
          };

          $scope.rating.status = $scope.rating.status || 'potential';

          $scope.changeQualified = function(value) {
            $scope.$parent.$broadcast('changeQualified', value);
            if (
              value &&
              $scope.rating &&
              $scope.rating.status === 'potential'
            ) {
              $scope.rating.status = 'approved';
            }
          };
        }
      ]
    };
  });
