angular
  .module('company')

  .directive('companyForm', [
    '$filter',
    function($filter) {
      return {
        restrict: 'E',
        replace: true,
        require: '^ngModel',
        scope: {
          company: '=ngModel'
        },
        templateUrl: 'custom/company/views/partials/companyForm.html'
      };
    }
  ]);
