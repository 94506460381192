angular
  .module('pb.file')

  .service('PbUploader', [
    '$q',
    '$filter',
    'Upload',

    function($q, $filter, Upload) {
      'use strict';

      const bucket = '@@aws_bucket';
      const sizeLimit = parseInt('@@aws_sizeLimit');

      this.upload = function(file, key, progressCallback) {
        var deferred = $q.defer();
        var fileSize = Math.round(parseInt(file.size));
        var callback =
          typeof progressCallback === 'function'
            ? progressCallback
            : new Function();

        if (fileSize > sizeLimit) {
          //Size exceeds the limit
          deferred.reject(
            $filter('translate')('file.ERROR_DIMENSION') +
              Math.round(sizeLimit / 1024 / 1024) +
              ' MB'
          );
        } else {
          let data = {
            bucket: bucket,
            key: key,
            contentType: file.type,
            file: file
          };

          Upload.upload({
            url: '@@fileUrl' + '/uploads',
            data: data
          }).then(
            function() {
              deferred.resolve({
                bucket: bucket,
                key: key,
                size: fileSize,
                type: file.type
              });
            },
            function(err) {
              deferred.reject(err && err.data ? err.data : err);
            },
            function(evt) {
              callback(Math.round((100.0 * evt.loaded) / evt.total));
            }
          );
        }

        return deferred.promise;
      };
    }
  ]);
