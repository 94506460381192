angular
  .module('workflow')

  .directive('workflowSummary', [
    function() {
      'use strict';

      return {
        restrict: 'E',
        replace: true,
        scope: true,
        templateUrl: 'custom/workflow/views/partials/workflowSummary.html',
        controller: [
          '$scope',
          'WorkflowStore',
          'LoggedUser',
          '$state',
          'Confirm',
          '$filter',
          'WorkflowInvoicedCrud',
          '$timeout',
          'Dialog',
          'Toast',
          'WorkflowCancelBilledInvoiceCrud',
          'WorkflowChangeStatusToBilledCrud',
          'WorkflowStateChangeCrud',
          function(
            $scope,
            WorkflowStore,
            LoggedUser,
            $state,
            Confirm,
            $filter,
            WorkflowInvoicedCrud,
            $timeout,
            Dialog,
            Toast,
            WorkflowCancelBilledInvoiceCrud,
            WorkflowChangeStatusToBilledCrud,
            WorkflowStateChangeCrud
          ) {
            $scope.isPM = LoggedUser.isPM();
            $scope.isCustomer = LoggedUser.isCustomer();
            $scope.workflow = {};

            WorkflowStore.listenTo(function(workflow) {
              $scope.workflow = angular.copy(workflow || {});
            });

            $scope.goToOrder = function() {
              $state.go('root.app.simple.order.projects.list', {
                id: $scope.workflow._id
              });
            };

            $scope.forceContributorsMails = () => {
              new Confirm()
                .show(
                  $filter('translate')('workflow.SEND_CONTRIBUTORS_MAILS_MSG')
                )
                .then(() =>
                  WorkflowStore.emit('save', { forceContributorsMails: true })
                );
            };

            $scope.invoiceOrder = () => {
              new Confirm()
                .show($filter('translate')('order.INVOICE_ORDER_MSG'))
                .then(() => {
                  new WorkflowChangeStatusToBilledCrud()
                    .update({ invoiced: true })
                    .then(workflow => {
                      WorkflowStore.emitChange(workflow);
                      $timeout(
                        () =>
                          new Toast().success(
                            $filter('translate')('crud.SAVED')
                          ),
                        100
                      );
                    })
                    .catch(() => {
                      $timeout(
                        () =>
                          new Dialog().error(
                            $filter('translate')('order.INVOICE_ORDER_ERR')
                          ),
                        250
                      );
                    });
                })
                .catch(() => {});
            };

            $scope.setInvoiceToBill = () => {
              const { delivery_date_effective } = $scope.workflow || {};
              if (
                !delivery_date_effective ||
                !new Date(delivery_date_effective).getTime()
              ) {
                return new Dialog().error(
                  $filter('translate')(
                    'order.ACTUAL_DELIVERY_TIME_REQUIRED_ERROR'
                  )
                );
              }
              new Confirm()
                .show($filter('translate')('order.TO_BILL_MSG'))
                .then(() => {
                  new WorkflowInvoicedCrud()
                    .update({ invoiced: true })
                    .then(workflow => {
                      WorkflowStore.emitChange(workflow);
                      $timeout(
                        () =>
                          new Toast().success(
                            $filter('translate')('crud.SAVED')
                          ),
                        100
                      );
                    })
                    .catch(() => {
                      $timeout(
                        () =>
                          new Dialog().error(
                            $filter('translate')('order.INVOICE_ORDER_ERR')
                          ),
                        250
                      );
                    });
                })
                .catch(() => {});
            };

            $scope.cancelInvoice = () => {
              new Confirm()
                .show($filter('translate')('order.CANCEL_INVOICE_MSG'))
                .then(() => {
                  new WorkflowCancelBilledInvoiceCrud()
                    .update({})
                    .then(workflow => {
                      WorkflowStore.emitChange(workflow);
                      $timeout(
                        () =>
                          new Toast().success(
                            $filter('translate')('crud.SAVED')
                          ),
                        100
                      );
                    })
                    .catch(() => {
                      $timeout(
                        () =>
                          new Dialog().error(
                            $filter('translate')('order.CANCEL_INVOICE_ERR')
                          ),
                        250
                      );
                    });
                })
                .catch(() => {});
            };

            $scope.setWorkflowStatus = status => {
              new Confirm()
                .show($filter('translate')('order.ORDER_STATUS_CHANGE'))
                .then(() => {
                  new WorkflowStateChangeCrud()
                    .update({ status })
                    .then(workflow => {
                      WorkflowStore.emitChange(workflow);
                      $timeout(
                        () =>
                          new Toast().success(
                            $filter('translate')('crud.SAVED')
                          ),
                        100
                      );
                    })
                    .catch(() => {
                      $timeout(
                        () =>
                          new Dialog().error(
                            $filter('translate')(
                              'order.ORDER_STATUS_CHANGE_ERROR'
                            )
                          ),
                        250
                      );
                    });
                })
                .catch(() => {});
            };

            const increaseRevisionNumber = () => {
              let { revision = 0 } = $scope.workflow || {};
              revision++;
              WorkflowStore.emit('save', { revision });
            };

            $scope.revision = () => {
              new Confirm()
                .show($filter('translate')('quote.REVISION_MSG'))
                .then(() => increaseRevisionNumber())
                .catch(() => {});
            };

            $scope.isStatusNotBillable = () => {
              const { order } = $scope.workflow;
              const { status } = order || {};
              return (
                status &&
                ['internal', 'not_billable', 'pre_invoiced', 'test'].includes(
                  status
                )
              );
            };
          }
        ]
      };
    }
  ]);
