angular
  .module('home')

  .directive('pieDonut', function() {
    return {
      restrict: 'A',
      scope: {
        data: '<',
        onClick: '&'
      },
      link: function(scope, element, attrs) {
        element.bind &&
          element.bind('plotclick', () => {
            scope.onClick && scope.onClick();
            $('.flot-tooltip').hide();
          });

        var drawDough = function(data) {
          if (data != undefined) {
            /* Pie or Donut Chart */

            var innerRadius;
            var container;
            if (element[0].id == 'pie-chart') {
              container = '.flc-pie';
            } else if (element[0].id == 'donut-chart') {
              innerRadius = 0.5;
              container = '.flc-donut';
            }

            element.plot(data, {
              series: {
                pie: {
                  innerRadius: innerRadius,
                  show: true,
                  stroke: {
                    width: 2
                  }
                }
              },
              legend: {
                container: container,
                backgroundOpacity: 0.5,
                noColumns: 2,
                backgroundColor: 'white',
                lineWidth: 0
              },
              grid: {
                hoverable: true,
                clickable: true
              },
              tooltip: true,
              tooltipOpts: {
                //content: "%p.0%, %s",
                content: '%p.0% %s',
                shifts: {
                  x: 20,
                  y: 0
                },
                defaultTheme: false,
                cssClass: 'flot-tooltip'
              }
            });
          }
        };

        scope.$watch('data', function(newValue) {
          drawDough(newValue);
        });
      }
    };
  });
