angular
  .module('library')

  .directive('checkboxLibrary', function() {
    return {
      restrict: 'E',
      replace: false,
      scope: {
        edit: '=',
        value: '=ngModel'
      },
      template: `
      <div>
        <check-label class="text-center" ng-hide="edit" ng-model="value"></check-label>
          <div ng-show="edit" class="checkbox text-center">
          <label style="padding-left: 17px">
            <input type="checkbox" ng-model="value"> <i class="input-helper"></i>
          </label>
        </div>
      </div>`
    };
  });
