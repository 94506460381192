angular
  .module('pb.base')

  .controller('LoginCtrl', [
    '$rootScope',
    '$scope',
    '$timeout',
    '$filter',
    '$translate',
    '$state',
    'Auth',
    'AuthLogin',
    'MeCrud',
    'User',
    'Alert',

    function(
      $rootScope,
      $scope,
      $timeout,
      $filter,
      $translate,
      $state,
      Auth,
      AuthLogin,
      MeCrud,
      User,
      Alert
    ) {
      var alert = new Alert();
      $scope.currentLang = $translate.use();

      $scope.login = function() {
        Auth.login(new AuthLogin(), $scope.email, $scope.password)
          .then(() => $state.go('root.loginMfa', { email: $scope.email }))
          .catch(err => {
            const { message = '' } = err || {};
            const key =
              message === 'FORCE_RESET_PASSWORD'
                ? 'auth.FORCE_RESET_PASSWORD'
                : 'auth.LOGIN_ERROR';
            alert.error($filter('translate')(key));
          });
      };
    }
  ]);
