angular
  .module('company')

  .directive('activityList', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      replace: true,
      scope: {
        activities: '=ngModel',
        editable: '='
      },
      templateUrl: 'custom/company/views/partials/activityList.html',
      link: function(scope) {
        scope.add = function() {
          scope.activities = scope.activities || [];
          scope.activities.push({});
        };

        scope.delete = function(index) {
          scope.activities.splice(index, 1);
        };
      }
    };
  });
