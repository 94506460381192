angular
  .module('contributor')

  .directive('contributorEditHeader', [
    '$filter',
    function($filter) {
      'use strict';

      return {
        restrict: 'E',
        replace: true,
        scope: true,
        templateUrl:
          'custom/contributor/views/partials/contributorEditHeader.html',
        controller: [
          '$scope',
          'ContributorStore',
          'LoggedUser',
          function($scope, ContributorStore, LoggedUser) {
            // ContributorStore.listenTo(function (contributor) {
            //   $scope.showHeadquarter = contributor && contributor.lsp;
            // });

            $scope.isPM = LoggedUser.isPM();
          }
        ]
      };
    }
  ]);
