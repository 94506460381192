angular
  .module('company')

  .directive('selectDestinationUseDocument', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        destinationDocument: '=',
        macrosector: '=',
        type: '@',
        onChange: '&'
      },
      template:
        '<select ng-model="destinationDocument" ng-options="s.name.{{lang}} for s in documents track by s._id" ng-change="save()">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        '$timeout',
        'DestinationUseDocumentCrud',
        'UserLang',
        function(
          $scope,
          $controller,
          $timeout,
          DestinationUseDocumentCrud,
          UserLang
        ) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new DestinationUseDocumentCrud('-list'),
            packageName: 'document',
            dataName: 'documents',
            filters: {
              type: $scope.type
            }
          });

          $scope.lang = UserLang.get();

          $scope.loadList();

          $scope.save = () =>
            $timeout(() => $scope.onChange && $scope.onChange());
        }
      ]
    };
  });
