angular
  .module('pb.base')

  .directive('toggleSubmenu', function() {
    return {
      restrict: 'A',
      link: function(scope, element) {
        element.click(function() {
          element.parent().toggleClass('toggled');
          element
            .parent()
            .find('ul')
            .stop(true, false)
            .slideToggle(200);
        });
      }
    };
  });
