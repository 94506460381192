angular
  .module('workflow')

  .directive('costsSummary', [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'custom/workflow/views/partials/costsSummary.html',
        controller: [
          '$scope',
          '$filter',
          '$timeout',
          'Dialog',
          'CostsSummarySrv',
          'UserLang',
          function(
            $scope,
            $filter,
            $timeout,
            Dialog,
            CostsSummarySrv,
            UserLang
          ) {
            let alreadyAlerted;
            const summary = CostsSummarySrv;
            const userLang = UserLang.get();

            $scope.$watch(
              'project.totals',
              (totals = {}) => {
                const { amount = 0, profit: { gross = 0 } = {} } = totals;

                $scope.profitPercentage = ((gross / amount || 0) * 100).toFixed(
                  2
                );
              },
              true
            );

            $scope.$watch('project', function(newValue) {
              if (!newValue) {
                return;
              }
              if (
                newValue.services &&
                newValue.services[0] &&
                newValue.services[0].source
              ) {
                summary.setSource(newValue.services[0].source);
              }
              if (
                newValue.services &&
                newValue.services[0] &&
                newValue.services[0].target &&
                newValue.services[0].target[0]
              ) {
                summary.setTarget(newValue.services[0].target[0]);
              }
              if (newValue.batches) {
                summary.setBatches(newValue.batches);
              }
              if (newValue.customIndirectCostsRatio) {
                $scope.ratio = summary.setRatio(
                  newValue.customIndirectCostsRatio
                );
              }

              calculateTotals();
            });

            $scope.$watch('company', function(newValue) {
              newValue && summary.setCompany(newValue);
            });

            const noListAlert = function(task) {
              let { name } = task;
              name = name[userLang] || name;
              const dialog = new Dialog();
              dialog.info(
                $filter('translate')('workflow.NO_LIST_ERR') + ': ' + name
              );
              alreadyAlerted = true;
            };

            const incompleteListAlert = function(task) {
              const dialog = new Dialog();
              let { name } = task;
              name = name[userLang] || name;
              dialog.error(
                $filter('translate')('workflow.INCOMPLETE_LIST_ERR') +
                  ': ' +
                  name
              );
            };

            $scope.searchPrice = task => summary.searchPrice(task);

            $scope.pricePerTask = function(task) {
              return summary.pricePerTask(task);
            };

            $scope.totalPrice = function() {
              const returned = summary.totalPrice();

              if (returned.incompleteList) {
                incompleteListAlert(returned.incompleteList);
              }
              if (returned.ok) {
                return returned.ok;
              } else if (returned.error && !alreadyAlerted) {
                noListAlert(returned.error);
              }
              return 0;
            };

            $scope.totContributorCost = function() {
              return summary.totContributorCost('trados');
            };

            $scope.pricePerUnit = function(task) {
              return summary.pricePerUnit(task.unit.list.prices, task.trados);
            };

            $scope.editRatio = newRatio => {
              if (newRatio) {
                $scope.project = $scope.project || {};
                $scope.project.customIndirectCostsRatio = $scope.ratio = summary.setRatio(
                  newRatio / 100
                );
                $timeout(() => $scope.save());
              }
              $scope.editingRatio = !$scope.editingRatio;
              delete $scope.newRatio;
            };

            $scope.pmFee = summary.getFee();
            $scope.ratio = summary.getRatio();

            const calculateTotals = () => {
              const totContributorCost = $scope.totContributorCost();
              const totalPrice = $scope.totalPrice() || 0;
              const indirectCosts = totalPrice * $scope.ratio;

              Object.assign($scope.project, {
                totals: {
                  amount: totalPrice,
                  costs: {
                    directs: totContributorCost,
                    indirects: indirectCosts,
                    pm: $scope.pmFee,
                    total: totContributorCost + indirectCosts + $scope.pmFee
                  },
                  profit: {
                    gross: totalPrice - totContributorCost,
                    net:
                      totalPrice * (1 - $scope.ratio) -
                      $scope.pmFee -
                      totContributorCost
                  }
                }
              });
            };
          }
        ]
      };
    }
  ]);
