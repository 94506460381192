angular
  .module('customer')

  .factory('CustomerStore', [
    'Store',

    function(Store) {
      'use strict';

      class CustomerStore extends Store {
        emitActive(active) {
          this.set('active', active).emitChange();
        }
      }

      return new CustomerStore();
    }
  ]);
