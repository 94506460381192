angular
  .module('company')

  .directive('companyContacts', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        company: '=ngModel',
        save: '&',
        undo: '&'
      },
      templateUrl: 'custom/company/views/partials/companyContacts.html'
    };
  });
