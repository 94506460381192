angular
  .module('pb.base')

  .directive('previewOptions', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      transclude: true,
      templateUrl: 'modules/base/views/partials/previewOptions.html',
      scope: {
        data: '=ngModel',
        resource: '@'
      }
    };
  });
