angular
  .module('contributor')

  .config([
    '$stateProvider',

    function($stateProvider) {
      var path = 'custom/contributor/views/';

      var tab_permission_default = {
        resources: { contributors: ['read', 'me'] }
      };

      $stateProvider
        .state('root.app.simple.contributors', {
          url: '/contributors',
          params: {
            fromId: null,
            fromProjectCode: null,
            fromState: null,
            batch: null,
            task: null,
            filterByTask: null,
            filterBySource: null,
            filterByTarget: null,
            filterByPrice: null,
            customerBlacklist: null,
            document: null,
            macrosector: null,
            sector: null,
            iso17100: null,
            sourceCode: null,
            targetCode: null,
            company: null
          },
          views: {
            header: {
              templateUrl: path + 'search-header.html',
              controller: 'ContributorHeaderCtrl'
            },
            content: {
              templateUrl: path + 'search.html',
              controller: 'ContributorListCtrl'
            }
          },
          permissions: {
            resources: { contributors: ['read'] }
          }
        })

        .state('root.app.simple.contributor', {
          url: '/contributor/:id',
          views: {
            content: {
              templateUrl: path + 'edit.html',
              controller: 'ContributorEditCtrl'
            }
          },
          permissions: {
            resources: { contributors: ['read'] }
          }
        })

        .state('root.app.simple.contributor.about', {
          url: '/about',
          templateUrl: path + 'tab-about.html',
          controller: 'ContributorTabCtrl',
          permissions: tab_permission_default
        })

        .state('root.app.simple.contributor.headquarters', {
          url: '/headquarters',
          templateUrl: path + 'tab-headquarters.html',
          controller: 'ContributorTabCtrl',
          permissions: tab_permission_default
        })

        .state('root.app.simple.contributor.services_bilingual', {
          url: '/services-bilingual',
          templateUrl: path + 'tab-services-bilingual.html',
          controller: 'ContributorTabCtrl',
          permissions: tab_permission_default
        })

        .state('root.app.simple.contributor.services_monolingual', {
          url: '/services-monolingual',
          templateUrl: path + 'tab-services-monolingual.html',
          controller: 'ContributorTabCtrl',
          permissions: tab_permission_default
        })

        .state('root.app.simple.contributor.services_other', {
          url: '/services-other',
          templateUrl: path + 'tab-services-other.html',
          controller: 'ContributorTabCtrl',
          permissions: tab_permission_default
        })

        .state('root.app.simple.contributor.specializations', {
          url: '/specializations',
          templateUrl: path + 'tab-specializations.html',
          controller: 'ContributorTabCtrl',
          permissions: tab_permission_default
        })

        .state('root.app.simple.contributor.tools', {
          url: '/tools',
          templateUrl: path + 'tab-tools.html',
          controller: 'ContributorTabCtrl',
          permissions: tab_permission_default
        })

        .state('root.app.simple.contributor.cv', {
          url: '/cv',
          templateUrl: path + 'tab-cv.html',
          controller: 'ContributorTabCtrl',
          permissions: tab_permission_default
        })

        .state('root.app.simple.contributor.lsp', {
          url: '/lsp',
          templateUrl: path + 'tab-lsp.html',
          controller: 'ContributorTabCtrl',
          permissions: tab_permission_default
        })

        .state('root.app.simple.contributor.note', {
          url: '/note',
          templateUrl: path + 'tab-note.html',
          controller: 'ContributorTabCtrl',
          permissions: tab_permission_default
        })

        .state('root.app.simple.contributor.rating', {
          url: '/rating',
          templateUrl: path + 'tab-rating.html',
          controller: 'ContributorTabCtrl',
          permissions: {
            resources: { contributors: ['read'] }
          }
        })

        .state('root.app.simple.contributor.billing', {
          url: '/billing',
          templateUrl: path + 'tab-billing.html',
          controller: 'ContributorTabCtrl',
          permissions: tab_permission_default
        })

        .state('root.app.simple.contributor.agreement', {
          url: '/agreement',
          templateUrl: path + 'tab-agreement.html',
          controller: 'ContributorTabCtrl',
          permissions: tab_permission_default
        })

        .state('root.app.simple.contributor.holidays', {
          url: '/holidays',
          templateUrl: path + 'tab-holidays.html',
          controller: 'ContributorTabCtrl',
          permissions: tab_permission_default
        })

        .state('root.app.simple.contributor.doc', {
          url: '/doc',
          templateUrl: path + 'tab-doc.html',
          controller: 'ContributorDocCtrl',
          permissions: {
            resources: { contributors: ['read'] }
          }
        })

        .state('root.app.simple.create-contributor', {
          url: '/create-contributor',
          views: {
            header: {
              templateUrl: path + 'create-header.html',
              controller: 'ContributorHeaderCtrl'
            },
            content: {
              templateUrl: path + 'create.html',
              controller: 'ContributorCreateCtrl'
            }
          },
          permissions: {
            resources: { contributors: ['read'] }
          }
        });
    }
  ]);
