angular
  .module('contributor')

  .directive('contributorCantUpdate', [
    '$timeout',
    'LoggedUser',

    function($timeout, LoggedUser) {
      return {
        restrict: 'A',
        priority: 800,
        link: function(scope, element) {
          var user = LoggedUser.get();

          if (user.get().type === 'contributor' && user.isActive()) {
            $timeout(function() {
              element.attr('disabled', 'disabled');
              element
                .find('input, select, textarea, button')
                .attr('disabled', 'disabled');
              element.find('a').remove();
            });
          }
        }
      };
    }
  ]);
