angular
  .module('pb.file')

  .service('PbDownloader', [
    '$http',

    function($http) {
      let bucket = '@@aws_bucket';

      this.download = function(file) {
        let params = {
          bucket: bucket,
          key: typeof file === 'object' ? file.key : file,
          expires: 3600 // 1h
        };

        return $http({
          method: 'POST',
          url: '@@apiUrl' + '/get-file-url',
          data: params
        }).then(response => response.data);
      };
    }
  ]);
