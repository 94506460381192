angular
  .module('workflow')

  .directive('formBilling', [
    '$filter',
    function($filter) {
      'use strict';
      return {
        replace: true,
        scope: {
          billing: '=ngModel'
        },
        templateUrl: 'custom/workflow/views/partials/formBilling.html',
        controller: [
          '$scope',
          '$timeout',
          'WorkflowStore',
          function($scope, $timeout, WorkflowStore) {
            $scope.getBillFromCompany = function() {
              WorkflowStore.emit('company', WorkflowStore.get('company'));
            };
            $scope.addBillingAddress = function() {
              console.log('addBillingAddress');
            };
            $timeout(function() {
              $scope.getBillFromCompany();
            });
          }
        ]
      };
    }
  ]);
