'use strict';

angular.module('workflow').factory('Clipboard', [
  '$filter',
  'Toast',
  function($filter, Toast) {
    const copy = (text = '') => {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      const isCopySuccessful = document.execCommand('copy');
      document.body.removeChild(textArea);

      if (isCopySuccessful) {
        new Toast().success($filter('translate')('workflow.COPY_PATH_SUCCESS'));
      } else {
        new Toast().error($filter('translate')('workflow.COPY_PATH_ERROR'));
      }
    };

    return { copy };
  }
]);
