angular
  .module('customer')

  .directive('headquarterAssociation', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      scope: {
        customer: '=ngModel',
        save: '&',
        undo: '&'
      },
      templateUrl: 'custom/customer/views/partials/headquarterAssociation.html',
      controller: [
        '$scope',
        '$filter',
        'Dialog',
        'Confirm',
        function($scope, $filter, Dialog, Confirm) {
          var confirm = new Confirm(),
            dialog = new Dialog();

          $scope.existingHeadquarter = function() {
            if (!$scope.customer) {
              dialog.error($filter('translate')('ERROR'));
              return;
            }
            if (!$scope.headquarter) {
              dialog.error($filter('translate')('customers.NO_HEADQUARTER'));
              return;
            }

            confirm
              .info(
                $filter('translate')('customers.ASK_EXISTING_HEADQUARTER') +
                  '\n' +
                  $filter('translate')('ASK_CONTINUE')
              )
              .then(function() {
                $scope.customer.headquarter = $scope.headquarter;
                $scope.save();
              });
          };

          $scope.newHeadquarter = function() {
            confirm
              .info(
                $filter('translate')('customers.ASK_NEW_HEADQUARTER') +
                  '\n' +
                  $filter('translate')('ASK_CONTINUE')
              )
              .then(function() {
                $scope.save();
              });
          };
        }
      ]
    };
  });
