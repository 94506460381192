angular
  .module('components')

  .directive('createProfile', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        profile: '=ngModel',
        state: '@'
      },
      templateUrl: function(element, attrs) {
        if (attrs.templateUrl) {
          return attrs.templateUrl;
        }
        return 'custom/components/views/partials/createProfile.html';
      },
      controller: [
        '$scope',
        '$state',
        '$controller',
        'UserCrud',
        function($scope, $state, $controller, UserCrud) {
          $controller('SaveBaseCtrl', {
            $scope: $scope,
            crud: new UserCrud($scope),
            packageName: 'profile',
            dataName: 'profile',
            formName: 'profileForm'
          });

          $scope.save = function() {
            $scope.processSaving().then(function(response) {
              $state.go($scope.state, { id: response._id });
            });
          };

          $scope.undo = function() {
            if ($scope.state === '^.contributor.about') {
              $state.go('^.contributors');
            }
          };
        }
      ]
    };
  });
