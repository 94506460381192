angular
  .module('pb.base')

  .component('betaBanner', {
    template: `
        <div class="alert alert-info" role="alert" type="info">
            <div class="row">
                <div class="col-md-12">
                    {{::('BETA_BANNER' | translate)}} - {{::('BETA_BANNER_INFO' | translate)}} <b><a href="mailto:help@palmabit.com">{{::('CLICK_HERE' | translate)}}</a></b>
                </div>
            </div>
        </div>
      `
  });
