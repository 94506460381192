angular
  .module('pb.base')

  .factory('BootstrapService', [
    '$rootScope',
    '$timeout',
    '$q',
    'Auth',
    'UserLang',
    'MeCrud',
    'User',
    'CheckStatesPermissions',

    function(
      $rootScope,
      $timeout,
      $q,
      Auth,
      UserLang,
      MeCrud,
      User,
      CheckStatesPermissions
    ) {
      return function() {
        var deferred = $q.defer();

        function complete() {
          CheckStatesPermissions();
          $rootScope.$broadcast('onBootstrapComplete');
        }

        function setLang(user) {
          const { lang = '' } = user || {};
          var lcLang = UserLang.get();

          if (lang || lcLang) {
            UserLang.set(lang || lcLang);
          }
        }

        function goToLogin() {
          $timeout(function() {
            $rootScope.$broadcast('goToLogin');
            complete();
            deferred.reject();
          }, 1);
        }

        function goToWizard() {
          $timeout(function() {
            $rootScope.$broadcast('goToWizard');
            deferred.reject();
          }, 1);
        }

        //Get logged user if token is present
        if (Auth.isAuthenticated()) {
          new MeCrud().get({ id: Auth.getUserId() }).then(function(response) {
            if (response && response.active === true) {
              setLang(response);
              complete();
              deferred.resolve();
            } else {
              goToWizard();
            }
          }, goToLogin);
        } else {
          goToLogin();
        }

        return deferred.promise;
      };
    }
  ]);
