angular
  .module('pb.base')

  .directive('listHeader', function() {
    return {
      restrict: 'E',
      transclude: true,
      templateUrl: 'modules/base/views/partials/listHeader.html',
      scope: {
        search: '=search',
        noSearch: '@',
        title: '@'
      },
      link: function(scope, element, attrs) {
        var keywords;

        scope.search = {
          search: ''
        };

        if (attrs.keyword) {
          if (typeof attrs.keyword !== 'string') {
            throw new TypeError('keyword must be a string');
          }

          keywords = attrs.keyword.split(',');

          angular.forEach(keywords, function(value, i) {
            keywords[i] = keywords[i].trim();
          });

          angular.extend(scope.search, {
            keyword: keywords
          });
        }

        if (scope.noSearch === 'true') {
          scope.enableSearch = false;
        } else {
          scope.enableSearch = true;
        }
      }
    };
  });
