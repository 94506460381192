angular
  .module('library')

  .directive('listUnits', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        units: '=ngModel',
        taskId: '<',
        taskName: '<',
        isTranslation: '<',
        readonly: '<',
        langRequired: '<'
      },
      templateUrl: 'custom/library/views/partials/listUnits.html',

      controller: [
        '$scope',
        '$controller',
        '$timeout',
        '$filter',
        '$state',
        '$translate',
        'ValueLang',
        function(
          $scope,
          $controller,
          $timeout,
          $filter,
          $state,
          $translate,
          ValueLang
        ) {
          var currentLang = $translate.proposedLanguage() || $translate.use();

          $scope.getName = function(unit) {
            return ValueLang(unit, 'name', currentLang).value;
          };

          $scope.setPrice = function(newPrice, unit, range) {
            //console.log('setPrice', newPrice, range);
            if (unit.list && unit.list.prices) {
              unit.list.prices[range ? range : 0] = newPrice;
            } else {
              var rangeArray = [];
              rangeArray[range ? range : 0] = newPrice;
              unit.list = {
                dateStart: new Date(),
                price: rangeArray
              };
            }
          };

          $scope.getPrice = combo => {
            let { list } = combo;
            list = list || {};
            combo.list = list;
            const { notes, prices } = list;
            list.notes = notes || [];
            return prices;
          };

          $scope.getPriceString = function(combo) {
            if ($scope.getPrice(combo)) {
              return $scope.getPrice(combo).join(' - ');
            }
          };

          $scope.addRange = function(combo) {
            if ($scope.getPrice(combo)) {
              $scope.getPrice(combo).push(undefined);
            } else {
              combo.list = {
                dateStart: new Date(),
                prices: []
              };
              combo.list.prices.push(undefined);
            }
          };

          $scope.removeRange = function(combo) {
            if ($scope.getPrice(combo)) {
              $scope.getPrice(combo).pop();
            }
          };

          $scope.addFirstRange = function(combo) {
            if (!$scope.getPrice(combo) || !$scope.getPrice(combo).length) {
              $scope.addRange(combo);
            }
          };

          $scope.searchCompany = function() {
            $state.go('root.app.simple.companies', {
              associating: true,
              units: angular.copy($scope.units),
              task: {
                task_id: $scope.taskId,
                task_name: angular.copy($scope.taskName),
                translate: $scope.isTranslation,
                lang_required: $scope.langRequired
              }
            });
          };

          $scope.addCombo = unit => {
            const { lang_required } = $scope;
            const { combos = [] } = unit;

            unit.combos = combos;

            const combo = {};

            combo.list = {
              dateStart: new Date(),
              prices: []
            };

            if (lang_required) {
              combo.source = {};
            }

            if (lang_required === 2) {
              combo.target = {};
            }

            combos.push(combo);
          };

          $scope.deleteCombo = (unit, comboIndex) => {
            const { combos = [] } = unit;

            combos.splice(comboIndex, 1);
          };
        }
      ]
    };
  });
