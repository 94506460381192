angular
  .module('customer')

  .factory('ItalianCityCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class ItalianCityCrud extends Crud {
        constructor() {
          super(
            'signup',
            '@@apiUrl' + '/city/:country_code',
            { country_code: '@country_code' },
            { query: { method: 'GET', isArray: true } }
          );
        }
      }

      return ItalianCityCrud;
    }
  ]);
