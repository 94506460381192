angular
  .module('company')

  .directive('activityForm', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        company: '=ngModel',
        save: '&',
        undo: '&'
      },
      templateUrl: 'custom/company/views/partials/activityForm.html'
    };
  });
