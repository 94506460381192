angular
  .module('contributor')

  .directive('selectTasks', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        task: '=',
        type: '@'
      },
      template:
        '<select ng-model="task" ng-options="t.value for t in tasks track by t._id">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'TaskCrud',
        function($scope, $controller, TaskCrud) {
          var langs = 0;

          $controller('ListCtrl', {
            $scope: $scope,
            crud: new TaskCrud('-list'),
            packageName: 'tasks',
            dataName: 'tasks',
            filters: {}
          });

          switch ($scope.type) {
            case 'bilingual':
              langs = 2;
              break;
            case 'monolingual':
              langs = 1;
              break;
          }

          $scope.loadList({ lang_required: langs });
        }
      ]
    };
  });
