angular
  .module('workflow')

  .directive('saveContributorNotes', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        close: '&',
        task: '<'
      },
      template: `<button class="btn btn-link btn-sm waves-effect" type="button" ng-click="save()">
        {{::('SAVE' | translate)}}</button>`,
      controller: [
        '$scope',
        '$filter',
        'ContributorNotesCrud',
        'Dialog',
        function($scope, $filter, ContributorNotesCrud, Dialog) {
          $scope.save = () => {
            const dialog = new Dialog();
            const {
              notes,
              batchUuid,
              contributorCost,
              contributors: contributor = {},
              projectCode,
              uuid: taskUuid,
              workflowId
            } = $scope.task;
            const {
              networkUnit,
              networkUnitPrice,
              networkUnitQuantity,
              networkSecondUnit,
              networkSecondUnitPrice,
              networkSecondUnitQuantity
            } = contributor;
            const crud = new ContributorNotesCrud(
              workflowId,
              batchUuid,
              projectCode,
              taskUuid
            );
            const data = {
              contributorCost,
              networkUnit,
              networkUnitPrice,
              networkUnitQuantity,
              networkSecondUnit,
              networkSecondUnitPrice,
              networkSecondUnitQuantity,
              notes
            };
            crud
              .update(data)
              .then(() =>
                dialog.success(
                  $filter('translate')('workflow.SAVE_CONTRIBUTOR_NOTES_SUCC')
                )
              )
              .catch(error => {
                Sentry.captureException(error);
                dialog.error(
                  $filter('translate')('workflow.SAVE_CONTRIBUTOR_NOTES_ERR')
                );
              })
              .finally($scope.close({ reload: true }));
          };
        }
      ]
    };
  });
