angular
  .module('task')

  .factory('BatchDeliveredCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class BatchDeliveredCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/batch-delivered' + '/:id', { id: '@id' });
        }
      }
      return BatchDeliveredCrud;
    }
  ]);
