angular
  .module('components')

  .directive('buttonAdd', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        click: '&'
      },
      template: `
        <div class="text-right">
            <button class="btn btn-danger btn-icon m-15 waves-effect waves-circle" ng-click="click()">
                <i class="zmdi zmdi-plus"></i>
            </button>
        </div>
      `
    };
  });
