angular
  .module('pb.user')

  .directive('selectUser', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        user: '='
      },
      template:
        '<select ng-model="user" ng-options="u.name for u in users track by u._id" />',
      controller: [
        '$scope',
        '$controller',
        'UserListCrud',
        function($scope, $controller, UserListCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new UserListCrud(),
            packageName: 'user',
            dataName: 'users',
            filters: {
              limit: 100000
            }
          });

          $scope.loadList();
        }
      ]
    };
  });
