angular
  .module('library')

  .controller('LibraryCtrl', [
    '$scope',
    '$controller',
    'Dialog',
    'GetCrudOptions',
    '$filter',
    'Confirm',
    '$stateParams',
    '$state',
    'LibraryParser',
    'ValueLang',

    function(
      $scope,
      $controller,
      Dialog,
      GetCrudOptions,
      $filter,
      Confirm,
      $stateParams,
      $state,
      LibraryParser,
      ValueLang
    ) {
      var confirm = new Confirm();
      $scope.packageName = $stateParams.table;
      $scope.enableErrorNotifications = false;

      if (!$scope.packageName) {
        return;
      }

      var crudOptions = GetCrudOptions($scope.packageName);

      if (!crudOptions) {
        return;
      }

      $scope.tableParams = $stateParams.table + 'List';
      $scope.defaultSorting = { code: 'asc' };
      $scope.noAdd = crudOptions.noAdd;
      $scope.noDelete = crudOptions.noDelete;
      $scope.readOnlyCode = crudOptions.readOnlyCode;
      $scope.readOnlyName = crudOptions.readOnlyName;

      $controller('DataTableCtrl', {
        $scope: $scope,
        crud: crudOptions.crud,
        packageName: $scope.packageName,
        tableParams: $scope.tableParams,
        filters: {},
        parser: LibraryParser.arrayToObjects
      });

      $controller('SaveBaseCtrl', {
        $scope: $scope,
        crud: crudOptions.crud,
        packageName: $scope.packageName + '2',
        dataName: 'data',
        formName: 'libraryForm'
      });

      $controller('DeleteBaseCtrl', {
        $scope: $scope,
        crud: crudOptions.crud,
        packageName: $scope.packageName + '2',
        dataName: 'data'
      });

      $scope.save = function(item) {
        if (item.abbreviation) {
          item.abbreviation = addLangProperty(item.abbreviation);
        }

        $scope.data = LibraryParser.objectsToArray(item);
        $scope.processSaving().then(() => $scope.reload());
        item.$edit = false;
      };

      $scope.delete = function(item) {
        confirm
          .warning($filter('translate')('dialog.DELETE_ASK'))
          .then(function() {
            $scope.data = item;
            $scope.processRemoving().then(() => $scope.reload());
          });
      };

      $scope.undo = function(item) {
        $scope.reload();
        item.$edit = false;
      };

      $scope.edit = function(item) {
        if (crudOptions.editRoute) {
          $state.go(crudOptions.editRoute, { id: item._id });
          return;
        }
        item.$edit = true;
      };

      $scope.$on('addLibraryItem', function(e, item) {
        $scope.data = item;
        $scope
          .processSaving()
          .then(function() {
            $scope.reload();
          })
          .catch(err => {
            Sentry.captureException(err);
            const dialog = new Dialog();
            const error_code = err && err.code;
            switch (error_code) {
              case 11000: {
                dialog.error($filter('translate')('crud.error.11000'));
                break;
              }
            }
          });
      });

      $scope.hasOption = function(option) {
        if (!crudOptions || !crudOptions.options) {
          return false;
        }
        return crudOptions.options.indexOf(option) > -1;
      };

      $scope.getCreateRoute = function() {
        return crudOptions.createRoute;
      };

      $scope.getValueLang = function(w, key, lang) {
        return ValueLang(w, key, lang);
      };

      function addLangProperty(item) {
        const itemCopy = angular.copy(item);
        const keys = Object.keys(itemCopy);

        keys.forEach(key => {
          itemCopy[key] = Object.assign({}, itemCopy[key], { lang: key });
        });
        return itemCopy;
      }

      $scope.limitText = (text, maxChars) => {
        if (typeof text != 'string' || text.length <= maxChars) return text;
        return text.slice(0, maxChars) + '...';
      };
    }
  ]);
