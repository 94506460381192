angular
  .module('workflow')

  .factory('WorkflowLinkAsParentCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class WorkflowLinkAsParentCrud extends Crud {
        constructor(id) {
          super(null, '@@apiUrl' + '/link-as-parent/' + id, {});
        }
      }

      return WorkflowLinkAsParentCrud;
    }
  ]);
