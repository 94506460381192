angular
  .module('pb.core.form')

  .directive('pbForm', [
    'LoggedUser',

    function(LoggedUser) {
      return {
        restrict: 'A',
        scope: false,
        link: function(scope, element, attrs) {
          //Enable form
          function enable() {
            element.removeAttr('disabled');
            element.find('fieldset').removeAttr('disabled');
            element.find('button[type="submit"], a[type="submit"]').show();
          }

          //Disable form
          function disable() {
            element.attr('disabled', 'disabled');
            element.find('fieldset').attr('disabled', 'disabled');
            element.find('button[type="submit"], a[type="submit"]').hide();
          }

          if (LoggedUser.get().isReadonly(attrs.pbForm)) {
            disable();
          } else {
            //Watching for disabled attribute changes
            attrs.$observe('disabled', function(disabled) {
              disabled ? disable() : enable();
            });
          }
        },
        controller: [
          '$attrs',
          function($attrs) {
            this.getFormName = function() {
              return $attrs.name || '';
            };
          }
        ]
      };
    }
  ]);
