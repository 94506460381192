angular
  .module('company')

  .controller('HeadquartersCtrl', [
    '$scope',
    'FormValidator',
    'Dialog',
    '$filter',

    function($scope, FormValidator, Dialog, $filter) {
      $scope.save = function(index) {
        const form = $scope.$parent['companyForm'];
        if (form) {
          const validator = new FormValidator(form);
          if (!validator.validate()) {
            new Dialog().error($filter('translate')('crud.VALIDATION_ERROR'));
            return;
          }
        }

        $scope.headquarters = $scope.headquarters || [];

        //De-active previous legal address
        if (
          $scope.headquarters[index] &&
          $scope.headquarters[index].legal === true
        ) {
          angular.forEach($scope.headquarters, function(headquarter, i) {
            if (i !== parseInt(index)) {
              headquarter.legal = false;
            }
          });

          $scope.store.emit('legal_address', $scope.headquarters[index]);
        }

        // Save always not editable
        angular.forEach($scope.headquarters, function(headquarter, i) {
          headquarter.editable = false;
        });

        $scope.processSaving();
      };

      $scope.add = function() {
        $scope.headquarters = $scope.headquarters || [];
        $scope.headquarters.push({ editable: true });
      };

      $scope.delete = function(index) {
        $scope.headquarters.splice(index, 1);
        $scope.store.emit('save', { headquarters: $scope.headquarters });
      };
    }
  ]);
