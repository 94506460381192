angular
  .module('contributor')

  .directive('contributorCv', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        contributor: '=ngModel',
        filesNumber: '='
      },
      templateUrl: 'custom/contributor/views/partials/contributorCv.html',
      controller: [
        '$scope',
        'ContributorStore',
        function($scope, ContributorStore) {
          $scope.$watchCollection('contributor.educations', function(newValue) {
            $scope.cvForm.$setValidity(
              'educationForm',
              newValue && newValue.length
            );
          });

          const callback = (event, message, options) => {
            const { type, filesNumber } = options;
            if (type !== 'contributor-cv') return;
            if ($scope.contributor.lsp) return;

            $scope.contributor.hasCV = filesNumber > 0;
            ContributorStore.emit('save');
          };

          $scope.$on('onFileDeleted', callback);
          $scope.$on('onFilesUploaded', callback);
        }
      ]
    };
  });
