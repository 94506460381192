angular
  .module('home')

  .directive('quotesPieChart', [
    '$controller',
    '$filter',
    'Colors',
    'QuoteCountersCrud',
    '$state',
    function($controller, $filter, Colors, QuoteCountersCrud, $state) {
      return {
        restrict: 'E',
        scope: false,
        templateUrl: 'custom/home/views/partials/quotesPieChart.html',
        link: function(scope) {
          $controller('LoadBaseCtrl', {
            $scope: scope,
            crud: new QuoteCountersCrud(),
            object: null,
            dataName: 'data'
          });

          scope.goToQuotes = () => $state.go('^.quotes');

          scope.noQuotes = true;
          scope.qCounters = [
            {
              data: 1,
              color: '#e3e3e3',
              label: ''
            }
          ];

          scope.processLoading().then(function(response) {
            var processedCounters = [];

            var rawCounters = response && response.counters;

            const colors = Colors.workflow.status;

            var capitalizeFirstLetter = function(string) {
              return string.charAt(0).toUpperCase() + string.slice(1);
            };

            /* Check if there are no quotes, translate labels and add proper colors  */
            var statusName;
            for (var key in rawCounters) {
              if (rawCounters[key]) {
                scope.noQuotes = false;
              }
              statusName = 'status.' + key;
              processedCounters.push({
                data: rawCounters[key],
                color: colors[key],
                label:
                  capitalizeFirstLetter($filter('translate')(statusName)) +
                  ' (' +
                  rawCounters[key] +
                  ')'
              });
            }

            scope.qCounters = scope.noQuotes
              ? scope.qCounters
              : processedCounters;
          });
        }
      };
    }
  ]);
