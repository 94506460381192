angular
  .module('company')

  .directive('headquartersFormList', [
    'Confirm',
    '$filter',
    function(Confirm, $filter) {
      return {
        restrict: 'E',
        replace: true,
        require: '^ngModel',
        scope: {
          headquarters: '=ngModel'
        },
        templateUrl: 'custom/company/views/partials/headquartersFormList.html',
        link: function(scope) {
          var confirm = new Confirm();

          scope.legal = {};

          function setLegal() {
            scope.headquarters = scope.headquarters || [{ legal: true }];

            if (scope.headquarters.length > 0) {
              scope.legal = scope.headquarters[0];
            }
          }

          scope.$watchCollection('headquarters', setLegal);

          scope.add = function() {
            scope.headquarters.push({ legal: false });
          };

          scope.delete = function(index) {
            confirm
              .info($filter('translate')('company.HEADQUARTERS_MESSAGE_DELETE'))
              .then(function() {
                scope.headquarters.splice(index, 1);
              });
          };
        }
      };
    }
  ]);
