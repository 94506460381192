angular
  .module('workflow')

  .directive('serviceFormModal', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        company: '<',
        service: '=ngModel',
        is24h: '<',
        templateType: '<'
      },
      templateUrl: 'custom/workflow/views/partials/service.html',
      controller: [
        '$scope',
        'LoggedUser',
        'WorkflowFields',
        'ServiceUtils',
        function($scope, LoggedUser, WorkflowFields, ServiceUtils) {
          $scope.userType = LoggedUser.getData().type;
          $scope.title = $scope.service.code || 'Nuovo servizio';

          $scope.formFieldsList = WorkflowFields.getFields();
          $scope.selectOptions = WorkflowFields.getOptions();

          $scope.checkServiceID = function(itemServiceId, element) {
            return ServiceUtils.checkServiceID(itemServiceId, element);
          };

          $scope.checkConditions = function(field) {
            return ServiceUtils.checkConditions(field, $scope.service);
          };

          $scope.isRequired = function(field) {
            return ServiceUtils.isRequired(field);
          };
        }
      ]
    };
  });
