angular
  .module('task')

  .factory('TasksInvoicedUndoCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class TasksInvoicedUndoCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/tasks-invoiced-undo');
        }
      }
      return TasksInvoicedUndoCrud;
    }
  ]);
