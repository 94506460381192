angular
  .module('task')

  .factory('BatchInvoicedCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class BatchInvoicedCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/batch-invoiced' + '/:id', { id: '@id' });
        }
      }
      return BatchInvoicedCrud;
    }
  ]);
