angular
  .module('company')

  .directive('companyActivationButton', [
    '$controller',
    '$filter',
    function($controller, $filter) {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: true,
        template:
          '<a href="" class="pv-follow-btn btn-success" ng-class="active ? \'btn-success\' : \'btn-danger\'" ng-transclude></a>',
        controller: [
          '$scope',
          '$element',
          'Confirm',
          'CompanyStore',
          'CompanyActivation',
          function($scope, $element, Confirm, CompanyStore, CompanyActivation) {
            var confirm = new Confirm();

            CompanyStore.listenTo(function(company) {
              $scope._id = company ? company._id : null;
              $scope.active = company ? !!company.active : false;
            });

            $controller('EditBaseCtrl', {
              $scope: $scope,
              crud: new CompanyActivation(),
              object: null,
              packageName: 'active',
              dataName: 'activeObj',
              formName: 'activeForm'
            });

            $element.click(function() {
              var active = $scope.active;
              confirm
                .info(
                  $filter('translate')(
                    active ? 'company.ASK_DEACTIVE' : 'company.ASK_ACTIVE'
                  )
                )
                .then(function() {
                  $scope.activeObj = {
                    _id: $scope._id,
                    active: !active
                  };

                  $scope.processSaving().then(function() {
                    $scope.active = !active;
                    CompanyStore.emitActive($scope.active);
                  });
                });
            });
          }
        ]
      };
    }
  ]);
