angular
  .module('pb.base')

  .directive('datepickerModal', function() {
    return {
      restrict: 'EA',
      scope: {
        date: '=',
        formatdate: '='
      },
      controller: [
        '$controller',
        '$scope',
        '$attrs',
        '$filter',
        'Utilities',
        function($controller, scope, $attrs, $filter, Utilities) {
          $controller('ModalBaseCtrl', {
            $scope: scope,
            controller: 'DatepickerModalInstanceCtrl',
            templateUrl: 'modules/base/views/partials/datepickerModal.html',
            content: {
              title: $attrs.title || $filter('translate')('DATE_SELECT')
            },
            childScope: false
          });

          var formatDate = $filter('translate')('DATE_FORMAT');

          scope.$on('datepickerRequest', function() {
            //Open modal function in ModalBaseCtrl
            scope.open('adapt');
          });

          scope.$watch('selectDate', function(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
              scope.date = Utilities.getDateNoon(newValue);
            }
          });

          scope.$watch('date', function(newValue, oldValue) {
            if (newValue) {
              return (scope.formatdate = $filter('date')(newValue, formatDate));
            }
            scope.formatdate = '';
          });
        }
      ],
      link: function(scope, element, attrs) {
        if (attrs.datepickerModal !== 'disable-click') {
          element.click(function() {
            scope.open('adapt');
          });
        }
      }
    };
  })

  .controller('DatepickerModalInstanceCtrl', [
    '$controller',
    '$scope',
    '$modalInstance',
    'content',

    function($controller, $scope, $modalInstance, content) {
      $controller('ModalBaseInstanceCtrl', {
        $scope: $scope,
        $modalInstance: $modalInstance,
        content: content
      });
    }
  ]);
