angular
  .module('workflow')

  .factory('WorkflowFields', [
    '$filter',

    function($filter) {
      'use strict';

      var formFieldsList = [
        {
          label: 'service_detail.SOURCE_FILE_FORMAT',
          inputType: 'select',
          shortLabel: 'inputFileFormat',
          serviceCodes: ['4']
        },
        {
          label: 'service_detail.DEST_FILE_FORMAT',
          inputType: 'select',
          shortLabel: 'outputFileFormat',
          serviceCodes: ['4']
        },
        {
          label: 'service_detail.ORIG_FILE_LANGUAGE',
          inputType: 'select-language',
          shortLabel: 'origFileLang',
          serviceCodes: ['6', '69', '114']
        },
        {
          label: 'service_detail.RECEIVE_FILE_LANG',
          inputType: 'select-multiple-languages',
          shortLabel: 'outFileLang',
          serviceCodes: ['6', '114']
        },
        {
          label: 'service_detail.SOURCE_FILE_FORMAT',
          inputType: 'select-multiple-formats',
          shortLabel: 'sourceFileFormat',
          serviceCodes: ['6', '7', '69', '114', '94', '32']
        },
        {
          label: 'service_detail.DELIVERY_FILE_FORMAT',
          inputType: 'select-multiple-formats',
          shortLabel: 'deliveryFileFormat',
          serviceCodes: ['6', '7', '69', '114', '94', '32']
        },
        {
          label: 'service_detail.CREATE_FILE_LANG',
          inputType: 'select-language',
          shortLabel: 'createFileLang',
          serviceCodes: ['69'],
          onlyFirst: true
        },
        {
          label: 'service_detail.CUR_SELECTION',
          inputType: 'select',
          shortLabel: 'curSel',
          serviceCodes: ['113']
        },
        {
          label: 'service_detail.CONTRACT',
          inputType: 'select',
          shortLabel: 'contract',
          serviceCodes: ['113']
        },
        {
          label: 'service_detail.MANAGEMENT',
          inputType: 'select',
          shortLabel: 'management',
          serviceCodes: ['113']
        },
        {
          label: 'service_detail.ORIG_SUPPORT',
          inputType: 'select',
          shortLabel: 'origSupport',
          serviceCodes: ['32', '94']
        },
        {
          label: 'service_detail.FINAL_SUPPORT',
          inputType: 'select',
          shortLabel: 'finalSupport',
          serviceCodes: ['32', '94']
        },
        {
          label: 'service_detail.AUDIO_TRANSCR',
          inputType: 'boolean',
          shortLabel: 'audioTranscr',
          serviceCodes: ['32']
        },
        {
          label: 'service_detail.LEN',
          inputType: 'time',
          shortLabel: 'len',
          serviceCodes: ['32']
        },
        {
          label: 'service_detail.MALE_SPKR_NUM',
          inputType: 'int',
          shortLabel: 'maleSpkrNum',
          serviceCodes: ['32']
        },
        {
          label: 'service_detail.FEMALE_SPKR_NUM',
          inputType: 'int',
          shortLabel: 'femaleSpkrNum',
          serviceCodes: ['32']
        },
        {
          label: 'service_detail.SUB_POSITION',
          inputType: 'select',
          shortLabel: 'subTtlPosition',
          serviceCodes: ['94']
        },
        {
          label: 'service_detail.EFFECTS',
          inputType: 'select',
          shortLabel: 'effects',
          serviceCodes: ['94']
        },
        {
          label: 'service_detail.COLOR',
          inputType: 'select',
          shortLabel: 'color94',
          serviceCodes: ['94']
        },
        {
          label: 'service_detail.INTER_TYPE',
          inputType: 'select',
          shortLabel: 'interType',
          serviceCodes: ['165'],
          requireFor: ['pm_s', 'pm_j']
        },
        {
          label: 'service_detail.SUBJECT',
          inputType: 'select-document-macrosector',
          shortLabel: 'subject',
          serviceCodes: ['165']
        },
        {
          label: 'service_detail.LOCATION',
          inputType: 'text',
          shortLabel: 'location',
          serviceCodes: ['165']
        },
        {
          label: 'service_detail.MOV_TYPE',
          inputType: 'text',
          shortLabel: 'moveType',
          serviceCodes: ['165'],
          conditions: [
            {
              fieldName: 'interType',
              requiredSelections: ['08']
            }
          ]
        },
        {
          label: 'service_detail.PEOPLE_NUM',
          inputType: 'select',
          shortLabel: 'peopleNum',
          serviceCodes: ['165'],
          conditions: [
            {
              fieldName: 'interType',
              requiredSelections: ['11']
            }
          ]
        },
        {
          label: 'service_detail.DAYS_NUM',
          inputType: 'int',
          shortLabel: 'daysNum',
          serviceCodes: ['165']
        },
        {
          label: 'service_detail.HOURS_PER_DAY',
          inputType: 'int',
          shortLabel: 'hoursPerDay',
          serviceCodes: ['165']
        },
        {
          label: 'service_detail.LANG_BI',
          inputType: 'boolean',
          shortLabel: 'langBi',
          serviceCodes: ['165']
        },
        {
          label: 'service_detail.GEAR_TYPE',
          inputType: 'select',
          shortLabel: 'gearType',
          serviceCodes: ['165'],
          conditions: [
            {
              fieldName: 'interType',
              requiredSelections: ['07']
            }
          ]
        },
        {
          label: 'service_detail.ROOM_AMP',
          inputType: 'boolean',
          shortLabel: 'roomAmp',
          serviceCodes: ['165'],
          conditions: [
            {
              fieldName: 'interType',
              requiredSelections: ['07', '10']
            }
          ]
        },
        {
          label: 'service_detail.ORG_BY',
          inputType: 'select',
          shortLabel: 'orgBy',
          serviceCodes: ['165'],
          conditions: [
            {
              fieldName: 'interType',
              requiredSelections: ['12']
            }
          ]
        },
        {
          label: 'service_detail.ASSER_TYPE',
          inputType: 'select',
          shortLabel: 'asserType',
          serviceCodes: ['11']
        },
        {
          label: 'service_detail.COPIES_NUM',
          inputType: 'int',
          shortLabel: 'copiesNum',
          serviceCodes: ['11', '9'],
          conditions: [
            {
              fieldName: 'asserType',
              requiredSelections: ['19']
            }
          ]
        },
        {
          label: 'service_detail.DEST_COUNTRY',
          inputType: 'select-country',
          shortLabel: 'destCountry',
          serviceCodes: ['12', '146', '14"']
        },
        {
          label: 'service_detail.PRINT_FILE_FORMAT',
          inputType: 'select-multiple-formats',
          //shortLabel: "printFileFormat",
          shortLabel: 'deliveryFileFormat', //must be the same as deliveryFileFormat
          serviceCodes: ['9']
        },
        {
          label: 'service_detail.FORMAT',
          inputType: 'text',
          shortLabel: 'format',
          serviceCodes: ['9']
        },
        {
          label: 'service_detail.PAPER_WEIGHT',
          inputType: 'int',
          shortLabel: 'paperWeight',
          serviceCodes: ['9']
        },
        {
          label: 'service_detail.COLOR',
          inputType: 'select',
          shortLabel: 'color9',
          serviceCodes: ['9']
        },
        {
          label: 'service_detail.BOOKBINDING',
          inputType: 'select',
          shortLabel: 'bookbinding',
          serviceCodes: ['9']
        },
        {
          label: 'service_detail.PRINT_TYPE',
          inputType: 'select',
          shortLabel: 'printType',
          serviceCodes: ['9']
        }
      ];

      var selectOptions = {
        curSel: [
          {
            value: $filter('translate')('service_detail.PUB_HOUSE'),
            code: '01'
          },
          {
            value: $filter('translate')('service_detail.TRANSEDIT'),
            code: '02'
          }
        ],
        management: [
          {
            value: $filter('translate')('service_detail.PUB_HOUSE'),
            code: '03'
          },
          {
            value: $filter('translate')('service_detail.TRANSEDIT'),
            code: '04'
          }
        ],
        contract: [
          {
            value: $filter('translate')('service_detail.PUB_HOUSE'),
            code: '05'
          },
          {
            value: $filter('translate')('service_detail.TRANSEDIT'),
            code: '06'
          }
        ],
        interType: [
          {
            value: $filter('translate')('service_detail.SIMUL'),
            code: '07'
          },
          {
            value: $filter('translate')('service_detail.NEGOT'),
            code: '08'
          },
          {
            value: $filter('translate')('service_detail.FAIR'),
            code: '09'
          },
          {
            value: $filter('translate')('service_detail.CONSEC'),
            code: '10'
          },
          {
            value: $filter('translate')('service_detail.CHUCHO'),
            code: '11'
          },
          {
            value: $filter('translate')('service_detail.TELECONF'),
            code: '12'
          }
        ],
        gearType: [
          {
            value: $filter('translate')('service_detail.CAB'),
            code: '13'
          },
          {
            value: $filter('translate')('service_detail.CUST_GEAR'),
            code: '14'
          }
        ],
        orgBy: [
          {
            value: $filter('translate')('service_detail.CUSTOMER'),
            code: '15'
          },
          {
            value: $filter('translate')('service_detail.TRANSEDIT'),
            code: '16'
          }
        ],
        asserType: [
          {
            value: $filter('translate')('service_detail.FROM_ORIGINAL'),
            code: '17'
          },
          {
            value: $filter('translate')('service_detail.FROM_COPY'),
            code: '18'
          },
          {
            value: $filter('translate')('service_detail.FILE_PR_DOC'),
            code: '19'
          }
        ],
        color: [
          {
            value: $filter('translate')('service_detail.QUAD'),
            code: '20'
          },
          {
            value: $filter('translate')('service_detail.BW'),
            code: '21'
          }
        ],
        bookbinding: [
          {
            value: $filter('translate')('service_detail.NONE'),
            code: '22'
          },
          {
            value: $filter('translate')('service_detail.COIL'),
            code: '23'
          },
          {
            value: $filter('translate')('service_detail.STAPLE'),
            code: '24'
          },
          {
            value: $filter('translate')('service_detail.NET'),
            code: '25'
          },
          {
            value: $filter('translate')('service_detail.PAPERBACK'),
            code: '26'
          }
        ],
        printType: [
          {
            value: $filter('translate')('service_detail.DIGITAL'),
            code: '27'
          },
          {
            value: $filter('translate')('service_detail.OFFSET'),
            code: '28'
          }
        ],
        peopleNum: [
          {
            value: $filter('translate')('service_detail.ONE'),
            code: '29'
          },
          {
            value: $filter('translate')('service_detail.TWO'),
            code: '30'
          }
        ]
      };

      function getFields() {
        return formFieldsList;
      }

      function getOptions() {
        return selectOptions;
      }

      return {
        getFields: getFields,
        getOptions: getOptions
      };
    }
  ]);
