angular
  .module('pb.user')

  .config([
    '$stateProvider',
    '$urlRouterProvider',

    function($stateProvider, $urlRouterProvider) {
      var path = 'modules/user/views/';

      $stateProvider

        .state('root.app.user', {
          url: '/user',
          controller: 'ProfileSwitchCtrl'
        })

        .state('root.app.user-about', {
          url: '/user-about/:id',
          templateUrl: path + 'userAbout.html',
          controller: 'ProfileCtrl'
        });
    }
  ]);
