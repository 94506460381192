angular
  .module('task')

  .directive('cardFilterTask', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      replace: true,
      scope: {
        filtersModel: '=ngModel',
        performSearch: '&search',
        contributor: '<'
      },
      templateUrl: 'custom/tasks/views/partials/cardFilterTask.html',
      controller: [
        '$scope',
        '$controller',
        function($scope, $controller) {
          $scope.prefix = 'task';

          $controller('filterController', {
            $scope: $scope
          });

          $scope.reset = () => {
            $scope.filtersModel = {};
            $scope.$broadcast('resetFilters');
          };
        }
      ]
    };
  });
