angular
  .module('workflow')

  .directive('projectFileData', function() {
    return {
      restrict: 'E',
      scope: {
        project: '=ngModel',
        required: '=',
        filesNumber: '<'
      },
      templateUrl: 'custom/workflow/views/partials/projectFileData.html'
    };
  });
