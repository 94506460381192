angular
  .module('customer')

  .directive('customerActivationButton', [
    '$controller',
    '$filter',
    function($controller, $filter) {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: true,
        template:
          '<a href="" class="pv-follow-btn btn-success" ng-class="active ? \'btn-success\' : \'btn-danger\'" ng-transclude></a>',
        controller: [
          '$scope',
          '$element',
          'Confirm',
          'CustomerStore',
          'CustomerActivation',
          function(
            $scope,
            $element,
            Confirm,
            CustomerStore,
            CustomerActivation
          ) {
            var confirm = new Confirm();

            CustomerStore.listenTo(function(customer) {
              $scope._id = customer ? customer._id : null;
              $scope.active = customer ? !!customer.active : false;
            });

            $controller('EditBaseCtrl', {
              $scope: $scope,
              crud: new CustomerActivation(),
              object: null,
              packageName: 'active',
              dataName: 'activeObj',
              formName: 'activeForm'
            });

            $element.click(function() {
              var active = $scope.active;
              confirm
                .info(
                  $filter('translate')(
                    active ? 'customers.ASK_DEACTIVE' : 'customers.ASK_ACTIVE'
                  )
                )
                .then(function() {
                  $scope.activeObj = {
                    _id: $scope._id,
                    active: !active
                  };

                  $scope.processSaving().then(function() {
                    $scope.active = !active;
                    CustomerStore.emitActive($scope.active);
                  });
                });
            });
          }
        ]
      };
    }
  ]);
