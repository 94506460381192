angular
  .module('contributor')

  .directive('contributorHolidays', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        holidays: '=ngModel'
      },
      templateUrl: 'custom/contributor/views/partials/contributorHolidays.html',
      controller: [
        '$scope',
        '$filter',
        function($scope, $filter) {
          $scope.addHoliday = () => {
            $scope.holidays = Array.isArray($scope.holidays)
              ? $scope.holidays
              : [];
            $scope.holidays.unshift({});
          };
          $scope.deleteHoliday = index => {
            $scope.holidays.splice(index, 1);
          };
        }
      ]
    };
  });
