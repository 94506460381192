angular
  .module('pb.user', ['ui.router', 'pb.core', 'pb.file', 'dcbImgFallback'])

  .run([
    '$filter',
    'Module',
    function($filter, Module) {
      var module = new Module();

      module.push({
        userMenu: {
          name: 'profile',
          title: $filter('translate')('user.PROFILE'),
          icon: 'zmdi zmdi-account',
          link: 'root.app.user'
        }
      });
    }
  ]);
