angular
  .module('home', ['pb.core'])

  .run([
    '$filter',
    'Module',
    '$rootScope',
    '$state',
    function($filter, Module, $rootScope, $state) {
      var module = new Module();

      module.push({
        menu: {
          name: 'home',
          title: $filter('translate')('HOME'),
          icon: 'zmdi zmdi-home',
          link: 'root.app.simple.home'
        }
      });

      //Go to home page
      $rootScope.$on('goToHome', function() {
        $state.go('root.app.simple.home');
      });
    }
  ]);
