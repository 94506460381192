angular
  .module('quote')

  .factory('QuoteLastDaysCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class QuoteLastDaysCrud extends Crud {
        constructor() {
          super(
            '',
            '@@apiUrl' + '/quotes-lastdays' + '/:id',
            { id: '@id' },
            {
              query: {
                method: 'GET',
                isArray: true
              }
            }
          );
        }
      }
      return QuoteLastDaysCrud;
    }
  ]);
