angular
  .module('pb.core.module')

  .factory('AbstractMenu', function() {
    //Composite pattern

    /**
     * AbstractMenu class
     * @param data
     * @constructor
     */
    var AbstractMenu = function(data) {
      this.set(data);
    };

    /**
     * Set menu
     * @param data
     * @returns {AbstractMenu}
     */
    AbstractMenu.prototype.set = function(data) {
      var menuData = angular.extend({}, angular.copy(data));

      angular.forEach(menuData.submenus, function(submenu, index) {
        if (typeof submenu === 'object' && !(submenu instanceof AbstractMenu)) {
          menuData.submenus[index] = new AbstractMenu(submenu);
        }
      });

      this.resource = menuData;
      return this;
    };

    /**
     * Get AbstractMenu object
     * @returns {*|void|Object}
     */
    AbstractMenu.prototype.get = function() {
      return angular.extend({}, this.resource, {
        submenus: this._getChildrenResource()
      });
    };

    /**
     * Get children resources (recursive)
     * @returns {Array}
     * @private
     */
    AbstractMenu.prototype._getChildrenResource = function() {
      var submenus = [];

      angular.forEach(this.getChildren(), function(submenu) {
        if (submenu instanceof AbstractMenu) {
          submenus.push(submenu.get());
        }
      });

      return submenus;
    };

    /**
     * Get child
     * @param i
     * @returns {*}
     */
    AbstractMenu.prototype.getChild = function(i) {
      return this.getChildren()[i] || undefined;
    };

    /**
     * Get children
     * @returns {*}
     */
    AbstractMenu.prototype.getChildren = function() {
      this.resource.submenus = this.resource.submenus || [];
      return this.resource.submenus;
    };

    /**
     * Has children
     * @returns {boolean}
     */
    AbstractMenu.prototype.hasChildren = function() {
      return this.getChildren().length > 0;
    };

    /**
     * Add child
     * @param submenu
     * @returns {AbstractMenu}
     */
    AbstractMenu.prototype.add = function(submenu) {
      this.getChildren().push(submenu);
      return this;
    };

    /**
     * Remove child
     * @param submenu
     * @returns {AbstractMenu}
     */
    AbstractMenu.prototype.remove = function(submenu) {
      var submenus = this.getChildren(),
        length = submenus.length;

      for (var i = 0; i < length; i += 1) {
        if (submenus[i] === submenu) {
          submenus.splice(i, 1);
          return this;
        }
      }

      return this;
    };

    return AbstractMenu;
  });
