angular
  .module('task')

  .factory('TaskCountersCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class TaskCountersCrud extends Crud {
        constructor() {
          super(
            null,
            '@@apiUrl' + '/tasks-counters' + '/:id',
            { id: '@id' },
            {
              query: {
                method: 'GET',
                isArray: false
              }
            }
          );
        }
      }
      return TaskCountersCrud;
    }
  ]);
