'use strict';
angular
  .module('library')

  .factory('NameArrayToObject', function() {
    return function(name = []) {
      function findName(lang) {
        const found =
          Array.isArray(name) && name.find(n => n && n.lang === lang);
        return found ? found.value : '';
      }
      return {
        it: findName('it'),
        en: findName('en')
      };
    };
  });
