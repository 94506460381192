angular
  .module('contributor')

  .directive('toolsConnection', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        connection: '=ngModel',
        label: '@'
      },
      templateUrl: 'custom/contributor/views/partials/toolsConnection.html'
    };
  });
