angular
  .module('pb.base')

  .config([
    '$provide',
    function($provide) {
      $provide.decorator('datepickerPopupDirective', [
        '$delegate',
        function($delegate) {
          var directive = $delegate[0];
          var link = directive.link;

          directive.compile = function() {
            return function(scope, element, attrs) {
              link.apply(this, arguments);
              element.mask('99/99/9999');
            };
          };

          return $delegate;
        }
      ]);
    }
  ])

  .directive('inputDatepicker', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      templateUrl: 'modules/base/views/partials/inputDatepicker.html',
      scope: {
        date: '=ngModel',
        inputClass: '@',
        onChange: '&'
      },
      link: function(scope, element, attrs, ngModelCtrl, $filter) {
        var tempDate;
        var minDays = attrs.mindays;
        var maxDays = attrs.maxdays;
        var allowClose = false;

        scope.$watch('opened', function(newValue) {
          allowClose = scope.date ? true : false;
          if (newValue === false && !allowClose) {
            scope.opened = true;
          } else if (newValue === false) {
            allowClose = false;
          }
        });

        scope.isRequired = false;

        attrs.$observe('required', function(value) {
          scope.isRequired = value;
        });

        scope.inputName = attrs.name || 'datepicker';
        scope.format = attrs.format || 'dd/MM/yyyy';

        if (minDays) {
          tempDate = new Date();
          tempDate = tempDate.setDate(tempDate.getDate() + parseInt(minDays));
          scope.minDate = tempDate;
        } else {
          scope.minDate = null;
        }

        if (maxDays) {
          tempDate = new Date();
          tempDate = tempDate.setDate(tempDate.getDate() + parseInt(maxDays));
          scope.maxDate = tempDate;
        } else {
          scope.maxDate = null;
        }

        scope.dateOptions = {
          formatYear: 'yy',
          startingDay: 1
        };

        scope.open = function($event, opened) {
          $event.preventDefault();
          $event.stopPropagation();

          scope[opened] = true;
          scope.date = null;
        };
      }
    };
  });
