angular.module('company').controller('UsedContributorsCtrl', [
  '$scope',
  '$controller',
  'UsedContributorsCrud',

  function($scope, $controller, UsedContributorsCrud) {
    $controller('CompanyTabCtrl', { $scope });

    $scope.usedContributors = [];

    $scope.searchContributors = (sourceLang, targetLang) => {
      const { code: source } = sourceLang || {};
      const { code: target } = targetLang || {};
      const { _id: company } = $scope.company || {};

      new UsedContributorsCrud()
        .query({ company, source, target })
        .then(
          (usedContributors = []) =>
            ($scope.usedContributors = usedContributors)
        )
        .catch(() => ($scope.usedContributors = []));
    };
  }
]);
