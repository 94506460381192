angular
  .module('contributor')

  .directive('updateRequestButton', [
    '$controller',
    '$filter',
    function($controller, $filter) {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
          id: '<'
        },
        template:
          '<a href="" class="pv-follow-btn btn-default" ng-transclude></a>',
        controller: [
          '$scope',
          '$element',
          '$timeout',
          'Confirm',
          'Dialog',
          'ContributorSendRequest',
          function(
            $scope,
            $element,
            $timeout,
            Confirm,
            Dialog,
            ContributorSendRequest
          ) {
            var confirm = new Confirm();
            var dialog = new Dialog();

            $controller('LoadBaseCtrl', {
              $scope: $scope,
              crud: new ContributorSendRequest(),
              object: null,
              dataName: 'requestObj'
            });

            $element.click(function() {
              confirm
                .info(
                  $filter('translate')(
                    'contributors.SEND_UPDATE_REQUEST_CONFIRM'
                  )
                )
                .then(function() {
                  $scope.requestObj = {
                    _id: $scope.id
                  };

                  $scope.processLoading().then(({ email }) => {
                    let message = $filter('translate')(
                      'contributors.SEND_UPDATE_REQUEST_SUCCESS'
                    );
                    if (email)
                      message += ` ${$filter('translate')(
                        'TO'
                      ).toLowerCase()} ${email}`;
                    $timeout(() => dialog.success(message), 100);
                  });
                });
            });
          }
        ]
      };
    }
  ]);
