angular
  .module('customer')

  .directive('changeHeadquarter', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      scope: {
        customer: '=ngModel',
        performSaving: '&save',
        undo: '&'
      },
      templateUrl: 'custom/customer/views/partials/changeHeadquarter.html',
      controller: [
        '$scope',
        '$filter',
        'Dialog',
        function($scope, $filter, Dialog) {
          var dialog = new Dialog();

          $scope.save = function() {
            if (!$scope.customer) {
              dialog.error($filter('translate')('ERROR'));
              return;
            }
            if (!$scope.customer.headquarter) {
              dialog.error($filter('translate')('customers.NO_HEADQUARTER'));
              return;
            }

            $scope.performSaving();
          };
        }
      ]
    };
  });
