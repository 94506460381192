angular
  .module('pb.file')

  .service('FilesZipDownload', [
    'FilesTempTokenCrud',
    '$http',

    function(FilesTempTokenCrud, $http) {
      const crud = new FilesTempTokenCrud();
      const bucket = '@@aws_bucket';

      return (keys, filename = 'attachment.zip') => {
        return crud
          .save({ bucket, keys })
          .then(({ token }) =>
            $http({
              responseType: 'blob',
              method: 'POST',
              url: '@@fileUrl' + '/get-zip',
              headers: {
                Authorization: token
              },
              data: {
                bucket,
                keys,
                filename
              }
            })
          )
          .then(({ data }) => {
            const blob = new Blob([data], { type: 'application/zip;' });
            saveAs(blob, filename);
          });
      };
    }
  ]);
