angular
  .module('company')

  .controller('SelectCompanyCtrl', [
    '$scope',
    '$controller',
    'CompanyCrud',

    function($scope, $controller, CompanyCrud) {
      $controller('ListCtrl', {
        $scope: $scope,
        crud: new CompanyCrud($scope),
        packageName: 'companies',
        dataName: 'companies',
        filters: {
          page: 1,
          limit: 1000000,
          sort: {
            company_name: 1
          }
        }
      });

      $scope.$watch('searchCompanyInput', name => {
        if (!name || name.length < 3) return delete $scope.companies;
        const filter = JSON.stringify({
          name,
          projectCompanyName: $scope.isFilter ? true : undefined
        });
        return $scope.loadList({ filter });
      });

      let firstCompanyChange = true;
      $scope.$watch('company', id => {
        if (id && firstCompanyChange) {
          $scope
            .loadList({ id })
            .then(company => ($scope.companies = [company]));
        }
        firstCompanyChange = false;
        if (!id) delete $scope.searchCompanyInput;
      });
    }
  ]);
