angular
  .module('quote')

  .directive('quoteConfirmButton', [
    '$controller',
    '$filter',
    'EmptyProjects',
    function($controller, $filter, EmptyProjects) {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
          id: '<',
          workflow: '='
        },
        template:
          '<a href="" class="pv-follow-btn btn-default" ng-transclude></a>',
        controller: [
          '$scope',
          '$element',
          '$timeout',
          'Confirm',
          'Dialog',
          'QuoteConfirmRequest',
          function(
            $scope,
            $element,
            $timeout,
            Confirm,
            Dialog,
            QuoteConfirmRequest
          ) {
            var confirm = new Confirm();
            var dialog = new Dialog();

            $controller('LoadBaseCtrl', {
              $scope: $scope,
              crud: new QuoteConfirmRequest(),
              object: null,
              dataName: 'requestObj'
            });

            var emptyProjects = new EmptyProjects();

            $element.click(function() {
              if (!emptyProjects.isEmpty($scope.workflow)) {
                confirm
                  .info(
                    $filter('translate')('quote.SEND_REQUEST_CONFIRM') +
                      '\n' +
                      $filter('translate')('quote.SEND_REQUEST_CONFIRM_INFO')
                  )
                  .then(function() {
                    $scope.requestObj = {
                      _id: $scope.id
                    };

                    $scope.processLoading().then(function() {
                      $timeout(function() {
                        dialog.success(
                          $filter('translate')(
                            'quote.SEND_REQUEST_CONFIRM_SUCCESS'
                          )
                        );
                      }, 100);
                    });
                  });
              }
            });
          }
        ]
      };
    }
  ]);
