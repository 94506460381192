angular
  .module('components')

  .directive('vat', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      replace: true,
      scope: {
        vat: '=ngModel',
        prefix: '=',
        requiredVat: '<'
      },
      templateUrl: 'custom/components/views/partials/vat.html',
      controller: [
        '$scope',
        function($scope) {
          $scope.prefixPattern = /^[A-Z]{2}$/;

          var regexp = /^[0-9]{11}$/;

          function setPattern(prefix) {
            $scope.vatPattern = prefix !== 'IT' ? /^/ : regexp;
          }

          $scope.$watch('prefix', function(value, old) {
            if (value && value !== old) {
              value = value.toUpperCase();
              if (!value.slice(-1).match(/[A-Z]/)) {
                value = value.substring(0, value.length - 1);
              }
              if (value.length > 2) {
                value = value.slice(-2);
              }
              $scope.prefix = value;
              setPattern($scope.prefix);
            }
          });
        }
      ]
    };
  });
