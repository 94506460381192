angular
  .module('workflow')

  .factory('ServiceUtils', [
    'LoggedUser',

    function(LoggedUser) {
      'use strict';

      return {
        checkServiceID: function(itemServiceId, element) {
          return element.serviceCodes.some(function(id) {
            return itemServiceId === id;
          });
        },

        checkConditions: function(field, service) {
          if (field.onlyFirst && !service.first) {
            return false;
          }
          if (!field.conditions) {
            return true;
          }

          for (var i = 0; i < field.conditions.length; i++) {
            var condition = field.conditions[i];

            if (
              service[condition.fieldName] &&
              condition.requiredSelections.some(function(
                requiredSelectionCode
              ) {
                return (
                  requiredSelectionCode === service[condition.fieldName].code
                );
              })
            ) {
              return true;
            }
          }

          return false;
        },

        isRequired: function(field) {
          if (
            field.requireFor &&
            field.requireFor.some(function(element) {
              return LoggedUser.getData().type === element;
            })
          ) {
            return true;
          }
          return false;
        },

        generateServiceCode: function(project) {
          project.countService = project.countService || 1;
          return 'S' + project.countService++;
        }
      };
    }
  ]);
