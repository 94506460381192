angular
  .module('library')

  .factory('TaskModel', function() {
    function TaskModel() {
      return {
        name: [{ lang: 'it' }, { lang: 'en' }],
        description: [{ lang: 'it' }, { lang: 'en' }],
        abbreviation: [{ lang: 'it' }, { lang: 'en' }],
        units: []
      };
    }

    return TaskModel;
  });
