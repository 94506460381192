/* global jquery.nicescroll */

angular
  .module('pb.base')

  .service('NiceScroll', [
    '$rootScope',
    '$timeout',
    function($rootScope, $timeout) {
      var elements = [];

      var ns = {};
      ns.niceScroll = function(selector, color, cursorWidth, persist) {
        if ($rootScope.system.isMacOs) {
          return;
        }

        angular.forEach(elements, function(element) {
          $('div[id^="' + element + '"]').remove();
        });

        elements = [];

        $timeout(function() {
          var nscroll = $(selector).niceScroll({
            cursorcolor: color,
            cursorborder: 0,
            cursorborderradius: 0,
            cursorwidth: cursorWidth,
            bouncescroll: true,
            scrollspeed: 60,
            mousescrollstep: 40,
            autohidemode: false
          });

          if (!persist) {
            elements.push(nscroll.id);
          }
        }, 10);
      };

      return ns;
    }
  ]);
