angular
  .module('task')

  .factory('ContributorProjectManagerCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class ContributorProjectManagerCrud extends Crud {
        constructor() {
          super(
            null,
            '@@apiUrl/contributor-project-managers/',
            {},
            {
              get: {
                method: 'GET',
                isArray: true
              }
            }
          );
        }
      }

      return ContributorProjectManagerCrud;
    }
  ]);
