angular
  .module('company')

  .factory('CompanyModel', function() {
    function CompanyModel() {
      //Company default model
      return {};
    }

    return CompanyModel;
  });
