angular
  .module('pb.core.crud')

  .controller('SaveBaseCtrl', [
    'Dialog',
    'ErrorsManager',
    'Toast',
    'FormValidator',
    '$q',
    '$timeout',
    '$filter',
    '$rootScope',
    '$scope',
    'crud',
    'packageName',
    'dataName',
    'formName',

    function(
      Dialog,
      ErrorsManager,
      Toast,
      FormValidator,
      $q,
      $timeout,
      $filter,
      $rootScope,
      $scope,
      crud,
      packageName,
      dataName,
      formName
    ) {
      var dialog = new Dialog(),
        toast = new Toast();

      packageName = packageName || 'data';
      dataName = dataName || 'data';
      $scope[dataName] = $scope[dataName] || {};

      $scope.enableSuccessNotifications =
        typeof $scope.enableSuccessNotifications === 'boolean'
          ? $scope.enableSuccessNotifications
          : true;
      $scope.enableErrorNotifications =
        typeof $scope.enableErrorNotifications === 'boolean'
          ? $scope.enableErrorNotifications
          : true;

      //Save document to server
      $scope.processSaving = function(params) {
        var deferred, promise, validator;

        if (params && params.skipValidation) {
          // no form validation
        } else if (formName && $scope[formName]) {
          validator = new FormValidator($scope[formName]);

          if (!validator.validate()) {
            dialog.error($filter('translate')('crud.VALIDATION_ERROR'));
            deferred = $q.defer();
            deferred.reject();
            return deferred.promise;
          }
        }

        promise = $scope[dataName]._id
          ? crud.update($scope[dataName])
          : crud.save($scope[dataName]);
        return promise.then(
          function(response) {
            $scope[dataName] = response;
            $rootScope.$broadcast('reloadList.' + packageName);

            if ($scope.enableSuccessNotifications) {
              $timeout(function() {
                toast.success($filter('translate')('crud.SAVED'));
              }, 100);
            }
            return response;
          },
          function(err) {
            if ($scope.enableErrorNotifications) {
              $timeout(function() {
                dialog.error(ErrorsManager.getErrorsAsString(err));
              }, 100);
            }
            return $q.reject(err);
          }
        );
      };
    }
  ]);
