angular
  .module('task')

  .factory('TasksInvoicedCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class TasksInvoicedCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/tasks-invoiced');
        }
      }
      return TasksInvoicedCrud;
    }
  ]);
