angular
  .module('contributor')

  .directive('contributorAbout', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        contributor: '=ngModel'
      },
      templateUrl: 'custom/contributor/views/partials/contributorAbout.html',
      controller: [
        '$scope',
        'ContributorStore',
        'LoggedUser',
        function($scope, ContributorStore, LoggedUser) {
          $scope.isPM = LoggedUser.isPM();

          function findHeadquarter(response) {
            var i, headquarter;

            if (!Array.isArray(response.headquarters)) {
              return;
            }

            for (i = 0; i < response.headquarters.length; i += 1) {
              headquarter = response.headquarters[i];

              if (headquarter && headquarter.legal) {
                return headquarter;
              }
            }
          }

          ContributorStore.listenTo(function(response) {
            if (response.birth && response.birth.date) {
              const dateObj = new Date(response.birth.date);
              if (dateObj.getTime())
                response.birth.date = dateObj.toISOString();
            }
            if (response && !response.lsp) {
              ContributorStore.emit('legal_address', response.residence);
            } else {
              ContributorStore.emit('legal_address', findHeadquarter(response));
            }
          });
        }
      ]
    };
  });
