angular
  .module('contributor')

  .directive('contributorTools', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        contributor: '=ngModel'
      },
      templateUrl: 'custom/contributor/views/partials/contributorTools.html',
      controller: [
        '$scope',
        function($scope) {
          function hasSoftware(services) {
            if (!services || services.length == 0) {
              return false;
            }

            for (var i = 0; i < services.length; i += 1) {
              if (
                services[i] &&
                services[i].task &&
                services[i].task.software
              ) {
                return true;
              }
            }

            return false;
          }

          $scope.showSoftware = function() {
            if (!$scope.contributor || !$scope.contributor.services) {
              return false;
            }

            return (
              hasSoftware($scope.contributor.services.bilingual) ||
              hasSoftware($scope.contributor.services.monolingual) ||
              hasSoftware($scope.contributor.services.others)
            );
          };
        }
      ]
    };
  });
