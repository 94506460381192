angular
  .module('workflow')

  .controller('WorkflowWizardCtrl', [
    '$scope',
    '$state',
    '$stateParams',
    '$controller',
    '$filter',
    '$timeout',
    'BuidDefaultOfferTemplate',
    'WorkflowCrud',
    'WorkflowModel',
    'WorkflowStore',
    'Confirm',
    'Dialog',
    'EmptyProjects',
    'WorkflowOrigins',
    'LoggedUser',
    'PbWizard',
    'WorkflowStateChange',
    'Utilities',
    'BatchTaskCreator',
    'WorkflowISO17100',
    'S3CreateFolderCrud',
    'ProjectFilesUtils',

    function(
      $scope,
      $state,
      $stateParams,
      $controller,
      $filter,
      $timeout,
      BuidDefaultOfferTemplate,
      WorkflowCrud,
      WorkflowModel,
      WorkflowStore,
      Confirm,
      Dialog,
      EmptyProjects,
      WorkflowOrigins,
      LoggedUser,
      PbWizard,
      WorkflowStateChange,
      Utilities,
      BatchTaskCreator,
      WorkflowISO17100,
      S3CreateFolderCrud,
      ProjectFilesUtils
    ) {
      let wizard, stepsUrls;
      const confirm = new Confirm();
      const dialog = new Dialog();
      const emptyProjects = new EmptyProjects();

      $state.go('root.app.simple.workflow-wizard.step1');
      WorkflowStore.clear();
      $scope.editable = WorkflowStore.isEditable();

      $controller('EditBaseCtrl', {
        $scope: $scope,
        crud: new WorkflowCrud($scope),
        object: WorkflowModel,
        packageName: 'workflow',
        dataName: 'workflow',
        formName: null
      });

      //Initial loading
      if ($stateParams.id && $stateParams.id !== 'new') {
        $scope.processLoading().then(function() {
          $scope.workflow.step = 1;
          $scope.workflow.done = 1;
          $scope.workflow.complete = false;
          $scope.prefixAdminFiles =
            'workflows/' + $scope.workflow._id + '/admin_docs';

          $controller('WorkflowCompanyCtrl', {
            $scope: $scope
          });

          $scope.workflow.billing =
            $scope.workflow.billing || $scope.workflow.company;
          WorkflowStore.emitChange($scope.workflow);
        });
      }

      //Wizard
      $scope.steps = 5;
      stepsUrls = [1, 2, 3, 4, 5].map(step => {
        return '^.step' + step;
      });

      function setParams(data) {
        var d = data || {};

        Object.assign($scope.workflow, {
          step: d.step,
          done: d.completed,
          percentage: d.percentage,
          complete: d.finish
        });
      }

      function onNextStep(data) {
        setParams(data);

        $scope.processSaving().then(
          function() {
            $state.go(data.currentStep);
          },
          function() {
            wizard.cancel();
          }
        );
      }

      function onPreviousStep(data) {
        setParams(data);
        $state.go(data.currentStep);
      }

      function onStartFinish(done) {
        if (emptyProjects.isEmpty($scope.workflow)) {
          return;
        }
        if (LoggedUser.isCustomer()) {
          $scope.workflow.origin = WorkflowOrigins.teg;
        }
        if ($scope.workflow.type === 'order') {
          confirm
            .warning($filter('translate')('workflow.SEND_ORDER_REQUEST'))
            .then(function() {
              $scope.workflow.order.status = 'standby';
              done();
            });
          return;
        }
        confirm
          .warning($filter('translate')('workflow.SEND_QUOTE_REQUEST'))
          .then(function() {
            $scope.workflow.quote.status = 'standby';
            done();
          });
      }

      function onFinish(data) {
        setParams(data);

        $scope.processSaving().then(function(response) {
          $state.go('^.^.' + (response.type === 'order' ? 'orders' : 'quotes'));
        });
      }

      $scope.saveDraft = onFinish;

      function onExit(data) {
        // setParams(data);
        $scope.workflow.order.status
          ? $state.go('^.^.orders')
          : $state.go('^.^.quotes');
      }

      wizard = new PbWizard({
        stepsUrls: stepsUrls,
        onNextStep: onNextStep,
        onPreviousStep: onPreviousStep,
        onStartFinish: onStartFinish,
        onFinish: onFinish,
        onExit: onExit
      });
      //End wizard

      $scope.next = function() {
        wizard.next();
      };

      $scope.back = function(step) {
        step ? $scope.goTo(step) : wizard.previous();
      };

      $scope.goTo = function(step) {
        if (step === 4) {
          emptyProjects
            .hasNoFile($scope.workflow)
            .then(noFile => !noFile && wizard.goTo(step));
          return;
        }

        if (
          $scope.workflow.step !== 2 ||
          !emptyProjects.isEmpty($scope.workflow)
        ) {
          wizard.goTo(step);
        }
      };

      $scope.saveAndSend = changeStatus => {
        setParams();

        const { processSaving, steps, workflow = {} } = $scope;
        const { is24h, isTemplate, isDuplicated } = workflow;
        workflow.step = steps;
        workflow.complete = true;

        if (is24h) {
          workflow.forceContributorsMails = true;
        }

        return processSaving().then(({ type } = {}) => {
          const isOrder = type === 'order';
          const nextView = `^.^.${isOrder ? 'orders' : 'quotes'}`;

          if (changeStatus) {
            const nextWorkflowStatus =
              (is24h || isTemplate || isDuplicated) && isOrder
                ? 'progress'
                : 'standby';
            WorkflowStateChange.sendRequest(
              workflow,
              nextWorkflowStatus
            ).then(() => $state.go(nextView));
          } else {
            $state.go(nextView);
          }

          const key =
            ProjectFilesUtils.getHumanAdminPath(workflow) ||
            `workflows/${workflow._id}/admin_landoor/`;
          new S3CreateFolderCrud()
            .save({ key })
            .catch(err =>
              Sentry.captureException(
                err || new Error(`Error in creating S3 folder ${key}`)
              )
            );
        });
      };

      $scope.send = function() {
        if (emptyProjects.isEmpty($scope.workflow)) {
          return;
        }
        if ($scope.filesNumber < 1) {
          dialog.error($filter('translate')('file.NO_FILES_ERR'));
          return;
        }
        if (LoggedUser.isCustomer()) {
          $scope.workflow.origin = WorkflowOrigins.teg;
        }
        confirm
          .warning(
            $filter('translate')(
              $scope.workflow.type === 'order'
                ? 'workflow.SEND_ORDER_REQUEST'
                : 'workflow.SEND_QUOTE_REQUEST'
            )
          )
          // .then(() => BatchTaskCreator.generateBatchedProjects($scope.workflow.projects))
          // .then(batchedProjects => $scope.workflow.projects = batchedProjects)
          .then(() => BuidDefaultOfferTemplate($scope.workflow))
          .then(() => WorkflowISO17100($scope.workflow))
          .then(template =>
            Object.assign($scope.workflow, { offer: { template: template } })
          )
          .then(() => $scope.saveAndSend(true))
          .catch(error => {
            if (!error) return;
            Sentry.captureException(error);
            $timeout(
              () => dialog.error($filter('translate')('error.SOMETHING_WRONG')),
              300
            );
          });
      };

      $scope.splitProject = function(projectCode) {
        const originalProject = $scope.workflow.projects.find(
          ({ code }) => code === projectCode
        );
        const originalCode = originalProject.code || '';
        const originalUuid = originalProject.uuid || '';
        const duplicatedProjects = [];
        const dupProject = angular.copy(originalProject);

        while (originalProject.services[0].target.length > 1) {
          const oldPrefix = originalCode.substring(0, originalCode.length - 2);
          delete dupProject.uuid;
          dupProject.code =
            oldPrefix + Utilities.pad(++$scope.workflow.countProject, 2);
          dupProject.services[0].target = originalProject.services[0].target.splice(
            -1
          );
          dupProject.duplicatedFrom = {
            code: originalCode,
            uuid: originalUuid
          };
          duplicatedProjects.push(angular.copy(dupProject));
        }

        $scope.workflow.projects = $scope.workflow.projects.concat(
          duplicatedProjects
        );
      };

      $scope.$watch('workflow.projects[0].fileMode', (fileMode, oldValue) => {
        if (!fileMode || fileMode === oldValue) return;

        const { workflow = {} } = $scope;
        const { projects = [] } = workflow;
        projects.forEach((project = {}) => (project.fileMode = fileMode));
      });
    }
  ]);
