angular
  .module('pb.core.crud')

  .factory('Dispatcher', function() {
    'use strict';

    class Dispatcher {
      constructor(l, cl) {
        this.listeners = typeof l === 'object' ? l : {};
        this.changeListeners = Array.isArray(cl) ? cl : [];
        this.children = [];
      }

      addChild(child) {
        this.children.push(child);
        return this;
      }

      clearChildren() {
        this.children.forEach(child => {
          if (child && typeof child.clear === 'function') {
            child.clear();
          }
        });

        this.children = [];
        return this;
      }

      clear() {
        this.listeners = {};
        this.changeListeners = [];
        this.clearChildren();
        return this;
      }

      emit(type, event) {
        if (!this.listeners || !this.listeners[type]) {
          return;
        }

        this.listeners[type].forEach(listener => {
          listener(event);
        });
      }

      emitChange(event) {
        this.changeListeners.forEach(listener => {
          listener(event);
        });
      }

      addListener(type, listener) {
        this.listeners[type] = this.listeners[type] || [];
        this.listeners[type].push(listener);
        return this.listeners[type].length - 1;
      }

      listenTo(listener) {
        this.changeListeners.push(listener);
        return this.changeListeners.length - 1;
      }
    }

    return Dispatcher;
  });
