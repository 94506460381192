angular.module('company').component('companyInfo', {
  bindings: {
    company: '='
  },
  template: [
    '<div class="pmbb-body">',
    '<p>',
    '<span>{{$ctrl.company.company_name || "No name"}}</span><br>',
    '<span>{{$ctrl.company.vat || "No name"}}</span>',
    '</p>',
    '</div>'
  ].join('')
});
