angular
  .module('admin')

  .directive('selectManagerId', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        manager: '=',
        type: '@'
      },
      template:
        '<select ng-model="manager" ng-options="m._id as (m.last_name + \' \'+ m.first_name) for m in managers">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'UserCrud',
        'AccountManagerIdCache',
        'PMIdCache',
        function(
          $scope,
          $controller,
          UserCrud,
          AccountManagerIdCache,
          PMIdCache
        ) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new UserCrud($scope),
            packageName: 'manager',
            dataName: 'managers',
            filters: {
              limit: 100000,
              page: 1
            }
          });

          const { type } = $scope;
          let cache;

          if ($scope.type === 'am') cache = AccountManagerIdCache;
          else if ($scope.type === 'pm') cache = PMIdCache;

          const managers = cache && cache.get();
          if (managers) return ($scope.managers = managers);

          $scope
            .loadList({ type })
            .then(() => cache && cache.set($scope.managers));
        }
      ]
    };
  });
