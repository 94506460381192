angular
  .module('contributor')

  .controller('ContributorListCtrl', [
    '$scope',
    '$controller',
    '$timeout',
    '$state',
    '$stateParams',
    '$filter',
    '$rootScope',
    'ContributorSearchCrud',
    'ContributorSendRequests',
    'ContributorExport',
    'ContributorExportJson',
    'Confirm',
    'Dialog',
    'LSPrefix',
    'LoggedUser',
    'UsedContributorsCrud',

    function(
      $scope,
      $controller,
      $timeout,
      $state,
      $stateParams,
      $filter,
      $rootScope,
      ContributorSearchCrud,
      ContributorSendRequests,
      ContributorExport,
      ContributorExportJson,
      Confirm,
      Dialog,
      LSPrefix,
      LoggedUser,
      UsedContributorsCrud
    ) {
      $scope.addingToTask =
        $stateParams.fromState ===
          'root.app.simple.quote.projects.detail.tasks' ||
        $stateParams.fromState ===
          'root.app.simple.order.projects.detail.tasks';
      $scope.addingToBlacklist =
        $stateParams.fromState === 'root.app.simple.customer.contributorsList';
      $scope.skipInitialTableLoading =
        !$scope.addingToTask && !$scope.addingToBlacklist;
      $scope.usedContributors = [];

      const crud = new ContributorSearchCrud();

      $controller('DataTableCtrl', {
        $scope: $scope,
        crud,
        packageName: 'contributor',
        tableParams: 'contributorList',
        filters: {
          type: 'contributor',
          sort: {
            company_name: 1,
            last_name: 1,
            registration_date: 1
          }
        },
        parser: null
      });

      let searchEnabled = false;

      const storageName = `${LSPrefix}contributor.filters`;
      $scope.filters = JSON.parse(sessionStorage.getItem(storageName) || '{}');

      if (!LoggedUser.isHRM()) {
        const { active = 'active' } = $scope.filters;
        $scope.filters.active = active || 'active';
      }

      var confirm = new Confirm();
      var dialog = new Dialog();

      $scope.filtersApplied = false;

      $scope.reset = () => {
        $scope.filters = {};
      };

      $scope.search = function() {
        sessionStorage.setItem(storageName, JSON.stringify($scope.filters));
        $scope.reload($scope.filters);
      };

      $scope.searchAndResetPage = () => {
        $scope.search();
        $scope.resetPage();
      };

      const { name } = $scope.filters;
      $scope.searchName = { search: name };

      $scope.$watch(
        'searchName',
        function(newValue) {
          $scope.filters = $scope.filters || {};
          if (newValue && newValue.search) {
            searchEnabled = true;
            $scope.filters.name = newValue.search;
          } else if ($scope.filters.hasOwnProperty('name')) {
            delete $scope.filters.name;
          }

          if (searchEnabled) {
            $scope.searchAndResetPage();
          }
        },
        true
      );

      if ($scope.skipInitialTableLoading) {
        $scope.reload($scope.filters);
      }

      $scope.$on('downloadContributors', () => {
        ContributorExport.download($scope.filters);
      });

      $scope.$on('downloadSpecializations', () => {
        const url =
          '@@apiUrl' + '/contributors-export-iso9001-csv' + '?type=contributor';
        ContributorExport.download($scope.filters, url);
      });

      $scope.$on('downloadSpecializationsTotals', () => {
        const url = '@@apiUrl' + '/contributors-export-iso9001-totals';
        ContributorExport.download($scope.filters, url);
      });

      $scope.$on('exportContributorsToJson', () => {
        ContributorExportJson.download();
      });

      $scope.add = () => {
        $state.go('^.create-contributor');
      };

      if ($scope.addingToTask) {
        $scope.filters = { active: 'active' };
        $scope.filters.services = {};
        $scope.filters.services.monolingual = [];
        $scope.filters.services.bilingual = [];
        $scope.filters.services.others = [];
        //console.log('filterByTask', $stateParams.filterByTask);
        if ($stateParams.filterByTask) {
          switch ($stateParams.filterByTask.lang_required) {
            case 2:
              $scope.filters.services.bilingual.push({
                task: $stateParams.filterByTask,
                combo: {
                  source: $stateParams.filterBySource,
                  target: $stateParams.filterByTarget,
                  uom: $stateParams.filterByTask.unit,
                  price: $stateParams.filterByPrice
                }
              });
              break;
            case 1:
              $scope.filters.services.monolingual.push({
                task: $stateParams.filterByTask,
                combo: {
                  source: $stateParams.filterBySource,
                  uom: $stateParams.filterByTask.unit,
                  price: $stateParams.filterByPrice
                }
              });
              break;
            case 0:
              $scope.filters.services.others.push({
                task: $stateParams.filterByTask,
                combo: {
                  uom: $stateParams.filterByTask.unit,
                  price: $stateParams.filterByPrice
                }
              });
              break;
          }
        }
        $scope.filters.customerBlacklist = $stateParams.customerBlacklist;
        $scope.filters.specializations = $scope.filters.specializations || [];

        const { document, macrosector, sector } = $stateParams;

        if (macrosector) {
          const specialization = {
            macrosector,
            sectors: sector ? [sector] : [],
            documents: document ? [document] : [],
            rating: {
              status: 'approved',
              qualified: true,
              active: true
            }
          };
          $scope.filters.specializations.unshift(specialization);
        }

        $scope.filters.iso = !!$stateParams.iso17100;

        $scope.filtersApplied = true;
        $scope.reload($scope.filters);

        const {
          company,
          sourceCode: source,
          targetCode: target
        } = $stateParams;
        if (company) {
          new UsedContributorsCrud()
            .query({ company, source, target })
            .then(
              usedContributors =>
                ($scope.usedContributors = usedContributors || [])
            )
            .catch(() => ($scope.usedContributors = []));
        }
      }

      const showContributorsAlert = (contributors = []) => {
        const alerts = contributors
          .filter(c => c.alert)
          .map(
            c =>
              `${c.lsp ? c.company_name : `${c.first_name} ${c.last_name}`} ${
                c.alert
              }`
          );

        const notISO17100 = contributors
          .filter(({ lsp }) => !lsp)
          .filter(
            ({ iso }) => !iso || iso.code === '@@no_iso17100_suitability_code'
          )
          .map(
            c =>
              `${c.first_name} ${c.last_name} ${$filter('translate')(
                'contributors.ISO_17100_NOT_REACHED'
              )}`
          );

        const lspNotISO17100 = contributors
          .filter(({ lsp }) => lsp)
          .filter(({ certifications }) => {
            const certificationsList = certifications || [];
            return certificationsList.every(certification => {
              const { code } = certification || {};
              return ![
                '@@certification_iso17100_code',
                '@@certification_landoor17100_code'
              ].includes(code);
            });
          })
          .map(
            ({ company_name = '' }) =>
              `${company_name} ${$filter('translate')(
                'contributors.ISO_17100_NOT_REACHED'
              )}`
          );

        const messages = [...alerts, ...notISO17100, ...lspNotISO17100];

        if (messages.length) {
          return confirm
            .info(messages.join('\n'))
            .then(() => ({ alreadyConfirmed: true }));
        }

        return Promise.resolve();
      };

      const doAddContributorsToTask = (
        contributors = [],
        alreadyConfirmed = true
      ) => {
        const msg = $filter('translate')(
          `task.${
            contributors.length === 1
              ? 'ADD_CONTRIBUTOR_MSG'
              : 'ADD_CONTRIBUTORS_MSG'
          }`
        );
        (alreadyConfirmed ? Promise.resolve() : confirm.info(msg)).then(() => {
          $state.go($stateParams.fromState, {
            id: $stateParams.fromId,
            projectCode: $stateParams.fromProjectCode,
            newContributors: contributors,
            batch: $stateParams.batch,
            task: $stateParams.task
          });
        });
      };

      $scope.addContributorsToTask = contributor => {
        showContributorsAlert([contributor])
          .then(({ alreadyConfirmed } = {}) => {
            $timeout(
              () => doAddContributorsToTask([contributor], alreadyConfirmed),
              250
            );
          })
          .catch(() => {});
      };

      $scope.addUsedContributorToTask = ({ _id } = {}) => {
        if (!_id) return;
        crud
          .query({ _id, limit: 1, page: 1, type: 'contributor' })
          .then(({ results }) => {
            const [contributor] = results || [];
            return contributor && $scope.addContributorsToTask(contributor);
          })
          .catch(() => {});
      };

      $scope.addToBlackList = contributor => {
        confirm
          .info($filter('translate')('customers.ADD_CONTRIBUTOR_BLACKLIST_MSG'))
          .then(() => {
            $state.go($stateParams.fromState, {
              id: $stateParams.fromId,
              toBlacklist: contributor
            });
          });
      };

      const contributorsSelected = [];
      $scope.addSelected = contributor => {
        const contributorsSelectedIndex = contributorsSelected.findIndex(
          c => c._id === contributor._id
        );
        if (contributorsSelectedIndex > -1) {
          contributorsSelected.splice(contributorsSelectedIndex, 1);
        } else {
          contributorsSelected.push(contributor);
        }
        $scope.multiContributorsSelect = contributorsSelected.length > 0;
        $rootScope.$broadcast(
          'multiContributorsSelect',
          $scope.multiContributorsSelect
        );
      };

      $scope.$on('addContributorsToTask', () => {
        showContributorsAlert(contributorsSelected)
          .then(({ alreadyConfirmed } = {}) => {
            $timeout(
              () =>
                doAddContributorsToTask(contributorsSelected, alreadyConfirmed),
              250
            );
          })
          .catch(() => {});
      });

      const sendUpdateProfileRequests = () => {
        const contributorList =
          ($scope.contributorList && $scope.contributorList.data) || [];
        const contributorsCheckedIds = contributorList.reduce((acc, c) => {
          if (c.checked && c._id) {
            acc.push(c._id);
          }
          return acc;
        }, []);

        if (contributorsCheckedIds.length) {
          confirm
            .info(
              $filter('translate')('contributors.SEND_UPDATE_REQUESTS_CONFIRM')
            )
            .then(() => {
              const contributorSendRequestsCrud = new ContributorSendRequests();
              contributorSendRequestsCrud
                .save({ ids: contributorsCheckedIds })
                .catch(err => {
                  Sentry.captureException(err);
                  dialog.error($filter('translate')('error.SOMETHING_WRONG'));
                });
            });
        } else {
          dialog.error(
            $filter('translate')('contributors.NO_SELECTED_CONTRIBUTORS_MSG')
          );
        }
      };

      $scope.$on('sendUpdateProfileRequests', function() {
        sendUpdateProfileRequests();
      });

      $scope.$on('selectAllContributors', function() {
        if (
          $scope.contributorList &&
          Array.isArray($scope.contributorList.data)
        ) {
          $scope.contributorList.data.forEach(c => (c.checked = true));
        }
      });

      // Contributor Modal
      $scope.contributorDetailModalShow = false;

      $scope.closeContributorDetailModal = () => {
        $scope.contributorDetailModalShow = false;
      };

      $scope.showContributorDetailModal = contributor => {
        const {
          company_name = '',
          contacts = {},
          email = '',
          first_name = '',
          last_name = '',
          lsp,
          rating
        } = contributor || {};
        const contributorContacts = contacts || {};
        const { emails = [], phones = {} } = contributorContacts;
        const contributorEmailContacts = emails || [];
        const contributorPhoneContacts = phones || {};
        const { alternative = {} } = contributorPhoneContacts;
        const contributorAlternativePhoneContacts = alternative || {};
        let { note = '' } = rating || {};
        note = note || '';
        const servicesList = contributor.servicesList || [];

        const modalValues = [];

        servicesList.forEach(service => {
          const task = service.task || {};
          // Ignore non-translation tasks (code '1')
          if (task.code != '1') return;

          const combos = service.combos || [];

          combos.forEach(combo => {
            const { uom = {}, price = 0, source = {}, target = {} } =
              combo || {};
            const { value: sourceValue = '' } = source || {};
            const { value: targetValue = '' } = target || {};
            const { code: uomCode, value: uomValue = '' } = uom || {};
            // Ignore non-word uom (code '1')
            if (uomCode != '1') return;

            if (
              source.hasOwnProperty('name') &&
              target.hasOwnProperty('name')
            ) {
              const { it: sourceLanguage = '' } = source.name;
              const { it: targetLanguage = '' } = target.name;

              modalValues.push({
                name:
                  sourceLanguage && targetLanguage
                    ? `${sourceLanguage.trim()}-${targetLanguage.trim()}`
                    : 'N/D',
                value: price ? `${price}/${uomValue.trim()}` : 'N/D'
              });

              return;
            }

            modalValues.push({
              name:
                sourceValue && targetValue
                  ? `${sourceValue.trim()}-${targetValue.trim()}`
                  : 'N/D',
              value: price ? `${price}/${uomValue.trim()}` : 'N/D'
            });
          });
        });

        $scope.contributorDetailUserInfo = lsp
          ? company_name
          : last_name && first_name
          ? `${last_name} ${first_name}`
          : 'N/D';
        const contributorDetailEmail = [
          ...new Set([email, ...contributorEmailContacts])
        ]
          .filter(item => item)
          .join('; ');
        const contributorDetailPhone = [
          ...new Set(Object.values(contributorPhoneContacts))
        ]
          .filter(item => item && typeof item == 'string')
          .join('; ');
        const contributorDetailAlternativePhone = [
          ...new Set(Object.values(contributorAlternativePhoneContacts))
        ]
          .filter(item => item && typeof item == 'string')
          .join('; ');
        $scope.contributorDetailContacts =
          [
            contributorDetailEmail,
            contributorDetailPhone,
            contributorDetailAlternativePhone
          ]
            .filter(item => item && typeof item == 'string')
            .join('; ') || 'N/D';
        $scope.contributorDetailModalValues = modalValues.length
          ? modalValues
          : [{ name: 'N/D', value: 'N/D' }];
        const maxNotesLength = 3000;
        $scope.contributorNotes =
          note.substring(0, maxNotesLength) +
          (note.length > maxNotesLength ? '...' : '');
        $scope.contributorDetailModalShow = true;
      };
    }
  ]);
