angular
  .module('pb.core.crud')

  .factory('QueryFilters', [
    '$translate',
    function($translate) {
      return function(filters, search, page) {
        var query = {},
          searchStr = '',
          keywordStr = '';

        if (typeof page !== 'undefined') {
          query.limit = query.limit || 20;
          query = angular.extend(query, { page: page });
        }

        if (typeof search === 'string') {
          searchStr = search;
        } else if (typeof search === 'object') {
          searchStr = search.search || '';
          keywordStr = search.keyword || '';
        }

        if (searchStr && searchStr.length > 1) {
          query = angular.extend(query, { search: searchStr });
        }

        if (keywordStr && keywordStr.length > 0) {
          query = angular.extend(query, { keyword: keywordStr });
        }

        if (filters) {
          query = angular.extend(query, filters);
        }

        if (!query.lang) {
          query.lang = $translate.proposedLanguage() || $translate.use();
        }

        return query;
      };
    }
  ]);
