angular
  .module('workflow')

  .factory('ProjectStore', [
    'Store',

    function(Store) {
      'use strict';

      class ProjectStore extends Store {}

      return new ProjectStore();
    }
  ]);
