angular
  .module('workflow')

  .controller('WorkflowWizardStepCtrl', [
    '$scope',
    '$filter',
    '$timeout',
    '$anchorScroll',
    '$state',
    'Dialog',
    'FormValidator',
    'ProjectFilesUtils',
    'LoggedUser',
    'Utilities',

    function(
      $scope,
      $filter,
      $timeout,
      $anchorScroll,
      $state,
      Dialog,
      FormValidator,
      ProjectFilesUtils,
      LoggedUser,
      Utilities
    ) {
      const dialog = new Dialog();
      let validator;

      $scope.editable = true;
      $scope.ammDocsNumber = 0;
      $scope.isPM = LoggedUser.isPM();
      $scope.isCustomer = LoggedUser.isCustomer();

      //Scroll to top
      $anchorScroll(0);

      const updateProjectCodes = (workflow = {}) => {
        const {
          order_num,
          projects = [],
          quote_num,
          type,
          workflow_num
        } = workflow;

        let typePrefix, typeNumber;

        if (type === 'order') {
          typePrefix = 'O';
          typeNumber = order_num;
        } else {
          typePrefix = 'P';
          typeNumber = quote_num;
        }

        const typeCounter = typePrefix + Utilities.pad(typeNumber || 1, 6);
        const workflowCounter = 'C' + Utilities.pad(workflow_num || 1, 6);

        projects.forEach((project = {}) => {
          const { code = '' } = project;
          const split = code.split('_');
          const prefix = split[0];
          const suffix = split[3];
          project.code = `${prefix}_${typeCounter}_${workflowCounter}_${suffix}`;
        });
      };

      $timeout(() => {
        validator = new FormValidator($scope.workflowForm);
      });

      $scope.$watch('$parent.workflow', (workflow = {}) => {
        $scope.workflow = workflow;
        const { step, templateType } = workflow;
        if (step === 1 && templateType === '24h') {
          updateProjectCodes(workflow);
        }
      });

      $scope.back = function(step) {
        $scope.$parent.back(step);
      };

      function validateFormAndNext(step) {
        if (!validator.validate()) {
          dialog.error(
            $filter('translate')('crud.VALIDATION_ERROR') +
              '\n' +
              $filter('translate')('crud.VALIDATION_ERROR_VERIFY')
          );
          return;
        }

        $scope.$parent.next(step);
      }

      $scope.next = function(step) {
        if ($scope.workflow.step === 3) {
          $scope.filemodeRequired = false;

          var callback = function(noLoadedFiles) {
            if (noLoadedFiles) {
              $scope.filemodeRequired = true;
              $scope.$apply();
            }

            $timeout(function() {
              validateFormAndNext(step);
            });
          };

          if ($scope.isPM) {
            ProjectFilesUtils.validateSource(callback);
          } else {
            validateFormAndNext(step);
          }

          return;
        }

        validateFormAndNext(step);
      };

      $scope.send = () => {
        $timeout(() => {
          // if ($scope.isPM) {
          //   $scope.$parent.send();
          // }
          // else {
          //   if (($scope.workflow.type === 'order') && ($scope.ammDocsNumber < 1) &&
          //     $scope.workflow.company.settings && $scope.workflow.company.settings.requirePdf) {
          //     dialog.error($filter('translate')('file.NO_FILES_ERR'));
          //   }
          //   else {
          //     $scope.$parent.send();
          //   }
          // }
          $scope.$parent.send();
        });
      };

      $scope.confirmStep3 = () => {
        const { project = {}, filesNumber } = $scope;
        const { services = [], fileMode } = project;
        if (!services.length) {
          const dialog = new Dialog();
          const message = $filter('translate')('VALIDATION.NO_SERVICES_ERR');
          return dialog.error(message);
        }

        if (!fileMode && !filesNumber) {
          const dialog = new Dialog();
          const message = $filter('translate')(
            'quote.CANNOT_LOAD_FILE_WARNING'
          );
          return dialog.error(message);
        }
        $scope.processSaving().then(() => $scope.goTo(2));
      };
    }
  ]);
