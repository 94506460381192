angular
  .module('pb.core.user')

  .directive('pbCanRead', [
    'LoggedUser',

    function(LoggedUser) {
      return {
        restrict: 'A',
        priority: 1001,
        link: function(scope, element, attrs) {
          var resourceType = attrs.pbCanRead,
            resources = LoggedUser.get().getResources();

          if (!resourceType) {
            return;
          }

          if (
            typeof resources !== 'object' ||
            !resources[resourceType] ||
            !angular.isArray(resources[resourceType])
          ) {
            element.remove();
            return;
          }

          if (resources[resourceType].indexOf('read') >= 0) {
            //remove the attribute to avoid infinite loop
            element.removeAttr('pb-can-read');
          } else {
            element.remove();
          }
        }
      };
    }
  ]);
