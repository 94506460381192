angular
  .module('company')

  .directive('companyEmployees', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        employees: '=ngModel',
        showFoundation: '@'
      },
      templateUrl: 'custom/company/views/partials/companyEmployees.html'
    };
  });
