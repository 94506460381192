angular
  .module('task')

  .config([
    '$stateProvider',

    function($stateProvider) {
      var path = 'custom/tasks/views/';

      $stateProvider.state('root.app.simple.tasks', {
        url: '/tasks',
        params: {
          contributor: null
        },
        views: {
          content: {
            templateUrl: path + 'search.html',
            controller: 'TaskListCtrl'
          }
        },
        ncyBreadcrumb: {
          label: '{{::( breadCrumbTasks | uppercase )}}'
        },
        permissions: {
          resources: { contributor_tasks: ['read'] }
        }
      });
    }
  ]);
