angular
  .module('library')

  .directive('weight', function() {
    return {
      restrict: 'E',
      scope: {
        weight: '=ngModel'
      },
      template: `
      <table>
       <tr ng-repeat="z in weight">
        <td>
          {{z.type}}
        </td>
        <td style="padding: 3px">
          <input style="width: 50px" ng-model="z.value" type="number"
        </td>
       </tr>
      </table>`,

      controller: [
        '$scope',
        function($scope) {
          function setup(type) {
            var is = false;

            for (var i = 0; i < $scope.weight.length; i++) {
              if ($scope.weight[i].type === type) {
                is = true;
                break;
              }
            }

            if (!is) {
              $scope.weight.push({ type: type, value: 0 });
            }
          }

          if ($scope.weight) {
            setup('contributor');
            setup('supplier');
            setup('hostess');
            setup('dtp');
          }
        }
      ]
    };
  });
