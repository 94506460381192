angular
  .module('contributor')

  .directive('certification', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        iso: '=',
        certifications: '=',
        other: '='
      },
      templateUrl: 'custom/contributor/views/partials/certification.html',
      controller: [
        '$scope',
        '$controller',
        'CertificationCrud',
        '$timeout',
        function($scope, $controller, CertificationCrud, $timeout) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new CertificationCrud('-list'),
            packageName: 'certs',
            dataName: 'certs',
            filters: {}
          });

          $scope.loadList().then(
            $timeout(function() {
              setCertSelected();
            }, 1000)
          );

          function setSelected(cert) {
            $scope.certifications = $scope.certifications || [];

            for (var i = 0; i < $scope.certifications.length; i += 1) {
              if ($scope.certifications[i]._id === cert._id) {
                cert.selected = true;
                return;
              }
            }
          }

          function setCertSelected() {
            if ($scope.certs && $scope.certs.length > 0) {
              angular.forEach($scope.certs, function(c) {
                setSelected(c);
              });
            }
          }

          $scope.toggle = function(cert) {
            if (
              cert.selected &&
              $scope.certifications &&
              $scope.certifications.indexOf(cert) < 0
            ) {
              $scope.certifications.push(cert);
            } else if (!cert.selected) {
              for (var i = 0; i < $scope.certifications.length; i += 1) {
                if ($scope.certifications[i]._id === cert._id) {
                  $scope.certifications.splice(i, 1);
                }
              }
            }
            const shouldRemoveISO = $scope.certifications.every(
              certification => {
                if (!certification) return true;
                return ![
                  '@@certification_iso17100_code',
                  '@@certification_landoor17100_code'
                ].includes(certification.code);
              }
            );
            if (shouldRemoveISO) $scope.iso = '';
          };

          $scope.iso17100selected = function() {
            if ($scope.certifications) {
              for (var i = 0; i < $scope.certifications.length; i += 1) {
                if ($scope.certifications[i].value.indexOf('17100') >= 0) {
                  return true;
                }
              }
            }
            return false;
          };

          $scope.otherSelected = function() {
            if ($scope.certifications) {
              return $scope.certifications.some(function(cert) {
                return cert.other;
              });
            }
          };
        }
      ]
    };
  });
