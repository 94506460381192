angular.module('workflow').directive('dateOffer', function() {
  return {
    restrict: 'E',
    require: '^ngModel',
    replace: true,
    scope: {
      date: '=ngModel',
      defaultDeliveryDate: '<'
    },
    template: `
                <div>
                  <label for="date" class="col-sm-4 control-label"><h4 class="m-b-25">{{::('DATE' | translate)}}</h4></label> 
                  <div class="col-sm-8">
                    <div class="form-group fg-line">
                      <input-datepicker name="date" id="date" ng-model="date"/>
                    </div>
                  </div>
                </div>`,
    controller: [
      '$scope',
      'WorkflowStore',
      function($scope, WorkflowStore) {
        WorkflowStore.listenTo(workflow => {
          if (!Object.keys(workflow).length) return;
          $scope.date = new Date($scope.date).getTime()
            ? $scope.date
            : workflow.delivery_date
            ? new Date(workflow.delivery_date)
            : new Date();
        });
      }
    ]
  };
});
