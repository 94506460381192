angular
  .module('contributor')

  .directive('contributorVoicesScore', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        votes: '=ngModel',
        firstVote: '=',
        lastVote: '=',
        general: '@',
        utype: '='
      },
      templateUrl:
        'custom/contributor/views/partials/contributorVoicesScore.html',
      controller: [
        '$scope',
        '$filter',
        '$controller',
        'RatingVoiceCrud',
        'Confirm',
        '$timeout',
        function(
          $scope,
          $filter,
          $controller,
          RatingVoiceCrud,
          Confirm,
          $timeout
        ) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new RatingVoiceCrud('-list'),
            packageName: 'voices',
            dataName: 'voices',
            filters: {}
          });

          function setCurrentLast() {
            if ($scope.votes && $scope.votes.length > 0) {
              $scope.current = $scope.votes[$scope.votes.length - 1];
            }
          }

          $timeout(function() {
            setCurrentLast();
          }, 1000);

          $scope.addVotes = function() {
            $scope
              .loadList({ general: $scope.general, utype: $scope.utype })
              .then(function() {
                var vote = {
                  date: new Date(),
                  voices: []
                };

                angular.forEach($scope.voices, function(voice) {
                  var v = angular.copy(voice);
                  v.vote = 0;
                  vote.voices.push(v);
                });

                if (vote.voices.length === 0) {
                  return;
                }

                if (!$scope.votes) {
                  $scope.votes = [];
                }

                $scope.votes.push(vote);

                setCurrentLast();
              });
          };

          $scope.deleteVotes = function() {
            new Confirm()
              .info($filter('translate')('contributors.DELETE_VOTE'))
              .then(function() {
                var index = $scope.votes.indexOf($scope.current);
                $scope.votes.splice(index, 1);

                setCurrentLast();
              });
          };

          $scope.calculateScore = function(index) {
            var result = 0;

            if (
              !$scope.votes ||
              !$scope.votes ||
              !$scope.votes[index] ||
              !$scope.votes[index].voices
            ) {
              return result;
            }

            angular.forEach($scope.votes[index].voices, function(voice) {
              result += (voice.weight || 0) * (voice.vote || 0);
            });

            $scope.votes[index].vote = result;

            if (index === 0) {
              $scope.firstVote = result;
            } else if (index === $scope.votes.length - 1) {
              $scope.lastVote = result;
            }

            return result;
          };

          $scope.getVoteDescription = function(vote) {
            switch (vote) {
              case 1:
                return $filter('translate')('ratings.INSUFFICIENT');
              case 2:
                return $filter('translate')('ratings.SUFFICIENT');
              case 3:
                return $filter('translate')('ratings.GOOD');
              case 4:
                return $filter('translate')('ratings.EXCELLENT');
              default:
                return '';
            }
          };
        }
      ]
    };
  });
