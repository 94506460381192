angular
  .module('pb.base')

  .directive('alertNotification', [
    '$timeout',

    function($timeout) {
      return {
        restrict: 'E',
        templateUrl: 'modules/base/views/partials/alert.html',
        scope: true,
        link: function(scope, element, attrs) {
          var autoClose = parseInt(attrs.autoClose || 0),
            maxNumber = parseInt(attrs.maxNumber || 0);

          scope.alerts = [];

          scope.$on('onAlertNotification', function(event, notification) {
            if (maxNumber > 0 && scope.alerts.length >= maxNumber) {
              scope.alerts.shift();
            }

            scope.alerts.push(notification);

            if (autoClose > 0) {
              $timeout(function() {
                scope.alerts.shift();
              }, autoClose);
            }
          });

          scope.close = function(index) {
            scope.alerts.splice(index, 1);
          };
        }
      };
    }
  ]);
