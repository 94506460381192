angular
  .module('components')

  .directive('inputUrl', function() {
    return {
      restrict: 'E',
      replace: true,
      template:
        '<input type="text" pattern="^(http|https|ftp)?(://)?(www|ftp)?.?[a-z0-9-]+(.|:)([a-z0-9-]+)+(.)([a-z0-9-]+)+([/?].*)?$" pattern-msg="{{::(\'VALIDATION.URL\' | translate)}}">'
    };
  });
