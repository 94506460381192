angular
  .module('components')

  .directive('taxCode', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      replace: true,
      scope: {
        code: '=ngModel',
        nation: '=',
        vatPrefix: '<'
      },
      templateUrl: 'custom/components/views/partials/taxCode.html',
      controller: [
        '$scope',
        function($scope) {
          var regexp = /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$|^[0-9]{11}$/;

          function setPattern(code) {
            $scope.taxCodePattern = code !== 'IT' ? /^/ : regexp;
          }

          $scope.$watch('nation.code', function(value, old) {
            if (value !== old) {
              $scope.italianVat = value === 'IT';
              setPattern(value);
            }
          });

          $scope.$watch('vatPrefix', function(value) {
            $scope.italianVat = value === 'IT';
          });
        }
      ]
    };
  });
