angular
  .module('library')

  .directive('priceList', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        task: '<'
      },
      templateUrl: 'custom/library/views/partials/priceList.html',
      controller: [
        '$scope',
        '$controller',
        'TaskCrud',
        'Dialog',
        '$filter',
        '$timeout',
        function($scope, $controller, TaskCrud, Dialog, $filter, $timeout) {
          $scope.addCombo = () => {
            const { combos = [], lang_required } = $scope.task;

            $scope.task.combos = combos;

            const combo = {
              uom: {},
              price: ''
            };

            if (lang_required) {
              combo.source = {};
            }

            if (lang_required === 2) {
              combo.target = {};
            }

            combos.push(combo);
          };

          $scope.deleteCombo = (task, index) => {
            const { combos = [] } = task;

            combos.splice(index, 1);
          };
        }
      ]
    };
  });
