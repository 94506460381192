angular
  .module('customer')

  .factory('JobRoleCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class JobRoleCrud extends Crud {
        constructor(list) {
          super(
            'jobrole' + (list || ''),
            '@@apiUrl' + '/jobroles' + (list || '') + '/:id',
            { id: '@id' },
            {
              query: {
                method: 'GET',
                isArray: true
              }
            }
          );
        }
      }
      return JobRoleCrud;
    }
  ]);
