angular
  .module('pb.base')

  .directive('listFilterDropdown', [
    '$document',
    '$timeout',
    function($document, $timeout) {
      return {
        restrict: 'E',
        transclude: true,
        templateUrl: 'modules/base/views/partials/listFilterDropdown.html',
        scope: {
          initialCollapsed: '<',
          search: '=',
          title: '@'
        },
        link: function(scope, element, attrs) {
          var keywords;

          scope.isCollapsed =
            typeof scope.initialCollapsed === 'boolean'
              ? scope.initialCollapsed
              : true;
          scope.enableSearchInput = false;

          const { search = { search: '' } } = scope;

          scope.search = search;

          if (attrs.keyword) {
            if (typeof attrs.keyword !== 'string') {
              throw new TypeError('keyword must be a string');
            }

            keywords = attrs.keyword.split(',');

            angular.forEach(keywords, function(value, i) {
              keywords[i] = keywords[i].trim();
            });

            angular.extend(scope.search, {
              keyword: keywords
            });
          }

          // function close() {
          //   scope.enableSearchInput = false;
          //   scope.isCollapsed = true;
          //   scope.$apply();
          // }

          element.bind('click', function(e) {
            e.stopPropagation();
            if (
              e &&
              e.target &&
              (e.target.id === 'searchBar' ||
                e.target.id === 'searchPlaceHolder' ||
                e.target.id === 'searchInput')
            ) {
              $timeout(function() {
                element.find('.lvh-search > input')[0].focus();
              }, 100);
            }
          });

          // element.bind('keydown keypress', function(event) {
          //   if (event.which === 27) {
          //     // 27 = esc key
          //     close();
          //     event.preventDefault();
          //   }
          // });
          // $document.bind('click', close);
        }
      };
    }
  ]);
