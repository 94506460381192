angular
  .module('customer')

  .controller('CustomerListCtrl', [
    '$scope',
    '$controller',
    'UserCrud',
    '$state',
    '$filter',
    '$timeout',
    'CustomerExport',
    'LoggedUser',
    'Confirm',
    'Dialog',
    'ZohoCustomerSync',
    function(
      $scope,
      $controller,
      UserCrud,
      $state,
      $filter,
      $timeout,
      CustomerExport,
      LoggedUser,
      Confirm,
      Dialog,
      ZohoCustomerSync
    ) {
      let searchEnabled = false;
      const crud = new UserCrud($scope);
      $scope.isPM = LoggedUser.isPM();

      $controller('DataTableCtrl', {
        $scope: $scope,
        crud,
        packageName: 'customer',
        tableParams: 'customerList',
        filters: {
          type: 'customer',
          sort: {
            active: 1,
            last_name: 1,
            first_name: 1
          }
        },
        parser: null
      });

      $scope.add = () => {
        $state.go('^.create-customer');
      };

      $scope.search = () => {
        $scope.reload($scope.filters);
      };

      $scope.$watch(
        'searchName',
        function(newValue) {
          $scope.filters = $scope.filters || {};
          if (newValue && newValue.search) {
            searchEnabled = true;
            $scope.filters.name = newValue.search;
          } else if ($scope.filters.hasOwnProperty('name')) {
            delete $scope.filters.name;
          }

          if (searchEnabled) {
            $scope.search();
          }
        },
        true
      );

      $scope.$on('downloadCustomers', () => {
        CustomerExport.download();
      });

      $scope.deleteCustomer = (customer = {}) => {
        new Confirm()
          .show($filter('translate')('customers.DEL_MSG'))
          .then(() =>
            $timeout(() => {
              crud
                .delete(customer)
                .then(() => $scope.search())
                .catch(() => {});
            }, 250)
          )
          .catch(() => {});
      };

      $scope.zohoSync = function({ _id }) {
        new Confirm().show($filter('translate')('zoho.SYNC_MSG')).then(() => {
          new ZohoCustomerSync(_id)
            .save()
            .then(() => $scope.reload($scope.filters))
            .catch(error => {
              Sentry.captureException(error);
              $timeout(
                () =>
                  new Dialog().error($filter('translate')('zoho.SYNC_ERROR')),
                250
              );
            });
        });
      };
    }
  ]);
