angular
  .module('pb.base')

  .directive('selectUnit', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        unit: '=',
        preselectCode: '@'
      },
      template:
        '<select ng-model="unit" ng-options="u.value for u in units track by u._id">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'UnitCrud',
        function($scope, $controller, UnitCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new UnitCrud('-list'),
            packageName: 'units',
            dataName: 'units',
            filters: {}
          });

          $scope.loadList().then(() => {
            if (!$scope.preselectCode || $scope.unit) return;
            const preselectedUnit = $scope.units.find(
              ({ code }) => code === $scope.preselectCode
            );
            if (!preselectedUnit) return;
            $scope.unit = preselectedUnit;
          });
        }
      ]
    };
  });
