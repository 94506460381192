angular.module('components').factory('DeepMergeAndSum', function() {
  function isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  function deepMergeAndSum(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          deepMergeAndSum(target[key], source[key]);
        } else {
          const sum =
            typeof source[key] == 'number'
              ? source[key] + (target[key] || 0)
              : source[key];
          Object.assign(target, { [key]: sum });
        }
      }
    }

    return deepMergeAndSum(target, ...sources);
  }

  return deepMergeAndSum;
});
