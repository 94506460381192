angular
  .module('customer')

  .factory('CustomerModel', function() {
    function CustomerModel() {
      //Customer default model
      return {
        type: 'customer'
      };
    }

    return CustomerModel;
  });
