angular
  .module('pb.core.user')

  .directive('pbIsActive', [
    'LoggedUser',

    function(LoggedUser) {
      return {
        restrict: 'A',
        priority: 989,
        link: function(scope, element) {
          if (LoggedUser.get().isActive()) {
            //remove the attribute to avoid infinite loop
            element.removeAttr('pb-is-active');
          } else {
            element.remove();
          }
        }
      };
    }
  ]);
