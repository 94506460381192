angular
  .module('pb.user')

  .directive('userBasicInfo', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        user: '=ngModel',
        save: '&',
        undo: '&',
        isPm: '<',
        drivePrefix: '<',
        setDriveUnit: '&'
      },
      templateUrl: 'modules/user/views/partials/userBasicInfo.html'
    };
  });
