angular
  .module('contributor')

  .directive('associations', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        contribAssociations: '=ngModel'
      },
      templateUrl: 'custom/contributor/views/partials/associations.html',
      controller: [
        '$scope',
        '$controller',
        '$filter',
        'Dialog',
        'AssociationCrud',
        'UserLang',
        function(
          $scope,
          $controller,
          $filter,
          Dialog,
          AssociationCrud,
          UserLang
        ) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new AssociationCrud('-list'),
            packageName: 'associations',
            dataName: 'associations',
            filters: {}
          });

          $scope.loadList();
          $scope.userLang = UserLang.get();

          $scope.addAssociation = function() {
            var association = {
              association: {},
              description: ''
            };

            $scope.contribAssociations = $scope.contribAssociations || [];
            $scope.contribAssociations.push(association);
          };

          $scope.deleteAssociation = function(index) {
            $scope.contribAssociations.splice(index, 1);
          };
        }
      ]
    };
  });
