angular
  .module('customer')

  .factory('CountryCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class CountryCrud extends Crud {
        constructor(list) {
          super(
            'country' + (list || ''),
            '@@apiUrl' + '/countries' + (list || '') + '/:id',
            { id: '@id' },
            {
              query: {
                method: 'GET',
                isArray: true
              }
            }
          );
        }
      }

      return CountryCrud;
    }
  ]);
