angular
  .module('components')

  .factory('PdfCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class PdfCrud extends Crud {
        constructor(list) {
          super(
            'pdf' + (list || ''),
            '@@apiUrl' + '/pdf' + (list || '') + '/:id',
            { id: '@id' },
            {
              query: {
                method: 'GET',
                isArray: true
              }
            }
          );
        }
      }

      return PdfCrud;
    }
  ]);
