angular
  .module('pb.base')

  .directive('fgLine', function() {
    return {
      restrict: 'C',
      link: function(scope, element) {
        //On .fg-float, I'm using built in directive

        if (!element.hasClass('fg-float')) {
          var x = element.children('.form-control');

          x.focus(function() {
            element.addClass('fg-toggled');
          });

          x.blur(function() {
            element.removeClass('fg-toggled');
          });
        }
      }
    };
  });
