angular
  .module('customer')

  .controller('CustomerHeaderCtrl', [
    '$scope',
    '$filter',
    '$timeout',
    'Confirm',
    'Dialog',
    'LoggedUser',
    'ZohoCustomerBulkSync',
    function(
      $scope,
      $filter,
      $timeout,
      Confirm,
      Dialog,
      LoggedUser,
      ZohoCustomerBulkSync
    ) {
      $scope.isPM = LoggedUser.isPM();

      const confirm = new Confirm();
      const zohoCustomerBulkSync = new ZohoCustomerBulkSync();

      zohoCustomerBulkSync
        .get()
        .then(({ updatedAt }) => ($scope.lastZohoSync = updatedAt))
        .catch(() => {});

      $scope.download = () => {
        confirm.info($filter('translate')('DOWNLOAD_REQUEST')).then(() => {
          $scope.$parent.$broadcast('downloadCustomers');
        });
      };

      $scope.zohoBulkSync = () => {
        new Confirm()
          .show($filter('translate')('zoho.BULK_SYNC_MSG'))
          .then(() => {
            zohoCustomerBulkSync
              .save()
              .then(() => {
                $timeout(() => {
                  new Dialog().success(
                    $filter('translate')('zoho.BULK_SYNC_SUCCESS')
                  );
                }, 250);
              })
              .catch(error => {
                Sentry.captureException(error);
                $timeout(
                  () =>
                    new Dialog().error($filter('translate')('zoho.SYNC_ERROR')),
                  250
                );
              });
          });
      };
    }
  ]);
