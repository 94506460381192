angular
  .module('pb.user')

  .factory('MeApiCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class MeApiCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/me/:id', { id: '@id' });
        }
      }

      return MeApiCrud;
    }
  ]);
