angular
  .module('pb.base')

  //Html

  .directive('html', [
    'NiceScroll',
    function(NiceScroll) {
      return {
        restrict: 'E',
        link: function(scope, element) {
          if (!element.hasClass('ismobile')) {
            if (!$('.login-content')[0]) {
              NiceScroll.niceScroll(element, 'rgba(0,0,0,0.3)', '5px', true);
            }
          }
        }
      };
    }
  ])

  //Sidebar

  .directive('sidebarMenu', [
    'NiceScroll',
    function(NiceScroll) {
      return {
        restrict: 'C',
        link: function(scope, element) {
          if (!$('html').hasClass('ismobile')) {
            NiceScroll.niceScroll(element, 'rgba(0,0,0,0.5)', '5px', true);
          }
        }
      };
    }
  ])

  //Table

  .directive('tableResponsive', [
    'NiceScroll',
    function(NiceScroll) {
      return {
        restrict: 'C',
        link: function(scope, element) {
          if (!$('html').hasClass('ismobile')) {
            NiceScroll.niceScroll(element, 'rgba(0,0,0,0.3)', '5px');
          }
        }
      };
    }
  ])

  //Chosen

  .directive('chosenResults', [
    'NiceScroll',
    function(NiceScroll) {
      return {
        restrict: 'C',
        link: function(scope, element) {
          if (!$('html').hasClass('ismobile')) {
            NiceScroll.niceScroll(element, 'rgba(0,0,0,0.3)', '5px');
          }
        }
      };
    }
  ])

  //Tabs

  .directive('tabNav', [
    'NiceScroll',
    function(NiceScroll) {
      return {
        restrict: 'C',
        link: function(scope, element) {
          if (!$('html').hasClass('ismobile')) {
            NiceScroll.niceScroll(element, 'rgba(0,0,0,0.3)', '2px');
          }
        }
      };
    }
  ])

  //For custom class

  .directive('cOverflow', [
    'NiceScroll',
    function(NiceScroll) {
      return {
        restrict: 'C',
        link: function(scope, element) {
          if (!$('html').hasClass('ismobile')) {
            NiceScroll.niceScroll(element, 'rgba(0,0,0,0.5)', '5px');
          }
        }
      };
    }
  ]);
