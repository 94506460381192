angular
  .module('workflow')

  .controller('ServiceCtrl', [
    '$scope',
    'WorkflowStore',
    'ProjectStore',
    'WorkflowFields',
    'FormValidator',
    '$filter',
    'Dialog',
    'Confirm',
    '$stateParams',
    '$state',
    'ServiceUtils',
    '$timeout',
    'BatchModel',
    'BatchTaskCreator',
    'Utilities',
    'WorkflowISO17100',
    'CleanProject',

    function(
      $scope,
      WorkflowStore,
      ProjectStore,
      WorkflowFields,
      FormValidator,
      $filter,
      Dialog,
      Confirm,
      $stateParams,
      $state,
      ServiceUtils,
      $timeout,
      BatchModel,
      BatchTaskCreator,
      Utilities,
      WorkflowISO17100,
      CleanProject
    ) {
      var dialog = new Dialog();
      var confirm = new Confirm();

      $scope.breadCrumbEditSrv = $stateParams.serviceCode;

      $scope.title =
        $stateParams.serviceTitle ||
        $filter('translate')('service_detail.ADD_SRV');

      $scope.formFieldsList = WorkflowFields.getFields();
      $scope.selectOptions = WorkflowFields.getOptions();

      $scope.checkServiceID = function(itemServiceId, element) {
        return ServiceUtils.checkServiceID(itemServiceId, element);
      };

      $scope.checkConditions = function(field) {
        return ServiceUtils.checkConditions(field, $scope.service);
      };

      $scope.isRequired = function(field) {
        return ServiceUtils.isRequired(field);
      };

      WorkflowStore.addProjectStore($stateParams.projectCode);
      ProjectStore.listenTo(function(project) {
        $scope.project = project;

        $scope.service = getService() || {};

        if (!$scope.service.title) {
          $scope.service.first =
            project && (!project.services || project.services.length === 0);
        }

        // Pre-fill della data di consegna del servizio come quella del workflow
        if (!$scope.service.delivery_date) {
          $scope.service.delivery_date = $scope.workflow.delivery_date;
        }
      });

      const addServiceTitle = () => {
        $scope.service.title = ServiceUtils.generateServiceCode($scope.project);
        $scope.project.services = $scope.project.services || [];
        $scope.project.services.push($scope.service);
      };

      const checkAndDuplicateProjects = () => {
        if ($scope.service.target && $scope.service.target.length > 1) {
          confirm
            .info($filter('translate')('workflow.DUPLICATE_PROJECT_MSG'))
            .then(() => {
              const {
                code: originalCode = '',
                uuid: originalUuid = ''
              } = $scope.project;
              const duplicatedProjects = [];
              const dupProject = angular.copy($scope.project);

              while ($scope.service.target.length > 1) {
                CleanProject({
                  shouldDeleteCode: true,
                  shouldDeleteContributors: true,
                  shouldDeleteTarget: true
                })(dupProject);
                const oldPrefix = originalCode.substring(
                  0,
                  originalCode.length - 2
                );
                dupProject.code =
                  oldPrefix + Utilities.pad(++$scope.workflow.countProject, 2);
                dupProject.services[0].target = $scope.project.services[0].target.splice(
                  -1
                );
                dupProject.duplicatedFrom = {
                  code: originalCode,
                  uuid: originalUuid
                };
                duplicatedProjects.push(angular.copy(dupProject));
              }

              $scope.workflow.projects = $scope.workflow.projects.concat(
                duplicatedProjects
              );
            });
        }
        WorkflowISO17100($scope.workflow)
          .then(() => $state.go('^.data'))
          .then(() => $scope.$parent.save());
      };

      $scope.save = function() {
        const validator = new FormValidator($scope.serviceForm);
        if (!validator.validate())
          return dialog.error(
            $filter('translate')('crud.VALIDATION_ERROR') +
              '\n' +
              $filter('translate')('crud.VALIDATION_ERROR_VERIFY')
          );

        const isNew = !$scope.service.title;

        if (isNew) {
          return Promise.resolve()
            .then(() => {
              addServiceTitle();
              checkAndDuplicateProjects();
            })
            .catch(err => {
              Sentry.captureException(err);
              dialog.error(err);
            });
        }

        checkAndDuplicateProjects();
      };

      $scope.undo = function() {
        $scope.performUndo();

        $timeout(function() {
          switch ($state.current.name) {
            case 'root.app.simple.quote.projects.detail.service-detail': {
              $state.go('^.data');
              break;
            }
            case 'root.app.simple.quote.projects.detail.data': {
              $state.go('^.^.list');
              break;
            }
            case 'root.app.simple.quote.projects.detail.service-create': {
              if (
                $scope.project &&
                $scope.project.services &&
                $scope.project.services.length
              ) {
                $state.go('^.data');
                break;
              } else {
                $timeout(function() {
                  $state.go('^.^.list');
                });
                break;
              }
            }
            default: {
              $timeout(function() {
                $state.go('^.^.list');
              });
            }
          }
        });
      };

      function getService() {
        if (
          !$stateParams.serviceCode ||
          !$scope.project ||
          !$scope.project.services
        ) {
          return null;
        }

        for (var i = 0; i < $scope.project.services.length; i++) {
          if ($scope.project.services[i].title === $stateParams.serviceCode) {
            return $scope.project.services[i];
          }
        }

        $state.go('root.404');
      }
    }
  ]);
