'use strict';
angular
  .module('library')

  .factory('MultiSelectLabels', [
    '$filter',
    function($filter) {
      return function() {
        return {
          selectAll: $filter('translate')('library.multiselect.selectAll'),
          selectNone: $filter('translate')('library.multiselect.selectNone'),
          reset: $filter('translate')('library.multiselect.reset'),
          search: $filter('translate')('library.multiselect.search'),
          nothingSelected: $filter('translate')(
            'library.multiselect.nothingSelected'
          )
        };
      };
    }
  ]);
