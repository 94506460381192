angular
  .module('contributor')

  .directive('contributorReferent', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        referent: '=ngModel'
      },
      templateUrl: 'custom/contributor/views/partials/contributorReferent.html'
    };
  });
