angular
  .module('workflow')

  .directive('projectsList', function() {
    return {
      restrict: 'E',
      templateUrl: 'custom/workflow/views/partials/projectsList.html',
      controller: 'ProjectsCtrl',
      link: function(scope, elem, attrs) {
        scope.detail = attrs.detail;
        scope.tasks = attrs.tasks;
        scope.wizard = attrs.wizard;
      }
    };
  });
