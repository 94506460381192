angular
  .module('contributor')

  .directive('contributorSummary', [
    function() {
      'use strict';

      return {
        restrict: 'E',
        replace: true,
        scope: true,
        templateUrl:
          'custom/contributor/views/partials/contributorSummary.html',
        controller: [
          '$scope',
          '$state',
          'ContributorStore',
          'LoggedUser',
          'TaskCountersCrud',
          function(
            $scope,
            $state,
            ContributorStore,
            LoggedUser,
            TaskCountersCrud
          ) {
            $scope.active = false;
            $scope.contributor = {};
            $scope.headquarter = {};
            $scope.isPM = LoggedUser.isPM();

            ContributorStore.listenTo(function(contributor) {
              $scope.active = contributor ? !!contributor.active : false;
              $scope.contributor = angular.copy(contributor || {});
              $scope.contacts = $scope.contributor.contacts;
              contributor &&
                new TaskCountersCrud()
                  .query({ contributor: contributor._id })
                  .then(({ counters, total } = {}) => {
                    let { not_confirmed, notapproved } = counters || {};
                    not_confirmed = not_confirmed || 0;
                    notapproved = notapproved || 0;
                    $scope.totalTasks =
                      (total || 0) - not_confirmed - notapproved || 0;
                  })
                  .catch(() => {});
            });

            ContributorStore.addListener('legal_address', function(
              headquarter
            ) {
              $scope.headquarter = headquarter;
            });

            $scope.show = what => {
              if (!['orders', 'tasks'].includes(what)) return;
              const { contributor } = $scope;
              $state.go(`root.app.simple.${what}`, { contributor });
            };
          }
        ]
      };
    }
  ]);
