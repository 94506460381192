angular
  .module('customer')

  .directive('customerSummary', [
    '$filter',
    function($filter) {
      'use strict';

      return {
        restrict: 'E',
        replace: true,
        scope: true,
        templateUrl: 'custom/customer/views/partials/customerSummary.html',
        controller: [
          '$scope',
          'CustomerStore',
          'LoggedUser',
          function($scope, CustomerStore, LoggedUser) {
            $scope.active = false;
            $scope.customer = {};
            $scope.headquarter = {};
            $scope.isPM = LoggedUser.isPM();

            CustomerStore.listenTo(function(customer) {
              $scope.active = customer ? !!customer.active : false;
              $scope.customer = angular.copy(customer || {});
              $scope.headquarter = $scope.customer.headquarter;
              $scope.lastZohoSync = customer.lastZohoSync;
            });
          }
        ]
      };
    }
  ]);
