angular
  .module('company')

  .directive('serviceCustom', [
    '$filter',
    function($filter) {
      'use strict';

      return {
        restrict: 'E',
        replace: true,
        scope: {
          serviceCustom: '=ngModel',
          collapsed: '=',
          delete: '&'
        },
        templateUrl: 'custom/company/views/partials/serviceCustom.html',
        controller: [
          '$scope',
          '$controller',
          'ServiceCrud',
          function($scope, $controller, ServiceCrud) {
            $controller('LoadBaseCtrl', {
              $scope: $scope,
              crud: new ServiceCrud('-list'),
              object: null,
              dataName: 'service'
            });

            $scope.processLoading({ id: $scope.serviceCustom.service });
          }
        ]
      };
    }
  ]);
