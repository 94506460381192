angular
  .module('task')

  .factory('ContributorAgreementsCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class ContributorAgreementsCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/contributor-set-agreement-version');
        }
      }
      return ContributorAgreementsCrud;
    }
  ]);
