angular
  .module('workflow')

  .directive('workflowChangeStatusButton', [
    '$controller',
    '$filter',
    'EmptyProjects',
    'WorkflowStore',
    function($controller, $filter, EmptyProjects, WorkflowStore) {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
          workflow: '=',
          status: '@',
          callback: '&'
        },
        template:
          '<a href="" class="pv-follow-btn btn-default" ng-transclude></a>',
        controller: [
          '$scope',
          '$element',
          '$timeout',
          'Confirm',
          'Dialog',
          'WorkflowStateChange',
          function(
            $scope,
            $element,
            $timeout,
            Confirm,
            Dialog,
            WorkflowStateChange
          ) {
            var confirm = new Confirm();
            var dialog = new Dialog();
            var isOrder = $scope.workflow.type === 'order';
            var emptyProjects = new EmptyProjects();

            const STATUS_MSG = {
              approved: $filter('translate')('quote.APPROVE_ASK_MSG'),
              canceled: $filter('translate')('quote.CANCEL_MSG'),
              dataconfirm: $filter('translate')('order.INVOICE_CONFIRM_MSG'),
              dataconfirmed: $filter('translate')(
                'order.INVOICE_CONFIRMED_MSG'
              ),
              invoiced: $filter('translate')('order.INVOICED_CONFIRM_MSG'),
              notapproved: $filter('translate')('quote.REJECT_MSG'),
              pm_assigned: $filter('translate')(
                isOrder
                  ? 'order.SEND_REQUEST_PM_ASSIGNED_MSG'
                  : 'quote.SEND_REQUEST_PM_ASSIGNED_MSG'
              ),
              standby: $filter('translate')(
                isOrder
                  ? 'order.SEND_REQUEST_CONFIRM'
                  : 'quote.SEND_REQUEST_CONFIRM'
              ),
              tobill: $filter('translate')('order.TO_BILL_MSG'),
              waiting: $filter('translate')('quote.ASK_APPROVAL_MSG')
            };

            const STATUS_MSG_SUCCESS = {
              approved: $filter('translate')('quote.APPROVE_SUCCESS'),
              canceled: $filter('translate')('quote.CANCEL_SUCCESS'),
              dataconfirm: $filter('translate')('order.INVOICE_CONFIRM_SUCC'),
              dataconfirmed: $filter('translate')(
                'order.INVOICE_CONFIRMED_SUCC'
              ),
              invoiced: $filter('translate')('order.INVOICED_CONFIRM_SUCC'),
              notapproved: $filter('translate')('quote.REJECT_SUCCESS'),
              pm_assigned: $filter('translate')(
                isOrder
                  ? 'order.SEND_REQUEST_PM_ASSIGNED_SUCCESS'
                  : 'quote.SEND_REQUEST_PM_ASSIGNED_SUCCESS'
              ),
              standby: $filter('translate')(
                'quote.SEND_REQUEST_CONFIRM_SUCCESS'
              ),
              tobill: $filter('translate')('order.TO_BILL_SUCC'),
              waiting: $filter('translate')('quote.ASK_APPROVAL_SUCC')
            };

            $element.click(function() {
              if (
                $scope.status !== 'draft' &&
                emptyProjects.isEmpty($scope.workflow)
              ) {
                return;
              }

              if (
                $scope.status === 'pm_assigned' &&
                !$scope.workflow.project_manager
              ) {
                dialog.warning($filter('translate')('workflow.NO_PM_SELECTED'));
                return;
              }

              if ($scope.status === 'waiting') {
                const { company, offer } = $scope.workflow;
                const { conditionsOfPayment } = company || {};
                const { conditionsOfSupply, date, extraServices, template } =
                  offer || {};
                const isOfferComplete =
                  conditionsOfPayment &&
                  conditionsOfSupply &&
                  date &&
                  extraServices &&
                  template;
                if (!isOfferComplete) {
                  return dialog.error(
                    $filter('translate')('workflow.OFFER_INCOMPLETE_ERROR')
                  );
                }
              }

              if ($scope.status === 'tobill') {
                const { delivery_date_effective } = $scope.workflow || {};
                if (
                  !delivery_date_effective ||
                  !new Date(delivery_date_effective).getTime()
                ) {
                  return dialog.error(
                    $filter('translate')(
                      'order.ACTUAL_DELIVERY_TIME_REQUIRED_ERROR'
                    )
                  );
                }
              }

              confirm
                .info(STATUS_MSG[$scope.status])
                .then(() =>
                  WorkflowStateChange.sendRequest(
                    $scope.workflow,
                    $scope.status
                  )
                )
                .then(response => {
                  WorkflowStore.emit('save', response);

                  $timeout(function() {
                    dialog.success(STATUS_MSG_SUCCESS[$scope.status]);
                  }, 100);

                  if ($scope.callback) {
                    $scope.callback();
                  }
                });
            });
          }
        ]
      };
    }
  ]);
