angular
  .module('pb.core.user')

  .factory('User', function() {
    var User = function(user) {
      this.set(user);
    };

    /**
     * Get User object
     * @returns {*|{}}
     */
    User.prototype.get = function() {
      return this.user;
    };

    /**
     * Set User object
     * @param user
     * @returns {User}
     */
    User.prototype.set = function(user) {
      this.user = user || {};
      this.user.resources = this.user.resources || {};
      return this;
    };

    /**
     * Get user's profile
     * @returns {string}
     */
    User.prototype.getProfile = function() {
      return angular.isObject(this.user.profile) ? this.user.profile : {};
    };

    /**
     * Get user's resources
     * @returns {*|User.user.resources|{}}
     */
    User.prototype.getResources = function() {
      return angular.isObject(this.user.resources) ? this.user.resources : {};
    };

    /**
     * Set user's resources
     * @returns {User}
     */
    User.prototype.setResources = function(resources) {
      this.user.resources = resources || {};
      return this;
    };

    /**
     * Check if user is admin
     * @returns {boolean}
     */
    User.prototype.isAdmin = function() {
      return (
        typeof this.user.__t === 'string' &&
        this.user.__t.toLowerCase() === 'admin'
      );
    };

    /**
     * Check if user is active
     * @returns {boolean}
     */
    User.prototype.isActive = function() {
      return typeof this.user.active === 'boolean' ? this.user.active : false;
    };

    /**
     * Check if user has permission
     * @param requiredResources
     * @returns {boolean}
     */
    User.prototype.hasPermission = function(requiredResources) {
      var hasPermission = true,
        userResources = this.getResources();

      if (
        !angular.isObject(requiredResources) ||
        Object.keys(requiredResources).length == 0
      ) {
        return true;
      }

      angular.forEach(
        requiredResources,
        function(resource, key) {
          if (!hasPermission || !userResources[key]) {
            hasPermission = false;
            return;
          }

          if (
            angular.isArray(resource) &&
            resource.length > 0 &&
            !this._checkActions(resource, userResources[key])
          ) {
            hasPermission = false;
          }
        },
        this
      );

      return hasPermission;
    };

    /**
     * Check if user has action
     * @param actions
     * @param userActions
     * @returns {boolean}
     * @private
     */
    User.prototype._checkActions = function(actions, userActions) {
      var hasPermission = false;

      angular.forEach(actions, function(action) {
        if (userActions.indexOf(action) >= 0) {
          hasPermission = true;
        }
      });

      return hasPermission;
    };

    /**
     * Check user type
     * @param typeAttrKey
     * @param typeValue
     * @returns {boolean}
     */
    User.prototype.isType = function(typeValue, typeAttrKey) {
      typeAttrKey = typeAttrKey || '__t';

      if (!typeValue || !this.user) {
        return true;
      }
      if (!this.user[typeAttrKey]) {
        return false;
      }

      return this.user[typeAttrKey] === typeValue;
    };

    /**
     * Check if a user's resource is readonly
     * @param resourceName
     * @returns {boolean}
     */
    User.prototype.isReadonly = function(resourceName) {
      var actions;

      if (typeof resourceName !== 'string') {
        throw new TypeError('isReadonly function needs a string as parameter');
      }

      actions = this.getResources()[resourceName];

      if (!actions || !angular.isArray(actions)) {
        return false;
      }

      return (
        actions.indexOf('read') >= 0 &&
        actions.indexOf('create') < 0 &&
        actions.indexOf('update') < 0
      );
    };

    return User;
  });
