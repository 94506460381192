angular
  .module('components')

  .directive('chosenService', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        service: '='
      },
      template: `<select chosen="{'placeholder_text_single': ('contributors.SELECT_TASK_MESSAGE' | translate)}" ng-model="service" ng-options="s.value for s in services track by s._id" />`,
      controller: [
        '$scope',
        '$controller',
        'ServiceCrud',
        function($scope, $controller, ServiceCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new ServiceCrud('-list'),
            packageName: 'services',
            dataName: 'services',
            filters: {}
          });

          $scope.loadList();
        }
      ]
    };
  });
