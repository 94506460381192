angular
  .module('workflow')

  .controller('WorkflowEditCtrl', [
    '$scope',
    '$state',
    '$controller',
    '$stateParams',
    'CompanyExpiredCrud',
    'Dialog',
    'WorkflowCrud',
    'WorkflowModel',
    'WorkflowStore',
    'ProjectStore',
    'LoggedUser',
    '$filter',
    '$rootScope',
    'ZohoWorkflowSync',

    function(
      $scope,
      $state,
      $controller,
      $stateParams,
      CompanyExpiredCrud,
      Dialog,
      WorkflowCrud,
      WorkflowModel,
      WorkflowStore,
      ProjectStore,
      LoggedUser,
      $filter,
      $rootScope,
      ZohoWorkflowSync
    ) {
      $scope.loggedUser = LoggedUser.getData();
      $scope.newService = {};
      $scope.isCustomer = LoggedUser.isCustomer();
      $scope.isPM = LoggedUser.isPM();
      const crud = new WorkflowCrud($scope);
      const { id: idStateParam } = $stateParams;

      $controller('EditBaseCtrl', {
        $scope: $scope,
        crud: crud,
        object: WorkflowModel,
        packageName: 'workflow',
        dataName: 'workflow',
        formName: 'workflowForm'
      });

      WorkflowStore.clear().addListener('save', function(data) {
        if (typeof data === 'object') {
          angular.extend($scope.workflow, data);
        }

        $scope.processSaving().then(function(response) {
          WorkflowStore.emitChange(response);
          crud.set(response);
          $scope.$broadcast('save:done');
        });
      });

      $scope.processLoading().then(function(response) {
        if (response.type === 'order' && $state.includes('**.quote.**')) {
          $state.go('root.app.simple.order', { id: response.id });
        } else if (
          response.type === 'quote' &&
          $state.includes('**.order.**')
        ) {
          $state.go('root.app.simple.quote', { id: response.id });
        } else {
          WorkflowStore.emitChange(response);
          $scope.breadCrumbQuote = $scope.workflow.title;
          $scope.breadCrumbOrder = $scope.workflow.title;
        }
      });

      $scope.save = function() {
        WorkflowStore.emit('save');
      };

      $scope.performUndo = function() {
        $scope.undo();
        WorkflowStore.emitChange($scope.workflow);
      };

      $scope.breadCrumbQuotes = $filter('translate')('quote.QUOTES');
      $scope.breadCrumbOrders = $filter('translate')('order.ORDERS');

      // Do not show expired popup if associating contributor
      // let showPopupExpired = !Array.isArray($stateParams.newContributors);

      WorkflowStore.listenTo(function(workflow) {
        $scope.workflow = angular.copy(workflow || {});
        // const { _id: workflowId, company } = workflow || {};
        // const { _id: companyId } = company || {};
        // if (
        //   workflowId === idStateParam &&
        //   $scope.isPM &&
        //   showPopupExpired &&
        //   companyId
        // ) {
        //   showPopupExpired = false;
        //   new CompanyExpiredCrud()
        //     .get({ id: companyId })
        //     .then(res => {
        //       const { expired } = res;
        //       if (expired)
        //         new Dialog().info(
        //           `${$filter('translate')(
        //             'company.EXPIRED_MESSAGE'
        //           )} ${expired}`
        //         );
        //     })
        //     .catch(() => {});
        // }
      });

      $rootScope.$on('$stateChangeSuccess', function(event, toState) {
        switch (toState.name) {
          case 'root.app.simple.quote':
            $state.go('root.app.simple.quote.projects.list');
            break;
          case 'root.app.simple.order':
            $state.go('root.app.simple.order.projects.list');
            break;
        }
      });

      $scope.broadcastSave = () => $scope.$broadcast('formAbout:save');

      $scope.zohoWorkflowSync = () => {
        new ZohoWorkflowSync($stateParams.id)
          .save()
          .then(() => {
            new Dialog().success($filter('translate')('zoho.SYNC_SUCCESS'));
          })
          .catch(() => {
            new Dialog().error($filter('translate')('zoho.SYNC_ERROR'));
          });
      };
    }
  ]);
