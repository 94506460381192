angular
  .module('contributor')

  .directive('educations', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        contribEducations: '=ngModel'
      },
      templateUrl: 'custom/contributor/views/partials/educations.html',
      controller: [
        '$scope',
        'UserLang',
        function($scope, UserLang) {
          $scope.addEducation = function() {
            var education = {
              education: {},
              description: '',
              place: '',
              year: ''
            };

            $scope.contribEducations = $scope.contribEducations || [];
            $scope.contribEducations.push(education);
          };

          $scope.deleteEducation = function(index) {
            $scope.contribEducations.splice(index, 1);
          };

          $scope.isNew = function(education = {}) {
            const { education: innerEducation = {} } = education || {};
            const { _id } = innerEducation || {};
            return (
              $scope.contribEducations.indexOf(education) ===
                $scope.contribEducations.length - 1 && !_id
            );
          };

          $scope.userLang = UserLang.get();
        }
      ]
    };
  });
