angular
  .module('company')

  .factory('CompanyStore', [
    'Store',

    function(Store) {
      'use strict';

      class CompanyStore extends Store {
        emitActive(active) {
          this.set('active', active).emitChange();
        }

        emitLegalHeadquarter(company) {
          if (!company) {
            return;
          }

          this.emit('legal_address', company.legal_address);
        }
      }

      return new CompanyStore();
    }
  ]);
