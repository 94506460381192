angular
  .module('company')

  .directive('headquarters', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      scope: {
        headquarters: '=ngModel',
        store: '<',
        processSaving: '&save',
        undo: '&'
      },
      templateUrl: 'custom/company/views/partials/headquarters.html',
      controller: 'HeadquartersCtrl'
    };
  });
