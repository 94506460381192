angular
  .module('admin')

  .config([
    '$stateProvider',

    function($stateProvider) {
      var path = 'custom/admin/views/';

      $stateProvider
        .state('root.app.simple.admins', {
          url: '/admins',
          views: {
            header: {
              templateUrl: path + 'list-header.html'
            },
            content: {
              templateUrl: path + 'list.html',
              controller: 'AdminListCtrl'
            }
          },
          permissions: {
            resources: { admins: ['read'] }
          }
        })

        .state('root.app.simple.create-admin', {
          url: '/create-admin',
          views: {
            header: {
              templateUrl: path + 'create-header.html'
            },
            content: {
              templateUrl: path + 'create.html',
              controller: 'AdminCreateCtrl'
            }
          },
          permissions: {
            resources: { admins: ['read'] }
          }
        });
    }
  ]);
