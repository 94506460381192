angular
  .module('library')

  .directive('addLibraryItemButton', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        type: '='
      },
      template:
        '<button class="btn btn-float bgm-cyan m-btn ng-scope waves-effect waves-circle"><i class="zmdi zmdi-plus"></i></button>',
      controller: [
        '$controller',
        '$scope',
        '$attrs',
        function($controller, $scope, $attrs) {
          $scope.doctype = null;

          $controller('ModalBaseCtrl', {
            $scope: $scope,
            controller:
              $scope.type === 'value'
                ? 'LibraryValueCtrl'
                : 'LibraryNamesModalCtrl',
            templateUrl:
              $scope.type === 'value'
                ? 'custom/library/views/partials/addLibraryItemValue.html'
                : 'custom/library/views/partials/addLibraryItemNames.html',
            content: {
              title: $attrs.title || '@@appName'
            },
            childScope: false
          });
        }
      ],
      link: function($scope, element, attrs) {
        element.bind('click', function() {
          $scope.open();
        });
      }
    };
  })

  .controller('LibraryNamesModalCtrl', [
    '$controller',
    '$scope',
    '$modalInstance',
    'content',
    'LibraryNamesModel',

    function($controller, $scope, $modalInstance, content, LibraryNamesModel) {
      $controller('ModalBaseInstanceCtrl', {
        $scope: $scope,
        $modalInstance: $modalInstance,
        content: content
      });

      $scope.item = new LibraryNamesModel();

      $scope.insert = function() {
        $scope.$emit('addLibraryItem', $scope.item);
        $scope.ok();
      };
    }
  ])

  .controller('LibraryValueCtrl', [
    '$controller',
    '$scope',
    '$modalInstance',
    'content',
    'LibraryValueModel',

    function($controller, $scope, $modalInstance, content, LibraryValueModel) {
      $controller('ModalBaseInstanceCtrl', {
        $scope: $scope,
        $modalInstance: $modalInstance,
        content: content
      });

      $scope.item = new LibraryValueModel();

      $scope.insert = function() {
        $scope.$emit('addLibraryItem', $scope.item);
        $scope.ok();
      };
    }
  ]);
