angular
  .module('workflow')

  .directive('projectsCostsSummary', [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'custom/workflow/views/partials/projectsCostsSummary.html',
        controller: [
          '$scope',
          function($scope) {
            $scope.$watch(
              'workflow.totals',
              (totals = {}) => {
                const { amount = 0, profit: { gross = 0 } = {} } = totals;

                $scope.profitPercentage = ((gross / amount || 0) * 100).toFixed(
                  2
                );
              },
              true
            );
          }
        ]
      };
    }
  ]);
