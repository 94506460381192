angular
  .module('contributor')

  .directive('selectEducation', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        education: '='
      },
      template:
        '<select chosen ng-model="education" ng-options="e.value for e in educations track by e._id" />',
      controller: [
        '$scope',
        '$controller',
        'EducationCrud',
        function($scope, $controller, EducationCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new EducationCrud('-list'),
            packageName: 'educations',
            dataName: 'educations',
            filters: {}
          });

          $scope.loadList().then(() => {
            $scope.educations.unshift({
              _id: '',
              value: '-'
            });
          });

          $scope.$watch(
            'education',
            education => {
              if (education && !education._id) delete $scope.education;
            },
            true
          );
        }
      ]
    };
  });
