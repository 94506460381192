angular
  .module('admin')

  .directive('selectAdminRole', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        role: '='
      },
      template:
        '<select ng-model="role" ng-options="key as value for (key, value) in roles" /></select>',
      controller: [
        '$scope',
        '$filter',
        function($scope, $filter) {
          $scope.roles = {
            superadmin: $filter('translate')('roles.superadmin'),
            pm_s: $filter('translate')('roles.pm_s'),
            pm_j: $filter('translate')('roles.pm_j'),
            am: $filter('translate')('roles.am'),
            admin: $filter('translate')('roles.ADMIN_MANAGER'),
            cf: $filter('translate')('roles.cf'),
            cc: $filter('translate')('roles.cc'),
            mm: $filter('translate')('roles.mm'),
            dtp_s: $filter('translate')('roles.dtp_s'),
            dtp_j: $filter('translate')('roles.dtp_j'),
            hrm: $filter('translate')('roles.hrm'),
            vm: $filter('translate')('roles.vm')
          };
        }
      ]
    };
  });
