angular
  .module('pb.core.crud')

  .factory('QueryAdapter', [
    '$stateParams',

    function($stateParams) {
      'use strict';

      class QueryAdapter {
        static adaptGet(params) {
          return !params || typeof params === 'string'
            ? { id: params || $stateParams.id }
            : params;
        }

        static adaptQuery(params) {
          return params;
        }

        static adaptSave(data) {
          return data;
        }

        static adaptUpdate(data) {
          data.id = data._id || $stateParams.id;
          return data;
        }

        static adaptDelete(data) {
          return { id: data._id || $stateParams.id };
        }
      }

      return QueryAdapter;
    }
  ]);
