angular
  .module('signup')

  .controller('SignupStartCtrl', [
    '$scope',
    '$state',
    '$filter',
    '$timeout',
    '$translate',
    '$window',
    'Confirm',
    'Dialog',
    'FormValidator',
    'Utilities',

    function(
      $scope,
      $state,
      $filter,
      $timeout,
      $translate,
      $window,
      Confirm,
      Dialog,
      FormValidator,
      Utilities
    ) {
      var confirm = new Confirm(),
        dialog = new Dialog(),
        validator;

      $timeout(function() {
        validator = new FormValidator($scope.signupForm);
      });

      $scope.next = function(type) {
        if (!validator.validate()) {
          dialog.error(
            $filter('translate')('crud.VALIDATION_ERROR') +
              '\n' +
              $filter('translate')('crud.VALIDATION_ERROR_VERIFY')
          );
          return;
        }

        if (type === 'customer') {
          confirm
            .info($filter('translate')('signup.SIGNUP_ASK_CONFIRM_CUSTOMER'))
            .then(function() {
              $state.go('^.simple.signup-customer.step1');
            });
        } else if (type === 'contributor') {
          confirm
            .info($filter('translate')('signup.SIGNUP_ASK_CONFIRM_CONTRIBUTOR'))
            .then(function() {
              $state.go('^.simple.signup-contributor');
            });
        }
      };

      $scope.lang = Utilities.getLang();

      $scope.$watch('lang', function(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          $translate.use(newValue);
          localStorage.setItem('@@localStoragePrefix' + 'lang', newValue);
          $window.location.reload();
        }
      });
    }
  ]);
