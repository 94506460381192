angular
  .module('contributor')

  .directive('selectOs', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        os: '='
      },
      template:
        '<select ng-model="os" ng-options="o.value for o in oss track by o._id">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'OSCrud',
        function($scope, $controller, OSCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new OSCrud('-list'),
            packageName: 'contributor',
            dataName: 'oss',
            filters: {}
          });

          $scope.loadList();
        }
      ]
    };
  });
