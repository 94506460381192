angular
  .module('library')

  .directive('selectChildTasks', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        tasks: '='
      },
      template: `
        <select chosen="{'placeholder_text_multiple': ('library.SELECT_TASKS' | translate)}"
          ng-model="tasks" style="min-width: 200px" multiple ng-options="m as m.code + ' - ' + m.value for m in items track by m._id" >
          <option value=""></option>
        </select>
        `,

      controller: [
        '$scope',
        '$controller',
        '$attrs',
        'TaskCrud',
        function($scope, $controller, $attrs, TaskCrud) {
          const showTasks = $attrs.showTasks === 'true';

          $controller('ListCtrl', {
            $scope: $scope,
            crud: new TaskCrud('-list'),
            packageName: 'task',
            dataName: 'items',
            filters: {}
          });

          $scope.loadList().then((items = []) => {
            items.forEach(
              item => (item.code = parseInt(item.code) || item.code)
            );

            items.sort((a, b) => (a.code > b.code ? 1 : -1));

            $scope.tasks = $scope.tasks || [];

            if (!showTasks) {
              return;
            }

            $scope.tasks = $scope.tasks.map(function(t) {
              var id = typeof t === 'object' ? t._id : t;
              return { _id: id };
            });
          });
        }
      ]
    };
  });
