angular
  .module('workflow')

  .directive('billingCompanyPreview', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      replace: true,
      scope: {
        billing: '<ngModel'
      },
      templateUrl: 'custom/workflow/views/partials/billingCompanyPreview.html'
    };
  });
