angular
  .module('company')

  .directive('selectFullCompany', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        company: '='
      },
      templateUrl: 'custom/company/views/partials/selectFullCompany.html',
      controller: 'SelectCompanyCtrl'
    };
  });
