angular
  .module('pb.file')

  .factory('FilesCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class FilesCrud extends Crud {
        constructor() {
          super(
            null,
            '@@apiUrl' + '/files/:id',
            { id: '@id' },
            { query: { method: 'GET', isArray: true } }
          );
        }
      }

      return FilesCrud;
    }
  ]);
