angular
  .module('contributor')

  .directive('interpreters', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        interpreters: '=ngModel'
      },
      templateUrl: 'custom/contributor/views/partials/interpreters.html',
      controller: [
        '$scope',
        function($scope) {
          $scope.addInterpreter = function() {
            var interpreter = {
              days: '',
              macrosector: {}
            };

            $scope.interpreters = $scope.interpreters || [];
            $scope.interpreters.push(interpreter);
          };

          $scope.deleteInterpreter = function(index) {
            $scope.interpreters.splice(index, 1);
          };
        }
      ]
    };
  });
