angular
  .module('contributor')

  .directive('specializations', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        specializations: '=ngModel'
      },
      templateUrl: 'custom/contributor/views/partials/specializations.html',
      controller: 'SpecializationCtrl'
    };
  });
