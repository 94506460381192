angular.module('workflow').directive('extraServices', function() {
  return {
    restrict: 'E',
    require: '^ngModel',
    replace: true,
    scope: {
      extraServices: '=ngModel'
    },
    templateUrl: 'custom/workflow/views/partials/extraServices.html',
    controller: [
      '$scope',
      function($scope) {
        $scope.delete = extraServiceIndex => {
          $scope.extraServices.splice(extraServiceIndex, 1);
        };

        $scope.edit = extraService => {
          extraService.edit = extraService.edit ? false : true;
        };

        $scope.addExtraService = () => {
          const newService = { edit: true };
          $scope.extraServices && Array.isArray($scope.extraServices)
            ? $scope.extraServices.push(newService)
            : ($scope.extraServices = [newService]);
        };
      }
    ]
  };
});
