angular
  .module('components')

  .directive('selectJobRoles', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        role: '='
      },
      template:
        '<select ng-model="role" ng-options="j.value for j in jobroles track by j._id">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'JobRoleCrud',
        function($scope, $controller, JobRoleCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new JobRoleCrud('-list'),
            packageName: 'jobroles',
            dataName: 'jobroles',
            filters: {}
          });

          $scope.loadList();
        }
      ]
    };
  });
