angular
  .module('components')

  .directive('selectProjectTemplate', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        selection: '=',
        company: '<',
        templateType: '@'
      },
      template: `<select chosen ng-model="selection" ng-options="o.title for o in options track by o._id" 
        placeholder-text-single="::('workflow.SELECT_PROJECT' | translate)" no-results-text="::('NO_RESULT_FOR' | translate)"></select>`,
      controller: [
        '$scope',
        'LoggedUser',
        function($scope, LoggedUser) {
          let templateType;

          switch ($scope.templateType) {
            case '24h':
              templateType = 'workflows24h';
              break;
            case 'template':
              templateType = 'workflowTemplates';
              break;
            default:
              templateType = 'workflows24h';
          }

          const setOptions = company => {
            $scope.options = (company || {})[templateType] || [{}];
          };

          if (!LoggedUser.isPM) {
            const { company = {} } = LoggedUser.getData();
            setOptions(company);
          } else {
            $scope.$watch('company', (company = {}) => setOptions(company));
          }
        }
      ]
    };
  });
