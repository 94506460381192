angular
  .module('pb.core.module')

  .factory('UserMenu', [
    'MenuManager',

    function(MenuManager) {
      var menus = [];
      return new MenuManager(menus);
    }
  ]);
