angular
  .module('pb.base')

  .factory('AuthRegister', [
    'Crud',

    function(Crud) {
      'use strict';

      class AuthRegister extends Crud {
        constructor() {
          super(null, '@@authUrl' + '/register?appId=' + '@@appId');
        }
      }

      return AuthRegister;
    }
  ]);
