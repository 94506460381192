angular
  .module('components')

  .directive('selectCountry', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        country: '='
      },
      template:
        '<select ng-model="country" ng-options="c.value for c in countries track by c._id">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'CountryCrud',
        function($scope, $controller, CountryCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new CountryCrud('-list'),
            packageName: 'countries',
            dataName: 'countries',
            filters: {}
          });

          $scope.loadList();
        }
      ]
    };
  });
