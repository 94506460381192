angular
  .module('workflow')

  .controller('WorkflowBillingCtrl', [
    '$scope',
    'WorkflowStore',

    function($scope, WorkflowStore) {
      function getLegalAddress(headquarters = []) {
        var headquarter = headquarters.find(function(headquarter) {
          return headquarter.legal === true;
        });

        return headquarter ? headquarter.address : null;
      }

      function getBillFromCompany(company) {
        if (!company || typeof company !== 'object') {
          return;
        }

        $scope.workflow = angular.extend($scope.workflow, {
          billing: {
            company_name: company.company_name,
            company_name2: company.company_name2,
            vat: company.vat,
            tax_code: company.tax_code,
            address: getLegalAddress(company.headquarters) || {}
          }
        });

        WorkflowStore.set('billing', $scope.workflow.billing);
      }

      WorkflowStore.addListener('company', getBillFromCompany);
    }
  ]);
