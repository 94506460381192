angular.module('doorDocumentale', ['pb.core', 'components']).run([
  '$filter',
  'Module',
  function($filter, Module) {
    var module = new Module();

    module.push({
      menu: {
        name: 'doorDocumentale',
        title: 'Documentale',
        icon: 'zmdi zmdi-folder',
        link: 'root.app.simple.doorDocumentale'
      }
    });
  }
]);
