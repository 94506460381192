angular
  .module('contributor')

  .directive('softwares', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        contribSoftwares: '=ngModel'
      },
      templateUrl: 'custom/contributor/views/partials/softwares.html',
      controller: [
        '$scope',
        '$controller',
        '$filter',
        'Dialog',
        'SoftwareCrud',
        '$timeout',
        function($scope, $controller, $filter, Dialog, SoftwareCrud, $timeout) {
          'use strict';

          const isSelected = function(software = {}, softwares = []) {
            return softwares.some((soft = {}) => {
              return soft && software && soft._id === software._id;
            });
          };

          $controller('ListCtrl', {
            $scope: $scope,
            crud: new SoftwareCrud('-list'),
            packageName: 'softwares',
            dataName: 'softwares',
            filters: {}
          });

          $scope.loadList().then(function() {
            $timeout(() => {
              $scope.softwares.forEach(software => {
                software.selected = isSelected(
                  software,
                  $scope.contribSoftwares
                );
              });
            });
          });

          $scope.toggleSoftware = function(software = {}) {
            if (!software.selected) {
              $scope.contribSoftwares = $scope.contribSoftwares.filter(
                (contribSoftware = {}) => {
                  return (
                    contribSoftware &&
                    software &&
                    contribSoftware._id !== software._id
                  );
                }
              );

              return;
            }

            if (
              $scope.contribSoftwares &&
              $scope.contribSoftwares.indexOf(software) < 0
            ) {
              $scope.contribSoftwares.push(software);
            }
          };
        }
      ]
    };
  });
