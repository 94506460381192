angular
  .module('company')

  .directive('selectDocumentMacrosector', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        sector: '=',
        onChange: '&'
      },
      template:
        '<select ng-model="sector" ng-options="s as (s.name[userLang] || s.value) for s in sectors track by s._id" ng-change="save()">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        '$timeout',
        'DocumentCrud',
        'UserLang',
        function($scope, $controller, $timeout, DocumentCrud, UserLang) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new DocumentCrud('-list'),
            packageName: 'document',
            dataName: 'sectors',
            filters: {
              macro: 1
            }
          });

          $scope.loadList();
          $scope.userLang = UserLang.get();
          $scope.save = () =>
            $timeout(() => $scope.onChange && $scope.onChange());
        }
      ]
    };
  });
