angular
  .module('contributor')

  .directive('contributorRating', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        contributor: '=ngModel'
      },
      templateUrl: 'custom/contributor/views/partials/contributorRating.html',
      controller: [
        '$scope',
        '$controller',
        'RatingsCrud',
        function($scope, $controller, RatingsCrud) {
          $controller('LoadBaseCtrl', {
            $scope: $scope,
            crud: new RatingsCrud(),
            object: null,
            dataName: 'ratings'
          });

          $scope.$watch('contributor', function(newValue) {
            if (newValue) {
              $scope.processLoading({ utype: newValue.utype });
            }
          });

          if (!$scope.contributor.rating) {
            $scope.contributor.rating = {
              generals: [],
              note: ''
            };
          }
        }
      ]
    };
  });
