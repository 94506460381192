angular
  .module('pb.base')

  .directive('datepickerInline', function() {
    return {
      restrict: 'EA',
      scope: {
        date: '=ngModel'
      },
      templateUrl: 'modules/base/views/partials/datepickerInline.html',
      controller: [
        '$scope',
        function($scope) {
          $scope.open = function($event, opened) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope[opened] = true;
          };

          $scope.dateOptions = {
            formatYear: 'yyyy',
            startingDay: 1
          };

          $scope.format = 'dd-MMMM-yyyy';
        }
      ]
    };
  });
