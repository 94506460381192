angular
  .module('contributor')

  .factory('ContributorStore', [
    'Store',

    function(Store) {
      'use strict';

      class ContributorStore extends Store {
        emitActive(active) {
          this.set('active', active).emitChange();
        }

        emitLegalHeadquarter(contributor) {
          if (!contributor) {
            return;
          }

          angular.forEach(
            contributor.headquarters,
            function(headquarter) {
              if (headquarter.legal === true) {
                this.emit('legal_address', headquarter);
              }
            },
            this
          );
        }
      }

      return new ContributorStore();
    }
  ]);
