angular
  .module('demo')

  .config([
    '$stateProvider',
    '$urlRouterProvider',

    function($stateProvider, $urlRouterProvider) {
      var path = 'custom/demo/views/';

      $stateProvider
        .state('root.app.base.demo', {
          url: '/demo',
          views: {
            header: {
              templateUrl: path + 'demo.html'
            },
            options: {
              templateUrl: path + 'demolist.html',
              controller: 'DemoListCtrl'
            },
            content: {
              template: '<div ui-view></div>'
            }
          }
        })

        .state('root.app.base.demo.details', {
          url: '/:id',
          templateUrl: path + 'demo.html',
          controller: 'DemoViewCtrl'
        })

        .state('root.app.demolist', {
          url: '/demolist',
          templateUrl: path + 'demolist.html',
          controller: 'DemoListCtrl'
        });
    }
  ]);
