angular
  .module('pb.core.user')

  .factory('CheckStatesPermissions', [
    '$rootScope',
    '$state',
    '$timeout',
    'LoggedUser',

    function($rootScope, $state, $timeout, LoggedUser) {
      var enabled = false;

      //Check user permissions for states
      $rootScope.$on('$stateChangeStart', function(event, toState) {
        if (
          !enabled ||
          !toState.permissions ||
          typeof toState.permissions.resources !== 'object'
        ) {
          return;
        }

        if (!LoggedUser.get().hasPermission(toState.permissions.resources)) {
          event.preventDefault();

          $timeout(function() {
            if (angular.isDefined(toState.permissions.redirectTo)) {
              $state.go(toState.permissions.redirectTo);
            } else {
              $rootScope.$broadcast('err403');
            }
          });
        }
      });

      return function() {
        enabled = true;
      };
    }
  ]);
