angular
  .module('workflow')

  .directive('addressBillingForm', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        address: '=ngModel'
      },
      templateUrl: 'custom/workflow/views/partials/addressBillingForm.html'
    };
  });
