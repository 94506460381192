angular
  .module('company')

  .directive('addressForm', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        address: '=ngModel',
        showName: '<'
      },
      templateUrl: 'custom/company/views/partials/addressForm.html'
    };
  });
