angular
  .module('workflow')

  .factory('BatchTaskCreator', [
    '$q',
    'ServiceCrud',
    'BatchTask',
    'UserLang',
    'BatchModel',

    function($q, ServiceCrud, BatchTask, UserLang, BatchModel) {
      function getName(task, lang) {
        if (!task) {
          return '';
        }

        if (typeof task.name === 'string') {
          return task.name;
        }

        return task.name.find(n => {
          return n && n.lang === lang;
        }).value;
      }

      function create(serviceId, serviceName) {
        const crud = new ServiceCrud();

        return crud.get({ id: serviceId }).then(
          function(response) {
            const tasks = response && response.tasks;

            if (!Array.isArray(tasks)) {
              return [];
            }

            return tasks.map(task => {
              const taskName = {
                name: getName(task, UserLang.get())
              };

              return BatchTask(Object.assign({}, task, taskName), {
                _id: serviceId,
                name: serviceName
              });
            });
          },
          function(err) {
            return $q.reject(err);
          }
        );
      }

      function concatTasksInBatches(tasksToAdd, project) {
        let { batches } = project || {};
        batches = batches && batches.length ? batches : [new BatchModel()];

        return batches.map(batch => {
          const { tasks = [] } = batch || {};
          batch.tasks = tasks.concat(tasksToAdd);
          return batch;
        });
      }

      function generateBatchedProjects(projects = []) {
        return Promise.all(
          projects.map((project = {}) => {
            const { services = [] } = project;

            const tasksForServices = services.map(serviceInThisProject => {
              const { service = {} } = serviceInThisProject || {};
              return create(service._id, service.value);
            });

            return Promise.all(tasksForServices).then(resolvedList => {
              resolvedList.forEach(
                tasks =>
                  (project.batches = concatTasksInBatches(tasks, project))
              );
              return project;
            });
          })
        );
      }

      return { create, concatTasksInBatches, generateBatchedProjects };
    }
  ]);
