angular
  .module('company')

  .directive('companyAssociationButton', [
    function() {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: true,
        template:
          '<a href="" class="pv-follow-btn btn-primary" ng-transclude></a>',
        controller: [
          '$scope',
          '$element',
          '$state',
          'CompanyStore',
          function($scope, $element, $state, CompanyStore) {
            CompanyStore.listenTo(function(company) {
              $scope._id = company ? company._id : null;
            });

            $element.click(function() {
              $state.go('root.app.simple.companies', {
                associating: true,
                billingId: $scope._id
              });
            });
          }
        ]
      };
    }
  ]);
