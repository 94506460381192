angular
  .module('components')

  .factory('SendOfferMail', [
    '$filter',
    '$timeout',
    'Confirm',
    'Dialog',
    'SendOfferCrud',

    function($filter, $timeout, Confirm, Dialog, SendOfferCrud) {
      function SendOfferMail(emailRecipient, workflow, template) {
        const confirm = new Confirm();
        const dialog = new Dialog();

        confirm
          .info($filter('translate')('workflow.SEND_OFFER_TO_CUSTOMER_MSG'))
          .then(() => {
            if (!template) {
              const dialog = new Dialog();
              dialog.error(
                $filter('translate')('pdf.error.NO_DEFAULT_TEMPLATE_FOUND')
              );
              return;
            }

            if (!emailRecipient) {
              return $timeout(
                () =>
                  dialog.error(
                    $filter('translate')('company.NO_EMAIL_LEGAL_ADDRESS')
                  ),
                200
              );
            }

            const sendOffer = new SendOfferCrud();
            const payload = {
              email: emailRecipient,
              workflow: workflow,
              template: template
            };

            return sendOffer
              .save(payload)
              .then(() =>
                $timeout(
                  () =>
                    dialog.success(
                      $filter('translate')(
                        'workflow.SEND_OFFER_TO_CUSTOMER_SUCCESS'
                      )
                    ),
                  250
                )
              )
              .catch(error => {
                Sentry.captureException(error);
                $timeout(
                  () =>
                    dialog.error($filter('translate')('error.SOMETHING_WRONG')),
                  250
                );
              });
          });
      }

      return SendOfferMail;
    }
  ]);
