angular
  .module('pb.base')

  .directive('fieldsetTitleCollapse', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      transclude: true,
      templateUrl: 'modules/base/views/partials/fieldsetTitleCollapse.html',
      scope: {
        isCollapsed: '=ngModel'
      },
      link: function(scope, element, attrs) {
        scope.isCollapsed = 'collapsed' in attrs;
      }
    };
  });
