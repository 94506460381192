angular
  .module('workflow')

  .factory('WorkflowStateChangeCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class WorkflowStateChangeCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/workflows-status' + '/:id', { id: '@id' });
        }
      }
      return WorkflowStateChangeCrud;
    }
  ]);
