angular
  .module('company')

  .factory('CompanyAssociation', [
    'Crud',

    function(Crud) {
      'use strict';

      class CompanyAssociation extends Crud {
        constructor() {
          super('company_association', '@@apiUrl' + '/associatecompany/:id', {
            id: '@id'
          });
        }
      }

      return CompanyAssociation;
    }
  ]);
