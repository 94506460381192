angular
  .module('workflow')

  .directive('invoiceConfirmedCheckLabel', [
    function() {
      return {
        replace: false,
        scope: {
          orderStatus: '<'
        },
        template: '<check-label ng-model="flag"></check-label>',
        controller: [
          '$scope',
          function($scope) {
            switch ($scope.orderStatus) {
              case 'dataconfirmed':
              case 'tobill':
              case 'invoiced':
                $scope.flag = true;
                break;

              default:
                $scope.flag = false;
                break;
            }
          }
        ]
      };
    }
  ]);
