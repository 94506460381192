angular
  .module('quote')

  .directive('quoteApproveButton', [
    '$controller',
    '$filter',
    function($controller, $filter) {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
          id: '<',
          workflow: '='
        },
        template:
          '<a href="" class="pv-follow-btn btn-default" ng-transclude></a>',
        controller: [
          '$scope',
          '$element',
          '$timeout',
          'Confirm',
          'Dialog',
          'QuoteApproveRequest',
          function(
            $scope,
            $element,
            $timeout,
            Confirm,
            Dialog,
            QuoteApproveRequest
          ) {
            var confirm = new Confirm();
            var dialog = new Dialog();

            $controller('LoadBaseCtrl', {
              $scope: $scope,
              crud: new QuoteApproveRequest(),
              object: null,
              dataName: 'requestObj'
            });

            $element.click(function() {
              confirm
                .info($filter('translate')('quote.APPROVE_ASK_MSG'))
                .then(function() {
                  $scope.requestObj = {
                    _id: $scope.id
                  };

                  $scope.processLoading().then(function() {
                    $timeout(function() {
                      dialog.success(
                        $filter('translate')('quote.APPROVE_SUCCESS')
                      );
                    }, 100);
                  });
                });
            });
          }
        ]
      };
    }
  ]);
