angular
  .module('pb.user')

  .factory('ProfileSignupCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class ProfileSignupCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/profiles/:id', { id: '@id' });
        }
      }

      return ProfileSignupCrud;
    }
  ]);
