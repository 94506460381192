angular
  .module('contributor')

  .directive('selectIso', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        iso: '='
      },
      template:
        '<select ng-model="iso" ng-options="i.value for i in isos track by i._id">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'ISOCrud',
        '$timeout',
        function($scope, $controller, ISOCrud, $timeout) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new ISOCrud('-list'),
            packageName: 'isos',
            dataName: 'isos',
            filters: {}
          });

          $scope.loadList().then(
            $timeout(function() {
              angular.forEach($scope.isos, function(iso) {
                if (iso.is_default && !$scope.iso) {
                  $scope.iso = iso;
                }
              });
            }, 1000)
          );
        }
      ]
    };
  });
