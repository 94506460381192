angular
  .module('pb.core.utilities')

  .factory('PbWizard', function() {
    'use strict';

    class PbWizard {
      constructor(parameters) {
        var params = parameters || {};

        this.step = 1;
        this.stepToGo = null;
        this.percentage = 0;
        this.completedFirst = false;
        this.finish = false;

        if (!Array.isArray(params.stepsUrls)) {
          throw new Error('stepsUrls params must be an array');
        } else if (params.stepsUrls.length === 0) {
          throw new Error('stepsUrls can not be an empty array');
        }

        this.completed =
          typeof params.completed !== 'undefined'
            ? parseInt(params.completed)
            : 0;
        this.stepsUrls = params.stepsUrls || [];
        this.steps = this.stepsUrls.length;

        function getFunction(func) {
          return typeof func === 'function' ? func : new Function();
        }

        this.onChangeStepCallback = getFunction(params.onChangeStep);
        this.onNextStepCallback = getFunction(params.onNextStep);
        this.onPreviousStepCallback = getFunction(params.onPreviousStep);
        this.onCompleteFirstCallback = getFunction(params.onCompleteFirst);
        this.onFinishCallback = getFunction(params.onFinish);
        this.onResetCallback = getFunction(params.onReset);
        this.onExitCallback = getFunction(params.onExit);

        this.onStartNextStep = params.onStartNextStep;
        this.onStartPreviousStep = params.onStartPreviousStep;
        this.onStartFinish = params.onStartFinish;
      }

      getData() {
        var prevStep = this.step - 1;
        this.completed = this.completed < prevStep ? prevStep : this.completed;

        if (this.finish) {
          this.completed = this.steps;
        }

        return {
          step: this.step,
          steps: this.steps,
          completed: this.completed,
          percentage:
            this.steps !== 0 ? (this.completed * 100) / this.steps : 0,
          finish: this.step === this.steps && this.finish,
          currentStep: this.stepsUrls[prevStep],
          nextStep: this.step < this.steps ? this.stepsUrls[this.step] : null,
          previousStep: this.step > 1 ? this.stepsUrls[prevStep - 1] : null
        };
      }

      respond(callbacks) {
        var data = this.getData();

        callbacks = callbacks || [];
        callbacks.unshift('onChangeStepCallback');

        if (data.finish) {
          callbacks.push('onFinishCallback');
        }

        callbacks.forEach(callback => {
          this[callback](data);
        });
      }

      /**
       * Goes to the next step
       */
      next() {
        if (this.step >= this.steps) {
          this._finish();
        } else if (typeof this.onStartNextStep === 'function') {
          this.onStartNextStep(() => {
            this._next();
          });
        } else {
          this._next();
        }

        return this;
      }

      _next() {
        var callbacks = [];

        this.step = this.stepToGo ? this.stepToGo : this.step + 1;
        this.stepToGo = null;

        callbacks = ['onNextStepCallback'];

        if (!this.completedFirst && this.step >= 2) {
          this.completedFirst = true;
          callbacks.push('onCompleteFirstCallback');
        }

        this.respond(callbacks);
      }

      _finish() {
        if (typeof this.onStartFinish === 'function') {
          this.onStartFinish(() => {
            this.finish = true;
            this.respond([]);
          });
        } else {
          this.finish = true;
          this.respond([]);
        }
      }

      /**
       * Goes to the previous step
       */
      previous() {
        if (this.step <= 1) {
          this.respond(['onExitCallback']);
        } else if (typeof this.onStartPreviousStep === 'function') {
          this.onStartPreviousStep(() => {
            this._previous();
          });
        } else {
          this._previous();
        }

        return this;
      }

      _previous() {
        this.step = this.stepToGo ? this.stepToGo : this.step - 1;
        this.stepToGo = null;
        this.respond(['onPreviousStepCallback']);
      }

      /**
       * Goes to the previous step
       */
      cancel() {
        if (this.step > 1) {
          this.step -= 1;
          this.respond([]);
        }

        return this;
      }

      /**
       * Goes to a step
       * @param step
       */
      goTo(step) {
        this.stepToGo = step;

        if (step > this.step) {
          this.next();
        } else if (step < this.step) {
          this.previous();
        }

        return this;
      }

      /**
       * Goes to the first step and resets all steps to incomplete
       */
      reset() {
        this.step = 1;
        this.stepToGo = null;
        this.completedFirst = false;
        this.finish = false;
        this.respond(['onResetCallback']);
        return this;
      }
    }

    return PbWizard;
  });
