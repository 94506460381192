angular
  .module('quote')

  .factory('QuoteCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class QuoteCrud extends Crud {
        constructor(list) {
          super(
            '' + (list || ''),
            '@@apiUrl' + '/quotes' + (list || '') + '/:id',
            { id: '@id' }
          );
        }
      }
      return QuoteCrud;
    }
  ]);
