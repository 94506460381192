angular
  .module('pb.base')

  .directive('selectYear', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        year: '=',
        min: '@'
      },
      template:
        '<select chosen ng-model="year" class="form-control input-sm" ng-options="y for y in years" />',
      controller: [
        '$scope',
        function($scope) {
          var today = parseInt(new Date().getFullYear());

          $scope.years = [];

          $scope.min = $scope.min ? parseInt($scope.min) : 100;

          for (var i = 0; i < $scope.min; i += 1) {
            $scope.years.push(today - i);
          }
        }
      ]
    };
  });
