angular
  .module('pb.base')

  .controller('ModalBaseCtrl', [
    '$modal',
    '$scope',
    'controller',
    'templateUrl',
    'content',
    'childScope',

    function($modal, $scope, controller, templateUrl, content, childScope) {
      var params = {
        animation: true,
        templateUrl: templateUrl || '',
        controller: controller || 'ModalBaseInstanceCtrl',
        backdrop: 'static',
        keyboard: true,
        resolve: {
          content: function() {
            return content;
          }
        }
      };

      if (childScope === false) {
        params.scope = $scope;
      }

      $scope.open = function(size) {
        $modal.open(angular.extend(params, { size: size || '' }));
      };
    }
  ]);
