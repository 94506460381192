angular
  .module('pb.file')

  .directive('pbFileTableActions', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: false,
      template: `
        <ul class="actions pull-right">
            <li ng-hide="hideDownload">
                <a tooltip="{{::('DOWNLOAD' | translate)}}" tooltip-placement="top"
                   tooltip-popup-delay="500" ng-click="download(file)">
                    <i class="zmdi zmdi-download"></i>
                </a>
            </li>
            <li ng-if="editable && isOwner(file)">
                <a tooltip="{{::('DELETE' | translate)}}" tooltip-placement="top"
                   tooltip-popup-delay="500" ng-click="delete(file)">
                    <i class="zmdi zmdi-delete"></i>
                </a>
            </li>
        </ul>
      `
    };
  });
