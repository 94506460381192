angular
  .module('demo')

  .controller('DemoViewCtrl', [
    '$scope',
    '$controller',
    'DemoCrud',

    function($scope, $controller, DemoCrud) {
      $controller('CrudBaseCtrl', {
        $scope: $scope,
        crud: new DemoCrud(),
        object: {},
        dataName: 'items'
      });

      $scope.load();
    }
  ]);
