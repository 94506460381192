angular
  .module('company')

  .directive('birthForm', [
    '$filter',
    function($filter) {
      return {
        restrict: 'E',
        replace: true,
        require: '^ngModel',
        scope: {
          birth: '=ngModel'
        },
        templateUrl: 'modules/base/views/partials/birthForm.html'
      };
    }
  ]);
