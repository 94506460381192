angular
  .module('pb.file')

  .factory('FilesTempTokenCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class FilesTempTokenCrud extends Crud {
        constructor() {
          super(null, '@@fileUrl' + '/get-temp-token');
        }
      }

      return FilesTempTokenCrud;
    }
  ]);
