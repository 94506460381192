angular
  .module('pb.file')

  .directive('fileInputCircle', [
    '$timeout',
    function($timeout) {
      return {
        restrict: 'E',
        transclude: true,
        templateUrl: 'modules/file/views/partials/fileInputCircle.html',
        scope: {
          key: '=',
          path: '<',
          fileName: '@',
          uniqueFileName: '@',
          class: '@'
        },
        controller: 'filesController'
      };
    }
  ]);
