angular
  .module('company')

  .directive('contactsForm', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        contacts: '=ngModel',
        nation: '=',
        isHeadquarter: '<',
        isCustomer: '<'
      },
      templateUrl: 'custom/company/views/partials/contactsForm.html'
    };
  });
