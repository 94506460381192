angular.module('home').directive('tasksLineChart', [
  '$controller',
  '$filter',
  'TaskLastDaysCrud',
  function($controller, $filter, TaskLastDaysCrud) {
    return {
      restrict: 'E',
      templateUrl: 'custom/home/views/partials/tasksLineChart.html',
      scope: false,
      link: function(scope) {
        $controller('ListCtrl', {
          $scope: scope,
          crud: new TaskLastDaysCrud(),
          packageName: 'lastTasks',
          dataName: 'tasks',
          filters: {}
        });

        scope.loadList();
      }
    };
  }
]);
