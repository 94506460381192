angular
  .module('quote')

  .factory('QuoteRejectRequest', [
    'Crud',

    function(Crud) {
      'use strict';

      class QuoteRejectRequest extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/quote-reject-request/:id', { id: '@id' });
        }
      }

      return QuoteRejectRequest;
    }
  ]);
