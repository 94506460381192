angular
  .module('contributor')

  .directive('hardware', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        hardware: '=ngModel'
      },
      templateUrl: 'custom/contributor/views/partials/hardware.html',
      controller: [
        '$scope',
        '$controller',
        '$filter',
        'Dialog',
        'SoftwareCrud',
        function($scope, $controller, $filter, Dialog, SoftwareCrud) {
          //$controller('ListCtrl', {
          //  $scope: $scope,
          //  crud: new SoftwareCrud(),
          //  packageName: 'softwares',
          //  dataName: 'softwares',
          //  filters: {}
          //});
          //
          //$scope.loadList().then(function () {
          //  function setSelected(software) {
          //    $scope.contribSoftwares = $scope.contribSoftwares || [];
          //
          //    for (var i = 0; i < $scope.contribSoftwares.length; i += 1) {
          //      if ($scope.contribSoftwares[i]._id === software._id) {
          //        software.selected = true;
          //        return;
          //      }
          //    }
          //  }
          //
          //  angular.forEach($scope.softwares, function (software) {
          //    setSelected(software);
          //  });
          //});
          //
          //$scope.toggleSoftware = function (software) {
          //  if (software.selected && $scope.contribSoftwares && $scope.contribSoftwares.indexOf(software) < 0) {
          //    $scope.contribSoftwares.push(software);
          //  }
          //
          //  else if (!software.selected) {
          //    for (var i = 0; i < $scope.contribSoftwares.length; i += 1) {
          //      if ($scope.contribSoftwares[i]._id === software._id) {
          //        $scope.contribSoftwares.splice(i, 1);
          //      }
          //    }
          //  }
          //}
        }
      ]
    };
  });
