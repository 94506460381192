angular
  .module('pb.base')

  .directive('undoModal', function() {
    return {
      restrict: 'EA',
      scope: {
        save: '&save',
        undo: '&undo',
        back: '&back'
      },
      controller: [
        '$controller',
        '$scope',
        '$attrs',
        '$filter',
        function($controller, scope, $attrs, $filter) {
          $controller('ModalBaseCtrl', {
            $scope: scope,
            controller: 'UndoModalInstanceCtrl',
            templateUrl: 'modules/base/views/partials/undoModal.html',
            content: {
              title: $attrs.title || '@@appName',
              content: $filter('translate')($attrs.content) || ''
            },
            childScope: false
          });

          scope.$on('saveObjectRequest', function() {
            //Open modal function in ModalBaseCtrl
            // scope.open();
            // Do not bother asking, the user would just get annoyed. Just save.
            scope.save();
          });
        }
      ]
    };
  })

  .controller('UndoModalInstanceCtrl', [
    '$controller',
    '$scope',
    '$modalInstance',
    'content',

    function($controller, $scope, $modalInstance, content) {
      $controller('ModalBaseInstanceCtrl', {
        $scope: $scope,
        $modalInstance: $modalInstance,
        content: content
      });
    }
  ]);
