angular
  .module('pb.core.crud')

  .factory('httpInterceptor', [
    '$q',
    '$rootScope',
    'DeleteToken',

    function($q, $rootScope, DeleteToken) {
      var checkStatus = function(status) {
        switch (status) {
          case 0:
            //              $rootScope.$broadcast('err0');
            break;
          case 400:
            $rootScope.$broadcast('err400');
            break;
          case 401:
            DeleteToken();
            $rootScope.$broadcast('err401');
            break;
          case 403:
            $rootScope.$broadcast('err403');
            break;
          case 404:
            $rootScope.$broadcast('err404');
            break;
          case 500:
            $rootScope.$broadcast('err500');
            break;
        }
      };

      return {
        request: function(config) {
          return config || $q.when(config);
        },

        requestError: function(rejection) {
          return $q.reject(rejection);
        },

        response: function(response) {
          checkStatus(response.status);
          return response || $q.when(response);
        },

        responseError: function(rejection) {
          checkStatus(rejection.status);
          return $q.reject(rejection);
        }
      };
    }
  ])

  .config([
    '$httpProvider',
    function($httpProvider) {
      $httpProvider.interceptors.push('httpInterceptor');
    }
  ]);
