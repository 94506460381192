angular.module('company').directive('companiesAssociatedTable', function() {
  'use strict';
  return {
    restrict: 'E',
    scope: {
      companies: '<'
    },
    templateUrl: 'custom/company/views/partials/companiesAssociatedTable.html'
  };
});
