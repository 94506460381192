angular
  .module('contributor')

  .directive('contributorContacts', [
    '$filter',
    function($filter) {
      return {
        restrict: 'E',
        replace: true,
        require: '^ngModel',
        scope: {
          contacts: '=ngModel',
          lsp: '<'
        },
        templateUrl:
          'custom/contributor/views/partials/contributorContacts.html',
        controller: [
          '$scope',
          function($scope) {
            $scope.invalidContacts = true;

            function setInvalid(value) {
              $scope.invalidContacts = value;
              $scope.contactsRequired = value ? undefined : 'something';
            }

            $scope.$watch(
              'contacts',
              function(val, old) {
                if (val) {
                  if (!val.phones && !val.emails) {
                    setInvalid(true);
                  } else if (
                    !val.phones ||
                    (!val.phones.home &&
                      !val.phones.cell &&
                      !val.phones.work &&
                      (!val.emails ||
                        (!val.emails[0] && !val.emails[1] && !val.emails[2])))
                  ) {
                    setInvalid(true);
                  } else {
                    setInvalid(false);
                  }
                }
              },
              true
            );
          }
        ]
      };
    }
  ]);
