angular
  .module('components')

  .directive('selectDocumentMacrosectorId', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        sector: '='
      },
      template:
        '<select ng-model="sector" ng-options="s._id as s.value for s in sectors">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'DocumentCrud',
        function($scope, $controller, DocumentCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new DocumentCrud('-list'),
            packageName: 'document',
            dataName: 'sectors',
            filters: {
              macro: 1
            }
          });

          $scope.loadList();
        }
      ]
    };
  });
