angular
  .module('components')

  .factory('EmptyProjects', [
    '$filter',
    'Dialog',
    'ProjectFilesUtils',
    'FilesCrud',

    function($filter, Dialog, ProjectFilesUtils, FilesCrud) {
      'use strict';

      const formatDialogCode = project => {
        const { num = 1, services: [service = {}] = [] } = project || {};
        const { source = {}, target: [target = {}] = [] } = service;
        const lang = target.value || source.value || '';
        let code = `P${String(num).padStart(2, '0')}`;
        if (lang) code += `_${lang}`;
        return code;
      };

      class EmptyProjects {
        isEmpty(workflow) {
          var dialog = new Dialog();
          if (!workflow.projects || !workflow.projects.length) {
            dialog.error($filter('translate')('VALIDATION.NO_PROJECTS_ERR'));
            return true;
          }

          if (
            workflow.projects.some(function(project) {
              return !project.services || !project.services.length;
            })
          ) {
            dialog.error($filter('translate')('VALIDATION.NO_SERVICES_ERR'));
            return true;
          }

          return false;
        }

        hasNoFile(workflow) {
          return new Promise(resolve => {
            const { projects = [], _id } = workflow || {};

            if (!projects || projects.length === 0) {
              return resolve(false);
            }

            const crud = new FilesCrud();
            const promises = projects.map(project => {
              const { duplicatedFrom, fileMode } = project;
              let parentPath = ProjectFilesUtils.getSourcePath(
                { _id },
                duplicatedFrom || project
              );
              const parentUuidPath = ProjectFilesUtils.getUUIDSourcePath(
                { _id },
                duplicatedFrom || project
              );
              if (parentUuidPath) {
                parentPath += `,${parentUuidPath}`;
              }
              const humanSourcePath = ProjectFilesUtils.getHumanSourcePath(
                workflow,
                (duplicatedFrom &&
                  duplicatedFrom.uuid &&
                  projects.find(({ uuid }) => uuid === duplicatedFrom.uuid)) ||
                  project
              );
              if (humanSourcePath) {
                parentPath += `,${humanSourcePath}`;
              }
              return crud.query({ parentPath }).then(files => {
                if (files.length === 0 && !fileMode) {
                  return Promise.reject(new Error(formatDialogCode(project)));
                }
              });
            });

            Promise.all(promises)
              .then(() => resolve(false))
              .catch(err => {
                const { message = '' } = err || {};
                const dialog = new Dialog();
                dialog.error(
                  $filter('translate')('VALIDATION.NO_FILES_FOR_PROJECT', {
                    code: message
                  })
                );
                resolve(true);
              });
          });
        }
      }

      return EmptyProjects;
    }
  ]);
