angular.module('company').controller('CompaniesAssociatedTabCtrl', [
  '$scope',
  'CompaniesAssociatedCrud',

  function($scope, CompaniesAssociatedCrud) {
    const crud = new CompaniesAssociatedCrud();
    crud.get().then(result => {
      $scope.companies = result;
    });
  }
]);
