angular
  .module('signup')

  .factory('SignupModelContributor', [
    'Auth',
    'Utilities',
    function(Auth, Utilities) {
      function SignupModelContributor() {
        //Signup default model
        return {
          lang: Utilities.getLang() || 'it',
          type: 'contributor',
          email: Auth.getUserEmail(),
          userId: Auth.getUserId(),
          residence: {
            address: {}
          },
          headquarters: [{ legal: true }],
          birth: {
            district: 'EE'
          },
          contacts: {
            phones: {},
            emails: []
          },
          rating: {
            generals: [],
            note: ''
          },
          services: {
            nativeLangs: [],
            bilingual: [],
            monolingual: [],
            others: []
          },
          specializations: [],
          softwares: [],
          educations: [],
          associations: [],
          experiences: [],
          interpreters: [],
          cvs: [],
          availability: {}
        };
      }

      return SignupModelContributor;
    }
  ]);

//services:{
//   bilingual:[
//     {
//       task:{},
//       combos:[
//         {
//           source:{},
//           target:{},
//           uom:{},
//           price:{}
//         },
//         ...
//       ]
//     },
//     ...
//   ],
//   monolingual:[
//     {
//       task:{},
//       combos:[
//         {
//           source:{},
//           uom:{},
//           price:{}
//         },
//         ...
//       ]
//     },
//     ...
//   ],
//   otherslingual:[
//     {
//       task:{},
//       combos:[
//         {
//           uom:{},
//           price:{}
//         },
//         ...
//       ]
//     },
//     ...
//   ],
// }
//specializations:[
//  {
//    macrosector:{},
//    sectors:[],
//    documents:[],
//    rating:{}
//  }...
// ],
//softwares:[],
//educations:[
//  {
//    education: {},
//    description: {},
//    place: {},
//    year: {}
//  }...
//],
//associations:[
//  {
//    association: {},
//    description: {}
//  }...
//],
//experiences:[
//  {
//    work: '',
//    place: '',
//    start:Date,
//    end:Date
//  }...
//],
//interpreters:[
//  {
//    days:Number,
//    macrosector:{}
//  }
//],
//cvs:[],
//availability:{
//    hour:Number,
//    word:Number,
//    folder:Number,
//    treat:Boolean
//}
