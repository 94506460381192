angular
  .module('company')

  .directive('headquartersList', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        selected: '=ngModel',
        company: '='
      },
      templateUrl: 'custom/company/views/partials/headquartersList.html'
    };
  });
