angular
  .module('workflow')

  .directive('batchSteps', function() {
    return {
      restrict: 'E',
      scope: {
        tasks: '<',
        date: '<',
        maxLength: '<',
        maxSteps: '<',
        go: '&'
      },
      templateUrl: 'custom/workflow/views/partials/batchSteps.html',
      link: function(scope) {
        scope.steps = [];

        scope.fullSteps = [];

        scope.compTooltipData = function(step) {
          var msg = [];
          if (step.last) {
            for (var i = 0; i < step.last; i++) {
              msg.push(scope.fullSteps[i].text);
            }
          } else if (step.first) {
            for (var i = step.first; i < scope.fullSteps.length; i++) {
              msg.push(scope.fullSteps[i].text);
            }
          } else {
            msg.push(step.fullText || step.text);
          }
          return msg.join(' - ');
        };

        if (scope.tasks) {
          scope.tasks.forEach(function(task) {
            scope.fullSteps.push({
              done: task.done,
              text: task.abbreviation || task.name || ''
            });
          });
        }

        if (!scope.maxSteps) {
          scope.maxSteps = 5;
        }

        var addHead = function(steps, index) {
          steps.push({
            done: true,
            text: '...',
            last: index
          });
        };

        var addTail = function(steps, index) {
          steps.push({
            done: false,
            text: '...',
            first: index
          });
        };

        if (scope.fullSteps.length > scope.maxSteps) {
          var diff = scope.fullSteps.length - scope.maxSteps;
          if (diff > 1) {
            var head = 0;
            if (scope.fullSteps[0].done) {
              head++;
              addHead(scope.steps, head);
            }
            var tail = 0;
            for (var i = head; i < scope.fullSteps.length - 1; i++) {
              //search first not done
              if (!scope.fullSteps[i].done) {
                //do not go out of bounds
                while (i + scope.maxSteps > scope.fullSteps.length) {
                  i--;
                }
                //scope.steps[0].last = i;
                for (var j = 0; j < scope.maxSteps - 1; j++) {
                  scope.steps.push(scope.fullSteps[i + j]);
                }
                tail = i + scope.maxSteps - 1;
                break;
              }
            }
            if (tail < scope.fullSteps.length - 1) {
              addTail(scope.steps, tail);
            }
          } else if (diff === 1) {
            if (scope.fullSteps[0].done) {
              addHead(scope.steps, 1);
              for (var i = 1; i < scope.fullSteps.length - 1; i++) {
                scope.steps.push(scope.fullSteps[i]);
              }
            } else {
              for (var i = 0; i < scope.fullSteps.length - 2; i++) {
                scope.steps.push(scope.fullSteps[i]);
              }
              addTail(scope.steps, scope.fullSteps.length - 2);
            }
          }
        } else {
          scope.steps = scope.fullSteps;
        }

        if (!scope.maxLength) {
          scope.maxLength = 5;
        }

        /*Truncate string if too long*/
        scope.steps.forEach(function(step, index) {
          if (step.text.length > scope.maxLength) {
            scope.steps[index].fullText = scope.steps[index].text;
            scope.steps[index].text = step.text.slice(0, scope.maxLength - 1);
          }
          step.tooltipData = scope.compTooltipData(step);
        });
      }
    };
  });
