angular
  .module('pb.base')

  .directive('appMenuItem', [
    '$state',
    function($state) {
      return {
        restrict: 'A',
        link: function(scope, element, attrs) {
          var hasSubmenu = false;

          if (
            scope.menu &&
            scope.menu.submenus &&
            scope.menu.submenus.length > 0
          ) {
            hasSubmenu = true;
          }

          element.click(function() {
            if (hasSubmenu) {
              element.parent().toggleClass('toggled');
              element
                .parent()
                .find('ul')
                .stop(true, false)
                .slideToggle(200);
            } else {
              $state.go(attrs.link || '');
            }
          });
        }
      };
    }
  ]);
