angular
  .module('customer')

  .directive('customerContacts', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        customer: '=ngModel',
        save: '&',
        undo: '&'
      },
      templateUrl: 'custom/customer/views/partials/customerContacts.html'
    };
  });
