angular
  .module('task')

  .controller('ConfirmTaskCtrl', [
    '$scope',
    '$modalInstance',
    'LoggedUser',
    'task',

    function($scope, $modalInstance, LoggedUser, task) {
      const {
        contributorConditionsVersion,
        contributorLegalAgreementVersion
      } = LoggedUser.getContributorAgreementsVersion();

      // Workaround to prevent unexpected type coercion by gulp uglify task
      const [currentConditionsVersion, currentLegalAgreementVersion] = [
        '@@contributor_conditions_v',
        '@@contributor_legal_agreement_v'
      ].map(item => parseInt(item));

      $scope.conditionsAgreementChecked = false;
      $scope.conditionsAgreementDownloaded =
        contributorConditionsVersion >= currentConditionsVersion;
      $scope.legalAgreementChecked = false;
      $scope.legalAgreementDownloaded =
        contributorLegalAgreementVersion >= currentLegalAgreementVersion;
      $scope.task = angular.copy(task);

      $scope.cancel = () => {
        $modalInstance.dismiss('cancel');
      };

      $scope.confirm = () => {
        $modalInstance.close($scope.task);
      };
    }
  ]);
