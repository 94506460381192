'use strict';
angular
  .module('library')

  .factory('GetCrudOptions', [
    'AcquisitionSourcesCrud',
    'ActivityCrud',
    'AssociationCrud',
    'CertificationCrud',
    'ContributorCertificationsCrud',
    'ConnectionCrud',
    'CountryCrud',
    'CostsCrud',
    'DestinationUseDocumentCrud',
    'DocumentCrud',
    'EducationCrud',
    'IncomeCrud',
    'ISOCrud',
    'JobRoleCrud',
    'LanguageCrud',
    'OSCrud',
    'PaymentCrud',
    'PdfCrud',
    'TitleCrud',
    'RatingVoiceCrud',
    'SoftwareCrud',
    'TaskCrud',
    'UnitCrud',
    'VatCrud',
    'ServiceCrud',

    function(
      AcquisitionSourcesCrud,
      ActivityCrud,
      AssociationCrud,
      CertificationCrud,
      ContributorCertificationsCrud,
      ConnectionCrud,
      CountryCrud,
      CostsCrud,
      DestinationUseDocumentCrud,
      DocumentCrud,
      EducationCrud,
      IncomeCrud,
      ISOCrud,
      JobRoleCrud,
      LanguageCrud,
      OSCrud,
      PaymentCrud,
      PdfCrud,
      TitleCrud,
      RatingVoiceCrud,
      SoftwareCrud,
      TaskCrud,
      UnitCrud,
      VatCrud,
      ServiceCrud
    ) {
      return function(packageName, list) {
        switch (packageName) {
          case 'acquisitionSources':
            return {
              crud: new AcquisitionSourcesCrud(list),
              options: ['value']
            };
          case 'activities':
            return {
              crud: new ActivityCrud(list),
              options: ['name_it', 'name_en', 'parent']
            };
          case 'associations':
            return {
              crud: new AssociationCrud(list),
              options: [
                'name_it',
                'name_en',
                'desc_required',
                'is_default',
                'other',
                'active'
              ]
            };
          case 'certifications':
            return {
              crud: new CertificationCrud(list),
              options: ['name_it', 'name_en', 'is_default', 'other', 'active']
            };
          case 'contributorCertifications':
            return {
              crud: new ContributorCertificationsCrud(list),
              options: ['name_it', 'name_en']
            };
          case 'connections':
            return {
              crud: new ConnectionCrud(list),
              options: ['name_it', 'name_en', 'is_default', 'other', 'active']
            };
          case 'countries':
            return {
              crud: new CountryCrud(list),
              options: ['name_it', 'name_en']
            };
          case 'costs':
            return {
              crud: new CostsCrud(list),
              options: ['name_it', 'name_en', 'percent'],
              noAdd: true,
              noDelete: true,
              readOnlyCode: true,
              readOnlyName: true
            };
          case 'destinationUseDocument':
            return {
              crud: new DestinationUseDocumentCrud(list),
              options: ['name_it', 'name_en']
            };
          case 'documents':
            return {
              crud: new DocumentCrud(list),
              options: [
                'name_it',
                'name_en',
                'parent',
                'generic',
                'active',
                'document_type'
              ]
            };
          case 'educations':
            return {
              crud: new EducationCrud(list),
              options: [
                'name_it',
                'name_en',
                'desc_required',
                'is_default',
                'other',
                'active',
                'obsolete',
                'foreign'
              ]
            };
          case 'incomes':
            return {
              crud: new IncomeCrud(list),
              options: ['name_it', 'name_en', 'is_default', 'other', 'active']
            };
          case 'isos':
            return {
              crud: new ISOCrud(list),
              options: ['name_it', 'name_en', 'is_default', 'other']
            };
          case 'jobroles':
            return {
              crud: new JobRoleCrud(list),
              options: ['name_it', 'name_en', 'is_default', 'other', 'active']
            };
          case 'langs':
            return {
              crud: new LanguageCrud(list),
              options: ['name_it', 'name_en', 'parent', 'active', 'tgest']
            };
          case 'oss':
            return {
              crud: new OSCrud(),
              options: ['name_it', 'name_en', 'is_default', 'other', 'active']
            };
          case 'payments':
            return {
              crud: new PaymentCrud(list),
              options: [
                'value',
                'days',
                'deadline_type',
                'type',
                'code_soa',
                'is_default',
                'other',
                'active'
              ]
            };
          case 'pdf':
            return {
              createRoute: 'root.app.simple.pdf-create',
              crud: new PdfCrud(list),
              editRoute: 'root.app.simple.pdf-detail',
              options: [
                'body.closing',
                'body.opening',
                'body.text',
                'is_default'
              ]
            };
          case 'titles':
            return {
              crud: new TitleCrud(list),
              options: ['name_it', 'name_en', 'is_default', 'other', 'active']
            };
          case 'ratings':
            return {
              crud: new RatingVoiceCrud(list),
              options: ['name_it', 'name_en', 'weight', 'active', 'general']
            };
          case 'softwares':
            return {
              crud: new SoftwareCrud(list),
              options: [
                'name_it',
                'name_en',
                'ext',
                'is_default',
                'other',
                'active',
                'nolayout'
              ]
            };
          case 'tasks':
            return {
              crud: new TaskCrud(list),
              options: ['name_it', 'name_en'],
              editRoute: 'root.app.simple.task-detail',
              createRoute: 'root.app.simple.task-create'
            };
          case 'units':
            return {
              crud: new UnitCrud(list),
              options: [
                'name_it',
                'name_en',
                'is_default',
                'other',
                'active',
                'ranges'
              ]
            };
          case 'vats':
            return {
              crud: new VatCrud(list),
              options: [
                'name_it',
                'name_en',
                'desc_it',
                'desc_en',
                'vat_value',
                'nondeducible',
                'idivaipsoa',
                'is_default',
                'other',
                'active'
              ]
            };
          case 'services':
            return {
              crud: new ServiceCrud(list),
              options: ['name_it', 'name_en', 'abbr_it', 'abbr_en', 'tasks']
            };
          default:
            return null;
        }
      };
    }
  ]);
