angular
  .module('company')

  .directive('contributorTradosRanges', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        ranges: '=ngModel',
        allowQuantity: '<',
        onChange: '&'
      },
      templateUrl:
        'custom/contributor/views/partials/contributorTradosRanges.html',
      controller: [
        '$scope',
        'TradosRangesAssets',
        function($scope, TradosRangesAssets) {
          $scope.ranges =
            $scope.ranges ||
            TradosRangesAssets.ranges.map(({ name }) => ({ name }));
        }
      ]
    };
  });
