angular
  .module('workflow')

  .directive('workflowSelectOrigin', [
    'WorkflowOrigins',
    function(WorkflowOrigins) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          origin: '='
        },
        templateUrl: 'custom/workflow/views/partials/workflowSelectOrigin.html',
        controller: [
          '$scope',
          '$filter',
          '$timeout',
          'LoggedUser',
          'WorkflowStore',
          function($scope, $filter, $timeout, LoggedUser, WorkflowStore) {
            $scope.origins = WorkflowOrigins.origins;

            $scope.changeOrigin = function() {
              $timeout(function() {
                WorkflowStore.emit('origin', $scope.origin);
              });
            };
          }
        ]
      };
    }
  ]);
