angular
  .module('signup')

  .factory('SignupModel', [
    'Auth',
    'Utilities',
    function(Auth, Utilities) {
      function SignupModel() {
        //Signup default model
        return {
          profile: {
            lang: Utilities.getLang() || 'it',
            type: 'customer',
            email: Auth.getUserEmail(),
            userId: Auth.getUserId(),
            headquarter: null
          },
          company: {
            headquarters: [],
            activities: []
          }
        };
      }

      return SignupModel;
    }
  ]);
