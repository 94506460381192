angular
  .module('workflow')

  .directive('projectFileDataPreview', function() {
    return {
      restrict: 'E',
      scope: {
        project: '=ngModel'
      },
      templateUrl: 'custom/workflow/views/partials/projectFileDataPreview.html'
    };
  });
