angular
  .module('workflow')

  .factory('ZohoWorkflowSync', [
    'Crud',

    function(Crud) {
      'use strict';

      class ZohoWorkflowSync extends Crud {
        constructor(id) {
          super(null, '@@apiUrl' + '/deals-sync/' + id, {});
        }
      }

      return ZohoWorkflowSync;
    }
  ]);
