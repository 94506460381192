angular.module('components').factory('PdfExport', [
  '$filter',
  '$http',
  'Dialog',
  'NameArrayToObject',
  function($filter, $http, Dialog, NameArrayToObject) {
    function download(workflow, template) {
      if (!template) {
        const dialog = new Dialog();
        dialog.error(
          $filter('translate')('pdf.error.NO_DEFAULT_TEMPLATE_FOUND')
        );
        return;
      }

      const { projects = [], revision, workflow_company_num = '' } = workflow;
      const { code = '' } = projects[0] || {};

      const data = Object.assign({}, template, { workflow });
      const revisionNumber = revision ? ` rev${revision}` : '';
      const fileName = `${$filter('translate')(
        'quote.QUOTE'
      ).toUpperCase()} ${workflow_company_num || code}${revisionNumber}.pdf`;
      const url = '@@workflowApiUrl' + '/pdf-exports';

      $http({
        url: url,
        method: 'POST',
        params: {},
        headers: {
          'Content-type': 'application/json'
        },
        data: data,
        responseType: 'arraybuffer'
      })
        .success(function(data) {
          var file = new Blob([data], {
            type: 'application/pdf'
          });

          var fileURL = URL.createObjectURL(file);
          var a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
        })
        .error(function(data) {});
    }

    const getServiceDescription = (services = [], service_id) => {
      const foundService =
        services.find(({ service } = {}) => {
          const { _id } = service;
          return _id === service_id;
        }) || {};
      const { /* service = {}, */ source = {}, target = [] } = foundService;
      // const { value: serviceName = '' } = service;
      const { _id: sourceId, value: sourceName = '' } = source;
      const { _id: targetId, value: targetName = '' } = target[0] || {};
      // return `${serviceName} ${sourceName} ${targetName ? `=> ${targetName}` : ''}`
      return {
        sourceId,
        targetId,
        sourceName,
        targetName
      };
    };

    const getTaskDescription = (_descriptions, lang) => {
      if (!_descriptions) return '';
      let descriptions;
      if (Array.isArray(_descriptions)) {
        descriptions = NameArrayToObject(_descriptions);
      } else descriptions = _descriptions;
      return descriptions[lang] || '';
    };

    const setTasksForOffer = (workflow = {}, lang) => {
      const { currency, exchangeRate, offer = {}, projects = [] } = workflow;
      const { extraServices = [] } = offer;

      extraServices.forEach((service = {}) => {
        const { description = [] } = service;
        const foundDescription = description.find(
          ({ lang: testedLang } = {}) => testedLang === lang
        );
        const { value = '' } = foundDescription;
        service.translatedDescription = value;
      });

      projects.forEach((project = {}) => {
        project.tasksForOffer = [];
        const { batches = [], services = [], tasksForOffer } = project;
        batches.forEach((batch = {}) => {
          const { tasks = [] } = batch;
          tasks.forEach((task = {}) => {
            const {
              _id,
              description: taskDescriptions = [],
              params = {},
              quantity = 0,
              service_id = '',
              unit = {},
              value = ''
            } = task;
            let { amount = 0, unitPrice = 0 } = task;

            const { offer } = params || {};
            if (!offer) return;

            const taskDescription = getTaskDescription(taskDescriptions, lang);

            const {
              sourceId,
              targetId,
              sourceName = '',
              targetName = ''
            } = getServiceDescription(services, service_id);

            if (currency && currency !== 'EUR' && +exchangeRate) {
              amount = +(amount * exchangeRate).toFixed(2) || 0;
              unitPrice = +(unitPrice * exchangeRate).toFixed(3) || 0;
            }

            tasksForOffer.push({
              _id,
              amount,
              taskDescription,
              // serviceDescription,
              quantity,
              unit: unit.value || '',
              unitId: unit._id,
              unitPrice,
              value,
              sourceId,
              targetId,
              sourceName,
              targetName
            });
          });
        });
      });
    };

    const filterEmptyProjects = workflow => {
      let { projects } = workflow || {};
      projects = Array.isArray(projects) ? projects : [];
      workflow.projects = projects.filter(project => {
        let { tasksForOffer } = project || [];
        tasksForOffer = Array.isArray(tasksForOffer) ? tasksForOffer : [];
        return tasksForOffer.length;
      });
    };

    return {
      download,
      filterEmptyProjects,
      setTasksForOffer
    };
  }
]);
