angular
  .module('workflow')

  .directive('radioBilling', function() {
    'use strict';
    return {
      replace: true,
      scope: {
        billingAddress: '=ngModel',
        company: '='
      },
      templateUrl: 'custom/workflow/views/partials/radioBilling.html',
      controller: [
        '$scope',
        '$timeout',
        '$modal',
        '$filter',
        'CompanyCrud',
        'BillingCompanyCreator',
        'LegalAddress',
        'Dialog',
        function(
          $scope,
          $timeout,
          $modal,
          $filter,
          CompanyCrud,
          BillingCompanyCreator,
          LegalAddress,
          Dialog
        ) {
          let modalInstance,
            billingCompanyCrud = new CompanyCrud($scope),
            companyCrud = new CompanyCrud($scope);

          function transformBillingAddress(billing) {
            const { note = '' } = $scope.billingAddress || {};
            const obj = angular.copy(billing || {});
            delete obj.billingCompanies;
            obj.note = note;
            return obj;
          }

          $scope.$watch('company', (company, old) => {
            if (!company) return;

            const { billingCompanies = [], _id } = company;

            $scope.selected = $scope.selected || _id;
            $scope.billingCompanies = [];
            $scope.legal_address = LegalAddress.get($scope.company);

            const promises = billingCompanies.map(billingCompany => {
              const companyId =
                typeof billingCompany === 'string'
                  ? billingCompany
                  : billingCompany._id;
              return billingCompanyCrud
                .get(companyId)
                .then(companyObject => {
                  if (
                    !$scope.billingCompanies.find(
                      ({ _id }) => _id === companyObject._id
                    )
                  ) {
                    $scope.billingCompanies.push(companyObject);
                  }
                })
                .catch(error => Sentry.captureException(error));
            });

            const operatives = companyCrud
              .get(_id)
              .then(
                ({ headquarters = [] }) =>
                  ($scope.operatives = headquarters.filter(h => !h.legal))
              )
              .catch(error => Sentry.captureException(error));

            promises.push(operatives);

            Promise.all(promises).then(() => $scope.setBilling());
          });

          $scope.$watch('billingAddress', billingAddress => {
            const { _id } = billingAddress || {};
            if (!_id) return;

            $scope.selected = _id;
          });

          $scope.setBilling = () => {
            $timeout(() => {
              if ($scope.selected === $scope.company._id) {
                $scope.billingAddress = transformBillingAddress($scope.company);
                $scope.billingAddress.legal_address = LegalAddress.get(
                  $scope.company
                );
              } else if (Array.isArray($scope.billingCompanies)) {
                $scope.billingAddress = transformBillingAddress(
                  $scope.billingCompanies.find(billingCompany => {
                    return (
                      billingCompany && billingCompany._id === $scope.selected
                    );
                  })
                );
              }
              $scope.setBillingOperative();
            });
          };

          $scope.setBillingOperative = () => {
            const selected = $scope.operatives.find(
              ({ _id }) => _id === $scope.selected
            );
            if (!selected) return;
            // costruisco una company unendo la company principale con la sede operativa
            const companyOperative = angular.copy($scope.company || {});
            companyOperative.headquarters = [{ ...selected, legal: true }];
            companyOperative._id = selected._id;
            companyOperative.legal_address = selected.address || {};
            companyOperative.legal_address.department = selected.department;

            $scope.billingAddress = transformBillingAddress(companyOperative);
            $scope.billingAddress.legal_address = LegalAddress.get(
              companyOperative
            );
          };

          function openModal() {
            $scope.billingComp = {};

            modalInstance = $modal.open({
              animation: true,
              templateUrl:
                'custom/company/views/partials/modalFormBilling.html',
              controller: 'ModalFormBillingCtrl',
              scope: $scope,
              size: 'lg'
            });
          }

          $scope.$watch('billing', function(billing) {
            if (billing) $scope.selected = billing._id || billing;
          });

          $scope.$watch('selected', function(selected) {
            if ($scope.company && selected === $scope.company._id) {
              $scope.billing = $scope.company;
            } else {
              $scope.billing = ($scope.billingCompanies || []).find(function(
                billingCompany
              ) {
                return (
                  selected === billingCompany._id || selected === billingCompany
                );
              });
            }
          });

          $scope.addBillingAddress = function() {
            openModal();

            modalInstance.result.then(function() {
              BillingCompanyCreator.save(billingCompanyCrud, $scope.billingComp)
                .then(response => {
                  delete $scope.vatErrorMail;
                  $scope.company.billingCompanies =
                    $scope.company.billingCompanies || [];
                  $scope.company.billingCompanies.push(response._id);
                  $scope.billingCompanies = $scope.billingCompanies || [];
                  $scope.billingCompanies.push(response);
                  $scope.selected = response._id;

                  $timeout(() => {
                    companyCrud.update($scope.company).then(function(result) {
                      $scope.company = result;
                      $scope.billingAddress = transformBillingAddress(
                        $scope.company.billingCompanies.slice(-1)[0]
                      );
                    });
                  }, 200);
                })
                .catch(error => {
                  Sentry.captureException(error);

                  const { errors = [] } = error;

                  if (errors.some(({ msg }) => msg === 'Vat already in use')) {
                    const {
                      company_name: customerCompanyName = '',
                      project_manager = {}
                    } = $scope.company;

                    const {
                      email: pmEmail = '@@pm_default_email'
                    } = project_manager;

                    const {
                      company_name = '',
                      company_name2 = '',
                      headquarters = [],
                      tax_code = '',
                      vat = ''
                    } = $scope.billingComp;

                    const { address = {} } = headquarters[0] || {};

                    const {
                      city = '',
                      district = '',
                      nation = {},
                      number = '',
                      rue = '',
                      state = '',
                      zip = ''
                    } = address;

                    const { code: nationCode = '' } = nation;

                    const subject =
                      $filter('translate')('company.BILLING_COMPANIES') +
                      ` ${customerCompanyName}`;
                    const body =
                      $filter('translate')(
                        'workflow.ADD_BILL_COMP_VAT_MAIL_1'
                      ) +
                      ` ${customerCompanyName}.\n\n` +
                      $filter('translate')(
                        'workflow.ADD_BILL_COMP_VAT_MAIL_2'
                      ) +
                      '\n\n' +
                      $filter('translate')('form.COMPANY_NAME') +
                      `: ${company_name}\n` +
                      $filter('translate')('form.COMPANY_NAME2') +
                      `: ${company_name2}\n` +
                      $filter('translate')('form.COMPANY_VAT') +
                      `: ${vat}\n` +
                      $filter('translate')('form.TAX_CODE') +
                      `: ${tax_code}\n` +
                      $filter('translate')('form.NATION') +
                      `: ${nationCode}\n` +
                      $filter('translate')('form.STATE') +
                      `: ${state || district}\n` +
                      $filter('translate')('form.CITY') +
                      `: ${city}\n` +
                      $filter('translate')('form.ADDRESS') +
                      `: ${rue}\n` +
                      $filter('translate')('form.CIVIC_NUMBER') +
                      `: ${number}\n` +
                      $filter('translate')('form.ZIP') +
                      `: ${zip}\n`;

                    return ($scope.vatErrorMail = `${pmEmail}?subject=${encodeURI(
                      subject
                    )}&body=${encodeURI(body)}`);
                  }

                  const { msg } = errors[0] || {};
                  const dialog = new Dialog();
                  dialog.error(
                    msg || $filter('translate')('error.SOMETHING_WRONG')
                  );
                  delete $scope.vatErrorMail;
                });
            });
          };
        }
      ]
    };
  });
