angular
  .module('pb.base')

  .directive('pbAutocomplete', [
    '$filter',
    '$timeout',
    function($filter, $timeout) {
      return {
        restrict: 'E',
        require: '^ngModel',
        replace: true,
        scope: {
          selected: '=ngModel',
          list: '=',
          required: '=',
          placeholder: '@',
          limit: '@'
        },
        templateUrl: 'modules/base/views/partials/pbAutocomplete.html',
        link: function(scope) {
          scope.filtered = [];
          scope.viewList = false;

          scope.search = function(value) {
            scope.autocomplete = value;
            scope.filtered = angular.copy(
              $filter('filter')(scope.list, { value: value })
            );
            scope.viewList = true;
          };

          scope.select = function(item) {
            scope.autocomplete = item ? item.value : '';
            scope.selected = item;
            scope.viewList = false;
          };

          scope.onFocus = function() {
            $timeout(function() {
              scope.viewList = true;
            });
          };

          scope.onBlur = function() {
            $timeout(function() {
              scope.viewList = false;
            });
          };
        }
      };
    }
  ]);
