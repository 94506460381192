angular
  .module('pb.core.crud')

  .factory('Crud', [
    '$q',
    '$resource',
    'CrudCacheFactory',
    'QueryAdapter',
    'SetAuthHeader',

    function($q, $resource, CrudCacheFactory, QueryAdapter, SetAuthHeader) {
      'use strict';

      class Crud {
        constructor(cacheKey, url, params, actions, cacheParams) {
          this.cacheKey = cacheKey;
          this.url = url;
          this.params = params;
          this.actions = actions;
          this.cacheParams = cacheParams;
          this.init(cacheKey, url, params, actions, cacheParams);
        }

        init(cacheKey, url, params, actions, cacheParams) {
          var cache;

          this.cacheFactory = new CrudCacheFactory(cacheKey, cacheParams);
          cache = this.cacheFactory.create();

          SetAuthHeader();

          if (
            arguments.length >= 1 &&
            arguments[0] &&
            typeof arguments[0] === 'object'
          ) {
            this.resource = arguments[0];
          } else {
            params = params || { id: '@id' };
            actions = angular.extend(
              {
                get: {
                  method: 'GET'
                },
                update: {
                  method: 'PUT'
                },
                query: {
                  method: 'GET',
                  isArray: false
                }
              },
              actions
            );

            actions.get.cache = cache;
            actions.query.cache = cache;

            this.resource = $resource(url, params, actions);
          }
        }

        get(params) {
          return this.resource.get(QueryAdapter.adaptGet(params)).$promise.then(
            response => {
              return response;
            },
            err => {
              return $q.reject(err ? err.data : {});
            }
          );
        }

        query(params) {
          this.currentCall = this.resource.query(
            QueryAdapter.adaptQuery(params)
          );

          return this.currentCall.$promise.then(
            response => {
              return response;
            },
            err => {
              return $q.reject(err ? err.data : {});
            }
          );
        }

        save(data) {
          return this.resource.save(QueryAdapter.adaptSave(data)).$promise.then(
            response => {
              this.cacheFactory.destroy();
              this.init(
                this.cacheKey,
                this.url,
                this.params,
                this.actions,
                this.cacheParams
              );
              return response;
            },
            err => {
              return $q.reject(err ? err.data : {});
            }
          );
        }

        update(data) {
          return this.resource
            .update(QueryAdapter.adaptUpdate(data))
            .$promise.then(
              response => {
                this.cacheFactory.destroy();
                this.init(
                  this.cacheKey,
                  this.url,
                  this.params,
                  this.actions,
                  this.cacheParams
                );
                return response;
              },
              err => {
                return $q.reject(err ? err.data : {});
              }
            );
        }

        delete(data) {
          return this.resource
            .delete(QueryAdapter.adaptDelete(data))
            .$promise.then(
              response => {
                this.cacheFactory.destroy();
                this.init(
                  this.cacheKey,
                  this.url,
                  this.params,
                  this.actions,
                  this.cacheParams
                );
                return response;
              },
              err => {
                return $q.reject(err ? err.data : {});
              }
            );
        }
      }

      return Crud;
    }
  ]);
