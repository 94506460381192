angular
  .module('pb.base')

  .directive('selectLanguage', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        language: '=',
        native: '@',
        source: '@',
        optional: '<',
        forbidUnselect: '<'
      },
      template: `<select chosen="{'placeholder_text_single': ('service_detail.SELECT_SINGLE_LANG' | translate)}"
                                   ng-model="language" ng-options="l.value for l in languages track by l._id"
                                   ui-validate="validator">
                 </select>`,
      controller: [
        '$scope',
        '$controller',
        'LanguageCrud',
        '$filter',
        function($scope, $controller, LanguageCrud, $filter) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new LanguageCrud('-list'),
            packageName: 'languages',
            dataName: 'languages',
            filters: {}
          });

          $scope.validator = {};
          var nameValidator = $filter('translate')('VALIDATION.REQUIRED');
          $scope.validator[nameValidator] = 'optional || notEmpty($value)';

          var params = {};

          if ($scope.native) {
            params.native = $scope.native;
          }

          if ($scope.source) {
            params.source = $scope.source;
          }

          $scope.loadList(params).then(() => {
            if (!$scope.forbidUnselect)
              $scope.languages.unshift({
                _id: '',
                code: '',
                value: '-'
              });
          });

          if (!$scope.forbidUnselect)
            $scope.$watch(
              'language',
              language => {
                if (language && !language._id) delete $scope.language;
              },
              true
            );

          $scope.notEmpty = function(value) {
            return value && Object.keys(value).length > 0;
          };
        }
      ]
    };
  });
