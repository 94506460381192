angular
  .module('workflow')

  .directive('workflowSelectCompany', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        company: '=',
        customer: '=',
        showPopup: '<',
        onCompanyChange: '&'
      },
      templateUrl: 'custom/workflow/views/partials/workflowSelectCompany.html',
      controller: [
        '$scope',
        '$filter',
        '$timeout',
        '$controller',
        'CompanyCrud',
        'Dialog',
        'LoggedUser',
        'WorkflowStore',
        'WorkflowCompanyModel',
        function(
          $scope,
          $filter,
          $timeout,
          $controller,
          CompanyCrud,
          Dialog,
          LoggedUser,
          WorkflowStore,
          WorkflowCompanyModel
        ) {
          $scope.companyId = null;
          $scope.customerId = null;

          $controller('ListCtrl', {
            $scope: $scope,
            crud: new CompanyCrud($scope),
            packageName: 'companies',
            dataName: '_companies',
            filters: {
              page: 1,
              limit: 1000000,
              sort: {
                company_name: 1
              }
            }
          });

          $scope.$watch('searchCompanyInput', name => {
            if (name === undefined) return;
            if (name.length < 3) return delete $scope.companies;
            const filter = JSON.stringify({ name });
            delete $scope.company;
            $scope.loadList({ filter }).then(({ results = [] } = {}) => {
              $scope.companies = results.map(company =>
                WorkflowCompanyModel(company)
              );
            });
          });

          let firstCompanyChange = true;
          $scope.$watch('company', company => {
            const { _id: id, expired, settings } = company || {};
            const { companies = [] } = $scope;
            const { disableExpiredAmountAlert, unreliable } = settings || {};
            if (LoggedUser.isPM() && $scope.showPopup) {
              const messages = [];
              // !disableExpiredAmountAlert && expired && messages.push(
              //   `${$filter('translate')('company.EXPIRED_MESSAGE')} ${expired}`
              // );
              unreliable &&
                messages.push(
                  $filter('translate')('company.UNRELIABLE_MESSAGE')
                );
              messages.length && new Dialog().info(messages.join('\n'));
            }
            const mustPreselect =
              id && (firstCompanyChange || !companies.length);
            if (!mustPreselect) return;
            $scope.companies = [company];
            firstCompanyChange = false;
          });

          $scope.$watch('customer', customer => {
            if (!customer) return;
            $scope.customerId = customer._id;
          });

          $scope.$on(
            'undo:workflow:about',
            () => ($scope.searchCompanyInput = '')
          );
        }
      ]
    };
  });
