angular
  .module('library')

  .factory('PdfModel', function() {
    function PdfModel() {
      return {};
    }

    return PdfModel;
  });
