angular.module('components').factory('BuidDefaultOfferTemplate', [
  '$filter',
  'GetDefaultPdfTemplate',

  function($filter, GetDefaultPdfTemplate) {
    return workflow => {
      return GetDefaultPdfTemplate().then(template => {
        if (!template || !template.length) return {};

        const templateCpy = JSON.parse(JSON.stringify(template[0]));
        const workflowCpy = JSON.parse(JSON.stringify(workflow));

        const subject = (
          $filter('translate')('pdf.OFFER_FOR') +
          ((workflowCpy && workflowCpy.title) || '')
        ).toUpperCase();

        const company = workflowCpy && workflowCpy.company;
        const companyLegaAddress =
          company && company.legal_address && company.legal_address.address;
        const legaAddress = companyLegaAddress || {};

        const recipient = {
          title: $filter('translate')('pdf.COMPANY_TITLE'),
          name: (company && company.company_name) || '',
          address: `${legaAddress.rue || ''} ${legaAddress.number ||
            ''}\n${legaAddress.zip || ''} ${legaAddress.city || ''}`,
          nation: (legaAddress.nation && legaAddress.nation.value) || ''
        };

        const customer = workflowCpy && workflowCpy.customer;
        const openingBody = `${$filter('translate')(
          'pdf.CUSTOMER_TITLE'
        )} ${customer.first_name || ''} ${customer.last_name || ''}`;

        templateCpy.subject = subject;
        templateCpy.recipient = recipient;
        templateCpy.body = Object.assign({}, templateCpy.body, {
          opening: openingBody
        });
        return templateCpy;
      });
    };
  }
]);
