angular
  .module('company')

  .factory('CompanyList', [
    'Crud',

    function(Crud) {
      'use strict';

      class CompanyList extends Crud {
        constructor() {
          super(
            null,
            '@@apiUrl' + '/companies/:id',
            { id: '@id' },
            { query: { method: 'GET', isArray: true } }
          );
        }
      }

      return CompanyList;
    }
  ]);
