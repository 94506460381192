angular
  .module('workflow')

  .controller('WorkflowCompanyCtrl', [
    '$scope',
    'LoggedUser',
    'WorkflowStore',

    function($scope, LoggedUser, WorkflowStore) {
      var user = LoggedUser.getData();

      if (!user || user.type !== 'customer') {
        return;
      }

      if (!WorkflowStore.get('company') || !WorkflowStore.get('customer')) {
        $scope.workflow.company = user.company;

        const userObj = angular.copy(user);
        delete userObj.company;

        $scope.workflow.customer = userObj;

        WorkflowStore.set('company', user.company);
        WorkflowStore.set('customer', userObj);

        WorkflowStore.emit('company', user.company);
      }
    }
  ]);
