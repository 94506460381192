angular
  .module('company')

  .directive('tradosRanges', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        ranges: '=ngModel',
        allowQuantity: '<'
      },
      templateUrl: 'custom/company/views/partials/tradosRanges.html',
      controller: [
        '$scope',
        'TradosRangesAssets',
        function($scope, TradosRangesAssets) {
          $scope.price_ranges = TradosRangesAssets.price_ranges;

          $scope.$watch('ranges', function(newValue) {
            if (!newValue) {
              $scope.ranges = TradosRangesAssets.ranges;
            }
          });
        }
      ]
    };
  });
