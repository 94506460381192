angular
  .module('quote')

  .factory('QuoteExport', [
    '$http',
    '$timeout',
    '$filter',
    'Dialog',
    function($http, $timeout, $filter, Dialog) {
      const download = (filters = {}) => {
        const generateExportUrl =
          '@@apiUrl' + '/quotes-export' + '?filter=' + JSON.stringify(filters);
        const generateExportParams = { method: 'GET', url: generateExportUrl };

        $http(generateExportParams)
          .then(() => {
            $timeout(
              () =>
                new Dialog().info(
                  $filter('translate')('EXPORT_EMAIL_SUCCESS_1') +
                    '\n' +
                    $filter('translate')('EXPORT_EMAIL_SUCCESS_2')
                ),
              200
            );
          })
          .catch(error => {
            Sentry.captureException(error);
            $timeout(
              () =>
                new Dialog().error($filter('translate')('error.RECEIVE_ERR')),
              200
            );
          });
      };

      return { download };
    }
  ]);
