angular
  .module('pb.file')

  .factory('UniqueFileName', function() {
    function uniqueString() {
      var text = '',
        possible =
          'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      for (let i = 0; i < 16; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }

      return text;
    }

    return function(file) {
      var fileName = file && file.name ? file.name : 'new_file';
      return uniqueString() + '_' + fileName;
    };
  });
