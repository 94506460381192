angular
  .module('contributor')

  .directive('contributorCertifications', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        contributorCertifications: '=ngModel'
      },
      templateUrl:
        'custom/contributor/views/partials/contributorCertifications.html',
      controller: [
        '$scope',
        '$controller',
        '$filter',
        'Dialog',
        'ContributorCertificationsCrud',
        'UserLang',
        function(
          $scope,
          $controller,
          $filter,
          Dialog,
          ContributorCertificationsCrud,
          UserLang
        ) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new ContributorCertificationsCrud('-list'),
            packageName: 'contributorCertifications',
            dataName: 'certificationsList',
            filters: {}
          });

          $scope.loadList();
          $scope.userLang = UserLang.get();

          $scope.addCertification = function() {
            $scope.contributorCertifications = Array.isArray(
              $scope.contributorCertifications
            )
              ? $scope.contributorCertifications
              : [];
            $scope.contributorCertifications.push({
              certification: {}
            });
          };

          $scope.deleteCertification = function(index) {
            $scope.contributorCertifications.splice(index, 1);
          };
        }
      ]
    };
  });
