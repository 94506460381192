angular
  .module('contributor')

  .factory('ServiceUnitCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class ServiceUnitCrud extends Crud {
        constructor() {
          super(
            'services-units',
            '@@apiUrl' + '/services-units' + '/:id',
            { id: '@id' },
            {
              query: {
                method: 'GET',
                isArray: true
              }
            }
          );
        }
      }

      return ServiceUnitCrud;
    }
  ]);
