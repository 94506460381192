angular
  .module('pb.core.utilities')

  .directive('autoFillableField', [
    '$timeout',

    function($timeout) {
      return {
        restrict: 'A',
        require: '?ngModel',
        link: function(scope, element, attrs, ngModel) {
          $timeout(function() {
            if (ngModel.$viewValue !== element.val()) {
              ngModel.$setViewValue(element.val());
            }
          }, 500);
        }
      };
    }
  ]);
