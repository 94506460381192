angular
  .module('pb.core.crud')

  .controller('LoadBaseCtrl', [
    '$q',
    '$timeout',
    'Dialog',
    'ErrorsManager',
    '$scope',
    'crud',
    'object',
    'dataName',

    function(
      $q,
      $timeout,
      Dialog,
      ErrorsManager,
      $scope,
      crud,
      object,
      dataName
    ) {
      var dialog = new Dialog();

      dataName = dataName || 'data';
      $scope[dataName] = object ? new object() : {};

      $scope.enableErrorNotifications =
        typeof $scope.enableErrorNotifications === 'boolean'
          ? $scope.enableErrorNotifications
          : true;

      if (typeof crud.set === 'function') {
        crud.set($scope[dataName]);
      }

      //Load document from server
      $scope.processLoading = function(params) {
        return crud.get(params).then(
          function(response) {
            $scope[dataName] = response;
            return response;
          },
          function(err) {
            if ($scope.enableErrorNotifications) {
              $timeout(function() {
                dialog.error(ErrorsManager.getErrorsAsString(err));
              }, 100);
            }
            return $q.reject(err);
          }
        );
      };
    }
  ]);
