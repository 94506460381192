angular
  .module('customer')

  .factory('ZohoCustomerSync', [
    'Crud',

    function(Crud) {
      'use strict';

      class ZohoCustomerSync extends Crud {
        constructor(id) {
          super(null, '@@apiUrl' + '/contacts-sync/' + id, {});
        }
      }

      return ZohoCustomerSync;
    }
  ]);
