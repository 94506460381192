angular
  .module('workflow')

  .controller('WorkflowServiceDetailCtrl', [
    '$scope',
    '$timeout',
    '$state',
    '$stateParams',

    function($scope, $timeout, $state, $stateParams) {
      $scope.breadCrumbEditSrv = $stateParams.serviceTitle;

      $scope.undo = function() {
        $scope.performUndo();

        $timeout(function() {
          $state.go('^.data');
        });
      };
    }
  ]);
