angular
  .module('order')

  .factory('OrderCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class OrderCrud extends Crud {
        constructor(list) {
          super(
            '' + (list || ''),
            '@@apiUrl' + '/orders' + (list || '') + '/:id',
            { id: '@id' }
          );
        }
      }
      return OrderCrud;
    }
  ]);
