angular
  .module('components')

  .directive('sendButton', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        click: '&',
        tooltipMsg: '@'
      },
      template: `
        <div class="text-right">
            <button class="btn btn-danger btn-icon m-15 waves-effect waves-circle" ng-click="click()" tooltip="{{tooltipMsg || ('workflow.ASK_SEND' | translate)}}" tooltip-placement="top" tooltip-popup-delay="1000">
              <i class="zmdi zmdi-mail-send"></i>
            </button>
        </div>
      `
    };
  });
