angular
  .module('contributor')

  .directive('documentVote', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        document: '=ngModel'
      },
      templateUrl: 'custom/contributor/views/partials/documentVote.html',
      controller: [
        '$scope',
        '$filter',
        'Dialog',
        function($scope, $filter, Dialog) {
          var init = function() {
            if ($scope.document.votes) {
              $scope.current =
                $scope.document.votes[$scope.document.votes.length - 1];
            }
          };

          init();

          $scope.addVote = function() {
            if (!$scope.newVote || $scope.newVote > 10 || $scope.newVote < 1) {
              new Dialog().warning(
                $filter('translate')('contributors.INSERT_VOTE')
              );
              return;
            }

            var vote = {
              date: new Date(),
              vote: $scope.newVote
            };

            $scope.document.votes = $scope.document.votes || [];

            $scope.document.votes.push(vote);

            $scope.newVote = null;

            init();
          };
        }
      ]
    };
  });
