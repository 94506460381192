angular
  .module('contributor')

  .directive('selectServiceUnit', [
    '$filter',
    function($filter) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          uom: '=',
          services: '='
        },
        template:
          '<select ng-model="uom" ng-options=" u.value for u in uoms track by u._id">' +
          '<option value="">-</option>' +
          '</select>',
        controller: [
          '$scope',
          '$controller',
          'ServiceUnitCrud',
          function($scope, $controller, ServiceUnitCrud) {
            $controller('ListCtrl', {
              $scope: $scope,
              crud: new ServiceUnitCrud(),
              packageName: 'service',
              dataName: 'uoms',
              filters: {}
            });

            $scope.$watch(
              'services',
              function(newValue, oldValue) {
                if (newValue) {
                  var ids = [];

                  for (var i = 0; i < newValue.length; i++) {
                    if (newValue[i].service) {
                      if (typeof newValue[i].service === 'string') {
                        ids.push(newValue[i].service);
                      } else if (newValue[i].service._id) {
                        ids.push(newValue[i].service._id);
                      }
                    }
                  }

                  $scope.loadList({ services: ids });
                }
              },
              true
            );
          }
        ]
      };
    }
  ]);
