angular
  .module('task')

  .factory('TaskContributorCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class TaskContributorCrud extends Crud {
        constructor() {
          super(
            null,
            '@@apiUrl' + '/contributor-tasks' + '/:id',
            { id: '@id' },
            {
              query: {
                method: 'GET',
                isArray: true,
                cancellable: true
              }
            }
          );
        }
      }
      return TaskContributorCrud;
    }
  ]);
