angular
  .module('workflow')

  .directive('previewAbout', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        workflow: '=ngModel'
      },
      templateUrl: 'custom/workflow/views/partials/previewAbout.html',
      controller: [
        '$scope',
        'LoggedUser',
        function($scope, LoggedUser) {
          $scope.isPM = LoggedUser.isPM();
        }
      ]
    };
  });
