angular
  .module('pb.base')

  .controller('LoginMfaCtrl', [
    '$rootScope',
    '$scope',
    '$timeout',
    '$filter',
    '$translate',
    '$state',
    '$stateParams',
    'Auth',
    'AuthLoginMfa',
    'MeCrud',
    'User',
    'Alert',
    'Toast',

    function(
      $rootScope,
      $scope,
      $timeout,
      $filter,
      $translate,
      $state,
      $stateParams,
      Auth,
      AuthLoginMfa,
      MeCrud,
      User,
      Alert,
      Toast
    ) {
      $scope.backToLogin = () => $state.go('root.login');
      !$stateParams.email && $scope.backToLogin();

      $scope.email = $stateParams.email;
      const meCrud = new MeCrud();
      const alert = new Alert();
      const toast = new Toast();
      $scope.currentLang = $translate.use();

      const getUser = () => {
        meCrud
          .get({ id: Auth.getUserId() })
          .then(response => {
            $rootScope.$broadcast('goToHome');
            $timeout(() => {
              toast.message(
                $filter('translate')('HI') +
                  (angular.isDefined(response.first_name)
                    ? ' ' + response.first_name
                    : '!')
              );
            }, 1000);
          })
          .catch(() => alert.error($filter('translate')('error.RECEIVE_ERR')));
      };

      $scope.loginMfa = () => {
        const code = ($scope.code || '').replace(/[^0-9]/g, '');
        Auth.loginMfa(new AuthLoginMfa(), $scope.email, code)
          .then(getUser)
          .catch(() => {
            alert.error($filter('translate')('auth.MFA_LOGIN_ERROR'));
          });
      };
    }
  ]);
