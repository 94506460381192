angular
  .module('library')

  .factory('CostsCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class CostsCrud extends Crud {
        constructor(list) {
          super(
            null,
            '@@apiUrl' + '/costs' + (list || '') + '/:id',
            { id: '@id' },
            {
              query: {
                method: 'GET',
                isArray: true
              }
            }
          );
        }
      }

      return CostsCrud;
    }
  ]);
