angular
  .module('pb.file')

  .service('FileUploader', [
    '$q',
    'FilesCrud',
    'PbUploader',
    'S3Uploader',

    function($q, FilesCrud, PbUploader, S3Uploader) {
      var crud = new FilesCrud();

      this.upload = function(file, params, customData, progressCallback) {
        var deferred = $q.defer();
        var name = params.fileName || file.name || 'new_file';
        var path = params.path || '';
        var key = path + '/' + name;

        var data = {
          name: name,
          path: path,
          data: Object.assign({}, customData)
        };

        // var uploaderType = '@@file_upload';
        // var uploader = uploaderType === 'S3' ? S3Uploader : PbUploader;
        //
        // uploader
        S3Uploader.upload(file, key, progressCallback).then(
          function(uploaderResponse) {
            crud.save(Object.assign(data, uploaderResponse)).then(
              function(response) {
                deferred.resolve(response);
              },
              function(err) {
                deferred.reject({ message: err });
              }
            );
          },
          function(err) {
            deferred.reject(err);
          }
        );

        return deferred.promise;
      };
    }
  ]);
