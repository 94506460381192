angular
  .module('library')

  .directive('selectMacro', function() {
    return {
      restrict: 'E',
      scope: {
        item: '=',
        packageName: '='
      },
      template: `
      <div style="display:flex">
        <select chosen="{'placeholder_text_single': ('library.SELECT_PARENT' | translate)}"
                ng-model="item.parent" style="width:200px" ng-options="m.value for m in macros track by m._id" />
        <ul class="actions pull-right">
          <li>
              <a ng-click="clear()"><i class="zmdi zmdi-close-circle"></i></a>
          </li>
        </ul>
      </div>
      `,

      controller: [
        '$scope',
        '$controller',
        'GetCrudOptions',
        function($scope, $controller, GetCrudOptions) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: GetCrudOptions($scope.packageName, '-list').crud,
            packageName: $scope.packageName + 'Macro',
            dataName: 'macros',
            filters: {
              macro: 1
            }
          });

          $scope.loadList().then(function(response) {
            for (var i = $scope.macros.length - 1; i >= 0; i--) {
              if ($scope.item._id === $scope.macros[i]._id) {
                $scope.macros.splice(i, 1);
              }
            }
          });

          $scope.clear = function() {
            $scope.item.parent = null;
          };
        }
      ]
    };
  });
