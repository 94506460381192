'use strict';

angular
  .module('workflow')

  .factory('CleanProject', [
    'CleanBatches',
    function(CleanBatches) {
      const cleanServices = (services = [], shouldDeleteTarget) => {
        services.forEach(service => {
          delete service.delivery_date;
          if (shouldDeleteTarget) {
            service.target = [];
          }
        });
      };

      return (options = {}) => (project, projectIndex) => {
        const {
          setEmptyDuplicatedFrom,
          shouldDeleteCode,
          shouldDeleteTarget
        } = options;

        delete project.delivered;
        delete project.delivery_date;
        delete project.started;
        delete project.uuid;
        delete project.fileMode;

        if (shouldDeleteCode) {
          const { code, uuid } = project;
          project.duplicatedFrom = { code, uuid };
          delete project.code;
          delete project.uuid;
        }
        if (projectIndex && project.duplicatedFrom && setEmptyDuplicatedFrom) {
          project.duplicatedFrom = {};
        }

        const { batches = [], services = [] } = project;
        CleanBatches(batches, options);
        cleanServices(services, shouldDeleteTarget);
      };
    }
  ]);
