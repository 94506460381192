angular
  .module('customer')

  .factory('CustomerActivation', [
    'Crud',

    function(Crud) {
      'use strict';

      class CustomerActivation extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/activeprofile/:id', { id: '@id' });
        }
      }

      return CustomerActivation;
    }
  ]);
