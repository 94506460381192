angular
  .module('home')

  .controller('HomeCtrl', [
    '$scope',
    '$state',
    'LoggedUser',

    function($scope, $state, LoggedUser) {
      $scope.isContributor = LoggedUser.isContributor();

      /*$scope.cancel = function() {
              $state.go("^");
          }

          $scope.setRange = function() {
              if ($scope.range > 0)
                $scope.newRange = $scope.range;
              $state.go("^");
          }*/
    }
  ]);
