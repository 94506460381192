angular
  .module('components')

  .directive('selectCurrency', [
    'ISO4217',
    function(ISO4217) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          currency: '=',
          type: '='
        },
        template:
          '<select ng-model="currency" ng-options="option as option for option in currencies">' +
          '<option value="">-</option>' +
          '</select>',
        controller: [
          '$scope',
          function($scope) {
            $scope.currencies = Object.keys(ISO4217.codeToCurrency);

            $scope.$watch('type', function(newValue, oldValue) {
              if (newValue !== oldValue && newValue === 'supplier') {
                $scope.currency = 'EUR';
              }
            });
          }
        ]
      };
    }
  ]);
