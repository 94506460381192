angular
  .module('workflow')

  .controller('ProjectsCtrl', [
    '$scope',
    '$state',
    'WorkflowStore',
    'ProjectStore',
    'Confirm',
    '$timeout',
    'LoggedUser',
    'ProjectModel',
    'Dialog',
    '$filter',
    'ProjectStartCrud',
    'CleanProject',
    'CostsCrud',
    'Clipboard',
    'LSPrefix',
    'CostsSummarySrv',
    'ProjectFilesUtils',

    function(
      $scope,
      $state,
      WorkflowStore,
      ProjectStore,
      Confirm,
      $timeout,
      LoggedUser,
      ProjectModel,
      Dialog,
      $filter,
      ProjectStartCrud,
      CleanProject,
      CostsCrud,
      Clipboard,
      LSPrefix,
      CostsSummarySrv,
      ProjectFilesUtils
    ) {
      const defaultIndirectCostsRatio =
        parseFloat('@@default_indirect_costs_ratio') || 0.6;
      const confirm = new Confirm();

      $scope.isPM = LoggedUser.isPM();

      WorkflowStore.clearProjectStore();
      ProjectStore.clear();

      function incrementCounter() {
        $scope.workflow.countProject = $scope.workflow.countProject || 0;
        return ($scope.workflow.countProject += 1);
      }

      const getIndirectCostsRatio = () => {
        const defaultRatio =
          parseFloat('@@default_indirect_costs_ratio') || 0.6;
        return new CostsCrud()
          .query()
          .then((costs = []) => {
            const { percent = 0 } =
              costs.find(({ code }) => code === '@@indirect_costs_code') || {};
            const ratio = percent / 100;
            return isNaN(ratio) ? defaultRatio : ratio;
          })
          .catch(error => {
            Sentry.captureException(error);
            return defaultRatio;
          });
      };

      $scope.addProject = () => {
        if (!$scope.workflow) return;

        $scope.workflow.projects = $scope.workflow.projects || [];
        const newProject = new ProjectModel(
          incrementCounter(),
          $scope.workflow
        );

        getIndirectCostsRatio().then(ratio => {
          newProject.customIndirectCostsRatio = ratio;
          $scope.workflow.projects.push(newProject);
          WorkflowStore.emit('save', $scope.workflow);
        });
      };

      $scope.delete = function(index) {
        confirm.info('Vuoi eliminare il progetto?').then(function() {
          if ($scope.workflow && $scope.workflow.projects) {
            $scope.workflow.projects.splice(index, 1);
            WorkflowStore.emit('save', $scope.workflow);
          }
        });
      };

      $scope.go = function(code, goToTasks) {
        $state.go(goToTasks ? $scope.tasks : $scope.detail, {
          projectCode: code
        });

        if (typeof $scope.next === 'function') {
          $scope.next();
        }

        $timeout(function() {
          WorkflowStore.emitChange($scope.workflow);
        });
      };

      $scope.getTarget = (project = {}) => {
        const { services = [] } = project;
        const { target: targetList = [] } =
          services.find(service => service.first) || {};
        const target = targetList[0] || {};
        const { value = '' } = target;
        return value;
      };

      $scope.getSource = (project = {}) => {
        const { services = [] } = project;
        const { source } = services.find(service => service.first) || {};
        const { value = '' } = source || {};
        return value;
      };

      const startProject = projectUuid => {
        const crud = new ProjectStartCrud();
        return crud
          .update({ projectUuid })
          .then(updatedWorkflow => WorkflowStore.emitChange(updatedWorkflow));
      };

      $scope.start = index => {
        const { workflow = {} } = $scope;
        const { projects = [] } = workflow;
        const thisProject = projects[index];
        if (!thisProject) return;

        const { started } = thisProject;

        if (started) {
          const dialog = new Dialog();
          return dialog.info($filter('translate')('workflow.ALREADY_STARTED'));
        }

        const { uuid: projectUuid } = thisProject;

        confirm
          .show($filter('translate')('workflow.START_PROJECT_MSG'))
          .then(() => startProject(projectUuid))
          .catch(error => {
            if (!error) return;

            Sentry.captureException(error);
            const message =
              error.message || $filter('translate')('error.SOMETHING_WRONG');
            const dialog = new Dialog();
            $timeout(() => dialog.error(message), 250);
          });
      };

      $scope.copyFirst = targetIndex => {
        confirm
          .show($filter('translate')('workflow.COPY_FIRST_PROJECT_CONF_MSG'))
          .then(() => {
            const { workflow = {} } = $scope;
            const { company, projects = [] } = workflow;
            const config = angular.copy(projects[0]);

            const cleanOptions = {
              shouldDeleteCode: true,
              shouldDeleteContributors: true,
              shouldDeleteTarget: true,
              shouldKeepTaskDeliveryDateTime: true
            };

            CleanProject(cleanOptions)(config);
            const targetProject = projects[targetIndex] || {};
            const originalServices = targetProject.services || [];
            const originalTargets = originalServices.map(service => {
              return service.target || [];
            });
            Object.assign(targetProject, config);
            originalTargets.forEach((target, index) => {
              targetProject.services[index].target = target;
            });
            const [firstService] = originalServices;
            const { source, target } = firstService || {};
            const [firstTarget] = target;
            if (company && source && firstTarget) {
              CostsSummarySrv.setCompany(company);
              CostsSummarySrv.setSource(source);
              CostsSummarySrv.setTarget(firstTarget);
              const batches = targetProject.batches || [];
              CostsSummarySrv.setBatches(batches);
              batches.forEach(batch => {
                const tasks = batch.tasks || [];
                tasks.forEach(task => {
                  delete task.amount;
                  task.unitPrice = CostsSummarySrv.searchPrice(task);
                  const { quantity, unitPrice } = task || {};
                  if ((unitPrice || unitPrice === 0) && quantity) {
                    task.amount = unitPrice * quantity;
                  }
                });
              });
              calculateProjectTotals(targetProject);
              // Reset CostsSummarySrv
              CostsSummarySrv.setCompany(undefined);
              CostsSummarySrv.setSource(undefined);
              CostsSummarySrv.setTarget(undefined);
              CostsSummarySrv.setBatches(undefined);
              WorkflowStore.emit('save', $scope.workflow);
            }
          });
      };

      $scope.copyS3Path = index => {
        const { workflow = {} } = $scope;
        const { _id = '', projects = [] } = workflow;
        const project = projects[index];
        const humanPath = ProjectFilesUtils.getHumanPath(
          workflow,
          project
        ).replace('@@landoor_network_share_cut', '');
        const networkSharePrefix = '@@landoor_network_share_prefix';
        Clipboard.copy(
          `${networkSharePrefix}/${humanPath}`.replace(/\//g, '\\')
        );
      };

      $scope.lastTargetPath = index => {
        const { workflow } = $scope;
        const { _id: workflowId, company, projects } = workflow || {};
        const { _id: companyId } = company || {};
        const project = projects && projects[index];
        const { batches, uuid: projectUuid } = project || {};
        const lastBatch = batches && batches[batches.length - 1];
        const { tasks, uuid: batchUuid } = lastBatch || {};
        const lastTask = tasks && tasks[tasks.length - 1];
        const { uuid: taskUuid } = lastTask || {};
        return `/app/p/docs/c/${companyId}/workflows/${workflowId}/projects/${projectUuid}/batches/${batchUuid}/tasks/${taskUuid}/target/`;
      };

      $scope.shareAllSources = () => {
        confirm
          .show($filter('translate')('workflow.SHARE_ALL_FILES_MSG'))
          .then(() => {
            const { workflow } = $scope;
            const { projects } = workflow || {};
            if (!projects || !projects.length) return;

            const [firstProject] = projects;
            const { code: firstProjectCode, uuid: firstProjectUuid } =
              firstProject || {};
            if (!firstProjectUuid) return;

            projects.forEach((project, index) => {
              if (!index) return;
              project.duplicatedFrom = {
                code: firstProjectCode,
                uuid: firstProjectUuid
              };
            });
            WorkflowStore.emit('save', $scope.workflow);
          });
      };

      const calculateProjectTotals = project => {
        if (!project) return;

        const { batches, customIndirectCostsRatio, services } = project;
        const ratio = CostsSummarySrv.setRatio(
          customIndirectCostsRatio || defaultIndirectCostsRatio
        );
        if (!services || !Array.isArray(services)) return;

        const [firstService] = services;
        if (!firstService) return;

        const { source, target } = firstService;
        source && CostsSummarySrv.setSource(source);

        if (target && Array.isArray(target)) {
          const [firstTarget] = target;
          firstTarget && CostsSummarySrv.setTarget(firstTarget);
        }

        if (batches && Array.isArray(batches)) {
          CostsSummarySrv.setBatches(batches);
        }

        const totContributorCost = CostsSummarySrv.totContributorCost('trados');
        let { ok: totalPrice } = CostsSummarySrv.totalPrice();
        totalPrice = totalPrice || 0;
        const indirectCosts = totalPrice * ratio;
        const pmFee = CostsSummarySrv.getFee();

        Object.assign(project, {
          totals: {
            amount: totalPrice,
            costs: {
              directs: totContributorCost,
              indirects: indirectCosts,
              pm: pmFee,
              total: totContributorCost + indirectCosts + pmFee
            },
            profit: {
              gross: totalPrice - totContributorCost,
              net: totalPrice * (1 - ratio) - pmFee - totContributorCost
            }
          }
        });
        // Reset to the default value before cycling the next project
        CostsSummarySrv.setRatio(defaultIndirectCostsRatio);
      };

      const copyWordsNumber = () => {
        const { workflow } = $scope;
        const { company, projects } = workflow || {};

        if (!projects || !projects.length)
          return $timeout(() => {
            new Dialog.error(
              $filter('translate')(
                'workflow.COPY_WORDS_NUMBER_TASK_NOT_FOUND_ERROR'
              )
            );
          }, 250);

        if (!company)
          return $timeout(() => {
            new Dialog.error(
              $filter('translate')(
                'workflow.COPY_WORDS_NUMBER_NO_COMPANY_ERROR'
              )
            );
          }, 250);

        const [firstProject] = projects;
        let firstTranslationTask;
        (firstProject.batches || []).some(batch => {
          return (batch.tasks || []).some(task => {
            const isTranslation = [1, '1'].includes(task && task.code);
            const hasWordUnit = [1, '1'].includes(
              task && task.unit && task.unit.code
            );
            if (isTranslation && hasWordUnit) {
              firstTranslationTask = task;
              return true;
            }
          });
        });
        if (!firstTranslationTask)
          return $timeout(() => {
            new Dialog.error(
              $filter('translate')(
                'workflow.COPY_WORDS_NUMBER_TASK_NOT_FOUND_ERROR'
              )
            );
          }, 250);
        const {
          delivery_date,
          delivery_date_time,
          delivery_time,
          quantity,
          trados
        } = firstTranslationTask;
        projects.forEach(project => {
          project.batches.forEach(batch => {
            batch.tasks.forEach(task => {
              if (task === firstTranslationTask) return; // Skip this task
              const isTranslation = [1, '1'].includes(task && task.code);
              const hasWordUnit = [1, '1'].includes(
                task && task.unit && task.unit.code
              );
              if (!isTranslation || !hasWordUnit) return; // Not a translation task with word unit, skip it
              task.delivery_date = delivery_date;
              task.delivery_date_time = delivery_date_time;
              task.delivery_time = delivery_time;
              task.quantity = quantity;
              if (quantity === 1 && trados) {
                task.trados = angular.copy(trados);
                delete task.unitPrice;
                delete task.amount;
              } else {
                delete task.trados;
                task.amount = task.unitPrice * quantity || 0;
              }
            });
          });
        });
        CostsSummarySrv.setCompany(company);
        projects.forEach(calculateProjectTotals);
        WorkflowStore.emit('save', $scope.workflow);
      };

      $scope.copyWordsNumber = () => {
        new Confirm()
          .show($filter('translate')('workflow.COPY_WORDS_NUMBER_MSG'))
          .then(copyWordsNumber)
          .catch(() => {});
      };
    }
  ]);
