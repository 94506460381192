angular
  .module('workflow')

  .controller('WorkflowTasksListCtrl', [
    '$scope',
    '$timeout',
    '$stateParams',
    '$filter',
    '$modal',
    '$state',
    '$controller',
    '$window',
    'LoggedUser',
    'ProjectStore',
    'WorkflowStore',
    'Confirm',
    'CostsSummarySrv',
    'Dialog',
    'OrderSetInProgressRequest',
    'CompanyCrud',
    'Toast',
    'BatchDeliveredCrud',
    'BatchInvoicedCrud',
    'ErrorsManager',
    'ProjectFilesUtils',
    'FilesCrud',
    'SetTaskPrices',
    'TaskStartCrud',
    'UserLang',
    'Clipboard',
    'LSPrefix',
    'CleanBatches',

    function(
      $scope,
      $timeout,
      $stateParams,
      $filter,
      $modal,
      $state,
      $controller,
      $window,
      LoggedUser,
      ProjectStore,
      WorkflowStore,
      Confirm,
      CostsSummarySrv,
      Dialog,
      OrderSetInProgressRequest,
      CompanyCrud,
      Toast,
      BatchDeliveredCrud,
      BatchInvoicedCrud,
      ErrorsManager,
      ProjectFilesUtils,
      FilesCrud,
      SetTaskPrices,
      TaskStartCrud,
      UserLang,
      Clipboard,
      LSPrefix,
      CleanBatches
    ) {
      $scope.userLang = UserLang.get();

      const unwatch = $scope.$watch('$parent.workflow.company', function(
        company
      ) {
        if (company) {
          const companyId = typeof company === 'object' ? company._id : company;

          $controller('LoadBaseCtrl', {
            $scope: $scope,
            crud: new CompanyCrud($scope),
            object: null,
            dataName: 'company'
          });

          $scope.processLoading({ id: companyId });
          unwatch();
        }
      });

      const setProjectType = project => {
        if (!(project && project.services && project.services.length)) return;

        if (!(project.services[0] && project.services[0].source)) return;

        $scope.projectType =
          project.services[0] && project.services[0].target
            ? 'bilingual'
            : 'monolingual';
      };

      var confirm = new Confirm();
      var dialog = new Dialog();
      var toast = new Toast();
      var deleteBatchMsg = $filter('translate')('task.DELETE_BATCH_MSG');
      var deleteTaskMsg = $filter('translate')('task.DELETE_TASK_MSG');
      var duplicateBatchMsg = $filter('translate')('task.DUPLICATE_BATCH_MSG');
      var modalInstance;

      WorkflowStore.addProjectStore($stateParams.projectCode);

      $scope.isPM = function() {
        return LoggedUser.isPM();
      };

      const onUiSortableUpdate = (e, ui) => {
        if (!$scope.editable) {
          ui.item.sortable.cancel();
        }
      };

      $scope.sortableOptions = {
        'ui-floating': true,
        update: onUiSortableUpdate
      };

      $scope.addContributor = function(taskIndex, batchIndex, contributor) {
        const { customer } = $scope.workflow || {};
        const { blacklist } = customer || {};
        let { contributors: blacklistedContributors } = blacklist || {};
        blacklistedContributors = blacklistedContributors || [];
        if (
          blacklistedContributors.includes(contributor._id) ||
          blacklistedContributors.some(({ _id }) => _id === contributor._id)
        ) {
          return $timeout(() => {
            dialog.warning(
              $filter('translate')('contributors.BLACKLISTED_WARNING')
            );
          }, 250);
        }

        const task = $scope.project.batches[batchIndex].tasks[taskIndex];
        const { trados, quantity, _id, unit } = task || {};
        const { servicesList = [] } = contributor || {};
        const {
          findMatchingService,
          findMatchingCombo,
          findMatchingComboByUnit
        } = CostsSummarySrv;

        const contributorService = findMatchingService(servicesList, _id) || {};
        const contributorCombo =
          findMatchingComboByUnit(contributorService.combos, unit) ||
          findMatchingCombo(contributorService.combos) ||
          {};

        const unitPrice = contributorCombo.price || 0;
        const price =
          unitPrice * quantity * (CostsSummarySrv.totalQuantities(trados) || 1);

        $scope.project.batches[batchIndex].tasks[taskIndex].contributors.push({
          _id: contributor._id,
          email: contributor.email,
          first_name: contributor.first_name,
          last_name: contributor.last_name,
          servicesList: contributor.servicesList,
          lsp: contributor.lsp,
          company_name: contributor.company_name,
          price: price,
          unit: contributorCombo.uom,
          unitPrice: unitPrice,
          quantity: quantity,
          contributor_type: contributor.contributor_type,
          lang: contributor.lang
        });

        SetTaskPrices(task);
      };

      ProjectStore.clear().listenTo(function(project) {
        setProjectType(project);
        $scope.project = project;
        $scope.project.batches = $scope.project.batches || [];
        $scope.project.batches.forEach(function(batch) {
          batch.tasks = batch.tasks || [];
          batch.tasks.forEach(function(task) {
            task.contributors = task.contributors || [];
          });
        });
        $scope.project.batchNames = Array.isArray($scope.project.batchNames)
          ? $scope.project.batchNames
          : new Array($scope.project.batches.length).fill('');
        $scope.editable = WorkflowStore.isEditable();
        $scope.orderStatus = WorkflowStore.getOrderStatus();
        $scope.requestObj = {
          _id: WorkflowStore.getOrderId()
        };

        if (Array.isArray($stateParams.newContributors)) {
          $stateParams.newContributors.forEach(newContributor => {
            if (
              newContributor &&
              $scope.project.batches[$stateParams.batch] &&
              !$scope.project.batches[$stateParams.batch].tasks[
                $stateParams.task
              ].contributors.some(function(contributor) {
                return newContributor._id === contributor._id;
              })
            ) {
              $scope.addContributor(
                $stateParams.task,
                $stateParams.batch,
                newContributor
              );
            }
          });
          $timeout(() => {
            if ($stateParams.newContributors) {
              delete $stateParams.newContributors;
              $scope.save();
            }
          }, 2000);
        }
      });

      $scope.deleteTask = function(taskIndex, batchIndex) {
        confirm.info(deleteTaskMsg).then(function() {
          $scope.project.batches[batchIndex].tasks.splice(taskIndex, 1);
        });
      };

      $scope.deleteBatch = function(index) {
        confirm.info(deleteBatchMsg).then(function() {
          const { batchNames = [], batches = [] } = $scope.project;
          batches.splice(index, 1);
          batchNames.splice(index, 1);
        });
      };

      $scope.duplicateBatch = function(index) {
        confirm.info(duplicateBatchMsg).then(function() {
          const newBatch = angular.copy($scope.project.batches[index]);
          CleanBatches([newBatch], { shouldDeleteContributors: true });
          $scope.project.batches.splice(index + 1, 0, newBatch);
          $scope.project.batchNames.splice(index + 1, 0, '');
        });
      };

      $scope.addBatch = function() {
        $scope.project.batches = $scope.project.batches || [];
        $scope.project.batches.push({ tasks: [] });
        $scope.project.batchNames.push('');
        $scope.addTask($scope.project.batches.length - 1);
      };

      const editTemplate = 'custom/workflow/views/partials/modalTask.html';
      const previewTemplate = 'custom/workflow/views/partials/previewTask.html';
      const modalTemplate = $scope.editable ? editTemplate : previewTemplate;

      var openModal = function(taskIndex, batchIndex, isNew, delivered) {
        $scope.targetString = '';
        $scope.isNew = isNew;
        let t;
        if (taskIndex || taskIndex === 0) {
          t = $scope.project.batches[batchIndex].tasks[taskIndex] || {};
          const delivery_time = new Date(t.delivery_time);
          t.delivery_time = delivery_time || undefined;
        } else {
          t = {
            delivery_date: $scope.workflow.delivery_date
          };
        }

        modalInstance = $modal.open({
          animation: true,
          templateUrl: modalTemplate,
          controller: 'TaskModalInstanceCtrl',
          scope: $scope,
          size: 'lg',
          resolve: {
            task: () => t,
            company: () => $scope.company,
            delivered: () => delivered,
            hideDownload: () => false
          }
        });
      };

      $scope.go = function(taskIndex, batchIndex) {
        const delivered = $scope.project.batches[batchIndex].delivered;
        openModal(taskIndex, batchIndex, false, delivered);

        modalInstance.result.then(function(task) {
          SetTaskPrices(task);
          $scope.project.batches[batchIndex].tasks[taskIndex] = task;
          $scope.save();
        });
      };

      $scope.goTaskDetail = (taskIndex, batchIndex) => {
        $state.go('^.task-detail', {
          taskIndex,
          batchIndex
        });
      };

      $scope.addTask = function(batchIndex) {
        $state.go('^.task-detail', {
          taskIndex: 'new',
          batchIndex
        });
      };

      const clearTask = task => {
        task.contributorCost = null;
        task.started = false;
        task.notified = false;
        task.done = false;
      };

      $scope.clearContributor = (taskIndex, batchIndex, contributorIndex) => {
        const task = $scope.project.batches[batchIndex].tasks[taskIndex];
        const { lsp, company_name, first_name, last_name } = task.contributors[
          contributorIndex
        ];

        const contributorName = lsp
          ? company_name
          : first_name + ' ' + last_name;

        confirm
          .info(
            $filter('translate')('task.DELETE_CONTRIBUTOR_MSG', {
              name: contributorName
            })
          )
          .then(function() {
            task.contributors.splice(contributorIndex, 1);
            SetTaskPrices(task);
            if (task.contributors.length === 0) {
              clearTask(task);
            }
            $scope.save();
          });
      };

      $scope.clearContributors = function(taskIndex, batchIndex) {
        confirm
          .info($filter('translate')('task.DELETE_CONTRIBUTORS_MSG'))
          .then(function() {
            const task = $scope.project.batches[batchIndex].tasks[taskIndex];
            task.contributors = [];
            clearTask(task);
            $scope.save();
          });
      };

      $scope.setFake = function(task) {
        if (task.params.fake) {
          task.params.fake = false;
          confirm.info($filter('translate')('task.SET_FAKE_MSG')).then(() => {
            task.contributors = [];
            task.params.fake = true;
            task.contributorCost = 0;
            task.started = false;
            task.notified = false;
            task.done = false;
          });
        } else {
          task.started = false;
          task.notified = false;
          task.done = false;
        }
      };

      const getMacrosectors = () => {
        const { $parent = {}, project } = $scope;
        const { workflow = {} } = $parent;

        if (!project || !workflow) return;

        const parentPath = ProjectFilesUtils.getSourcePath(workflow, project);

        $controller('ListCtrl', {
          $scope: $scope,
          crud: new FilesCrud(),
          packageName: 'files',
          dataName: 'files',
          filters: {}
        });

        return $scope
          .loadList({ parentPath })
          .then((files = []) => {
            const { data } = files[0] || {};
            return data || {};
          })
          .catch(error => Sentry.captureException(error));
      };

      $scope.searchContributor = (taskIndex, batchIndex) => {
        const unregister = $scope.$on('save:done', () => {
          unregister();
          $scope.doSearchContributor(taskIndex, batchIndex);
        });
        $scope.save();
      };

      $scope.doSearchContributor = (taskIndex, batchIndex) => {
        getMacrosectors().then(({ document, macrosector, sector }) => {
          $state.go('root.app.simple.contributors', {
            fromId: $stateParams.id,
            fromProjectCode: $stateParams.projectCode,
            fromState: $state.current.name,
            batch: batchIndex,
            task: taskIndex,
            iso17100: $scope.workflow.iso17100,
            // filterByTask: {
            //   _id: $scope.project.batches[batchIndex].tasks[taskIndex]._id,
            //   lang_required: $scope.project.batches[batchIndex].tasks[taskIndex].lang_required,
            //   value: $scope.project.batches[batchIndex].tasks[taskIndex].name
            // unit: {
            //   _id: $scope.project.batches[batchIndex].tasks[taskIndex].unit._id,
            //   code: $scope.project.batches[batchIndex].tasks[taskIndex].unit.code,
            //   value: $scope.project.batches[batchIndex].tasks[taskIndex].unit.value,
            //   lc: $scope.project.batches[batchIndex].tasks[taskIndex].unit.lc
            // }
            // },
            // filterBySource: $scope.project.services[0].source,
            sourceCode:
              $scope.project.services[0].source &&
              $scope.project.services[0].source.code,
            // filterByTarget: $scope.project.services[0].target[0],
            targetCode:
              $scope.project.services[0].target &&
              $scope.project.services[0].target[0].code,
            // filterByPrice: $scope.pricePerUnit($scope.project.batches[batchIndex].tasks[taskIndex]),
            customerBlacklist: WorkflowStore.getCustomerBlacklist(),
            // document,
            // macrosector,
            // sector,
            company: $scope.company && $scope.company._id
          });
        });
      };

      $scope.deliverBatch = function(batchUUID, index, delivered = true) {
        confirm
          .info(
            $filter('translate')(
              `task.DELIVER_BATCH_${delivered ? '' : 'UNDO_'}MSG`
            )
          )
          .then(() => {
            new BatchDeliveredCrud().update({ _id: batchUUID, delivered }).then(
              function(response) {
                $scope.project.batches[index].delivered = delivered;
                WorkflowStore.emitChange(response);
                $timeout(function() {
                  toast.success($filter('translate')('crud.SAVED'));
                }, 100);
              },
              function(err) {
                $timeout(function() {
                  dialog.error(ErrorsManager.getErrorsAsString(err));
                }, 100);
              }
            );
          });
      };

      $scope.invoiceBatch = function(batchUUID, index, invoiced = true) {
        confirm
          .info(
            $filter('translate')(
              `task.INVOICE_BATCH_${invoiced ? '' : 'UNDO_'}MSG`
            )
          )
          .then(() => {
            new BatchInvoicedCrud().update({ _id: batchUUID, invoiced }).then(
              function(response) {
                $scope.project.batches[index].invoiced = invoiced;
                WorkflowStore.emitChange(response);
                $timeout(function() {
                  toast.success($filter('translate')('crud.SAVED'));
                }, 100);
              },
              function(err) {
                $timeout(function() {
                  dialog.error(ErrorsManager.getErrorsAsString(err));
                }, 100);
              }
            );
          });
      };

      $scope.canDeliverBatch = (batch = {}) => {
        const { tasks = [] } = batch;
        const allDone = tasks.every(task => task.done);
        const requiresDelivery = tasks.some(
          ({ params }) => params && params.delivery
        );

        return allDone && requiresDelivery;
      };

      $scope.contributorTaskStatus = (task = {}, _statuses = []) => {
        const { contributors = [] } = task;
        const statuses = Array.isArray(_statuses) ? _statuses : [_statuses];

        return contributors.some(contributor =>
          statuses.includes(contributor.task_status)
        );
      };

      $scope.useShortLabel = $window.innerWidth <= 1330;

      $window.onresize = () => {
        $scope.useShortLabel = $window.innerWidth <= 1330;
        $scope.$apply();
      };

      const startTask = uuids => {
        const crud = new TaskStartCrud();
        crud
          .update(uuids)
          .then(updatedWorkflow => WorkflowStore.emitChange(updatedWorkflow))
          .catch(error => Sentry.captureException(error));
      };

      $scope.start = (taskIndex, batchIndex) => {
        const { project = {} } = $scope;
        const {
          batches = [],
          uuid: projectUuid,
          started: projectStarted
        } = project;

        if (!projectStarted) {
          return dialog.info($filter('translate')('task.CANNOT_START'));
        }

        const batch = batches[batchIndex] || {};
        const { tasks = [], uuid: batchUuid } = batch;
        const task = tasks[taskIndex] || {};
        const {
          contributors = [],
          params = {},
          started,
          uuid: taskUuid
        } = task;
        const { fake } = params;

        if (started) {
          return dialog.info($filter('translate')('workflow.ALREADY_STARTED'));
        }

        if (taskIndex > 0) {
          const previousTask = tasks[taskIndex - 1] || {};
          const { done: previousDone } = previousTask;
          if (!previousDone)
            return dialog.info($filter('translate')('task.CANNOT_START'));
        }

        const uuids = { projectUuid, batchUuid, taskUuid };

        if (fake) {
          return startTask(uuids);
        }

        if (!contributors || !contributors.length) {
          return dialog.info($filter('translate')('task.NO_CONTRIBUTORS'));
        }

        if (
          !contributors.some(({ task_status }) => task_status === 'confirmed')
        ) {
          return dialog.info($filter('translate')('task.NONE_CONFIRMED'));
        }

        confirm
          .show($filter('translate')('task.START_MSG'))
          .then(() => startTask(uuids));
      };

      $scope.getRejectionClass = (contributor = {}) => {
        const { rejection } = contributor;
        if (!rejection) return 'c-red';
        const { not_first } = rejection;
        return not_first ? '' : 'c-red';
      };

      $scope.getRejectionReason = (contributor = {}) => {
        const { rejection = {} } = contributor;
        const { other } = rejection;
        let reason = '';
        Object.keys(rejection).forEach((key, index, array) => {
          if (key === 'other') reason += `${other}`;
          else
            reason += $filter('translate')(
              `task.REJECTION_REASON_${key.toUpperCase()}`
            );

          if (index + 1 < array.length) reason += '; ';
        });
        return (
          reason || $filter('translate')('task.REJECTION_REASON_UNAVAILABLE')
        );
      };

      $scope.copyS3Path = (batchIndex, taskIndex) => {
        const { project = {}, workflow = {} } = $scope;
        const { batches = [] } = project;
        const batch = batches[batchIndex];
        const { tasks = [] } = batch;
        const task = tasks[taskIndex];
        const humanPath = ProjectFilesUtils.getHumanTaskPath(
          workflow,
          project,
          batchIndex,
          task
        ).replace('@@landoor_network_share_cut', '');
        const networkSharePrefix = '@@landoor_network_share_prefix';
        Clipboard.copy(
          `${networkSharePrefix}/${humanPath}`.replace(/\//g, '\\')
        );
      };

      $scope.showToCustomer = (task = {}, isLast) => {
        const { params, showTargetToCustomer } = task;
        const { hamster } = params || {};
        if (hamster && isLast && typeof showTargetToCustomer === 'undefined') {
          // In this case, target files are shown by default.
          // Explicitly hide them.
          task.showTargetToCustomer = false;
        } else task.showTargetToCustomer = !task.showTargetToCustomer;
        $timeout(() => $scope.save());
      };

      $scope.hideProjectSource = (task = {}) => {
        task.hideProjectSource = !task.hideProjectSource;
        $timeout(() => $scope.save());
      };

      $scope.exchangeCurrency = amount => {
        if (!amount) return 0;

        const { currency, exchangeRate } = $scope.workflow;
        if (currency === 'EUR' || !exchangeRate) return amount;

        return (amount * exchangeRate || amount).toFixed(3);
      };

      $scope.renameBatch = batchIndex => {
        const { project = {} } = $scope;
        const { batchNames = [] } = project;
        const modalOptions = {
          animation: true,
          templateUrl: 'custom/workflow/views/partials/renameBatch.html',
          controller: 'RenameBatchCtrl',
          size: 'lg',
          resolve: {
            batchName: () => batchNames[batchIndex] || ''
          }
        };
        $modal.open(modalOptions).result.then(newName => {
          batchNames[batchIndex] = newName || '';
        });
      };
    }
  ]);
