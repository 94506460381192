angular
  .module('pb.core.user')

  .directive('pbReadonly', [
    'UserReadonly',

    function(UserReadonly) {
      return {
        restrict: 'A',
        priority: 998,
        link: function(scope, element, attrs) {
          if (UserReadonly.isReadonly(attrs.pbReadonly)) {
            element.attr('readonly', true);
          }
        }
      };
    }
  ]);
