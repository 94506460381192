angular
  .module('pb.base')

  .directive('addButtonFixed', function() {
    return {
      restrict: 'E',
      replace: true,
      template:
        '<button class="btn btn-float bgm-cyan m-btn ng-scope waves-effect waves-circle" ng-click="add()"><i class="zmdi zmdi-plus"></i></button>',
      scope: {
        add: '&'
      }
    };
  });
