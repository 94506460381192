angular
  .module('order', ['pb.core', 'components'])

  .run([
    '$filter',
    'Module',
    function($filter, Module) {
      var module = new Module();

      module.push({
        menu: {
          name: 'orders',
          title: $filter('translate')('order.ORDERS'),
          icon: 'zmdi zmdi-case-check',
          link: 'root.app.simple.orders'
        }
      });
    }
  ]);
