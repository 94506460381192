angular
  .module('order')

  .factory('OrderCountersCache', function() {
    let cache;

    return {
      get: () => cache,
      set: value => (cache = value)
    };
  });
