angular
  .module('pb.user')

  .controller('ProfileCtrl', [
    '$scope',
    '$controller',
    '$stateParams',
    '$state',
    'LoggedUser',
    'LSPrefix',
    'UserCrud',
    'UserLang',

    function(
      $scope,
      $controller,
      $stateParams,
      $state,
      LoggedUser,
      LSPrefix,
      UserCrud,
      UserLang
    ) {
      $controller('EditBaseCtrl', {
        $scope: $scope,
        crud: new UserCrud($scope),
        object: null,
        packageName: 'user',
        dataName: 'user',
        formName: 'userForm'
      });

      if ($stateParams.id) {
        $scope.processLoading();
      } else {
        $scope.user = LoggedUser.getData();
      }

      $scope.save = function() {
        $scope.processSaving().then(({ _id, lang } = {}) => {
          const { _id: loggedUserId } = LoggedUser.getData() || {};
          if (_id !== loggedUserId) return;
          if (lang && lang !== UserLang.get()) UserLang.set(lang, true);
        });
      };

      $scope.setDriveUnit = (unitLetter = '') => {
        if (!unitLetter || unitLetter.length !== 1) return;
        const drivePrefix = unitLetter.toUpperCase();
        localStorage.setItem(LSPrefix + 'drivePrefix', drivePrefix);
        $scope.drivePrefix = drivePrefix;
      };

      $scope.cancel = () => $state.go('^.simple.home');
      $scope.isPM = LoggedUser.isPM();
      $scope.drivePrefix = localStorage.getItem(LSPrefix + 'drivePrefix');
    }
  ]);
