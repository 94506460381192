angular
  .module('components')

  .directive('profileAccountButton', [
    '$controller',
    function($controller) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          email: '<'
        },
        template:
          '<a href="" class="pv-follow-btn btn-primary">{{::(\'CREATE_ACCOUNT\' | translate)}}</a>',
        controller: [
          '$scope',
          '$element',
          '$controller',
          function($scope, $element, $controller) {
            $element.click(function() {
              $scope.open();
            });

            $controller('ModalBaseCtrl', {
              $scope: $scope,
              controller: 'CreateProfileCtrl',
              templateUrl:
                'custom/components/views/partials/createAccountModal.html',
              content: {
                title: '@@appName'
              },
              childScope: false
            });
          }
        ]
      };
    }
  ])

  .controller('CreateProfileCtrl', [
    '$controller',
    '$scope',
    '$modalInstance',
    'content',
    'AuthRegister',
    'LoggedUser',
    'Auth',

    function(
      $controller,
      $scope,
      $modalInstance,
      content,
      AuthRegister,
      LoggedUser,
      Auth
    ) {
      $scope.isPM = LoggedUser.isPM();

      $scope.account = {
        email: $scope.email
      };

      $scope.enableSuccessNotifications = false;

      $controller('ModalBaseInstanceCtrl', {
        $scope: $scope,
        $modalInstance: $modalInstance,
        content: content
      });

      $controller('SaveBaseCtrl', {
        $scope: $scope,
        crud: new AuthRegister(),
        packageName: 'account',
        dataName: 'account',
        formName: 'accountForm'
      });

      $scope.logout = () => {
        Auth.logout().then(() => $scope.$emit('goToLogin'));
      };

      $scope.save = function() {
        var email = $scope.account.email;
        $scope.account.externalRegistration = true;

        $scope.processSaving().then(function(response) {
          if (response) {
            $scope.$emit('onCreateAccount', response.id, email);
            $scope.ok();
          }
        });
      };
    }
  ]);
