angular
  .module('pb.base')

  .directive('passwordStrength', [
    'PasswordStrength',

    function(PasswordStrength) {
      return {
        restrict: 'E',
        require: '^ngModel',
        templateUrl: 'modules/base/views/partials/passwordStrength.html',
        scope: {
          password: '=ngModel'
        },
        link: function(scope, element) {
          scope.strength = 0;

          scope.$watch('password', function(newValue, oldValue) {
            scope.strength = PasswordStrength.getStrength(newValue);
            element.find('.progress-bar').css('width', scope.strength + '%');
          });
        }
      };
    }
  ]);
