angular
  .module('pb.base')

  .factory('BootstrapWizardService', [
    '$rootScope',
    '$timeout',
    '$q',
    'Auth',
    'MeCrud',
    'User',
    'CheckStatesPermissions',

    function(
      $rootScope,
      $timeout,
      $q,
      Auth,
      MeCrud,
      User,
      CheckStatesPermissions
    ) {
      return function() {
        var deferred = $q.defer();

        function complete() {
          CheckStatesPermissions();
          $rootScope.$broadcast('onBootstrapComplete');
        }

        function goToLogin() {
          $timeout(function() {
            $rootScope.$broadcast('goToLogin');
            complete();
            deferred.reject();
          }, 1);
        }

        function goToHome() {
          $timeout(function() {
            $rootScope.$broadcast('goToHome');
            deferred.reject();
          }, 1);
        }

        //Get logged user if token is present
        if (Auth.isAuthenticated()) {
          new MeCrud().get({ id: Auth.getUserId() }).then(function(response) {
            if (response && response.active === true) {
              goToHome();
            } else {
              $rootScope.$broadcast('onBootstrapComplete');
              deferred.resolve();
            }
          }, goToLogin);
        } else {
          goToLogin();
        }

        return deferred.promise;
      };
    }
  ]);
