angular
  .module('signup')

  .directive('selectLangs', [
    '$filter',
    function($filter) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          lang: '='
        },
        template:
          '<select ng-model="lang" ng-options="key as value for (key, value) in langs">' +
          '<option value="">-</option>' +
          '</select>',
        controller: [
          '$scope',
          function($scope) {
            $scope.langs = {
              it: $filter('translate')('langs.IT'),
              en: $filter('translate')('langs.EN')
            };
          }
        ]
      };
    }
  ]);
