angular
  .module('company')

  .factory('AcquisitionSourceCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class AcquisitionSourceCrud extends Crud {
        constructor() {
          super(
            'acquisition-sources-list',
            '@@apiUrl' + '/acquisition-sources-list',
            {},
            {
              query: {
                method: 'GET',
                isArray: true
              }
            }
          );
        }
      }

      return AcquisitionSourceCrud;
    }
  ]);
