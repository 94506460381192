angular
  .module('pb.file')

  .service('FileDeleter', [
    'FilesCrud',
    'PbDeleter',
    'S3Deleter',

    function(FilesCrud, PbDeleter, S3Deleter) {
      let crud = new FilesCrud();

      this.delete = function(file) {
        // let uploaderType = '@@file_upload';
        // let deleter = uploaderType === 'S3' ? S3Deleter : PbDeleter;
        //
        // return deleter
        return S3Deleter.delete(file).then(() => {
          return crud.delete(file);
        });
      };
    }
  ]);
