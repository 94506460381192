angular
  .module('company')

  .directive('frameworkAgreement', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        company: '=ngModel'
      },
      templateUrl: 'custom/company/views/partials/frameworkAgreement.html',
      controller: [
        '$scope',
        'LoggedUser',
        function($scope, LoggedUser) {
          $scope.isPM = LoggedUser.isPM();
        }
      ]
    };
  });
