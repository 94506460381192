angular
  .module('company')

  .factory('CompanyCrud', [
    'CrudUndoable',

    function(CrudUndoable) {
      'use strict';

      class CompanyCrud extends CrudUndoable {
        constructor($scope) {
          super($scope, null, '@@apiUrl' + '/companies/:id', { id: '@id' });
        }
      }

      return CompanyCrud;
    }
  ]);
