angular
  .module('components')

  .directive('selectTitles', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        title: '='
      },
      template:
        '<select chosen ng-model="title" ng-options="j.value for j in titles track by j._id">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'TitleCrud',
        function($scope, $controller, TitleCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new TitleCrud('-list'),
            packageName: 'titles',
            dataName: 'titles',
            filters: {}
          });

          $scope.loadList();
        }
      ]
    };
  });
