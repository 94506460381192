angular
  .module('company')

  .controller('CompanyCreateCtrl', [
    '$scope',
    '$state',
    '$controller',
    'CompanyCrud',
    'CompanyModel',

    function($scope, $state, $controller, CompanyCrud, CompanyModel) {
      $scope.company = new CompanyModel();

      $controller('SaveBaseCtrl', {
        $scope: $scope,
        crud: new CompanyCrud($scope),
        object: CompanyModel,
        packageName: 'company',
        dataName: 'company',
        formName: 'companyForm'
      });

      $scope.save = function() {
        $scope.processSaving().then(function(response) {
          $state.go('^.company.about', { id: response._id });
        });
      };
    }
  ]);
