angular
  .module('library')

  .directive('selectLibrary', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: true,
      templateUrl: 'custom/library/views/partials/selectLibrary.html',
      controller: 'LibraryHeaderCtrl'
    };
  });
