angular
  .module('contributor')

  .directive('selectAssociation', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        association: '='
      },
      template:
        '<select id="association-filter-select" ng-model="association" ng-options="s.code as s.value for s in associations track by s._id">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'AssociationCrud',
        function($scope, $controller, AssociationCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new AssociationCrud('-list'),
            packageName: 'associations',
            dataName: 'associations',
            filters: {}
          });

          $scope.loadList();
        }
      ]
    };
  });
