angular.module('workflow').controller('TaskDetailCtrl', [
  '$controller',
  '$scope',
  '$state',
  '$stateParams',
  'ProjectStore',

  // TaskModalInstanceCtrl
  '$filter',
  '$translate',
  '$timeout',
  'Dialog',
  'LoggedUser',
  'ValueLang',
  'XlsxService',
  'CostsSummarySrv',
  'UserLang',
  'GetUTypeToken',
  'GetToken',

  'SetTaskPrices',

  function(
    $controller,
    $scope,
    $state,
    $stateParams,
    ProjectStore,

    // TaskModalInstanceCtrl
    $filter,
    $translate,
    $timeout,
    Dialog,
    LoggedUser,
    ValueLang,
    XlsxService,
    CostsSummarySrv,
    UserLang,
    GetUTypeToken,
    GetToken,

    SetTaskPrices
  ) {
    $scope.userLang = UserLang.get();
    $scope.goBack = () => {
      const unregister = $scope.$on('save:done', () => {
        unregister();
        $state.go('^.tasks');
        // Save again to force project totals calculation. This is a VERY UGLY workaround.
        $timeout(() => $scope.save(), 500);
      });
      $scope.save();
    };

    if (!$stateParams.batchIndex || !$stateParams.taskIndex)
      return $scope.goBack();

    $scope.pricePerTask = task => CostsSummarySrv.pricePerTask(task);

    const modalInstance = {
      close(task) {
        SetTaskPrices(task);
        if (isNew($stateParams.taskIndex))
          $scope.project.batches[$stateParams.batchIndex].tasks.push(task);
        else
          $scope.project.batches[$stateParams.batchIndex].tasks[
            $stateParams.taskIndex
          ] = task;
        $scope.goBack();
      }
    };

    $controller('TaskModalInstanceCtrl', {
      $scope,
      $modalInstance: modalInstance,
      $filter,
      $translate,
      $timeout,
      task: $scope.task,
      company: $scope.company,
      delivered: $scope.delivered,
      Dialog,
      LoggedUser,
      ValueLang,
      XlsxService,
      CostsSummarySrv,
      hideDownload: () => false,
      UserLang,
      GetUTypeToken,
      GetToken
    });

    $scope.exchangeCurrency = amount => {
      if (!amount) return 0;

      const { currency, exchangeRate } = $scope.workflow;
      if (currency === 'EUR' || !exchangeRate) return amount;

      return +(amount * exchangeRate || amount).toFixed(3) || 0;
    };

    const getTask = (project = {}, batchIndex, taskIndex) => {
      try {
        const task = project.batches[batchIndex].tasks[taskIndex];
        task.delivery_time = task.delivery_time
          ? new Date(task.delivery_time)
          : task.delivery_time;
        task.exchangedUnitPrice = $scope.exchangeCurrency(task.unitPrice);
        return task;
      } catch (error) {
        return {};
      }
    };

    ProjectStore.clear().listenTo(function(project) {
      $scope.loadChosen = false;
      if (Object.keys(project).length === 0) return;
      $scope.project = project;
      $scope.task = getTask(
        project,
        $stateParams.batchIndex,
        $stateParams.taskIndex
      );
      $scope.trados = $scope.task.trados;
      $scope.delivered = isBatchDelivered(
        project,
        $stateParams.batchIndex,
        $stateParams.taskIndex
      );
      $scope.isNew = isNew($stateParams.taskIndex);
      const { services = [] } = project;
      const [{ source = {}, target: [target = {}] = [] } = {}] = services;
      CostsSummarySrv.setSource(source);
      CostsSummarySrv.setTarget(target);
      $scope.loadChosen = true;
    });

    function isNew(taskIndex) {
      return taskIndex === 'new';
    }

    function isBatchDelivered(project, batchIndex, taskIndex) {
      switch (taskIndex) {
        case 'new':
          return false;
        default:
          return project.batches[batchIndex].delivered;
      }
    }

    $scope.setExchangedUnitPrice = exchangedUnitPrice => {
      const { currency, exchangeRate } = $scope.workflow;
      if (currency === 'EUR' || !exchangeRate) return;

      $scope.task.unitPrice =
        +((exchangedUnitPrice || 0) / exchangeRate).toFixed(3) || 0;
      $scope.setTaskPrices();
    };
  }
]);
