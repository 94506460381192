angular
  .module('company')

  .directive('companySettings', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        company: '=ngModel',
        parentSave: '&save',
        undo: '&'
      },
      templateUrl: 'custom/company/views/partials/companySettings.html',
      controller: [
        '$scope',
        '$filter',
        '$timeout',
        'Dialog',
        function($scope, $filter, $timeout, Dialog) {
          $scope.checkTrados = function(hasTrados) {
            if (
              hasTrados &&
              $scope.company.lists &&
              $scope.company.lists.some(function(list) {
                return (
                  list.task.translate &&
                  list.units.some(function(unit) {
                    return !unit.ranges;
                  })
                );
              })
            ) {
              var dialog = new Dialog();
              dialog.error($filter('translate')('company.CHECK_TRADOS_ERR'));
              $timeout(function() {
                $scope.company.settings.hasTrados = false;
              }, 500);
            }
          };

          $scope.save = function(formValid) {
            if (formValid) {
              $scope.parentSave();
            }
            return formValid;
          };
        }
      ]
    };
  });
