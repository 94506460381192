angular
  .module('library')

  .factory('LibraryValueModel', function() {
    function LibraryValueModel() {
      return {
        code: '',
        value: ''
      };
    }

    return LibraryValueModel;
  });
