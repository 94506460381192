angular
  .module('workflow')

  .factory('ProjectModel', [
    'Functional',
    'Utilities',
    function(Functional, Utilities) {
      'use strict';

      function addToCode(code, partialCode) {
        return code && code.length ? code + '_' + partialCode : partialCode;
      }

      function addCompanyCode(company) {
        return code => {
          const { code: companyCode, settings = {} } = company || {};
          const { orderLabel } = settings;

          if (orderLabel) return orderLabel;

          if (companyCode) return addToCode(code, companyCode);

          return 'NO_LABEL';
        };
      }

      function addOrderNumber(prefix, num) {
        return code => {
          return addToCode(code, prefix + Utilities.pad(num || 1, 6));
        };
      }

      function addWorkflowNumber(num) {
        return code => {
          return addToCode(code, 'C' + Utilities.pad(num || 1, 6));
        };
      }

      function addProjectNumber(num) {
        return code => {
          return addToCode(code, 'P' + Utilities.pad(num || 1, 2));
        };
      }

      function getCode(num, data) {
        let orderPrefix = data.type === 'order' ? 'O' : 'P';
        let orderNum = data.type === 'order' ? data.order_num : data.quote_num;

        let composed = Functional.pipe(
          addCompanyCode(data.company),
          addOrderNumber(orderPrefix, orderNum),
          addWorkflowNumber(data.workflow_num),
          addProjectNumber(data.project_num)
        );

        return composed('');
      }

      return function(projectCounter, workflow) {
        let wFlow = workflow || {};
        let num = parseInt(projectCounter || 1);

        let data = {
          company: wFlow.company,
          quote_num: wFlow.quote_num || 1,
          order_num: wFlow.order_num || 1,
          workflow_num: wFlow.workflow_num || 1,
          project_num: num,
          type: wFlow.type || 'quote'
        };

        return {
          code: getCode(num, data),
          customIndirectCostsRatio: parseFloat(
            '@@default_indirect_costs_ratio'
          ),
          num: num,
          title: '',
          delivered: false,
          services: [],
          batches: [],
          counters: {},
          upload_file: false,
          templateType: wFlow.templateType
        };
      };
    }
  ]);
