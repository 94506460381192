angular.module('statistics', ['pb.core']).run([
  '$filter',
  'Module',
  function($filter, Module) {
    new Module().push({
      menu: {
        name: 'statistics',
        title: $filter('translate')('statistics.TITLE'),
        icon: 'zmdi zmdi-ruler',
        link: 'root.app.simple.statistics'
      }
    });
  }
]);
