angular
  .module('company')

  .directive('searchCompanyVatName', [
    '$filter',
    function($filter) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          company: '=',
          hideBilling: '<'
        },
        templateUrl: 'custom/company/views/partials/searchCompanyVatName.html',
        controller: [
          '$scope',
          '$controller',
          'CompanyList',
          function($scope, $controller, CompanyList) {
            $scope.enableErrorNotifications = false;

            $controller('ListCtrl', {
              $scope: $scope,
              crud: new CompanyList(),
              packageName: 'company',
              dataName: 'companies',
              filters: {}
            });

            $scope.$watch('search', function(value, old) {
              if (value !== old) {
                $scope.companies = [];
                $scope.company = undefined;

                if (!value || value.length < 3) {
                  return;
                }

                $scope.loadList({
                  search: value,
                  keywords: ['company_name', 'vat']
                });
              }
            });

            $scope.select = function(company) {
              $scope.company = undefined;

              angular.forEach($scope.companies, function(c) {
                if (c._id === company._id && company.selected === true) {
                  $scope.company = company;
                } else c.selected = false;
              });
            };

            $scope.allBilling = function() {
              return (
                $scope.companies &&
                $scope.companies.every(function(company) {
                  return company.billingCompany;
                })
              );
            };
          }
        ]
      };
    }
  ]);
