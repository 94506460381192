angular
  .module('company')

  .factory('CompanyExpiredCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class CompanyExpiredCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/companies-expired/:id', { id: '@id' });
        }
      }

      return CompanyExpiredCrud;
    }
  ]);
