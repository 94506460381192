angular
  .module('customer')

  .config([
    '$stateProvider',

    function($stateProvider) {
      var path = 'custom/customer/views/';

      $stateProvider
        .state('root.app.simple.customers', {
          url: '/customers',
          views: {
            header: {
              templateUrl: path + 'list-header.html',
              controller: 'CustomerHeaderCtrl'
            },
            content: {
              templateUrl: path + 'list.html',
              controller: 'CustomerListCtrl'
            }
          },
          permissions: {
            resources: { customers: ['read'] }
          }
        })

        .state('root.app.simple.customer', {
          url: '/customer/:id',
          views: {
            header: {
              templateUrl: path + 'edit-header.html',
              controller: 'CustomerHeaderCtrl'
            },
            content: {
              templateUrl: path + 'edit.html',
              controller: 'CustomerEditCtrl'
            }
          },
          permissions: {
            resources: { customers: ['read', 'me'] }
          }
        })

        .state('root.app.simple.customer.about', {
          url: '/about',
          templateUrl: path + 'tab-about.html',
          controller: 'CustomerTagCtrl',
          permissions: {
            resources: { customers: ['read', 'me'] }
          }
        })

        .state('root.app.simple.customer.headquarter', {
          url: '/headquarter',
          templateUrl: path + 'tab-headquarter.html',
          controller: 'CustomerTagCtrl',
          permissions: {
            resources: { customers: ['read', 'me'] }
          }
        })

        .state('root.app.simple.customer.agreements', {
          url: '/agreements',
          templateUrl: path + 'tab-agreements.html',
          controller: 'CustomerTagCtrl',
          permissions: {
            resources: { customers: ['read', 'me'] }
          }
        })

        .state('root.app.simple.customer.billingCompanies', {
          url: '/billing-companies',
          templateUrl: path + 'tab-billing-companies.html',
          controller: 'CustomerTagCtrl',
          permissions: {
            resources: { customers: ['read', 'me'] }
          }
        })

        .state('root.app.simple.customer.contributorsList', {
          url: '/contributors-blacklist',
          params: {
            toBlacklist: null
          },
          templateUrl: path + 'tab-contributors.html',
          controller: 'CustomerTagCtrl',
          permissions: {
            resources: { customers: ['read', 'me'] }
          }
        })

        .state('root.app.simple.create-customer', {
          url: '/create-customer',
          views: {
            header: {
              templateUrl: path + 'create-header.html'
            },
            content: {
              templateUrl: path + 'create.html',
              controller: 'CustomerCreateCtrl'
            }
          },
          permissions: {
            resources: { customers: ['read'] }
          }
        });
    }
  ]);
