angular
  .module('pb.core.utilities')

  .factory('Functional', function() {
    'use strict';

    const pipe = (headFn, ...restFns) => (...args) =>
      restFns.reduce((value, fn) => fn(value), headFn(...args));

    const compose = (...fns) => pipe(...fns.reverse());

    return {
      pipe: pipe,
      compose: compose
    };
  });
