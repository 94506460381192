angular
  .module('contributor')

  .directive('selectSoftware', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        software: '='
      },
      template:
        '<select ng-model="software" ng-options="s.value for s in softwares track by s._id">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'SoftwareCrud',
        function($scope, $controller, SoftwareCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new SoftwareCrud('-list'),
            packageName: 'softwares',
            dataName: 'softwares',
            filters: {}
          });

          $scope.loadList();
        }
      ]
    };
  });
