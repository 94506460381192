angular
  .module('contributor')

  .factory('ContributorSendRequests', [
    'Crud',

    function(Crud) {
      'use strict';

      class ContributorSendRequests extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/profiles-send-request/:id', { id: '@id' });
        }
      }

      return ContributorSendRequests;
    }
  ]);
