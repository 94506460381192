angular
  .module('contributor')

  .directive('toolsNotes', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        notes: '=ngModel',
        specs: '<',
        type: '<'
      },
      templateUrl: 'custom/contributor/views/partials/toolsNotes.html',
      controller: [
        '$scope',
        function($scope) {
          $scope.isQualified = function() {
            return (
              $scope.specs &&
              $scope.specs.some(function(spec) {
                return spec && spec.rating && spec.rating.qualified;
              })
            );
          };

          $scope.isNetwork = function() {
            return $scope.type == 'network';
          };
        }
      ]
    };
  });
