angular
  .module('order')

  .factory('DoneNotBilledTasksExport', [
    '$http',
    '$timeout',
    '$filter',
    'Dialog',
    function($http, $timeout, $filter, Dialog) {
      const download = () => {
        const exportUrl = '@@apiUrl' + '/done-not-billed-tasks';
        const exportParams = { method: 'GET', url: exportUrl };

        $http(exportParams)
          .then(({ data } = {}) => {
            const { bucket, key } = data;
            const exportData = { bucket, key, expires: 3600 };
            const getExportUrl = '@@apiUrl' + '/get-export-url';
            const getExportParams = {
              method: 'POST',
              url: getExportUrl,
              data: exportData
            };
            return $http(getExportParams);
          })
          .then(({ data: fileUrl } = {}) => {
            if (!fileUrl) return Promise.reject('Invalid file url');
            $timeout(
              () =>
                new Dialog().info(
                  $filter('translate')('file.DOWNLOAD_POPUP_MSG')
                ),
              200
            );
            window.open(fileUrl, '_blank');
          })
          .catch(error => {
            Sentry.captureException(error);
            $timeout(
              () =>
                new Dialog().error($filter('translate')('error.RECEIVE_ERR')),
              200
            );
          });
      };

      return { download };
    }
  ]);
