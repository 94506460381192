angular
  .module('workflow')

  .controller('RenameBatchCtrl', [
    '$scope',
    '$modalInstance',
    'batchName',

    function($scope, $modalInstance, batchName) {
      $scope.newBatchName = batchName;

      $scope.cancel = () => {
        $modalInstance.dismiss('cancel');
      };

      $scope.confirm = () => {
        $modalInstance.close($scope.newBatchName);
      };
    }
  ]);
