angular
  .module('library')

  .controller('LibraryTaskEditCtrl', [
    '$scope',
    '$controller',
    '$stateParams',
    'TaskCrud',
    'TaskModel',
    '$filter',
    'Confirm',
    '$state',
    'ValueLang',
    '$timeout',
    'Dialog',

    function(
      $scope,
      $controller,
      $stateParams,
      TaskCrud,
      TaskModel,
      $filter,
      Confirm,
      $state,
      ValueLang,
      $timeout,
      Dialog
    ) {
      $scope.id = $stateParams.id;

      if (!$scope.id) {
        $scope.errorMessage = 'Task not present';
        return;
      }

      var crud = new TaskCrud();
      var confirm = new Confirm();
      var dialog = new Dialog();

      $controller('EditBaseCtrl', {
        $scope: $scope,
        crud: crud,
        object: TaskModel,
        packageName: 'task',
        dataName: 'task',
        formName: 'taskForm'
      });

      $controller('DeleteBaseCtrl', {
        $scope: $scope,
        crud: crud,
        packageName: 'task',
        dataName: 'task'
      });

      $scope.processLoading().then(function(response) {
        $scope.errorMessage = response._id ? undefined : 'Error task';

        $scope.name_it = ValueLang($scope.task, 'name', 'it');
        $scope.name_en = ValueLang($scope.task, 'name', 'en');
        $scope.desc_it = ValueLang($scope.task, 'description', 'it');
        $scope.desc_en = ValueLang($scope.task, 'description', 'en');
        $scope.abbr_it = ValueLang($scope.task, 'abbreviation', 'it');
        $scope.abbr_en = ValueLang($scope.task, 'abbreviation', 'en');

        //console.log('$scope.task', $scope.task);
      });

      $scope.save = function() {
        $scope.processSaving().then(function() {
          $state.go('root.app.simple.libraries', { table: 'tasks' });
        });
      };

      $scope.delete = function() {
        confirm
          .warning($filter('translate')('dialog.DELETE_ASK'))
          .then(function() {
            $scope.processRemoving().then(function() {
              $state.go('root.app.simple.libraries', { table: 'tasks' });
            });
          });
      };

      $scope.getLatest = function(units) {
        if (units && units[0] && units[0].list) {
          var date = units[0].list.dateStart;
          units.forEach(function(unit) {
            date =
              unit.list && unit.list.dateStart > date
                ? unit.list.dateStart
                : date;
          });
          return date;
        }
      };

      $scope.today = new Date();
    }
  ]);
