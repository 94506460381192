angular
  .module('pb.core.notifications')

  .factory('Dialog', [
    '$rootScope',
    'Notification',

    function($rootScope, Notification) {
      'use strict';

      /**
       * Dialog class
       * @param params
       * @constructor
       */
      class Dialog extends Notification {
        /**
         * Broadcast event on notifications change
         * @private
         */
        _broadcast(notification) {
          $rootScope.$broadcast('onDialogNotification', notification);
        }
      }

      return Dialog;
    }
  ]);
