angular
  .module('contributor')

  .directive('selectContributorCertification', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        contributorCertification: '='
      },
      template:
        '<select id="contributor-certification-filter-select" ng-model="contributorCertification" ng-options="c.code as (c.name[userLang] || c.value) for c in certifications track by c._id">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'ContributorCertificationsCrud',
        'UserLang',
        function($scope, $controller, ContributorCertificationsCrud, UserLang) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new ContributorCertificationsCrud('-list'),
            packageName: 'contributorCertifications',
            dataName: 'certifications',
            filters: {}
          });

          $scope.userLang = UserLang.get();
          $scope.loadList();
        }
      ]
    };
  });
