angular
  .module('pb.core.crud')

  .factory('CrudCacheFactory', [
    'CacheFactory',
    function(CacheFactory) {
      'use strict';

      class CrudCacheFactory {
        constructor(cacheKey, cacheParams = {}) {
          this.cacheKey = cacheKey;
          this.cacheParams = cacheParams;
        }

        create() {
          var cache;

          if (this.cacheKey) {
            cache = CacheFactory.get(this.cacheKey);

            if (cache) {
              return cache;
            }

            return CacheFactory.createCache(
              this.cacheKey,
              angular.extend(
                {
                  disabled: '@@cacheEnabled' == 'false',
                  storageMode: '@@cacheStorageMode',
                  storagePrefix: '@@cacheStoragePrefix',
                  deleteOnExpire: 'aggressive',
                  capacity: parseInt('@@cacheCapacity') || 1000, //default 1000
                  maxAge: parseInt('@@cacheMaxAge') || 15 * 60 * 1000, // default 15 min
                  recycleFreq: parseInt('@@cacheRecycleFreq') || 60 * 60 * 1000 //default 1 hour
                },
                this.cacheParams
              )
            );
          }

          return false;
        }

        destroy() {
          if (this.cacheKey && this.cacheKey.length > 0) {
            CacheFactory.destroy(this.cacheKey);
          }
        }

        static clearAll() {
          CacheFactory.clearAll();
          CacheFactory.destroyAll();
        }
      }

      return CrudCacheFactory;
    }
  ]);
