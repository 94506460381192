angular
  .module('task')

  .controller('RejectTaskCtrl', [
    '$scope',
    '$modalInstance',
    'task',

    function($scope, $modalInstance, task) {
      $scope.task = angular.copy(task);

      $scope.cancel = () => {
        $modalInstance.dismiss('cancel');
      };

      $scope.reject = () => {
        $modalInstance.close($scope.task);
      };

      $scope.allowRejection = () => {
        const { task = {} } = $scope;
        const { rejection = {} } = task;
        const { date, part, price, unavailable, other } = rejection;
        return date || part || price || unavailable || other;
      };
    }
  ]);
