angular
  .module('pb.base')

  .directive('appMenu', [
    '$state',
    'Auth',
    'MainMenu',
    'UserMenu',
    'LoggedUser',

    function($state, Auth, MainMenu, UserMenu, LoggedUser) {
      return {
        restrict: 'E',
        replace: true,
        templateUrl: 'modules/base/views/partials/menu.html',
        scope: true,
        link: function(scope, element, attrs) {
          var user = LoggedUser.get();

          scope.user = user.get();
          scope.$state = $state;
          let menus = MainMenu.getByPermissions(user);
          const docMenuItem = menus.find(
            menu => menu.name === 'doorDocumentale'
          );

          if (docMenuItem && LoggedUser.isContributor()) {
            menus = LoggedUser.getData().docAccess
              ? menus
              : menus.filter(item => item !== docMenuItem);
          }

          scope.menus = menus;
          scope.userMenus = UserMenu.getByPermissions(user);

          scope.logout = function() {
            Auth.logout().then(function() {
              scope.$emit('goToLogin');
            });
          };
        }
      };
    }
  ]);
