angular
  .module('contributor')

  .directive('contributorAgreement', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        contributor: '=ngModel'
      },
      templateUrl:
        'custom/contributor/views/partials/contributorAgreement.html',
      controller: [
        '$scope',
        'LoggedUser',
        'ContributorStore',
        function($scope, LoggedUser, ContributorStore) {
          $scope.isPm = LoggedUser.isPM();

          const callback = (event, message, options) => {
            const { type, filesNumber } = options;
            if (type !== 'contributors-agreement') return;

            $scope.contributor.hasAgreement = filesNumber > 0;
            ContributorStore.emit('save');
          };

          $scope.$on('onFileDeleted', callback);
          $scope.$on('onFilesUploaded', callback);
        }
      ]
    };
  });
