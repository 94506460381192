angular
  .module('contributor')

  .directive('contributorLsp', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        contributor: '=ngModel'
      },
      templateUrl: 'custom/contributor/views/partials/contributorLsp.html',
      controller: [
        '$scope',
        '$filter',
        'ContributorStore',
        function($scope, $filter, ContributorStore) {
          ContributorStore.listenTo(function(data) {
            if (data) {
              // $scope.contributor = data;
              Object.assign($scope.contributor, data);
              $scope.contributor.due_diligence =
                $scope.contributor.due_diligence || {};

              $scope.contributor.due_diligence.story =
                $scope.contributor.due_diligence.story || [];
              $scope.contributor.due_diligence.production =
                $scope.contributor.due_diligence.production || [];
              $scope.contributor.due_diligence.quality =
                $scope.contributor.due_diligence.quality || [];
              $scope.contributor.due_diligence.vendor =
                $scope.contributor.due_diligence.vendor || [];
            }
          });
        }
      ]
    };
  });
