angular
  .module('pb.core.crud')

  .controller('PaginatedListCtrl', [
    'Dialog',
    'ErrorsManager',
    '$timeout',
    'QueryFilters',
    '$scope',
    'crud',
    'packageName',
    'dataName',
    'filters',

    function(
      Dialog,
      ErrorsManager,
      $timeout,
      QueryFilters,
      $scope,
      crud,
      packageName,
      dataName,
      filters
    ) {
      var dialog = new Dialog(),
        page = 0,
        toClear = false,
        enableSearch = false,
        watchTimeout;

      packageName = packageName || 'data';
      dataName = dataName || 'items';
      $scope[dataName] = [];
      $scope.moreResults = true;
      $scope.noItems = false;

      function clear() {
        page = 0;
        $scope.moreResults = true;
      }

      function clearResults() {
        toClear = false;
        $scope[dataName].length = 0;
        $scope.noItems = true;
      }

      function complete() {
        $timeout(function() {
          $scope.$broadcast('scroll.infiniteScrollComplete');
        }, 1000);
      }

      $scope.loadMore = function(noCache) {
        var query;

        if (!$scope.moreResults) {
          return;
        }

        page += 1;
        query = QueryFilters(filters, $scope.search, page);

        return crud.query(query).then(
          function(response) {
            if (toClear) {
              clearResults();
            }

            response = response || {};
            $scope[dataName] = $scope[dataName].concat(response.results || []);
            $scope.moreResults = angular.isDefined(response.show_next)
              ? response.show_next
              : true;
            $scope.noItems = $scope[dataName].length === 0;
            complete();
          },
          function(err) {
            clear();
            clearResults();
            complete();
            dialog.error(ErrorsManager.getErrorsAsString(err));
          }
        );
      };

      $scope.$on('reloadList.' + packageName, function() {
        $scope.reload();
      });

      $scope.reload = function(noCache) {
        toClear = true;
        clear();
        $scope.loadMore(noCache);
      };

      $scope.$watch(
        'search',
        function(newValue, oldValue) {
          if (typeof newValue !== 'undefined' && newValue != oldValue) {
            if (!enableSearch) {
              enableSearch = true;
              return;
            }

            $timeout.cancel(watchTimeout);

            watchTimeout = $timeout(function() {
              $scope.reload();
            }, 800);
          }
        },
        true
      );

      $scope.reload();
    }
  ]);
