angular
  .module('pb.file')

  .service('PbDeleter', [
    '$http',

    function($http) {
      let bucket = '@@aws_bucket';

      this.delete = function(file) {
        let url =
          '@@fileUrl' +
          '/uploads/' +
          bucket +
          '/' +
          (typeof file === 'object' ? file.key : file);
        return $http.delete(url);
      };
    }
  ]);
