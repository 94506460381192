angular
  .module('pb.base')

  .directive('listItem', function() {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      template: '<div class="lv-item media" ng-transclude></div>',
      scope: false
    };
  });
