angular
  .module('pb.base')

  .directive(
    'appHeader',

    function() {
      return {
        restrict: 'E',
        replace: true,
        templateUrl: 'modules/base/views/partials/header.html',
        scope: true,
        link: function(scope) {
          scope.appName = '@@appName';

          scope.fullScreen = function() {
            //Launch
            function launchIntoFullscreen(element) {
              if (element.requestFullscreen) {
                element.requestFullscreen();
              } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
              } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
              } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
              }
            }

            //Exit
            function exitFullscreen() {
              if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
              } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
              }
            }

            if (exitFullscreen()) {
              launchIntoFullscreen(document.documentElement);
            } else {
              launchIntoFullscreen(document.documentElement);
            }
          };
        },
        controller: [
          '$rootScope',
          '$scope',
          '$timeout',
          '$filter',
          'CrudCacheFactory',
          'Toast',
          function(
            $rootScope,
            $scope,
            $timeout,
            $filter,
            CrudCacheFactory,
            Toast
          ) {
            var toast = new Toast();

            $scope.system = $rootScope.system;

            $scope.clearCache = function() {
              CrudCacheFactory.clearAll();

              $timeout(function() {
                toast.info($filter('translate')('header.CACHE_DELETED'));
              }, 500);
            };
          }
        ]
      };
    }
  );
