angular
  .module('workflow')

  .directive('selectConditionsOfPaymentOffer', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        paymentsList: '<',
        selectedPayment: '='
      },
      templateUrl:
        'custom/workflow/views/partials/selectConditionsOfPaymentOfferTemplate.html',
      controller: [
        '$scope',
        function($scope) {
          const [defaultPayment] = $scope.paymentsList || [];
          const { value: defaultPaymentValue } = defaultPayment || {};
          $scope.selectedPayment =
            $scope.selectedPayment || defaultPaymentValue;
        }
      ]
    };
  });
