angular
  .module('pb.core.user')

  .directive('pbCanUserType', [
    'LoggedUser',

    function(LoggedUser) {
      return {
        restrict: 'A',
        priority: 1002,
        link: function(scope, element, attrs) {
          var negation,
            resourceType = attrs.pbCanUserType,
            type = LoggedUser.getData().type;

          if (!resourceType) {
            return;
          }
          if (!type) {
            element.remove();
            return;
          }

          negation = resourceType.substring(0, 1) === '!';

          if (negation) {
            resourceType = resourceType.slice(1);
          }

          if (
            (!negation && resourceType === type) ||
            (negation && resourceType !== type)
          ) {
            //remove the attribute to avoid infinite loop
            element.removeAttr('pb-can-user-type');
          } else {
            element.remove();
          }
        }
      };
    }
  ]);
