/* global bootstrap-sweetalert */

angular
  .module('pb.base')

  .factory('DialogNotification', [
    '$rootScope',

    function($rootScope) {
      function showDialog(notification) {
        if (notification.type === 'danger') {
          notification.type = 'error';
        }

        swal({
          title: notification.title || '',
          text: notification.message || '',
          type: notification.type
        });
      }

      $rootScope.$on('onDialogNotification', function(event, notification) {
        showDialog(notification);
      });

      return this;
    }
  ])

  .run(['DialogNotification', function(DialogNotification) {}]);
