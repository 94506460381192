angular
  .module('pb.user')

  .factory('UserCrud', [
    'CrudUndoable',

    function(CrudUndoable) {
      'use strict';

      class UserCrud extends CrudUndoable {
        constructor($scope) {
          super($scope, null, '@@apiUrl' + '/profiles/:id', { id: '@id' });
        }
      }

      return UserCrud;
    }
  ]);
