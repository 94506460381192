'use strict';

angular
  .module('doorDocumentale')

  .controller('DocumentalController', [
    '$scope',
    'GetUTypeToken',
    'GetToken',
    'LoggedUser',
    'Clipboard',
    function($scope, GetUTypeToken, GetToken, LoggedUser, Clipboard) {
      $scope.apiUrl = '@@apiUrl';
      $scope.workflowApiUrl = '@@workflowApiUrl';
      $scope.fileUrl = '@@fileUrl';
      $scope.xutype = GetUTypeToken();
      $scope.token = GetToken();
      $scope.loggedUser = LoggedUser.getData();
      $scope.bucketName = '@@aws_bucket';
      $scope.clipboard = Clipboard;
      $scope.hasDocAccess = LoggedUser.hasDocAccess();
      $scope.isPM = LoggedUser.isPM();
    }
  ]);
