angular
  .module('pb.base')

  // DEPRECATO: sostituito con link diretto al sito di Landoor.
  // Il contenuto di questo è obsoleto e non va usato.
  .directive('sla', [
    function() {
      return {
        restrict: 'E',
        replace: true,
        template: `
          <div>
            <sla-it ng-show="currentLang == 'it'"></sla-it>
            <sla-en ng-show="currentLang != 'it'"></sla-en>
          </div>
        `,
        scope: true,
        controller: [
          '$scope',
          'Utilities',
          function($scope, Utilities) {
            $scope.currentLang = Utilities.getLang();
          }
        ]
      };
    }
  ]);
