angular
  .module('pb.base')

  .factory('AuthLoginMfa', [
    '$resource',
    'Crud',

    function($resource, Crud) {
      const AuthLoginMfa = function() {
        this.resource = $resource(
          '@@authUrl' + '/login-v2-mfa?appId=' + '@@appId'
        );
      };

      AuthLoginMfa.prototype = new Crud();

      return AuthLoginMfa;
    }
  ]);
