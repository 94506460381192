angular
  .module('components')

  .directive('tableInputFiles', function() {
    return {
      restrict: 'E',
      scope: {
        path: '<',
        editable: '<',
        filesNumber: '=',
        hideSector: '<'
      },
      templateUrl: 'custom/components/views/partials/tableInputFiles.html',
      controller: [
        '$scope',
        '$element',
        '$controller',
        'UserLang',
        function($scope, $element, $controller, UserLang) {
          $scope.enableListLoading = true;
          $scope.userLang = UserLang.get();

          $controller('filesController', {
            $scope: $scope,
            $element: $element
          });

          $scope.$watch('files', function(newValue) {
            if (!($scope.filesNumber === undefined) && newValue) {
              $scope.filesNumber = newValue.length;
            }
          });
        }
      ]
    };
  });
