angular
  .module('pb.base')

  .factory('AuthForgotPassword', [
    '$resource',
    'Crud',

    function($resource, Crud) {
      var AuthForgotPassword = function() {
        this.resource = $resource('@@apiUrl' + '/api/forgot');
      };

      AuthForgotPassword.prototype = new Crud();

      return AuthForgotPassword;
    }
  ]);
