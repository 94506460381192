angular
  .module('contributor')

  .directive('selectTaxCodeObject', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        taxcode: '='
      },
      template:
        '<select ng-model="taxcode" ng-options="c as c.value for c in codes track by c.code" />',

      controller: [
        '$scope',
        '$filter',
        function($scope, $filter) {
          $scope.codes = [
            {
              code: 'tribute_1040',
              value: $filter('translate')('tribute.tribute_1040')
            },
            {
              code: 'NO_RDA',
              value: $filter('translate')('tribute.NO_RDA')
            }
          ];
        }
      ]
    };
  });
