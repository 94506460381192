'use strict';

angular
  .module('workflow')

  .factory('CleanBatches', function() {
    const cleanContributors = (contributors = []) => {
      contributors.forEach(contributor => {
        // Contributor quantity should NOT be deleted
        delete contributor.task_status;
        delete contributor.done;
        delete contributor.rejected;
        delete contributor.rejection;
        delete contributor.price;
        delete contributor.unitPrice;
      });
    };

    const cleanTasks = (tasks = [], options) => {
      const {
        shouldDeleteContributors,
        shouldKeepTaskDeliveryDateTime
      } = options;

      tasks.forEach(task => {
        delete task.delivered;
        delete task.done;
        delete task.notified;
        delete task.started;
        delete task.uuid;
        delete task.contributorCost;
        delete task.invoiced;
        delete task.invoiced_at;
        delete task.invoiced_by;
        delete task.invoiceData;
        delete task.pdfReceipt;

        if (!shouldKeepTaskDeliveryDateTime) {
          delete task.delivery_date;
          delete task.delivery_time;
        }

        if (shouldDeleteContributors) {
          delete task.contributorCost;
          task.contributors = [];
          return;
        }

        const { contributors = [] } = task;
        cleanContributors(contributors);
      });
    };

    return (batches = [], options) => {
      batches.forEach(batch => {
        const { tasks = [] } = batch;
        delete batch.delivered;
        delete batch.delivery_date;
        delete batch.invoiced;
        delete batch.uuid;
        cleanTasks(tasks, options);
      });
    };
  });
