angular
  .module('pb.base')

  .factory('AuthSignup', [
    '$resource',
    'Crud',

    function($resource, Crud) {
      var AuthSignup = function() {
        this.resource = $resource('@@apiUrl' + '/api/signup');
      };

      AuthSignup.prototype = new Crud();

      return AuthSignup;
    }
  ]);
