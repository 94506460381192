angular
  .module('workflow')

  .factory('WorkflowStateChange', [
    'WorkflowStateChangeCrud',
    'Dialog',
    '$filter',
    '$timeout',
    'ErrorsManager',

    function(
      WorkflowStateChangeCrud,
      Dialog,
      $filter,
      $timeout,
      ErrorsManager
    ) {
      'use strict';

      var dialog = new Dialog();

      class WorkflowStateChange {
        sendRequest(workflow, status) {
          var crud = new WorkflowStateChangeCrud();

          return crud.update({ _id: workflow._id, status: status }).then(
            function(response) {
              return response;
            },
            function(err) {
              $timeout(function() {
                dialog.error(ErrorsManager.getErrorsAsString(err));
              }, 100);
              return Promise.reject(err);
            }
          );
        }
      }

      return new WorkflowStateChange();
    }
  ]);
