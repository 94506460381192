angular
  .module('contributor')

  .directive('selectTaskUnit', [
    '$filter',
    function($filter) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          uom: '=',
          task: '='
        },
        template:
          '<select ng-model="uom" ng-options="u.value for u in uoms track by u._id"><option value="">-</option></select>',
        controller: [
          '$scope',
          '$controller',
          'TaskUnitCrud',
          function($scope, $controller, TaskUnitCrud) {
            $controller('ListCtrl', {
              $scope: $scope,
              crud: new TaskUnitCrud(),
              packageName: 'units',
              dataName: 'uoms',
              filters: {}
            });

            if ($scope.task) {
              $scope.loadList({ id: $scope.task });
            }
          }
        ]
      };
    }
  ]);
