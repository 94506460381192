'use strict';

angular
  .module('workflow')

  .factory('UpdateTaskContributorPrices', [
    'CostsSummarySrv',
    function(CostsSummarySrv) {
      return (workflow, newContributors = []) => {
        if (!newContributors.length) return workflow;
        const { projects = [] } = workflow;
        projects.forEach(project => {
          const { batches = [], services = [] } = project;
          const [service = {}] = services;
          CostsSummarySrv.setSource(service.source);
          CostsSummarySrv.setTarget(service.target[0]);

          batches.forEach(batch => {
            const { tasks = [] } = batch;

            tasks.forEach(task => {
              const { contributors = [] } = task;

              contributors.forEach(contributor => {
                const newContributor = newContributors.find(
                  ({ _id }) => _id === contributor._id
                );
                if (!newContributor) return;
                const { servicesList = [] } = newContributor || {};
                const { unit = {} } = contributor || {};
                const { _id, trados } = task || {};

                const contributorService = CostsSummarySrv.findMatchingService(
                  servicesList,
                  _id
                );
                if (!contributorService) return;

                const contributorCombo = CostsSummarySrv.findMatchingComboByUnit(
                  contributorService.combos,
                  unit
                );
                if (!contributorCombo) return;

                contributor.servicesList = servicesList;
                contributor.unitPrice = contributorCombo.price || 0;

                const totalQuantities =
                  CostsSummarySrv.totalQuantities(trados) || 1;

                contributor.price =
                  contributor.unitPrice *
                    contributor.quantity *
                    totalQuantities || 0;
              });
            });
          });
        });
        return workflow;
      };
    }
  ]);
