angular
  .module('workflow')

  .controller('ProjectCtrl', [
    '$scope',
    'WorkflowStore',
    'ProjectStore',
    '$stateParams',
    '$state',
    'FormValidator',
    '$filter',
    'Dialog',
    'Confirm',
    '$timeout',
    '$modal',
    'ServiceUtils',
    'LoggedUser',
    'ProjectFilesUtils',
    'UserLang',
    'GetUTypeToken',
    'GetToken',

    function(
      $scope,
      WorkflowStore,
      ProjectStore,
      $stateParams,
      $state,
      FormValidator,
      $filter,
      Dialog,
      Confirm,
      $timeout,
      $modal,
      ServiceUtils,
      LoggedUser,
      ProjectFilesUtils,
      UserLang,
      GetUTypeToken,
      GetToken
    ) {
      $scope.apiUrl = '@@apiUrl';
      $scope.workflowApiUrl = '@@workflowApiUrl';
      $scope.fileUrl = '@@fileUrl';
      $scope.xutype = GetUTypeToken();
      $scope.token = GetToken();
      $scope.loggedUser = LoggedUser.getData();
      $scope.bucketName = '@@aws_bucket';

      const dialog = new Dialog();
      const confirm = new Confirm();

      $scope.isPM = LoggedUser.isPM();
      $scope.userLang = UserLang.get();

      if (!$stateParams.projectCode) {
        $state.go('root.404');
        return;
      }

      $scope.editable = false;

      WorkflowStore.addProjectStore($stateParams.projectCode);

      ProjectStore.clear().listenTo(function(project) {
        if (!project) {
          $state.go('root.404');
        }

        $scope.project = project;
        $scope.uuid = project.uuid;
        $scope.editable = WorkflowStore.isEditable();
        const { duplicatedFrom } = project;
        const duplicatedFromFullProject =
          duplicatedFrom &&
          duplicatedFrom.uuid &&
          ($scope.workflow.projects || []).find(
            ({ uuid }) => uuid === duplicatedFrom.uuid
          );

        $scope.prefixSourceFiles = ProjectFilesUtils.getSourcePath(
          $scope.workflow,
          duplicatedFrom || project
        );
        if (duplicatedFrom && duplicatedFrom.uuid) {
          $scope.prefixSourceFiles +=
            ',' +
            ProjectFilesUtils.getUUIDSourcePath(
              $scope.workflow,
              duplicatedFrom
            );
        }
        $scope.humanPrefixSourceFiles = ProjectFilesUtils.getHumanSourcePath(
          $scope.workflow,
          duplicatedFromFullProject || project
        );
        $scope.prefixReferenceFiles = ProjectFilesUtils.getReferencePath(
          $scope.workflow,
          duplicatedFrom || project
        );
        $scope.humanPrefixReferenceFiles = ProjectFilesUtils.getHumanReferencePath(
          $scope.workflow,
          duplicatedFromFullProject || project
        );
        $scope.prefixTargetFiles = ProjectFilesUtils.getTargetPath(
          $scope.workflow,
          project,
          $scope.isPM
        );
        $scope.humanPrefixTargetFiles = ProjectFilesUtils.getHumanTargetPath(
          $scope.workflow,
          project,
          $scope.isPM
        );

        $scope.wizard && WorkflowStore.emitChange($scope.workflow);
      });

      $timeout(() => {
        if (!$scope.project) return;
        if (!$scope.project.services || $scope.project.services.length === 0) {
          $timeout($scope.createService);
        }
      }, 500);

      $scope.save = function() {
        $scope.filemodeRequired = false;

        const callback = function(noLoadedFiles) {
          if (!noLoadedFiles) {
            WorkflowStore.emit('save', $scope.workflow);
            return;
          }
          $scope.filemodeRequired = true;
          $scope.$apply();

          $timeout(function() {
            const validator = new FormValidator($scope.projectForm);
            if (!validator.validate()) {
              dialog.error(
                $filter('translate')('crud.VALIDATION_ERROR') +
                  '\n' +
                  $filter('translate')('crud.VALIDATION_ERROR_VERIFY')
              );
              return;
            }
            WorkflowStore.emit('save', $scope.workflow);
          });
        };

        if ($scope.isPM) {
          ProjectFilesUtils.validate($scope.prefixSourceFiles, callback);
        } else {
          WorkflowStore.emit('save', $scope.workflow);
        }
      };

      $scope.deleteService = function(index) {
        confirm
          .info($filter('translate')('service_detail.ASK_DELETE'))
          .then(function() {
            if ($scope.project && $scope.project.services) {
              $scope.project.services.splice(index, 1);
            }
          });
      };

      $scope.go = function(index) {
        if (!$scope.wizard) {
          $state.go('^.service-detail', {
            serviceCode: $scope.project.services[index].title
          });

          return $timeout(() => WorkflowStore.emitChange($scope.workflow));
        }

        openModal($scope.project.services[index], function(editService) {
          $scope.project.services[index] = editService;
        });
      };

      $scope.createService = function() {
        if (!$scope.wizard) {
          if ($state.current.name === 'root.app.simple.quote.projects.list') {
            $state.go('^.detail.service-create');
          } else {
            $state.go('^.service-create');
          }
          return;
        }

        const s = {};
        s.first =
          $scope.project &&
          (!$scope.project.services || $scope.project.services.length === 0);
        s.templateType = $scope.workflow.templateType;

        // Pre-fill della data di consegna del servizio come quella del workflow
        const { delivery_date } = $scope.workflow;
        s.delivery_date = s.delivery_date || delivery_date;

        openModal(s, function(service) {
          service.title = ServiceUtils.generateServiceCode($scope.project);
          $scope.project.services = $scope.project.services || [];
          $scope.project.services.push(service);

          WorkflowStore.emitChange($scope.workflow);
        });
      };

      function checkMultipleTargets(service, callback) {
        if (service.target && service.target.length > 1) {
          confirm
            .info($filter('translate')('workflow.DUPLICATE_PROJECT_MSG'))
            .then(() => {
              callback(service);
              $scope.splitProject($stateParams.projectCode);
            });
        } else {
          callback(service);
        }
      }

      function openModal(originalService, callback) {
        const { workflow = {} } = $scope;
        const { company = {}, is24h, templateType } = workflow;

        const modalInstance = $modal.open({
          animation: true,
          templateUrl: 'custom/workflow/views/partials/modalService.html',
          controller: 'ServiceModalInstanceCtrl',
          size: 'lg',
          resolve: {
            company: () => company,
            service: () => originalService,
            is24h: () => is24h,
            templateType: () => templateType
          }
        });

        modalInstance.result.then(editService =>
          checkMultipleTargets(editService, callback)
        );
      }

      $scope.isReadOnly = () => {
        const { isPM, workflow = {} } = $scope;
        if (isPM) return false;

        const { order = {} } = workflow;
        const { status } = order;
        return [
          'tobill',
          'billed',
          'dataconfirmed',
          'dataconfirm',
          'delivered'
        ].includes(status);
      };
    }
  ])

  .controller('ServiceModalInstanceCtrl', [
    '$scope',
    '$modalInstance',
    'company',
    'service',
    'is24h',
    'templateType',
    'FormValidator',
    function(
      $scope,
      $modalInstance,
      company,
      service,
      is24h,
      templateType,
      FormValidator
    ) {
      $scope.company = company;
      $scope.service = angular.copy(service);
      $scope.is24h = is24h;
      $scope.templateType = templateType;

      $scope.ok = function() {
        const validator = new FormValidator($scope.workflowForm);
        if (validator.validate()) {
          $modalInstance.close($scope.service);
        }
      };

      $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
      };
    }
  ]);
