angular
  .module('demo', ['pb.core'])

  .run([
    '$filter',
    'Module',
    function($filter, Module) {
      var module = new Module();

      module
        .push({
          menu: {
            name: 'demo',
            title: $filter('translate')('DEMO'),
            icon: 'zmdi zmdi-mood',
            link: 'root.app.base.vat'
          }
        })
        .pushSubmenu('demo', {
          menu: {
            name: 'demo',
            title: $filter('translate')('DEMO'),
            link: 'root.app.demolist'
          }
        });
    }
  ]);
