angular
  .module('task')

  .factory('ContributorNotesCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class ContributorNotesCrud extends Crud {
        constructor(workflowId, batchUuid, projectCode, taskUuid) {
          super(null, '@@apiUrl' + `/contributor-notes/${workflowId}`, {
            batchUuid,
            projectCode,
            taskUuid
          });
        }
      }
      return ContributorNotesCrud;
    }
  ]);
