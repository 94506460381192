angular
  .module('customer')

  .controller('CustomerCreateCtrl', [
    '$scope',
    'CustomerModel',
    '$controller',
    'UserCrud',
    'Dialog',
    '$state',

    function($scope, CustomerModel, $controller, UserCrud, Dialog, $state) {
      $scope.customer = {
        profile: new CustomerModel(),
        company: undefined
      };

      $controller('SaveBaseCtrl', {
        $scope: $scope,
        crud: new UserCrud($scope),
        packageName: 'customer',
        dataName: 'customer',
        formName: 'customerForm'
      });

      $scope.save = function() {
        if (!$scope.customer.company) {
          new Dialog().error("Seleziona un'azienda per il committente");
          return;
        }

        delete $scope.customer.company.workflowTemplates;
        delete $scope.customer.company.workflows24h;
        $scope.customer.profile.contacts =
          $scope.customer.profile.contacts || {};
        $scope.customer.profile.contacts.email = $scope.customer.email;

        $scope.processSaving().then(function(response) {
          $state.go('^.customer.about', { id: response._id });
        });
      };

      $scope.undo = function() {
        $state.go('^.customers');
      };
    }
  ]);
