angular
  .module('customer')

  .controller('CustomerEditCtrl', [
    '$scope',
    '$state',
    '$controller',
    '$filter',
    '$stateParams',
    'UserCrud',
    'CustomerModel',
    'CustomerStore',
    'LoggedUser',
    'UserLang',
    '$timeout',
    'Auth',
    'Dialog',
    'ZohoCustomerSync',

    function(
      $scope,
      $state,
      $controller,
      $filter,
      $stateParams,
      UserCrud,
      CustomerModel,
      CustomerStore,
      LoggedUser,
      UserLang,
      $timeout,
      Auth,
      Dialog,
      ZohoCustomerSync
    ) {
      const dialog = new Dialog();
      $scope.isPM = LoggedUser.isPM();

      $controller('EditBaseCtrl', {
        $scope: $scope,
        crud: new UserCrud($scope),
        object: CustomerModel,
        packageName: 'customer',
        dataName: 'customer',
        formName: 'customerForm'
      });

      function setHeadquarter(response) {
        var headquarter = response.headquarter;
        var headquarters = response.company
          ? response.company.headquarters
          : [];

        angular.forEach(headquarters, function(hq) {
          if (typeof headquarter === 'string' && hq && hq._id === headquarter) {
            response.headquarter = hq;
          }
        });

        return response;
      }

      CustomerStore.clear().addListener('save', function(data) {
        if (typeof data === 'object') {
          angular.extend($scope.customer, data);
        }

        $scope.processSaving().then(function(response) {
          CustomerStore.emitChange(setHeadquarter(response));

          if (LoggedUser.getData()._id === $scope.customer._id) {
            if ($scope.customer.lang !== UserLang.get()) {
              UserLang.set($scope.customer.lang, true);
            }
          }
        });
      });

      $scope.processLoading().then(function(response) {
        CustomerStore.emitChange(setHeadquarter(response));
      });

      $scope.performUndo = function() {
        $scope.undo();
        CustomerStore.emitChange($scope.customer);
      };

      $scope.save = function() {
        CustomerStore.emit('save');
      };

      $scope.$on('onCreateAccount', function(e, userId, email) {
        Object.assign($scope.customer, {
          userId: userId,
          email: email,
          complete: true,
          percentage: 100,
          complete_email_sent: true
        });

        $scope.processSaving({ skipValidation: true }).then(function(response) {
          CustomerStore.emitChange(response);
          if (LoggedUser.isCustomer()) {
            $timeout(() => {
              Auth.logout().then(() => $scope.$emit('goToLogin'));
            }, 100);
          }
        });
      });

      $scope.zohoCustomerSync = () => {
        new ZohoCustomerSync($stateParams.id)
          .save()
          .then(() => {
            dialog.success($filter('translate')('zoho.SYNC_SUCCESS'));
          })
          .catch(() => {
            dialog.error($filter('translate')('zoho.SYNC_ERROR'));
          });
      };
    }
  ]);
