angular
  .module('customer')

  .factory('DistrictCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class DistrictCrud extends Crud {
        constructor() {
          super(
            'district',
            '@@apiUrl' + '/districts/:country_code',
            { country_code: '@country_code' },
            { query: { method: 'GET', isArray: true } }
          );
        }
      }

      return DistrictCrud;
    }
  ]);
