angular
  .module('company')

  .controller('CompanyEditCtrl', [
    '$scope',
    '$state',
    '$stateParams',
    '$controller',
    '$filter',
    '$timeout',
    '$translate',
    'CompanyCrud',
    'CompanyModel',
    'CompanyStore',
    'LoggedUser',
    'Confirm',
    'Dialog',
    'CompanyAssociation',
    'ValueLang',
    'ZohoCompanySync',

    function(
      $scope,
      $state,
      $stateParams,
      $controller,
      $filter,
      $timeout,
      $translate,
      CompanyCrud,
      CompanyModel,
      CompanyStore,
      LoggedUser,
      Confirm,
      Dialog,
      CompanyAssociation,
      ValueLang,
      ZohoCompanySync
    ) {
      const currentLang = $translate.proposedLanguage() || $translate.use();
      const dialog = new Dialog();
      const confirm = new Confirm();
      const { associating, billingId } = $stateParams;

      if (associating && billingId) {
        const companyAssociation = new CompanyAssociation();

        $scope.associating = false;

        companyAssociation
          .update({ billingId })
          .then(() => {
            dialog.success($filter('translate')('company.ASSOCIATION_SUCCESS'));
            $state.go('^.^.companies');
          })
          .catch(error => {
            Sentry.captureException(error);
            dialog.error($filter('translate')('company.ASSOCIATION_ERROR'));
            $state.go('root.app.simple.company.about', {
              id: $stateParams.billingId
            });
          });
      }

      $scope.save = function() {
        CompanyStore.emit('save');
      };

      // function checkUnits (task, units) {
      //   if (!task.translate || !$scope.company.settings.hasTrados) {
      //     return true;
      //   }
      //   else {
      //     return units.every(function (unit) {
      //       return unit.ranges;
      //     })
      //   }
      // }

      function doAssociateList() {
        $scope.company.lists.push({
          task: $stateParams.task,
          units: $stateParams.units
        });
        $timeout(function() {
          dialog.success($filter('translate')('company.ASSOCIATION_SUCCESS'));
        }, 250);
        $scope.save();
      }

      function associateList() {
        $scope.company.lists = $scope.company.lists || [];
        if (
          $scope.company.lists.some(function(list) {
            return list.task.task_id === $stateParams.task.task_id;
          })
        ) {
          $timeout(function() {
            confirm
              .info($filter('translate')('company.ASSOCIATE_LIST_ALREADY_MSG'))
              .then(function() {
                $scope.company.lists.forEach(function(list) {
                  if (list.task.task_id === $stateParams.task.task_id) {
                    list.task.old = true;
                  }
                });
                doAssociateList();
              });
          }, 250);
        } else {
          doAssociateList();
        }
      }

      function associateAllLists() {
        if ($scope.company.lists) {
          $timeout(function() {
            confirm
              .info($filter('translate')('company.OVERWRITE_LISTS_MSG'))
              .then(function() {
                $scope.company.lists = $stateParams.allLists;
                $timeout(function() {
                  dialog.success(
                    $filter('translate')('company.ASSOCIATION_SUCCESS')
                  );
                }, 250);
              });
          }, 250);
        }
      }

      $controller('EditBaseCtrl', {
        $scope: $scope,
        crud: new CompanyCrud($scope),
        object: CompanyModel,
        packageName: 'company',
        dataName: 'company',
        formName: 'companyForm'
      });

      CompanyStore.clear().addListener('save', function(data) {
        if (typeof data === 'object') {
          angular.extend($scope.company, data);
        }

        $scope.processSaving().then(function(response) {
          CompanyStore.emitChange(response);
        });
      });

      $scope.processLoading().then(function(response) {
        CompanyStore.emitChange(response);
        CompanyStore.emitLegalHeadquarter(response);

        if (
          $stateParams.associating &&
          $stateParams.units &&
          $stateParams.task &&
          !$stateParams.allLists
        ) {
          // if (checkUnits($stateParams.task, $stateParams.units)) {
          associateList();
          // }
          // else {
          //   $timeout(() => dialog.error($filter('translate')('company.REQUIRES_TRADOS_ERR')), 300);
          // }
        } else if ($stateParams.associating && $stateParams.allLists) {
          // if ($stateParams.allLists.every(function (list) {
          //     return checkUnits(list.task, list.units);
          //   })) {
          associateAllLists();
          // }
          // else {
          //   $timeout(() => dialog.error($filter('translate')('company.REQUIRES_TRADOS_ERR')), 300);
          // }
        }
      });

      $scope.performUndo = function() {
        $scope.undo();
        CompanyStore.emitChange($scope.company);
      };

      $scope.isPM = function() {
        return LoggedUser.isPM();
      };

      $scope.removeList = function(index) {
        confirm
          .info($filter('translate')('company.DELETE_LIST_MSG'))
          .then(function() {
            $scope.company.lists.splice(index, 1);
            $scope.save();
            $timeout(function() {
              dialog.success($filter('translate')('DELETE_SUCCESS'));
            }, 250);
          });
      };

      $scope.getTaskName = function(task) {
        var name = ValueLang(task, 'task_name', currentLang).value;
        name += task.old ? ' (' + $filter('translate')('DISABLED') + ')' : '';
        return name;
      };

      $scope.setDefault = function(index, taskInfo) {
        confirm
          .info($filter('translate')('company.SET_DEFAULT_LIST_MSG'))
          .then(function() {
            $scope.company.lists.forEach(function(list) {
              if (list.task.task_id === taskInfo.task_id) {
                list.task.old = true;
              }
            });
            $scope.company.lists[index].task.old = false;
            $scope.save();
          });
      };

      $scope.searchCompany = function() {
        $state.go('root.app.simple.companies', {
          associating: true,
          allLists: angular.copy($scope.company.lists)
        });
      };

      $scope.zohoCompanySync = () => {
        new ZohoCompanySync($stateParams.id)
          .save()
          .then(() => {
            dialog.success($filter('translate')('zoho.SYNC_SUCCESS'));
          })
          .catch(() => {
            dialog.error($filter('translate')('zoho.SYNC_ERROR'));
          });
      };
    }
  ]);
