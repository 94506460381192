angular
  .module('pb.base')

  .directive('inputCurrency', [
    '$timeout',

    function($timeout) {
      return {
        restrict: 'E',
        require: '^ngModel',
        scope: {
          value: '=ngModel',
          disabled: '<'
        },
        template: `
            <div id="inputCurrency" ng-hide="edit" ng-click="!disabled && (edit = true)">{{(value || 0) | currency:'':3 }}</div>
            <input type="number" step="0.001" class="form-control input-sm" ng-model="value" name="value" ng-show="edit">
        `,
        link: function(scope, element, attrs) {
          scope.edit = false;

          element.bind('click', function(e) {
            e.stopPropagation();
            if (e && e.target && e.target.id === 'inputCurrency') {
              $timeout(function() {
                element.find('input')[0].focus();
              }, 100);
            }
          });

          element.bind('keydown keypress', function(event) {
            if (event.which === 27 || event.which === 13) {
              // 27 = esc key, 13 = enter key
              scope.edit = false;
              scope.$apply();
              event.preventDefault();
            }
          });
        }
      };
    }
  ]);
