angular
  .module('order')

  .config([
    '$stateProvider',

    function($stateProvider) {
      var path = 'custom/workflow/views/';

      $stateProvider

        .state('root.app.simple.orders', {
          url: '/orders',
          params: {
            contributor: null,
            linkingToParent: null,
            fromWorkflowId: null
          },
          views: {
            content: {
              templateUrl: path + 'search.html',
              controller: 'WorkflowListCtrl'
            }
          },
          ncyBreadcrumb: {
            label: '{{::( breadCrumbOrders | uppercase )}}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        })

        .state('root.app.simple.order', {
          url: '/order/:id',
          params: {
            newContributors: null
          },
          views: {
            content: {
              templateUrl: path + 'edit.html',
              controller: 'WorkflowEditCtrl'
            }
          },
          ncyBreadcrumb: {
            parent: 'root.app.simple.orders',
            label: '{{ breadCrumbOrder | uppercase }}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        })

        .state('root.app.simple.order.about', {
          url: '/about',
          templateUrl: path + 'tab-about.html',
          controller: 'WorkflowTabCtrl',
          ncyBreadcrumb: {
            label: '{{::( breadCrumbAbout | uppercase )}}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        })

        .state('root.app.simple.order.projects', {
          url: '/projects',
          abstract: true,
          template: ` <div ui-view></div> `,
          controller: 'WorkflowTabCtrl',
          ncyBreadcrumb: {
            label: '{{::( breadCrumbProjects | uppercase )}}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        })

        .state('root.app.simple.order.projects.list', {
          url: '/list',
          templateUrl: path + 'projects-list.html',
          ncyBreadcrumb: {
            label: '{{::( breadCrumbProjects | uppercase )}}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        })

        .state('root.app.simple.order.projects.detail', {
          url: '/:projectCode',
          abstract: true,
          templateUrl: path + 'project-container.html',
          controller: 'WorkflowProjectDetailCtrl',
          ncyBreadcrumb: {
            parent: 'root.app.simple.order.projects.list',
            label: '{{::( breadCrumbProject | uppercase )}}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        })

        .state('root.app.simple.order.projects.detail.data', {
          url: '/data',
          template: '<project-form></project-form>',
          ncyBreadcrumb: {
            parent: 'root.app.simple.order.projects.list',
            label: '{{::( breadCrumbProject | uppercase )}}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        })

        .state('root.app.simple.order.projects.detail.tasks', {
          url: '/tasks',
          params: {
            newContributors: null,
            batch: null,
            task: null
          },
          templateUrl: path + 'tab-task.html',
          ncyBreadcrumb: {
            parent: 'root.app.simple.order.projects.list',
            label: '{{::( breadCrumbProject | uppercase )}}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        })

        .state('root.app.simple.order.projects.detail.task-detail', {
          url: '/task-detail/:batchIndex/:taskIndex',
          params: {
            batchIndex: null,
            taskIndex: null
          },
          templateUrl: path + 'task-detail.html',
          controller: 'TaskDetailCtrl',
          ncyBreadcrumb: {
            parent: 'root.app.simple.order.projects.list',
            label: '{{::( breadCrumbProject | uppercase )}}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        })

        .state('root.app.simple.order.projects.detail.service-create', {
          url: '/service-create',
          templateUrl: path + 'tab-service.html',
          ncyBreadcrumb: {
            parent: 'root.app.simple.order.projects.detail.data',
            label: '{{::( breadCrumbCreateSrv | uppercase )}}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        })

        .state('root.app.simple.order.projects.detail.service-detail', {
          url: '/:serviceCode',
          templateUrl: path + 'tab-service.html',
          controller: 'WorkflowServiceDetailCtrl',
          ncyBreadcrumb: {
            parent: 'root.app.simple.order.projects.detail.data',
            label: '{{::( breadCrumbEditSrv | uppercase )}}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        })

        .state('root.app.simple.order.billing', {
          url: '/billing',
          templateUrl: path + 'tab-billing.html',
          controller: 'WorkflowTabCtrl',
          ncyBreadcrumb: {
            label: '{{::( breadCrumbBilling | uppercase )}}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        })

        .state('root.app.simple.order.admin', {
          url: '/admin',
          templateUrl: path + 'tab-doc-admin.html',
          controller: 'WorkflowTabCtrl',
          ncyBreadcrumb: {
            label: '{{::( breadCrumbAdmin | uppercase )}}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        })

        .state('root.app.simple.order.offer', {
          url: '/offer',
          templateUrl: path + 'tab-offer.html',
          controller: 'WorkflowTabCtrl',
          ncyBreadcrumb: {
            label: '{{::( breadCrumbOffer | uppercase )}}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        })

        .state('root.app.simple.order.invoices', {
          url: '/invoices',
          templateUrl: path + 'tab-invoices.html',
          controller: 'WorkflowTabCtrl',
          ncyBreadcrumb: {
            label: '{{::( breadCrumbOffer | uppercase )}}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        })

        .state('root.app.simple.order.pre_invoiced', {
          url: '/pre_invoiced',
          templateUrl: path + 'tab-pre_invoiced.html',
          controller: 'WorkflowPreInvoicedController',
          ncyBreadcrumb: {
            label: '{{::( breadCrumbOffer | uppercase )}}'
          },
          permissions: {
            resources: { orders: ['read'] }
          }
        });
    }
  ]);
