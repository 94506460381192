angular
  .module('pb.core.notifications')

  .factory('Notification', [
    '$rootScope',

    function($rootScope) {
      /**
       * Notification class
       * @constructor
       */
      function Notification() {
        this.type = 'info';
      }

      /**
       * Default notification parameters
       * @param params
       * @returns {Object}
       */
      Notification.prototype._defaultParams = function(params) {
        params = params || {};

        return {
          message: params.message || '',
          title: params.title || '@@appName',
          type: this.type
        };
      };

      /**
       * Get arguments or default parameters
       * @param args
       * @returns {Object}
       */
      Notification.prototype._argumentsOrDefault = function(args) {
        args = args || [];

        switch (typeof args[0]) {
          case 'string':
            return this._defaultParams({ title: args[0], message: args[1] });
            break;

          case 'object':
            return this._defaultParams(args[0]);
            break;

          default:
            return this._defaultParams({});
            break;
        }
      };

      /**
       * Set notification type
       * @param type
       * @returns {Notification}
       */
      Notification.prototype.setType = function(type) {
        this.type = type || 'info';
        return this;
      };

      /**
       * Info notification
       * @returns {Notification}
       */
      Notification.prototype.info = function() {
        return this.setType('info').show.apply(this, arguments);
      };

      /**
       * Success notification
       * @returns {Notification}
       */
      Notification.prototype.success = function() {
        return this.setType('success').show.apply(this, arguments);
      };

      /**
       * Warning notification
       * @returns {Notification}
       */
      Notification.prototype.warning = function() {
        return this.setType('warning').show.apply(this, arguments);
      };

      /**
       * Error notification
       * @returns {Notification}
       */
      Notification.prototype.error = function() {
        return this.setType('danger').show.apply(this, arguments);
      };

      /**
       * Show a notification message
       * @returns {Notification}
       */
      Notification.prototype.show = function() {
        this._broadcast(this._argumentsOrDefault(arguments));
        return this;
      };

      /**
       * Broadcast event on notifications change
       * @private
       */
      Notification.prototype._broadcast = function(notification) {
        $rootScope.$broadcast('onNotification', notification);
      };

      return Notification;
    }
  ]);
