angular
  .module('quote')

  .factory('QuoteCancelRequest', [
    'Crud',

    function(Crud) {
      'use strict';

      class QuoteCancelRequest extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/quote-cancel-request/:id', { id: '@id' });
        }
      }

      return QuoteCancelRequest;
    }
  ]);
