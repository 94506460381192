angular
  .module('company')

  .directive('searchCompany', [
    '$filter',
    function($filter) {
      return {
        restrict: 'E',
        scope: {
          company: '=',
          enableNext: '='
        },
        templateUrl: 'custom/company/views/partials/searchCompany.html',
        controller: [
          '$scope',
          '$controller',
          'CompanySearch',
          function(scope, $controller, CompanySearch) {
            scope.vat = '';
            scope.companies = [];
            scope.enableNext = false;
            scope.showResults = false;
            scope.enableErrorNotifications = false;

            $controller('LoadBaseCtrl', {
              $scope: scope,
              crud: new CompanySearch(),
              object: null,
              dataName: 'item'
            });

            scope.search = function(vat) {
              scope.showResults = vat && vat.length > 0;
              scope.company = { vat: vat };

              scope.processLoading({ vat: vat }).then(
                function(response) {
                  scope.companies = response ? [response] : [];
                  scope.enableNext = false;
                },
                function() {
                  scope.companies = [];
                  scope.enableNext = scope.showResults;
                }
              );
            };

            scope.select = function(company) {
              if (company.selected === true) {
                scope.company = company;
                scope.enableNext = true;
              } else {
                scope.company = { vat: scope.vat };
                scope.enableNext = false;
              }
            };
          }
        ]
      };
    }
  ]);
