angular
  .module('pb.base')

  /**
   * formGroupValidate directive
   *
   * It must be addedd at element with "form-group" class.
   * It must be contained in a form element with "pb-form" directive.
   *
   * @example
   * <form name="testForm" pb-form>
   *    <div class="form-group" form-group-validate>
   *        <label class="control-label" for="name">Input label</label>
   *        <div class="fg-line">
   *            <input type="text" name="name" ng-model="nameField" class="form-control" placeholder="Input Default"
   *                required
   *                ng-maxlength="5"
   *                required-msg="Required field"
   *                maxlength-msg="Max length 5 chars">
   *        </div>
   *    </div>
   * </form>
   */
  .directive('formGroupValidate', [
    '$timeout',
    function($timeout) {
      return {
        restrict: 'A',
        require: '^pbForm',
        scope: false,
        link: function(scope, element, attrs, controller) {
          $timeout(function() {
            var noMessages = attrs.noErrors === 'true';

            var inputElement = element.find(
                'input:not([type=file]), select, textarea'
              ),
              isSelect = element.find('select').length > 0,
              formName = controller.getFormName(),
              groupName = inputElement.attr('name'),
              labelElement = element.find('label[for="' + groupName + '"]'),
              labelName = labelElement.text(),
              errorKey = labelName || groupName,
              inputObject = scope[formName]
                ? scope[formName][groupName] || {}
                : {};

            if (
              inputObject &&
              inputObject.$validators &&
              typeof inputObject.$validators.required !== 'undefined'
            ) {
              if (
                typeof inputObject.$validators.required !== 'function' ||
                !inputObject.$validators.required()
              ) {
                labelElement.append('<span class="c-red"> *</span>');
              }
            }

            function getErrorMessages(errors) {
              var messages = [];

              angular.forEach(errors, function(error, key) {
                var message = inputElement.attr(key + '-msg') || key;

                if (typeof message === 'string') {
                  messages.push(message);
                }
              });

              return messages;
            }

            function setValid() {
              delete scope.errorMessages[errorKey];

              element
                .removeClass('has-error')
                .find('.input-error-close')
                .remove();

              element.find('.help-block').remove();
            }

            function setInvalid(errors) {
              var messages = getErrorMessages(errors);
              scope.errorMessages[errorKey] = messages;

              element.find('.input-error-close').remove();

              element.find('.help-block').remove();

              element
                .addClass('has-error')
                .addClass('has-feedback')
                .find('.fg-line')
                .after(
                  '<span class="input-error-close zmdi zmdi-close form-control-feedback' +
                    (isSelect ? ' error-select' : '') +
                    '"></span>'
                );

              if (!noMessages) {
                angular.forEach(messages, function(message) {
                  element
                    .find(
                      'input:not([type=file]), select:not([chosen]), textarea, div.chosen-container'
                    )
                    .first()
                    .after('<small class="help-block">' + message + '</small>');
                });
              }
            }

            scope.errorMessages = scope.errorMessages || {};

            scope.$watch(
              formName + '.' + groupName + '.$error',
              function(newValue, oldValue) {
                if (newValue && newValue != oldValue) {
                  inputObject.$dirty && inputObject.$invalid
                    ? setInvalid(newValue)
                    : setValid();
                }
              },
              true
            );

            scope.$watch(
              formName + '.' + groupName + '.$dirty',
              function(newValue, oldValue) {
                if (newValue && newValue != oldValue) {
                  newValue && inputObject.$invalid
                    ? setInvalid(scope[formName][groupName].$error)
                    : setValid();
                }
              },
              true
            );
          });
        }
      };
    }
  ]);
