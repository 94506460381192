angular
  .module('pb.base')

  .directive('changeLayout', function() {
    return {
      restrict: 'A',
      scope: {
        changeLayout: '='
      },
      link: function(scope, element) {
        //Default State
        if (scope.changeLayout === '1') {
          element.prop('checked', true);
        }

        //Change State
        element.on('change', function() {
          if (element.is(':checked')) {
            scope.$emit('closeSidebarMenu');
            localStorage.setItem('@@localStoragePrefix' + 'layout-status', 1);
            scope.$apply(function() {
              scope.changeLayout = '1';
            });
          } else {
            localStorage.setItem('@@localStoragePrefix' + 'layout-status', 0);
            scope.$apply(function() {
              scope.changeLayout = '0';
            });
          }
        });
      }
    };
  });
