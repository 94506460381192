angular
  .module('workflow')

  .factory('ProjectFilesUtils', [
    'Dialog',
    '$filter',
    'ErrorsManager',
    'FilesCrud',

    function(Dialog, $filter, ErrorsManager, FilesCrud) {
      'use strict';

      var dialog = new Dialog();
      var sourcePath = '';
      var referencePath = '';
      var crud = new FilesCrud();

      const getPathCompanyName = workflow => {
        let { company } = workflow;
        company = company || {};
        let { company_name } = company;
        company_name = typeof company_name === 'string' ? company_name : '';
        company_name = company_name
          .trim()
          .replace(/[^a-zA-Z0-9]/g, '_')
          .toUpperCase();
        while (company_name.endsWith('_')) {
          company_name = company_name.replace(/.$/, '');
        }
        return company_name;
      };

      const getPathWorkflowNumber = workflow => {
        let { workflow_company_num } = workflow;
        workflow_company_num =
          typeof workflow_company_num === 'string' ? workflow_company_num : '';
        return workflow_company_num.split('_').pop();
      };

      const getPathProjectIndex = (workflow, project) => {
        let { projects } = workflow;
        projects = Array.isArray(projects) ? projects : [];
        let projectIndex = projects.findIndex(item => {
          if (item === project) return true;
          const itemUuid = item && item.uuid;
          const projectUuid = project && project.uuid;
          if (itemUuid && projectUuid) return itemUuid === projectUuid;
          const itemCode = item && item.code;
          const projectCode = project && project.code;
          if (itemCode && projectCode) return itemCode === projectCode;
        });
        return projectIndex >= 0 ? `${++projectIndex}`.padStart(2, '0') : '';
      };

      const getPathProjectLang = project => {
        let { services } = project;
        services = Array.isArray(services) ? services : [];
        let [firstService] = services;
        firstService = firstService || {};
        let { source, target } = firstService;
        source = source || {};
        target = Array.isArray(target) ? target : [];
        const { name: sourceTranslatedName, value: sourceValue } = source;
        const sourceName =
          (sourceTranslatedName && sourceTranslatedName.it) ||
          sourceValue ||
          '';
        let [firstTarget] = target;
        firstTarget = firstTarget || {};
        const { name: targetTranslatedName, value: targetValue } = firstTarget;
        const targetName =
          (targetTranslatedName && targetTranslatedName.it) ||
          targetValue ||
          '';
        return (targetName || sourceName || '')
          .trim()
          .replace(/[^a-zA-Z0-9]/g, '_');
      };

      const getPathProject = (workflow, project) => {
        return [
          getPathProjectIndex(workflow, project),
          getPathProjectLang(project)
        ]
          .filter(item => item)
          .join('_');
      };

      const getHumanPath = (workflow, project, suffix = '') => {
        const pathElements = [
          getPathCompanyName(workflow),
          getPathWorkflowNumber(workflow),
          getPathProject(workflow, project)
        ];
        suffix && pathElements.push(suffix);
        return pathElements.every(item => item)
          ? ['workflows', ...pathElements].join('/')
          : '';
      };

      const getTargetTasksToShow = (workflow, project, isPM) => {
        const { is24h } = workflow;
        const tasksToShow = [];
        const { batches = [] } = project;
        batches.forEach(({ tasks = [], uuid }) => {
          tasks.forEach((task, taskIndex) => {
            const isLastInBatch = taskIndex === tasks.length - 1;
            const hamster = task.params && task.params.hamster;
            if (
              isPM ||
              task.showTargetToCustomer ||
              (isLastInBatch &&
                // If hamster is true and showTargetToCustomer is undefined,
                // files must be shown. Hide them only if showTargetToCustomer
                // is explicitly false.
                (is24h || (hamster && task.showTargetToCustomer !== false)))
            ) {
              tasksToShow.push({
                ...task,
                batchUuid: uuid
              });
            }
          });
        });
        return tasksToShow;
      };

      return {
        getHumanPath,

        getHumanAdminPath: workflow => {
          const pathElements = [
            getPathCompanyName(workflow),
            getPathWorkflowNumber(workflow)
          ];
          return pathElements.every(item => item)
            ? ['workflows', ...pathElements, 'admin_landoor'].join('/')
            : '';
        },

        getSourcePath: function(workflow, project) {
          sourcePath =
            'workflows/' +
            workflow._id +
            '/projects/' +
            project.code +
            '/' +
            'source';
          return sourcePath;
        },

        getHumanSourcePath: (workflow, project) =>
          getHumanPath(workflow, project, 'S'),

        getUUIDSourcePath: function(workflow, project) {
          const { uuid } = project || {};
          if (!uuid) return;

          sourcePath =
            'workflows/' + workflow._id + '/projects/' + uuid + '/' + 'source';
          return sourcePath;
        },

        getTargetPath: function(workflow, project, isPM) {
          const basePath =
            'workflows/' +
            workflow._id +
            '/projects/' +
            (project.uuid || project.code);
          let path = basePath + '/target';

          getTargetTasksToShow(workflow, project, isPM).forEach(task => {
            path += `,${basePath}/batches/${task.batchUuid}/tasks/${task.uuid}/target`;
          });

          return path;
        },

        getHumanTargetPath: (workflow, project, isPM) => {
          const basePath = getHumanPath(workflow, project);
          if (!basePath) return '';
          const projectPath = [basePath, 'T'].join('/');
          const tasksToShowPaths = getTargetTasksToShow(
            workflow,
            project,
            isPM
          ).map(task => {
            const { batchUuid } = task;
            let { batches } = project;
            batches = Array.isArray(batches) ? batches : [];
            let batchIndex = batches.findIndex(
              batch => batch && batch.uuid === batchUuid
            );
            batchIndex =
              batchIndex >= 0 ? `${++batchIndex}`.padStart(2, '0') : '';
            const { name, value } = task || {};
            let taskName = (name && name.it) || value || '';
            taskName = taskName
              .trim()
              .replace(/[^a-zA-Z0-9]/g, '_')
              .toUpperCase();
            return batchIndex && taskName
              ? [basePath, batchIndex, taskName, 'T'].join('/')
              : '';
          });
          return [projectPath, ...tasksToShowPaths].join(',');
        },

        getHumanTaskPath: (workflow, project, batchIndex, task) => {
          const basePath = getHumanPath(workflow, project);
          if (!basePath) return '';
          batchIndex =
            batchIndex >= 0 ? `${++batchIndex}`.padStart(2, '0') : '';
          const { name, value } = task || {};
          let taskName = (name && name.it) || value || '';
          taskName = taskName
            .trim()
            .replace(/[^a-zA-Z0-9]/g, '_')
            .toUpperCase();
          return batchIndex && taskName
            ? [basePath, batchIndex, taskName].join('/')
            : '';
        },

        getReferencePath: function(workflow, project) {
          referencePath =
            'workflows/' +
            workflow._id +
            '/projects/' +
            project.code +
            '/' +
            'reference';
          return referencePath;
        },

        getHumanReferencePath: (workflow, project) =>
          getHumanPath(workflow, project, 'R'),

        validateSource: function(callback) {
          this.validate(sourcePath, callback);
        },

        validate: function(prefix, callback) {
          crud.query({ parentPath: prefix }).then(
            function(response) {
              var files =
                response && typeof response.results === 'object'
                  ? response.results
                  : response;

              var fileNoData = files.find(f => {
                return (
                  !f.data.macrosector || !f.data.sector || !f.data.document
                );
              });

              if (fileNoData) {
                dialog.error(
                  $filter('translate')('file.INSERT_METADATA_FILE') +
                    ' ' +
                    fileNoData.name
                );
                return;
              }

              callback();
            },
            function(err) {
              dialog.error(ErrorsManager.getErrorsAsString(err));
            }
          );
        }
      };
    }
  ]);
