angular
  .module('pb.base')

  .directive('selectMultipleLanguages', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        languages: '=',
        native: '@',
        source: '@'
      },
      template: `
        <select chosen="{'placeholder_text_multiple': ('service_detail.SELECT_MULTIPLE_LANG' | translate)}"
          ng-model="languages" multiple ng-options="i.value for i in items track by i._id">
          <option value=""></option>
        </select>
        `,
      controller: [
        '$scope',
        '$controller',
        'LanguageCrud',
        function($scope, $controller, LanguageCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new LanguageCrud('-list'),
            packageName: 'languages',
            dataName: 'items',
            filters: {}
          });

          var params = {};

          if ($scope.native) {
            params.native = $scope.native;
          }

          if ($scope.source) {
            params.source = $scope.source;
          }

          $scope.loadList(params);
        }
      ]
    };
  });
