angular
  .module('workflow')

  .directive('summaryQuote', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        workflow: '<'
      },
      templateUrl: 'custom/workflow/views/partials/summaryQuote.html',
      controller: [
        '$scope',
        'DeepMergeAndSum',
        'UserLang',
        function($scope, DeepMergeAndSum, UserLang) {
          let groupByServiceResult = {};
          const userLang = UserLang.get();

          $scope.groupByService = (projectCode, batches) => {
            if (groupByServiceResult[projectCode])
              return groupByServiceResult[projectCode];

            const resultBatches = batches.reduce((acc, batch) => {
              const resultTasks = batch.tasks.reduce((acc, task) => {
                let {
                  service_name = '',
                  name: taskName = '',
                  description = {}
                } = task;
                const { it = '', en = '' } = description;
                service_name = service_name[userLang] || service_name;
                taskName = taskName[userLang] || taskName;
                if (!acc[service_name]) acc[service_name] = {};

                acc[service_name][taskName] = {
                  quantity:
                    (task.quantity || 0) +
                    ((acc[service_name][taskName] &&
                      acc[service_name][taskName].quantity) ||
                      0),
                  uom: task.unit && task.unit.lc,
                  amount:
                    (task.amount || 0) +
                    ((acc[service_name][taskName] &&
                      acc[service_name][taskName].amount) ||
                      0),
                  description: { it, en },
                  exchangedUnitPrice: task.exchangedUnitPrice,
                  unitPrice: task.unitPrice
                };
                return acc;
              }, {});

              return DeepMergeAndSum(acc, resultTasks);
            }, {});

            groupByServiceResult[projectCode] = resultBatches;
            return groupByServiceResult[projectCode];
          };

          $scope.getCombo = (project = {}) => {
            const { services = [] } = project;
            const firstService = services.find(({ first } = {}) => first) || {};
            const { source = {}, target = [] } = firstService;
            const { value: sourceValue = '' } = source;
            const { value: targetValue = '' } = target[0] || {};
            if (!sourceValue && !targetValue) return '';
            return `${sourceValue}${targetValue ? ` → ${targetValue}` : ''}`;
          };

          $scope.changeTaskDescription = (
            serviceName,
            taskName,
            description
          ) => {
            for (let project of $scope.workflow.projects) {
              for (let batch of project.batches) {
                for (let task of batch.tasks) {
                  const { service_name, name } = task;
                  if (
                    (name === taskName && service_name === serviceName) ||
                    (name[userLang] === taskName &&
                      service_name[userLang] === serviceName)
                  ) {
                    task.description = description;
                  }
                }
              }
            }
          };

          $scope.getTotal = () => {
            const hasForeignCurrency = $scope.hasForeignCurrency();
            let total = 0;
            for (let project of $scope.workflow.projects) {
              for (let batch of project.batches) {
                for (let task of batch.tasks) {
                  const {
                    amount = 0,
                    exchangedUnitPrice = 0,
                    quantity = 0
                  } = task;
                  total += hasForeignCurrency
                    ? exchangedUnitPrice * quantity
                    : amount;
                }
              }
            }
            return total;
          };

          $scope.hasForeignCurrency = () =>
            !!(
              $scope.workflow &&
              $scope.workflow.currency &&
              $scope.workflow.currency !== 'EUR' &&
              $scope.workflow.exchangeRate
            );
        }
      ]
    };
  });
