angular
  .module('workflow')

  .controller('WorkflowPreInvoicedController', [
    '$filter',
    '$scope',
    '$state',
    '$timeout',
    'Confirm',
    'Dialog',
    'WorkflowStore',
    'WorkflowUnlinkAsParentCrud',

    function(
      $filter,
      $scope,
      $state,
      $timeout,
      Confirm,
      Dialog,
      WorkflowStore,
      WorkflowUnlinkAsParentCrud
    ) {
      $scope.linkToParent = () => {
        const unregister = $scope.$on('save:done', () => {
          unregister();
          $state.go('^.^.orders', {
            linkingToParent: true,
            fromWorkflowId: $state.params.id
          });
        });
        WorkflowStore.emit('save', $scope.workflow);
      };

      $scope.goTo = workflow => {
        if (!workflow || !workflow._id) return;

        $state.go('^.^.order.projects.list', {
          id: workflow._id
        });
      };

      $scope.unlinkAsParent = childOrder => {
        if (!childOrder || !childOrder._id) return;

        const unlink = () => {
          new WorkflowUnlinkAsParentCrud($state.params.id)
            .update({ childWorkflowId: childOrder._id })
            .then(() => {
              $scope.workflow.childrenOrders = Array.isArray(
                $scope.workflow.childrenOrders
              )
                ? $scope.workflow.childrenOrders
                : [];
              $scope.workflow.childrenOrders = $scope.workflow.childrenOrders.filter(
                item => item && item._id !== childOrder._id
              );
              $timeout(
                () => new Dialog().success($filter('translate')('crud.SAVED')),
                250
              );
            })
            .catch(e => {
              console.error(e);
              new Dialog().error($filter('translate')('error.RECEIVE_ERR'));
            });
        };

        new Confirm()
          .show($filter('translate')('order.UNLINK_FROM_PARENT_MSG'))
          .then(unlink);
      };

      $scope.$watchGroup(
        [
          'workflow.childrenOrders',
          'workflow.childrenOrdersDiscount',
          'workflow.currency',
          'workflow.exchangeRate',
          'workflow.importoNettoFT',
          'workflow.importoNettoValutaFT',
          'workflow.packageExpiresAt'
        ],
        ([
          childrenOrders,
          childrenOrdersDiscount,
          currency,
          exchangeRate,
          importoNettoFT,
          importoNettoValutaFT,
          packageExpiresAt
        ]) => {
          const packageExpiresAtDate =
            packageExpiresAt && new Date(packageExpiresAt);
          const packageExpiresAtTime =
            packageExpiresAtDate && packageExpiresAtDate.getTime();
          const hasExpired =
            packageExpiresAtTime && packageExpiresAtTime < Date.now();

          const hasForeignCurrency = !!(
            currency &&
            currency !== 'EUR' &&
            exchangeRate
          );

          const totalPreInvoiced = (childrenOrders || []).reduce(
            (acc, order) =>
              acc + ((order && order.totals && order.totals.amount) || 0) ||
              acc,
            0
          );

          let discount;

          if (hasExpired) {
            discount = hasForeignCurrency
              ? totalPreInvoiced * (exchangeRate || 1) -
                (importoNettoValutaFT || 0)
              : totalPreInvoiced - (importoNettoFT || 0);
          } else {
            discount = childrenOrdersDiscount || 0;
          }

          $scope.discountEur = hasForeignCurrency
            ? discount / (exchangeRate || 1)
            : discount;
          $scope.discountForeignCurrency = hasForeignCurrency ? discount : 0;
          $scope.hasExpired = hasExpired;
          $scope.hasForeignCurrency = hasForeignCurrency;
          $scope.totalPreInvoiced = totalPreInvoiced;

          if (($scope.workflow.childrenOrdersDiscount || 0) !== discount) {
            $scope.workflow.childrenOrdersDiscount = discount;
            $timeout(() => WorkflowStore.emit('save', $scope.workflow), 100);
          }
        }
      );
    }
  ]);
