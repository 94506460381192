angular
  .module('home')

  .directive('tasksPieChart', [
    '$controller',
    '$filter',
    'TaskCountersCrud',
    function($controller, $filter, TaskCountersCrud) {
      return {
        restrict: 'E',
        scope: false,
        templateUrl: 'custom/home/views/partials/tasksPieChart.html',
        link: function(scope) {
          $controller('LoadBaseCtrl', {
            $scope: scope,
            crud: new TaskCountersCrud(),
            object: null,
            dataName: 'data'
          });

          scope.noTasks = true;
          scope.tCounters = [
            {
              data: 1,
              color: '#e3e3e3',
              label: ''
            }
          ];

          const now = new Date();
          scope.last_15_days = new Date();
          scope.last_15_days.setDate(now.getDate() - 15);
          scope.next_15_days = new Date();
          scope.next_15_days.setDate(now.getDate() + 15);

          const filters = {
            filter: {
              delivery_date: {
                from: scope.last_15_days,
                to: scope.next_15_days
              }
            }
          };
          scope.processLoading(filters).then(function(response) {
            var processedCounters = [];

            var rawCounters = response && response.counters;

            var colors = {
              approved: '#69A5D6',
              notapproved: 'rgb(178, 1, 1)',
              waiting: '#FFEB3B',
              done: '#4CAF50'
            };

            var capitalizeFirstLetter = function(string) {
              return string.charAt(0).toUpperCase() + string.slice(1);
            };

            var statusName;

            for (let key in rawCounters) {
              if (rawCounters[key]) {
                scope.noTasks = false;
              }
              statusName = 'status.' + key;
              processedCounters.push({
                data: rawCounters[key],
                color: colors[key],
                label:
                  capitalizeFirstLetter($filter('translate')(statusName)) +
                  ' (' +
                  rawCounters[key] +
                  ')'
              });
            }

            scope.tCounters = processedCounters;
          });
        }
      };
    }
  ]);
