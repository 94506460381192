angular
  .module('library')

  .factory('LibraryParser', function() {
    function arrayToObject(data) {
      var obj = angular.copy(data);

      for (var prop in data) {
        if (!data.hasOwnProperty(prop)) {
          continue;
        }

        if (prop === 'code') {
          obj.code = parseInt(data.code) || data.code;
        }

        if (prop !== 'name' && prop !== 'desc' && prop !== 'abbreviation') {
          continue;
        }

        if (Array.isArray(data[prop])) {
          obj[prop] = {};

          data[prop].forEach(function(item) {
            if (item.lang) {
              obj[prop][item.lang] = item;
            }
          });
        }
      }

      return obj;
    }

    function arrayToObjects(response) {
      return response.map(data => {
        return arrayToObject(data);
      });
    }

    function objectsToArray(data) {
      var obj = angular.copy(data);

      for (var prop in data) {
        if (!data.hasOwnProperty(prop)) {
          continue;
        }

        let attr = data[prop];

        if (prop === 'name' || prop === 'desc' || prop === 'abbreviation') {
          obj[prop] = [];

          for (var value in attr) {
            if (!attr.hasOwnProperty(value)) {
              continue;
            }

            obj[prop].push(attr[value]);
          }
        }
      }

      return obj;
    }

    return {
      arrayToObjects: arrayToObjects,
      objectsToArray: objectsToArray
    };
  });
