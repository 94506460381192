angular
  .module('admin')

  .factory('AdminModel', function() {
    function AdminModel() {
      //Admin default model
      return {};
    }

    return AdminModel;
  });
