angular
  .module('contributor')

  .directive('cardContributorsFilter', [
    'LSPrefix',
    '$timeout',
    function(LSPrefix, $timeout) {
      return {
        restrict: 'E',
        require: '^ngModel',
        replace: true,
        scope: {
          filtersModel: '=ngModel',
          performSearch: '&search',
          filtersApplied: '='
        },
        templateUrl:
          'custom/contributor/views/partials/cardContributorsFilter.html',
        controller: [
          '$scope',
          '$controller',
          function($scope, $controller) {
            $scope.prefix = 'contributor';

            $controller('filterController', {
              $scope: $scope
            });
          }
        ]
      };
    }
  ]);
