angular
  .module('contributor')

  .controller('ContributorEditCtrl', [
    '$scope',
    '$state',
    '$controller',
    'UserCrud',
    'ContributorModel',
    'ContributorStore',
    '$stateParams',
    'Dialog',
    'LoggedUser',
    '$timeout',
    'Auth',

    function(
      $scope,
      $state,
      $controller,
      UserCrud,
      ContributorModel,
      ContributorStore,
      $stateParams,
      Dialog,
      LoggedUser,
      $timeout,
      Auth
    ) {
      $controller('EditBaseCtrl', {
        $scope: $scope,
        crud: new UserCrud($scope),
        object: ContributorModel,
        packageName: 'contributor',
        dataName: 'contributor',
        formName: 'contributorForm'
      });

      ContributorStore.clear().addListener('save', function(data) {
        if (typeof data === 'object') {
          angular.extend($scope.contributor, data);
        }

        $scope.processSaving().then(function(response) {
          ContributorStore.emitChange(response);
        });
      });

      if ($stateParams.id) {
        $scope.processLoading().then(function(response) {
          ContributorStore.emitChange(response);
          if (
            $scope.contributor &&
            $scope.contributor.rating &&
            $scope.contributor.rating.alert &&
            LoggedUser.isPM()
          ) {
            var dialog = new Dialog();
            dialog.info($scope.contributor.rating.alert);
          }
        });
      } else {
        ContributorStore.emitChange({});
      }

      $scope.performUndo = function() {
        $scope.undo();
        ContributorStore.emitChange($scope.contributor);
      };

      $scope.save = function() {
        ContributorStore.emit('save');
      };

      $scope.$on('onCreateAccount', function(e, userId, email) {
        Object.assign($scope.contributor, {
          userId: userId,
          email: email,
          complete: true,
          percentage: 100,
          complete_email_sent: true
        });

        $scope.processSaving({ skipValidation: true }).then(function(response) {
          ContributorStore.emitChange(response);
          if (LoggedUser.isContributor()) {
            $timeout(() => {
              Auth.logout().then(() => $scope.$emit('goToLogin'));
            }, 100);
          }
        });
      });
    }
  ]);
