angular
  .module('company')

  .directive('paymentConditionsList', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      replace: true,
      scope: {
        payments: '=ngModel',
        lang: '<',
        editable: '='
      },
      templateUrl: 'custom/company/views/partials/paymentConditionsList.html',
      link: function(scope) {
        scope.add = function() {
          scope.payments = scope.payments || [];
          scope.payments.push({});
        };

        scope.delete = function(index) {
          scope.payments.splice(index, 1);
        };
      }
    };
  });
