angular
  .module('pb.user')

  .factory('UserLang', [
    '$translate',
    '$window',

    function($translate, $window) {
      function get() {
        return localStorage.getItem('@@localStoragePrefix' + 'lang');
      }

      function set(lang, reload) {
        $translate.use(lang);
        localStorage.setItem('@@localStoragePrefix' + 'lang', lang);

        if (reload === true) {
          $window.location.reload();
        }
      }

      return {
        get: get,
        set: set
      };
    }
  ]);
