angular
  .module('workflow')

  .factory('WorkflowStore', [
    'Store',
    'ProjectStore',
    '$state',
    'LoggedUser',

    function(Store, ProjectStore, $state, LoggedUser) {
      'use strict';

      class WorkflowStore extends Store {
        clear() {
          this.projectCode = null;
          return super.clear();
        }

        addProjectStore(projectCode) {
          this.projectCode = projectCode;
          this.clearChildren().addChild(ProjectStore);
          return this;
        }

        clearProjectStore() {
          this.projectCode = null;
        }

        emitChange(e) {
          super.emitChange(e);

          if (this.projectCode && this.projectCode.length > 0) {
            let projects = e.projects || [];

            let project = projects.find(p => {
              return p && p.code === this.projectCode;
            });

            if (!project) {
              return $state.go('root.404');
            }

            ProjectStore.emitChange(project);
          }
        }

        isEditable() {
          var user = LoggedUser.getData(),
            userType = user && user.type,
            event = this.event || {},
            quoteStatus = event['quote'] ? event['quote'].status : 'draft',
            orderStatus = event['order'] ? event['order'].status : 'draft';

          if (
            quoteStatus === 'cancelled' ||
            orderStatus === 'cancelled' ||
            quoteStatus === 'canceled' ||
            orderStatus === 'canceled'
          ) {
            return false;
          }

          return (
            userType !== 'customer' ||
            quoteStatus === 'draft' ||
            orderStatus === 'draft'
          );
        }

        getOrderStatus() {
          var event = this.event || {};

          return event['order'] ? event['order'].status : 'draft';
        }

        getOrderId() {
          var event = this.event || {};
          return event.id;
        }

        getCustomerBlacklist() {
          var event = this.event || {};
          return event.customer && event.customer.blacklist;
        }
      }

      return new WorkflowStore();
    }
  ]);
