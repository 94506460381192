angular
  .module('pb.base')

  .directive('voidCard', function() {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: 'modules/base/views/partials/voidCard.html',
      scope: {
        resource: '@'
      }
    };
  });
