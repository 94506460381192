angular
  .module('workflow')

  .directive('tasksList', function() {
    return {
      restrict: 'E',
      templateUrl: 'custom/workflow/views/partials/tasksList.html',
      controller: 'WorkflowTasksListCtrl'
    };
  });
