/* global bootstrap-growl */

angular
  .module('pb.base')

  .factory('ToastNotification', [
    '$rootScope',

    function($rootScope) {
      function notify(notification) {
        $.notify(
          {
            title: notification.title,
            message: notification.message
          },
          {
            element: 'body',
            type: notification.type,
            allow_dismiss: true,
            placement: {
              from: 'top',
              align: 'left'
            },
            offset: {
              x: 20,
              y: 85
            },
            spacing: 10,
            delay: 2500,
            timer: 1000,
            mouse_over: 'pause',
            icon_type: 'class',
            template:
              '<div data-notify="container" class="alert alert-dismissable alert-{0}" role="alert">' +
              '<button type="button" class="close" data-notify="dismiss">' +
              '<span aria-hidden="true">&times;</span>' +
              '<span class="sr-only">Close</span>' +
              '</button>' +
              '<span data-notify="icon"></span>' +
              '<span data-notify="title">{1}</span>' +
              '<span data-notify="message">{2}</span>' +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              '</div>'
          }
        );
      }

      $rootScope.$on('onToastNotification', function(event, notification) {
        notify(notification);
      });

      return this;
    }
  ])

  .run(['ToastNotification', function(ToastNotification) {}]);
