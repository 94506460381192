angular
  .module('admin', ['pb.core', 'components'])

  .run([
    '$filter',
    'Module',
    function($filter, Module) {
      var module = new Module();

      module.push({
        menu: {
          name: 'admin',
          title: $filter('translate')('admin.ADMINS'),
          icon: 'zmdi zmdi-accounts-list',
          link: 'root.app.simple.admins'
        }
      });
    }
  ]);
