angular
  .module('components')

  .directive('selectDistrict', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        district: '=',
        state: '=',
        country: '=',
        inputClass: '@',
        showLabel: '@'
      },
      templateUrl: 'custom/components/views/partials/selectDistrict.html',
      controller: [
        '$scope',
        '$controller',
        '$timeout',
        'DistrictCrud',
        function($scope, $controller, $timeout, DistrictCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new DistrictCrud(),
            packageName: 'districts',
            dataName: 'districts',
            filters: {}
          });

          $scope
            .loadList({ country_code: '@@it_country_code' })
            .then(function(response) {
              $scope.districtsIT = response;
            });
        }
      ],
      link: function(scope, element, attrs) {
        var firstLoading = true;

        scope.showSelect = false;
        scope.districts = [];

        scope.$watch('country.code', function(newValue) {
          if (firstLoading && !newValue) {
            return;
          }

          firstLoading = false;

          if (newValue === '@@it_country_code') {
            scope.showSelect = true;
            scope.districts = scope.districtsIT;
            scope.state = '';
          } else {
            scope.showSelect = false;
            scope.districts = [];
            scope.district = 'EE';
          }
        });
      }
    };
  });
