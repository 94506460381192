angular
  .module('contributor')

  .directive('experiences', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        experiences: '=ngModel'
      },
      templateUrl: 'custom/contributor/views/partials/experiences.html',
      controller: [
        '$scope',
        function($scope) {
          $scope.addExperience = function() {
            var experience = {
              work: '',
              place: '',
              start: '',
              end: ''
            };

            $scope.experiences = $scope.experiences || [];
            $scope.experiences.push(experience);
          };

          $scope.deleteExperience = function(index) {
            $scope.experiences.splice(index, 1);
          };

          $scope.setCurrent = experience => delete experience.end;
        }
      ]
    };
  });
