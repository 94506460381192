angular
  .module('quote')

  .factory('QuoteConfirmRequest', [
    'Crud',

    function(Crud) {
      'use strict';

      class QuoteConfirmRequest extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/quote-confirm-request/:id', { id: '@id' });
        }
      }

      return QuoteConfirmRequest;
    }
  ]);
