angular
  .module('pb.core.notifications')

  .factory('Alert', [
    '$rootScope',
    'Notification',

    function($rootScope, Notification) {
      'use strict';

      /**
       * Alert class
       * @param params
       * @constructor
       */
      class Alert extends Notification {
        /**
         * Broadcast event on notifications change
         * @private
         */
        _broadcast(notification) {
          $rootScope.$broadcast('onAlertNotification', notification);
        }
      }

      return Alert;
    }
  ]);
