angular
  .module('pb.base')

  .directive('checkLabel', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      template:
        "<div><i class=\"zmdi zmdi-hc-fw zmdi-{{(checked || value) === true ? 'check c-green' : 'close c-red'}}\"></i></div>",
      scope: {
        checked: '<',
        value: '=ngModel'
      }
    };
  });
