angular
  .module('workflow')

  .factory('WorkflowCompanyModel', function() {
    return (company = {}) => {
      const {
        _id,
        account_manager,
        acquisition_source,
        admin_manager,
        project_manager,
        company_name,
        company_name2,
        tax_code,
        vat,
        legal_address,
        billingCompanies,
        settings,
        code,
        conditionsOfPayment,
        conditionsOfPaymentList,
        headquarters,
        services,
        documents,
        lists,
        expired,
        styleguide
      } = company;

      return {
        _id,
        account_manager,
        acquisition_source,
        admin_manager,
        project_manager,
        company_name,
        company_name2,
        tax_code,
        vat,
        legal_address,
        billingCompanies,
        settings,
        code,
        conditionsOfPayment,
        conditionsOfPaymentList,
        headquarters,
        services,
        documents,
        lists,
        expired,
        styleguide
      };
    };
  });
