angular
  .module('components')

  .component('progressBar', {
    bindings: {
      value: '=',
      class: '@'
    },
    template: [
      '<div class="row">',
      '<div class="col-xs-6 col-xs-offset-2">',
      '<div class="progress" ng-class="!$ctrl.value || $ctrl.value === 0 ? \'value-0\' : \'\'">',
      '<div class="progress-bar {{$ctrl.class}}" role="progressbar" aria-valuenow="{{$ctrl.value || 0}}" aria-valuemin="0" aria-valuemax="100" style="width: {{$ctrl.value || 0}}%">',
      '<span class="sr-only">{{$ctrl.value || 0}}% Complete (success)</span>',
      '</div>',
      '</div>',
      '</div>',
      '<div class="col-xs-4">{{($ctrl.value || 0) | number:0}}%</div>',
      '</div>'
    ].join('')
  });
