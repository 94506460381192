'use strict';

angular
  .module('workflow')

  .factory('CleanWorkflow', [
    'CleanProject',
    function(CleanProject) {
      return (selectedWorkflow, templateType) => {
        if (!selectedWorkflow) return;

        const workflow = angular.copy(selectedWorkflow);

        delete workflow._id;
        delete workflow.id;
        delete workflow.complete;
        delete workflow.completed_at;
        delete workflow.n_order;
        delete workflow.delivery_date;
        delete workflow.delivery_date_effective;
        delete workflow.done;
        delete workflow.order_num;
        delete workflow.percentage;
        delete workflow.workflow_num;
        delete workflow.dataconfirm_request_sent_on;
        delete workflow.status;
        delete workflow.workflow_company_num;
        delete workflow.is24h;
        delete workflow.isDuplicated;
        delete workflow.isTemplate;
        delete workflow.forcedContributorsMailsSentAt;
        delete workflow.orderSentAt;
        delete workflow.DataFT;
        delete workflow.importoLordoFT;
        delete workflow.importoLordoValutaFT;
        delete workflow.importoNettoFT;
        delete workflow.importoNettoValutaFT;
        delete workflow.numeroFT;
        delete workflow.additionalInvoices;
        delete workflow.childrenOrders;
        delete workflow.childrenOrdersDiscount;
        delete workflow.parentOrder;
        workflow.created_at = new Date().toISOString();

        switch (templateType) {
          case '24h':
            workflow.is24h = true;
            break;
          case 'template':
            workflow.isTemplate = true;
            break;
          case 'duplicated':
            workflow.isDuplicated = true;
            break;
        }

        const { order = {}, quote = {} } = workflow;
        delete order.status;
        delete quote.status;

        const { projects = [] } = workflow;
        projects.forEach(CleanProject({ setEmptyDuplicatedFrom: true }));

        return workflow;
      };
    }
  ]);
