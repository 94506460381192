angular
  .module('company')

  .directive('companyPreview', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        company: '=ngModel'
      },
      templateUrl: 'custom/company/views/partials/companyPreview.html'
    };
  });
