angular
  .module('components')

  .factory('GetDefaultPdfTemplate', [
    'PdfCrud',

    function(PdfCrud) {
      function GetDefaultPdfTemplate() {
        const pdfCrud = new PdfCrud();
        const filters = { is_default: true };
        return pdfCrud.query({ filters: filters });
      }

      return GetDefaultPdfTemplate;
    }
  ]);
