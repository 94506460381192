angular
  .module('pb.file')

  .service('S3Deleter', [
    '$q',

    function($q) {
      var accessKeyId = '@@aws_access_key',
        secretAccessKey = '@@aws_secret_key',
        bucket = '@@aws_bucket',
        region = '@@aws_region';

      AWS.config.update({
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
        region: region
      });

      var s3 = new AWS.S3();

      this.delete = function(file) {
        var deferred = $q.defer();

        var params = {
          Bucket: bucket,
          Key: typeof file === 'object' ? file.key : file
        };

        s3.deleteObject(params, function(err) {
          err ? deferred.reject(err) : deferred.resolve();
        });

        return deferred.promise;
      };
    }
  ]);
