angular
  .module('pb.file')

  .service('S3Uploader', [
    '$q',

    function($q) {
      'use strict';

      var sizeLimit = parseInt('@@aws_sizeLimit'),
        accessKeyId = '@@aws_access_key',
        secretAccessKey = '@@aws_secret_key',
        bucket = '@@aws_bucket',
        region = '@@aws_region',
        encryption = '@@aws_encryption';

      AWS.config.update({
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
        region: region
      });

      var s3 = new AWS.S3();

      this.upload = function(
        file,
        key,
        progressCallback,
        alternativeBucket,
        ACL
      ) {
        var deferred = $q.defer();
        var fileSize = Math.round(parseInt(file.size));
        var callback =
          typeof progressCallback === 'function'
            ? progressCallback
            : new Function();

        if (fileSize > sizeLimit) {
          //Size exceeds the limit
          deferred.reject(
            $filter('translate')('file.ERROR_DIMENSION') +
              Math.round(sizeLimit / 1024 / 1024) +
              ' MB'
          );
        } else {
          const bucketParam = alternativeBucket || bucket;

          let params = {
            Bucket: bucketParam,
            Key: key,
            ContentType: file.type,
            Body: file,
            ServerSideEncryption: encryption
          };

          if (ACL) params.ACL = ACL;

          s3.putObject(params, function(err) {
            err
              ? deferred.reject(err)
              : deferred.resolve({
                  region: region,
                  bucket: bucketParam,
                  key: key,
                  size: fileSize,
                  type: file.type,
                  encryption: encryption
                });
          }).on('httpUploadProgress', function(progress) {
            callback(Math.round((progress.loaded / progress.total) * 100));
          });
        }

        return deferred.promise;
      };
    }
  ]);
