angular
  .module('contributor')

  .directive('checkboxSectorDocument', [
    '$filter',
    function($filter) {
      return {
        restrict: 'E',
        scope: {
          specialization: '=ngModel'
        },
        templateUrl:
          'custom/contributor/views/partials/checkboxSectorDocument.html',
        controller: [
          '$scope',
          '$controller',
          'DocumentCrud',
          function($scope, $controller, DocumentCrud) {
            const { specialization = {} } = $scope;
            const { macrosector = {} } = specialization;

            $controller('ListCtrl', {
              $scope: $scope,
              crud: new DocumentCrud('-list'),
              packageName: 'document',
              dataName: 'documents',
              filters: {
                obsolete: false,
                parent: macrosector._id
              }
            });

            $scope.loadList().then(function(response) {
              function setSelected(document) {
                var array =
                  document.type === 'sector'
                    ? $scope.specialization.sectors
                    : $scope.specialization.documents;

                if (!Array.isArray(array)) {
                  return;
                }

                for (var i = 0; i < array.length; i += 1) {
                  if (array[i]._id === document._id) {
                    document.selected = true;
                    return;
                  }
                }
              }

              angular.forEach(response, function(document) {
                setSelected(document);
              });
            });

            $scope.toggleDocument = function(document) {
              var array =
                document.type === 'sector'
                  ? $scope.specialization.sectors
                  : $scope.specialization.documents;

              if (document.selected && array.indexOf(document) < 0) {
                array.push(document);
              } else if (!document.selected) {
                for (var i = 0; i < array.length; i += 1) {
                  if (array[i]._id === document._id) {
                    array.splice(i, 1);
                  }
                }
              }
            };
          }
        ]
      };
    }
  ]);
