angular
  .module('company')

  .factory('ZohoCompanyBulkSync', [
    'Crud',

    function(Crud) {
      'use strict';

      class ZohoCompanyBulkSync extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/accounts-bulk-sync', {});
        }
      }

      return ZohoCompanyBulkSync;
    }
  ]);
