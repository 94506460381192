angular.module('home').directive('homeLineChart', [
  '$controller',
  '$filter',
  'QuoteLastDaysCrud',
  'OrderLastDaysCrud',
  function($controller, $filter, QuoteLastDaysCrud, OrderLastDaysCrud) {
    return {
      restrict: 'E',
      templateUrl: 'custom/home/views/partials/homeLineChart.html',
      scope: false,
      link: function(scope) {
        scope.none = true;

        $controller('ListCtrl', {
          $scope: scope,
          crud: new QuoteLastDaysCrud(),
          packageName: 'lastQuotes',
          dataName: 'data',
          filters: {}
        });

        scope.loadList().then(function(response) {
          scope.quotesData = response;
          if (scope.none) {
            scope.none = !scope.quotesData.some(function(element) {
              return element.counter;
            });
          }
        });

        $controller('ListCtrl', {
          $scope: scope,
          crud: new OrderLastDaysCrud(),
          packageName: 'lastOrders',
          dataName: 'data',
          filters: {}
        });

        scope.loadList().then(function(response) {
          scope.ordersData = response;
          if (scope.none) {
            scope.none = !scope.ordersData.some(function(element) {
              return element.counter;
            });
          }
        });
      }
    };
  }
]);
