angular
  .module('components')

  .directive('cardHeaderCollapse', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        isCollapsed: '=',
        title: '@'
      },
      template: [
        '<div class="card-header" ng-click="isCollapsed = !isCollapsed">',
        '<h2>{{title}}</h2>',
        '<ul class="actions">',
        '<li>',
        '<a tooltip="{{isCollapsed ? \'EXPAND\' : \'CLOSE\' | translate}}" tooltip-placement="top" tooltip-popup-delay="1000">',
        '<i class="zmdi zmdi-hc-fw" ng-class="{\'zmdi-chevron-up\': !isCollapsed, \'zmdi-chevron-down\' : isCollapsed}"></i>',
        '</a>',
        '</li>',
        '</ul>',
        '</div>'
      ].join('')
    };
  });
