angular
  .module('company')

  .factory('CompanyActivation', [
    'Crud',

    function(Crud) {
      'use strict';

      class CompanyActivation extends Crud {
        constructor() {
          super('company_activation', '@@apiUrl' + '/activecompany/:id', {
            id: '@id'
          });
        }
      }

      return CompanyActivation;
    }
  ]);
