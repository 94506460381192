angular
  .module('workflow')

  .factory('ProjectStartCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class ProjectStartCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/start-project' + '/:id', { id: '@id' });
        }
      }
      return ProjectStartCrud;
    }
  ]);
