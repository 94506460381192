angular
  .module('company')

  .config([
    '$stateProvider',

    function($stateProvider) {
      var path = 'custom/company/views/';

      $stateProvider
        .state('root.app.simple.companies', {
          url: '/companies',
          params: {
            associating: null,
            billingId: null,
            units: null,
            task: null,
            allLists: null
          },
          views: {
            header: {
              templateUrl: path + 'list-header.html',
              controller: 'CompanyHeaderCtrl'
            },
            content: {
              templateUrl: path + 'list.html',
              controller: 'CompanyListCtrl'
            }
          },
          permissions: {
            resources: { companies: ['read'] }
          }
        })

        .state('root.app.simple.company', {
          url: '/company/:id',
          params: {
            associating: null,
            billingId: null,
            units: null,
            task: null,
            allLists: null
          },
          views: {
            header: {
              templateUrl: path + 'edit-header.html'
            },
            content: {
              templateUrl: path + 'edit.html',
              controller: 'CompanyEditCtrl'
            }
          },
          permissions: {
            resources: { companies: ['read'] }
          }
        })

        .state('root.app.simple.company.about', {
          url: '/about',
          templateUrl: path + 'tab-about.html',
          controller: 'CompanyTabCtrl',
          permissions: {
            resources: { companies: ['read'] }
          }
        })

        .state('root.app.simple.company.activities', {
          url: '/activities',
          templateUrl: path + 'tab-activities.html',
          controller: 'CompanyTabCtrl',
          permissions: {
            resources: { companies: ['read'] }
          }
        })

        .state('root.app.simple.company.documents', {
          url: '/documents',
          templateUrl: path + 'tab-documents.html',
          controller: 'CompanyTabCtrl',
          permissions: {
            resources: { companies: ['read'] }
          }
        })

        .state('root.app.simple.company.frameworkAgreement', {
          url: '/agreement',
          templateUrl: path + 'tab-agreement.html',
          controller: 'CompanyTabCtrl',
          permissions: { companies: ['read'] }
        })

        .state('root.app.simple.company.headquarters', {
          url: '/headquarters',
          templateUrl: path + 'tab-headquarters.html',
          controller: 'CompanyTabCtrl',
          permissions: {
            resources: { companies: ['read'] }
          }
        })

        .state('root.app.simple.company.users', {
          url: '/users',
          templateUrl: path + 'tab-users.html',
          controller: 'CompanyUsersCtrl',
          permissions: {
            resources: { companies: ['read'] }
          }
        })

        .state('root.app.simple.company.projects24h', {
          url: '/projects-24h',
          templateUrl: path + 'tab-projects-24h.html',
          controller: 'CompanyProjects24hCtrl',
          permissions: {
            resources: { companies: ['read'] }
          }
        })

        .state('root.app.simple.company.projectTemplates', {
          url: '/project-templates',
          templateUrl: path + 'tab-project-templates.html',
          controller: 'CompanyProjectTemplatesCtrl',
          permissions: {
            resources: { companies: ['read'] }
          }
        })

        .state('root.app.simple.company.billingCompanies', {
          url: '/billing-companies',
          templateUrl: path + 'tab-billing-companies.html',
          controller: 'CompanyTabCtrl',
          permissions: {
            resources: { companies: ['read'] }
          }
        })

        .state('root.app.simple.company.associated', {
          url: '/associated',
          templateUrl: path + 'tab-companies-associated.html',
          controller: 'CompaniesAssociatedTabCtrl',
          permissions: {
            resources: { companies: ['read'] }
          }
        })

        .state('root.app.simple.company.services', {
          url: '/services',
          templateUrl: path + 'tab-services.html',
          controller: 'CompanyTabCtrl',
          permissions: {
            resources: { companies: ['read'] }
          }
        })

        .state('root.app.simple.company.lists', {
          url: '/lists',
          templateUrl: path + 'tab-lists.html',
          controller: 'CompanyTabCtrl',
          permissions: {
            resources: { companies: ['read'] }
          }
        })

        .state('root.app.simple.company.trados', {
          url: '/trados',
          templateUrl: path + 'tab-trados.html',
          controller: 'CompanyTabCtrl',
          permissions: {
            resources: { companies: ['read'] }
          }
        })

        .state('root.app.simple.company.paymentConditions', {
          url: '/payment',
          templateUrl: path + 'tab-payment.html',
          controller: 'CompanyTabCtrl',
          permissions: {
            resources: { companies: ['read'] }
          }
        })

        .state('root.app.simple.company.usedContributors', {
          url: '/contributors',
          templateUrl: path + 'tab-contributors.html',
          controller: 'UsedContributorsCtrl',
          permissions: {
            resources: { companies: ['read'] }
          }
        })

        .state('root.app.simple.create-company', {
          url: '/create-company',
          views: {
            header: {
              templateUrl: path + 'create-header.html'
            },
            content: {
              templateUrl: path + 'create.html',
              controller: 'CompanyCreateCtrl'
            }
          },
          permissions: {
            resources: { companies: ['read'] }
          }
        });
    }
  ]);
