angular
  .module('pb.base')

  .factory('AuthLogin', [
    '$resource',
    'Crud',

    function($resource, Crud) {
      var AuthLogin = function() {
        this.resource = $resource('@@authUrl' + '/login-v2?appId=' + '@@appId');
      };

      AuthLogin.prototype = new Crud();

      return AuthLogin;
    }
  ]);
