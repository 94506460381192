angular
  .module('task')

  .controller('TaskListCtrl', [
    '$scope',
    '$controller',
    '$timeout',
    '$filter',
    '$modal',
    '$state',
    '$stateParams',
    'Confirm',
    'ProjectFilesUtils',
    'TaskContributorCrud',
    'TaskProjectCrud',
    'TaskStatusCrud',
    'TaskDoneCrud',
    'TasksInvoicedCrud',
    'TasksInvoicedUndoCrud',
    'Toast',
    'ErrorsManager',
    'Dialog',
    'LoggedUser',
    'S3Uploader',
    'cfpLoadingBar',
    'UserLang',
    'ContributorAgreementsCrud',

    function(
      $scope,
      $controller,
      $timeout,
      $filter,
      $modal,
      $state,
      $stateParams,
      Confirm,
      ProjectFilesUtils,
      TaskContributorCrud,
      TaskProjectCrud,
      TaskStatusCrud,
      TaskDoneCrud,
      TasksInvoicedCrud,
      TasksInvoicedUndoCrud,
      Toast,
      ErrorsManager,
      Dialog,
      LoggedUser,
      S3Uploader,
      cfpLoadingBar,
      UserLang,
      ContributorAgreementsCrud
    ) {
      $scope.userLang = UserLang.get() || 'it';
      $scope.skipInitialTableLoading = true;
      const toast = new Toast();
      const dialog = new Dialog();
      $scope.isPM = LoggedUser.isPM();
      const { contributor } = $stateParams;

      $scope.breadCrumbTasks = $filter('translate')('task.TASKS');

      const filters = {};

      if ($scope.isPM && contributor) {
        const { _id } = ($scope.contributor = contributor);
        filters.contributor = _id;
      }

      $scope.taskContributorCrud = new TaskContributorCrud();

      $controller('DataTableCtrl', {
        $scope: $scope,
        crud: $scope.taskContributorCrud,
        packageName: 'task',
        tableParams: 'taskList',
        filters,
        parser: null
      });

      $scope.reject = task => {
        const { uuid } = task;
        $scope.lastOpenedTaskUuid = uuid;
        const modalOptions = {
          animation: true,
          templateUrl: 'custom/tasks/views/partials/rejectTask.html',
          controller: 'RejectTaskCtrl',
          size: 'lg',
          resolve: { task: () => task }
        };

        $modal.open(modalOptions).result.then(task => {
          $scope.task = task;
          const { contributor_status } = task;
          const nextStatus =
            contributor_status === 'approved' ? 'not_confirmed' : 'notapproved';
          $scope.update(TaskStatusCrud, task, nextStatus);
        });
      };

      $scope.approved = (task = {}) => {
        const { contributor_status, uuid } = task;
        $scope.lastOpenedTaskUuid = uuid;
        const isFinalConfirm = contributor_status === 'approved'; // Contributor has already approved one first time.

        if (isFinalConfirm) {
          const nextStatus = 'confirmed';
          const modalOptions = {
            animation: true,
            templateUrl: 'custom/tasks/views/partials/confirmTask.html',
            controller: 'ConfirmTaskCtrl',
            size: 'lg',
            resolve: { task: () => task }
          };
          $modal.open(modalOptions).result.then(() => {
            $scope.update(TaskStatusCrud, task, nextStatus);

            // Workaround to prevent unexpected type coercion by gulp uglify task
            const [
              contributorConditionsVersion,
              contributorLegalAgreementVersion
            ] = [
              '@@contributor_conditions_v',
              '@@contributor_legal_agreement_v'
            ].map(item => parseInt(item));

            new ContributorAgreementsCrud()
              .update({
                contributorConditionsVersion,
                contributorLegalAgreementVersion
              })
              .catch(e => console.error(e));
          });
        } else {
          const nextStatus = 'approved';
          const confirmButtonText = $filter('translate')('quote.APPROVE');
          const confirm = new Confirm(confirmButtonText);
          const message = $filter('translate')('task.CONTRIBUTOR_APPROVE_MSG');
          confirm
            .show(message)
            .then(() => $scope.update(TaskStatusCrud, task, nextStatus));
        }
      };

      $scope.done = function(task) {
        const { uuid, projectUuid, batchUuid } = task || {};

        const confirm = new Confirm();
        confirm
          .show($filter('translate')('task.DONE_MSG'))
          .then(() =>
            $scope.update(TaskDoneCrud, { uuid, projectUuid, batchUuid })
          );
      };

      $scope.update = function(crud, task, status) {
        const { uuid: _id, projectUuid, batchUuid, rejection } = task || {};
        const params = { _id, projectUuid, batchUuid, rejection, status };

        if ($scope.isPM && contributor) {
          params.contributor = contributor._id;
        }

        new crud()
          .update(params)
          .then(() => {
            $scope.$broadcast('reloadCounters.task');
            $timeout(
              () => toast.success($filter('translate')('crud.SAVED')),
              100
            );
          })
          .catch(_error => {
            Sentry.captureException(_error);
            let error = ErrorsManager.getErrorsAsString(_error);

            const { status, message } = _error || {};
            if (status === 400 && message === 'task_requires_file_error') {
              error = $filter('translate')('error.TASK_REQUIRES_FILE_ERROR');
            }

            $timeout(() => dialog.error(error), 100);
          });
      };

      const hideDownload = (task = {}) => {
        const { contributor_status = '' } = task;
        const hideWhen = ['notapproved', 'not_confirmed'];
        return hideWhen.includes(contributor_status);
      };

      $scope.detail = (_task = {}) => {
        const { uuid, workflowId } = _task;
        $scope.lastOpenedTaskUuid = uuid;
        let task;
        const modalTemplate = 'custom/workflow/views/partials/previewTask.html';
        const taskProjectCrud = new TaskProjectCrud();
        const openModal = function(taskIndex, batchIndex, isNew, delivered) {
          $scope.targetString = '';
          $scope.isNew = isNew;

          const modalInstance = $modal.open({
            animation: true,
            templateUrl: modalTemplate,
            controller: 'TaskModalInstanceCtrl',
            scope: $scope,
            size: 'lg',
            resolve: {
              task: () => task,
              company: () => $scope.company,
              delivered: () => delivered,
              hideDownload: () => hideDownload(_task)
            }
          });

          modalInstance.result.then(({ reload } = {}) => {
            reload &&
              $timeout(() => {
                const { currentCall } = $scope.taskContributorCrud;
                currentCall && currentCall.$cancelRequest();
                $scope.reload($scope.filters);
              }, 1000);
          });
        };

        const params = { uuid, workflowId };

        if ($scope.isPM && contributor) {
          params.contributor = contributor._id;
        }

        taskProjectCrud.get(params).then((res = {}) => {
          const {
            batchUuid,
            created_at,
            duplicatedFrom,
            notes,
            projectUuid,
            projectCode,
            project_manager,
            source = [],
            target = [],
            task: t = {},
            title,
            workflowId,
            taskIndex,
            previousTaskName,
            previousTaskUuid,
            workflow_delivery_date,
            styleguide,
            humanProjectPath,
            humanTaskPath
          } = res;
          const targetList = target[0] || [];
          task = Object.assign(t, {
            notes,
            batchUuid,
            duplicatedFrom,
            created_at,
            delivery_date: t.delivery_date,
            source: source[0],
            target: targetList[0],
            projectUuid,
            projectCode,
            project_manager,
            title,
            workflowId,
            taskIndex,
            previousTaskName,
            previousTaskUuid,
            workflow_delivery_date,
            styleguide: (styleguide || '').replace(/\n/g, '<br>'),
            humanProjectPath,
            humanTaskPath
          });
          const sourceProject = {
            code: (duplicatedFrom && duplicatedFrom.code) || projectCode
          };
          const targetProject = {
            code: projectCode
          };
          $scope.prefixSourceFiles = ProjectFilesUtils.getSourcePath(
            { _id: workflowId },
            sourceProject
          );
          $scope.prefixTargetFiles = ProjectFilesUtils.getTargetPath(
            { _id: workflowId },
            targetProject
          );
          openModal();
        });
      };

      let selectAllValue = true;
      $scope.selectAll = () => {
        const { taskList: { data = [] } = {} } = $scope;
        data.forEach(task => (task.checked = selectAllValue));
        selectAllValue = !selectAllValue;
      };

      const uploadInvoice = changeEvent => {
        $timeout(() => {
          const inputInvoiceElement = document.getElementById('inputInvoice');
          inputInvoiceElement.removeEventListener('change', uploadInvoice);

          const target = changeEvent && changeEvent.target;
          const file = target && target.files && target.files[0];
          changeEvent.target.value = '';
          const { name = '' } = file || {};

          if (name.split('.').pop() !== 'pdf') {
            delete $scope.tasksToInvoice;
            return new Dialog().error(
              $filter('translate')('task.INVOICE_INVALID_FILE_FORMAT')
            );
          }

          let { _id: userId } = LoggedUser.getData() || {};
          if ($scope.isPM && $scope.contributor)
            userId = $scope.contributor._id;

          const bucket = '@@aws_public_bucket';
          const bucketPath = `contributors/${userId}/invoices/${Date.now()}.pdf`;
          const progressCallback = () => {};
          const ACL = 'public-read';
          cfpLoadingBar.start();

          S3Uploader.upload(file, bucketPath, progressCallback, bucket, ACL)
            .then(uploadResults => {
              cfpLoadingBar.complete();
              return completeTasksBilling(uploadResults);
            })
            .catch(err => {
              cfpLoadingBar.complete();
              Sentry.captureException(err);
              const { message } = err || {};
              $timeout(() => new Dialog().error(message || err), 250);
            });
        }, 100);
      };

      const inputInvoice = () =>
        $timeout(() => {
          const inputInvoiceElement = document.getElementById('inputInvoice');
          inputInvoiceElement.addEventListener('change', uploadInvoice, false);
          inputInvoiceElement.click();
        });

      const completeTasksBilling = invoiceData => {
        const tasks = $scope.tasksToInvoice.map((task = {}) => {
          const { batchUuid, projectUuid, uuid: taskUuid, workflowId } = task;
          return { batchUuid, projectUuid, taskUuid, workflowId };
        });
        const body = { invoiceData, tasks };

        if ($scope.isPM && $scope.contributor)
          body.contributor = $scope.contributor._id;

        return new TasksInvoicedCrud()
          .save(body)
          .then(() => {
            $scope.tasksToInvoice.forEach(task => (task.invoiced = true));
            $scope.$broadcast('reloadCounters.task');
          })
          .catch(err => {
            Sentry.captureException(err);
            const { message } = err || {};
            $timeout(() => new Dialog().error(message || err), 250);
          })
          .then(() => delete $scope.tasksToInvoice);
      };

      $scope.invoiceTasks = () => {
        const { taskList: { data = [] } = {} } = $scope;
        $scope.tasksToInvoice = data.filter(({ checked } = {}) => checked);

        if (!$scope.tasksToInvoice.length) {
          delete $scope.tasksToInvoice;
          return new Dialog().error(
            $filter('translate')('task.INVOICE_SELECTED_TASKS_NO_TASK_ERR')
          );
        }

        if (!$scope.tasksToInvoice.every(({ done }) => done)) {
          delete $scope.tasksToInvoice;
          return new Dialog().error(
            $filter('translate')('task.INVOICE_SELECTED_TASKS_NOT_DONE_ERR')
          );
        }

        if ($scope.tasksToInvoice.some(({ invoiced }) => invoiced)) {
          delete $scope.tasksToInvoice;
          return new Dialog().error(
            $filter('translate')(
              'task.INVOICE_SELECTED_TASKS_ALREADY_INVOICED_ERR'
            )
          );
        }

        new Confirm()
          .show($filter('translate')('task.INVOICE_SELECTED_TASKS_MSG'))
          .then(() => inputInvoice())
          .catch(() => {});
      };

      $scope.undoInvoiceTasks = () => {
        if (!$scope.isPM || !$scope.contributor) return;

        const { taskList: { data = [] } = {} } = $scope;
        let tasksToUndo = data.filter(({ checked } = {}) => checked);

        if (!tasksToUndo.length) {
          return new Dialog().error(
            $filter('translate')('task.INVOICE_SELECTED_TASKS_NO_TASK_ERR')
          );
        }

        tasksToUndo = tasksToUndo.filter(({ invoiced } = {}) => invoiced);

        if (!tasksToUndo.length) {
          return new Dialog().error(
            $filter('translate')(
              'task.INVOICE_SELECTED_TASKS_NO_INVOICED_TASK_ERR'
            )
          );
        }

        new Confirm()
          .show($filter('translate')('task.INVOICE_SELECTED_TASKS_UNDO_MSG'))
          .then(() => {
            const tasks = tasksToUndo.map((task = {}) => {
              const {
                batchUuid,
                projectUuid,
                uuid: taskUuid,
                workflowId
              } = task;
              return { batchUuid, projectUuid, taskUuid, workflowId };
            });
            const body = { contributor: $scope.contributor._id, tasks };

            return new TasksInvoicedUndoCrud()
              .save(body)
              .then(() => {
                tasksToUndo.forEach(task => (task.invoiced = false));
                $scope.$broadcast('reloadCounters.task');
              })
              .catch(err => {
                Sentry.captureException(err);
                const { message } = err || {};
                $timeout(() => new Dialog().error(message || err), 250);
              });
          })
          .catch(() => {});
      };
    }
  ]);
