angular
  .module('pb.user')

  .controller('ProfileSwitchCtrl', [
    '$scope',
    '$state',
    'LoggedUser',

    function($scope, $state, LoggedUser) {
      var user = LoggedUser.get().get();
      var profile = { id: user._id };

      if (user.type === 'contributor') {
        $state.go('root.app.simple.contributor.about', profile);
      } else if (user.type === 'customer') {
        $state.go('root.app.simple.customer.about', profile);
      } else {
        $state.go('root.app.user-about', profile);
      }
    }
  ]);
