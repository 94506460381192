angular
  .module('library')

  .controller('LibraryHeaderCtrl', [
    '$scope',
    '$state',
    '$filter',
    '$stateParams',

    function($scope, $state, $filter, $stateParams) {
      var tables = [
        {
          key: 'acquisitionSources',
          value: $filter('translate')('library.ACQUISITION_SOURCES')
        },
        { key: 'activities', value: $filter('translate')('ACTIVITY') },
        {
          key: 'associations',
          value: $filter('translate')('contributors.ASSOCIATIONS')
        },
        {
          key: 'certifications',
          value: $filter('translate')('company.CERTIFICATIONS')
        },
        {
          key: 'contributorCertifications',
          value: $filter('translate')('contributors.CERTIFICATIONS')
        },
        {
          key: 'connections',
          value: $filter('translate')('contributors.CONNECTION')
        },
        { key: 'countries', value: $filter('translate')('form.NATION') },
        { key: 'costs', value: $filter('translate')('COSTS') },
        {
          key: 'destinationUseDocument',
          value: $filter('translate')('library.DESTINATION_USE_DOCUMENT')
        },
        { key: 'documents', value: $filter('translate')('DOCUMENTS') },
        {
          key: 'educations',
          value: $filter('translate')('contributors.EDUCATIONS')
        },
        { key: 'incomes', value: $filter('translate')('contributors.INCOME') },
        { key: 'isos', value: $filter('translate')('contributors.ISO_17100') },
        { key: 'jobroles', value: $filter('translate')('user.JOB_ROLE') },
        { key: 'langs', value: $filter('translate')('library.LANGS') },
        { key: 'oss', value: $filter('translate')('contributors.OS') },
        {
          key: 'payments',
          value: $filter('translate')('contributors.PAYMENT')
        },
        { key: 'pdf', value: $filter('translate')('task.OFFER') },
        { key: 'titles', value: $filter('translate')('user.TITLE') },
        { key: 'ratings', value: $filter('translate')('contributors.RATING') },
        { key: 'services', value: $filter('translate')('service.SERVICES') },
        { key: 'softwares', value: $filter('translate')('SOFTWARE') },
        { key: 'tasks', value: $filter('translate')('library.TASKS') },
        { key: 'units', value: $filter('translate')('UOM') },
        { key: 'vats', value: $filter('translate')('contributors.VAT') }
      ];

      $scope.tables = tables.sort(function(a, b) {
        var aValue = a.value.toLowerCase();
        var bValue = b.value.toLowerCase();

        if (aValue < bValue) {
          return -1;
        }
        if (aValue > bValue) {
          return 1;
        }
        return 0;
      });

      if ($stateParams.table) {
        for (var i = 0; i < $scope.tables.length; i += 1) {
          if ($scope.tables[i].key === $stateParams.table) {
            $scope.table = $scope.tables[i];
            break;
          }
        }
      }

      $scope.$watch('table', function(val, old) {
        if (val !== old) {
          $state.go('root.app.simple.libraries', { table: val.key });
        }
      });
    }
  ]);
