angular
  .module('task')

  .directive('amountSummary', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        tasks: '<'
      },
      templateUrl: 'custom/tasks/views/partials/amountSummary.html',
      controller: [
        '$scope',
        function($scope) {
          $scope.getTotal = () => {
            const { tasks } = $scope;
            if (!tasks) return 0;
            const { data } = $scope.tasks;
            if (!data || !Array.isArray(data)) return 0;
            return data
              .filter(({ checked }) => checked)
              .reduce(
                (acc, { price }) =>
                  acc + (typeof price === 'number' ? price : 0),
                0
              );
          };
        }
      ]
    };
  });
