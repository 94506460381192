angular
  .module('library')

  .config([
    '$stateProvider',

    function($stateProvider) {
      var path = 'custom/library/views/';

      $stateProvider
        .state('root.app.simple.libraries', {
          url: '/libraries/:table',
          views: {
            header: {
              templateUrl: path + 'header.html'
            },
            content: {
              templateUrl: path + 'tableList.html',
              controller: 'LibraryCtrl'
            }
          },
          permissions: {
            resources: { libraries: ['read'] }
          }
        })

        .state('root.app.simple.task-detail', {
          url: '/libraries/tasks/:id',
          views: {
            header: {
              templateUrl: path + 'headerTaskEdit.html'
            },
            content: {
              templateUrl: path + 'taskForm.html',
              controller: 'LibraryTaskEditCtrl'
            }
          },
          permissions: {
            resources: { libraries: ['read'] }
          }
        })

        .state('root.app.simple.task-create', {
          url: '/libraries/create/task',
          views: {
            header: {
              templateUrl: path + 'headerTaskCreate.html'
            },
            content: {
              templateUrl: path + 'taskForm.html',
              controller: 'LibraryTaskCreateCtrl'
            }
          },
          permissions: {
            resources: { libraries: ['read'] }
          }
        })

        .state('root.app.simple.pdf-create', {
          url: '/libraries/create/pdf',
          views: {
            header: {
              templateUrl: path + 'headerPdfCreate.html'
            },
            content: {
              templateUrl: path + 'pdfForm.html',
              controller: 'LibraryPdfCreateCtrl'
            }
          },
          permissions: {
            resources: { libraries: ['read'] }
          }
        })

        .state('root.app.simple.pdf-detail', {
          url: '/libraries/pdf/:id',
          views: {
            header: {
              templateUrl: path + 'headerPdfEdit.html'
            },
            content: {
              templateUrl: path + 'pdfForm.html',
              controller: 'LibraryPdfEditCtrl'
            }
          },
          permissions: {
            resources: { libraries: ['read'] }
          }
        });
    }
  ]);
