angular
  .module('pb.file')

  .controller('filesController', [
    '$scope',
    '$element',
    '$q',
    '$controller',
    '$filter',
    '$timeout',
    'Confirm',
    'Dialog',
    'Toast',
    'FilesCrud',
    'FileUploader',
    'FileDeleter',
    'FileDownloader',
    'UniqueFileName',
    'LoggedUser',
    'FilesZipDownload',

    function(
      $scope,
      $element,
      $q,
      $controller,
      $filter,
      $timeout,
      Confirm,
      Dialog,
      Toast,
      FilesCrud,
      FileUploader,
      FileDeleter,
      FileDownloader,
      UniqueFileName,
      LoggedUser,
      FilesZipDownload
    ) {
      var crud = new FilesCrud(),
        dialog = new Dialog(),
        confirm = new Confirm(),
        toast = new Toast();

      const { id: LoggedUserId } = LoggedUser.getData();

      $scope.enableListLoading =
        typeof $scope.enableListLoading === 'undefined'
          ? false
          : $scope.enableListLoading;
      $scope.uniqueFileName = false;
      $scope.showProgress = false;
      $scope.uploadProgress = 0;
      $scope.path = $scope.path || '';

      $controller('ListCtrl', {
        $scope: $scope,
        crud: crud,
        packageName: 'files',
        dataName: 'files',
        filters: {
          sort: { name: 1 }
        }
      });

      $controller('SaveBaseCtrl', {
        $scope: $scope,
        crud: crud,
        packageName: 'file',
        dataName: 'file',
        formName: null
      });

      function loadList(parentPath) {
        if ($scope.enableListLoading) {
          $scope.loadList({ parentPath: parentPath || $scope.path });
        }
      }

      $scope.$watch('path', function(newValue) {
        if (newValue && newValue.length > 0) {
          loadList(newValue);
        }
      });

      function checkExist(file) {
        if (!$scope.files || $scope.files.length === 0) {
          return false;
        }

        return !!$scope.files.find(function(f) {
          return f.name === file.name;
        });
      }

      function resetProgressBar(reload, delay) {
        $timeout(function() {
          $scope.showProgress = false;
          $scope.uploadProgress = 0;

          reload ? loadList() : null;
        }, delay || 1);
      }

      function upload(file, current, total) {
        var params = {
          path: $scope.path
        };

        current = current || 1;
        total = total || 1;
        $scope.showProgress = true;

        if (
          $scope.uniqueFileName === 'true' ||
          $scope.uniqueFileName === true
        ) {
          params.fileName = UniqueFileName(file);
        } else if ($scope.fileName && $scope.fileName.length > 0) {
          params.fileName = $scope.fileName;
        }

        const { files = [] } = $scope;
        const { data: firstFileData = {} } = files[0] || {};
        const { document, macrosector, sector } = firstFileData;
        const fileData = { uploadedBy: LoggedUserId };

        if (document) fileData.document = angular.copy(document);
        if (macrosector) fileData.macrosector = angular.copy(macrosector);
        if (sector) fileData.sector = angular.copy(sector);

        return FileUploader.upload(file, params, fileData, function(
          percentage
        ) {
          $timeout(function() {
            $scope.uploadProgress =
              (100 / total) * (current - 1) + percentage / total;
          });
        }).then(function(response) {
          $scope.$emit('onFileUploaded', response);
        });
      }

      function uploadFile(file, current, total) {
        var deferred = $q.defer();

        if (!checkExist(file)) {
          return upload(file, current, total);
        }

        confirm
          .info(
            $filter('translate')('file.FILE_ALREADY_EXISTS1') +
              '"' +
              (file.name || 'new_file') +
              '"' +
              $filter('translate')('file.FILE_ALREADY_EXISTS2')
          )
          .then(
            function() {
              upload(file, current, total).then(
                function(response) {
                  deferred.resolve(response);
                },
                function(err) {
                  deferred.reject(err);
                }
              );
            },
            function() {
              deferred.resolve();
            }
          );

        return deferred.promise;
      }

      function uploadFiles(files) {
        if (
          typeof files !== 'object' ||
          files.length === 0 ||
          $scope.editable === false ||
          $scope.editable === 'false'
        ) {
          return;
        }

        var i = 0,
          total = files.length;

        Object.keys(files)
          .map(key => files[key])
          .reduce(function(chain, file) {
            if (chain.then === undefined) {
              chain.resolve();
              chain = chain.promise;
            }

            return chain.then(function() {
              return uploadFile(file, (i += 1), total);
            });
          }, $q.defer())
          .then(
            function(response) {
              toast.info($filter('translate')('file.LOAD_COMPLETE'));

              const filesNumber = $scope.files.length + 1;
              $scope.$emit('onFilesUploaded', response, {
                type: $scope.type,
                filesNumber
              });

              $scope.uploaded = true;
              resetProgressBar(true);
            },
            function(err) {
              if (typeof err === 'object') {
                dialog.error(err.code, err.message);
              } else {
                dialog.error(err);
              }

              resetProgressBar(false, 1000);
            }
          );
      }

      //Upload files
      $element.find('input[type="file"]').bind('change', function(changeEvent) {
        $timeout(function() {
          //Trick to upload a single file
          var target = changeEvent.target;
          var isMultiple = $(target).attr('multiple') === 'multiple';
          var files = isMultiple ? target.files : { 0: target.files[0] };

          uploadFiles(files);

          // For 'onchange' same file;
          changeEvent.target.value = '';
        }, 100);
      });

      //Delete a file
      $scope.delete = function(file) {
        confirm
          .info($filter('translate')('file.MESSAGE_DELETE'))
          .then(function() {
            FileDeleter.delete(file).then(
              function(response) {
                const filesNumber = $scope.files.length - 1;
                $timeout(function() {
                  $scope.$emit('onFileDeleted', response, {
                    type: $scope.type,
                    filesNumber
                  });
                  toast.success($filter('translate')('crud.DELETED_INFO'));
                }, 100);

                loadList();
              },
              function(err) {
                $timeout(function() {
                  dialog.error(err);
                }, 100);
              }
            );
          });
      };

      //Download a file
      $scope.download = function(file) {
        dialog.info($filter('translate')('file.DOWNLOAD_POPUP_MSG'));
        FileDownloader.download(file).then(
          function(url) {
            window.open(url, '_blank');
          },
          function(err) {
            dialog.error(err);
          }
        );
      };

      //Update file data
      $scope.update = function(file) {
        $scope.file = file;
        $scope.processSaving();
      };

      $scope.isOwner = (file = {}) => {
        const { data = {} } = file;
        const { uploadedBy } = data;
        return !uploadedBy || LoggedUser.isPM() || uploadedBy === LoggedUserId;
      };

      $scope.downloadZip = () => {
        const keys = $scope.files && $scope.files.map(({ key }) => key);
        if (!keys || keys.length === 0) return;

        FilesZipDownload(keys).catch(err => {
          Sentry.captureException(err);
          const { message } = err;
          dialog.error(message || $filter('translate')('error.RECEIVE_ERR'));
        });
      };

      $scope.updateFilesData = (dataName, value) => {
        const { files } = $scope;
        if (!Array.isArray(files)) return;

        files.forEach((file = {}) => {
          const { data = {} } = file;
          file.data = data;
          data[dataName] = value;
          $scope.update(file);
        });
      };
    }
  ]);
