angular
  .module('pb.base')

  .directive('activeSubmenu', [
    '$timeout',

    function($timeout) {
      return {
        restrict: 'A',
        link: function(scope, element) {
          if (
            !scope.menu ||
            !scope.menu.submenus ||
            scope.menu.submenus.length === 0
          ) {
            return;
          }

          element.addClass('sub-menu');

          function findActive() {
            if (element.find('a').hasClass('active')) {
              element.addClass('active');
            } else {
              element.removeClass('active');
            }
          }

          scope.$on('$stateChangeSuccess', function() {
            $timeout(function() {
              findActive();
            }, 1);
          });
        }
      };
    }
  ]);
