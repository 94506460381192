angular
  .module('company')

  .factory('LegalAddress', function() {
    function get(company) {
      if (!company || !Array.isArray(company.headquarters)) {
        return null;
      }

      return Object.assign(
        company.headquarters.find(function(headquarter) {
          return headquarter.legal === true;
        }) || {},
        { _id: company._id }
      );
    }

    return {
      get: get
    };
  });
