angular
  .module('workflow')

  .factory('WorkflowCustomerModel', function() {
    return function(data) {
      var d = data || {};

      return {
        _id: d._id,
        active: d.active,
        type: d.type,
        email: d.email,
        first_name: d.first_name,
        last_name: d.last_name,
        job_role: d.job_role,
        lang: d.lang,
        blacklist: d.blacklist,
        title: d.title,
        contacts: d.contacts,
        code: d.code,
        disableWorkflowEmailsButOffer: d.disableWorkflowEmailsButOffer
      };
    };
  });
