angular
  .module('contributor')

  .directive('contributorServices', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        hidePrices: '<',
        services: '=ngModel',
        //obbligatorio!!! uno tra ['others', 'monolingual', 'bilingual']
        type: '@serviceType',
        lsp: '<'
      },
      templateUrl: 'custom/contributor/views/partials/contributorServices.html',
      controller: [
        '$scope',
        '$controller',
        'TaskCrud',
        'Dialog',
        '$filter',
        '$timeout',
        'LoggedUser',
        'UserLang',
        function(
          $scope,
          $controller,
          TaskCrud,
          Dialog,
          $filter,
          $timeout,
          LoggedUser,
          UserLang
        ) {
          $scope.isPM = LoggedUser.isPM();
          $scope.complete = LoggedUser.isComplete();
          $scope.qualified = LoggedUser.isQualified();
          $scope.userLang = UserLang.get();

          var dialog = new Dialog();

          $controller('ListCtrl', {
            $scope: $scope,
            crud: new TaskCrud('-list'),
            packageName: 'tasks',
            dataName: 'tasks',
            filters: {}
          });

          function getParamsLang() {
            var types = ['others', 'monolingual', 'bilingual'];
            if (types.indexOf($scope.type) < 0) {
              return 0;
            }
            return types.indexOf($scope.type);
          }

          $scope.loadList({ lang_required: getParamsLang() });

          var alreadyInsert = function() {
            if ($scope.services && $scope.services[$scope.type]) {
              for (var i = 0; i < $scope.services[$scope.type].length; i += 1) {
                if (
                  $scope.services[$scope.type][i].task === $scope.selectedTask
                ) {
                  return true;
                }
              }
            }

            return false;
          };

          $scope.addCombo = function(service) {
            service.combos = service.combos || [];

            var combo = {
              uom: {},
              price: ''
            };

            if (!$scope.lsp && $scope.type === 'bilingual') {
              combo.source = {};
              combo.target = $scope.services.nativeLangs[0];
            }

            if ($scope.type === 'monolingual') {
              combo.source = {};
            }

            service.combos.push(combo);
          };

          $scope.deleteCombo = function(service, index) {
            service.combos.splice(index, 1);
          };

          $scope.deleteTask = function(index) {
            $scope.services[$scope.type].splice(index, 1);
          };

          $scope.addTask = function() {
            if (!$scope.selectedTask) {
              dialog.warning(
                $filter('translate')('contributors.SELECT_TASK_MESSAGE')
              );
              return;
            }

            if (
              !$scope.lsp &&
              (!$scope.services ||
                !$scope.services.nativeLangs ||
                $scope.services.nativeLangs.length === 0)
            ) {
              dialog.warning(
                $filter('translate')('contributors.SELECT_NATIVE_MESSAGE')
              );
              return;
            }

            if (
              !$scope.lsp &&
              $scope.type === 'bilingual' &&
              !$scope.services.nativeLangs[0]
            ) {
              dialog.warning(
                $filter('translate')('contributors.SELECT_NATIVE_MESSAGE')
              );
              return;
            }

            if (alreadyInsert()) {
              dialog.warning(
                $filter('translate')('contributors.TASK_ALREADY_INSERT')
              );
              return;
            }

            var service = { collapsed: false };
            service.task = $scope.selectedTask;

            $scope.addCombo(service);

            $scope.services = $scope.services || {};
            $scope.services[$scope.type] = $scope.services[$scope.type] || [];

            $scope.services[$scope.type].push(service);

            $scope.selectedTask = null;

            $timeout(function() {
              delete service.collapsed;
            }, 100);
          };

          $scope.$watch(
            'services',
            function(val) {
              if (!val) {
                return;
              }
              if (val.nativeLangs && !val.nativeLangs[0]) {
                delete val.nativeLangs[0];
              }
              if (val.nativeLangs && !val.nativeLangs[1]) {
                delete val.nativeLangs[1];
              }
              var lastIndex =
                $scope.services.bilingual &&
                $scope.services.bilingual.length - 1;
              if (
                $scope.services.bilingual &&
                $scope.services.bilingual[lastIndex] &&
                $scope.services.bilingual[lastIndex].combos
              ) {
                var combos = $scope.services.bilingual[lastIndex].combos;
                if (
                  combos &&
                  combos.length &&
                  combos[combos.length - 1].target
                ) {
                  var target = combos[combos.length - 1].target;
                  var source = combos[combos.length - 1].source;
                  if (source && source._id === target._id) {
                    dialog.error(
                      $filter('translate')('service.TARGET_SAME_AS_SOURCE_ERR')
                    );
                    var lastCombosIndex =
                      $scope.services.bilingual[lastIndex].combos.length - 1;
                    $scope.services.bilingual[lastIndex].combos[
                      lastCombosIndex
                    ].target = undefined;
                  }
                }
              }
            },
            true
          );
        }
      ]
    };
  });
