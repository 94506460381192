angular
  .module('company')

  .directive('operativeAddresses', [
    '$filter',
    function($filter) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          address: '=',
          list: '='
        },
        templateUrl: 'custom/company/views/partials/operativeAddresses.html',
        link: function(scope) {
          scope.select = function(index) {
            var list = scope.list,
              mem = !!scope.list[index].selected;

            angular.forEach(list, function(address) {
              address.selected = false;
            });

            if (addresses.length >= index - 1) {
              scope.list[index].selected = mem;
            }

            scope.address = mem === true ? scope.list[index] : null;
          };
        }
      };
    }
  ]);
