angular
  .module('workflow')

  .directive('workflowSelectManagers', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        pm: '=',
        am: '=',
        admin: '=',
        company: '<',
        workflow: '<'
      },
      templateUrl: 'custom/workflow/views/partials/workflowSelectManagers.html',
      controller: [
        '$scope',
        'WorkflowStore',
        'LoggedUser',
        '$timeout',
        function($scope, WorkflowStore, LoggedUser, $timeout) {
          const setCompany = (company = {}) => {
            if (typeof company !== 'object') return;
            if (!LoggedUser.isPM()) return;

            const { project_manager, account_manager, admin_manager } = company;
            const { admin, am, pm } = $scope;

            $scope.admin = admin || admin_manager;
            $scope.am = am || account_manager;
            $scope.pm = pm || project_manager;

            $timeout(() => WorkflowStore.emitChange($scope.workflow));
          };

          WorkflowStore.addListener('company', company => {
            company && setCompany(company);
          });

          $scope.$watch('company', company => {
            company && setCompany(company);
          });
        }
      ]
    };
  });
