angular.module('workflow').factory('WorkflowTargetZip', [
  'ProjectFilesUtils',
  'FilesCrud',
  'FilesZipDownload',
  '$filter',
  'LoggedUser',

  function(
    ProjectFilesUtils,
    FilesCrud,
    FilesZipDownload,
    $filter,
    LoggedUser
  ) {
    const filesCrud = new FilesCrud();
    const isPM = LoggedUser.isPM();

    return workflow => {
      const { _id, projects = [] } = workflow;
      const paths = projects.map(project => {
        const { batches = [], code, uuid } = project;
        return [
          ProjectFilesUtils.getTargetPath(
            { _id },
            { batches, code, uuid },
            isPM
          ),
          ProjectFilesUtils.getHumanTargetPath(workflow, project, isPM)
        ].join(',');
      });

      const promises = paths.map(path => filesCrud.query({ parentPath: path }));

      return Promise.all(promises)
        .then((results = []) => {
          const keys = [];
          for (const r of results) {
            for (const file of r) {
              keys.push(file.key);
            }
          }
          if (keys.length === 0) {
            throw new Error(
              $filter('translate')('workflow.NO_TARGET_FILES_ERR')
            );
          }
          return keys;
        })
        .then(keys => FilesZipDownload(keys, `${_id}.zip`));
    };
  }
]);
