angular
  .module('workflow')

  .factory('WorkflowUnlinkAsParentCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class WorkflowUnlinkAsParentCrud extends Crud {
        constructor(id) {
          super(null, '@@apiUrl' + '/unlink-as-parent/' + id, {});
        }
      }

      return WorkflowUnlinkAsParentCrud;
    }
  ]);
