angular
  .module('components')

  .component('editActions', {
    bindings: {
      editable: '=',
      deletable: '@',
      delete: '&'
    },
    template: [
      '<ul class="actions" ng-hide="$ctrl.editable">',
      '<li class="dropdown" dropdown>',
      '<a href="" dropdown-toggle>',
      '<i class="zmdi zmdi-more-vert"></i>',
      '</a>',

      '<ul class="dropdown-menu dropdown-menu-right">',
      '<li>',
      '<a href="" ng-click="$ctrl.editable = true">{{::(\'MODIFY\' | translate)}}</a>',
      '</li>',

      '<li ng-if="$ctrl.deletable === \'true\'">',
      '<a href="" ng-click="$ctrl.delete()">{{::(\'DELETE\' | translate)}}</a>',
      '</li>',
      '</ul>',
      '</li>',
      '</ul>'
    ].join('')
  });
