angular
  .module('company')

  .directive('headquarterInfo', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      scope: {
        headquarter: '=ngModel'
      },
      templateUrl: 'custom/company/views/partials/headquarterInfo.html'
    };
  });
