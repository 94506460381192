angular
  .module('admin')

  .controller('AdminCreateCtrl', [
    '$scope',
    '$state',
    '$controller',
    'UserCrud',
    'AdminModel',
    'AuthRegister',
    'FormValidator',
    'Dialog',
    'ErrorsManager',
    '$filter',

    function(
      $scope,
      $state,
      $controller,
      UserCrud,
      AdminModel,
      AuthRegister,
      FormValidator,
      Dialog,
      ErrorsManager,
      $filter
    ) {
      $scope.admin = {};

      $controller('SaveBaseCtrl', {
        $scope: $scope,
        crud: new UserCrud($scope),
        object: AdminModel,
        packageName: 'admin',
        dataName: 'admin',
        formName: 'adminForm'
      });

      $scope.save = function() {
        const dialog = new Dialog();
        const validator = new FormValidator($scope['accountForm']);

        if (!validator.validate()) {
          dialog.error(
            $filter('translate')('crud.VALIDATION_ERROR') +
              '\n' +
              $filter('translate')('crud.VALIDATION_ERROR_VERIFY')
          );
          return;
        }

        const crud = new AuthRegister();
        crud.save($scope.account).then(
          function(res) {
            const { admin, account = {} } = $scope;
            const { email } = account;

            admin.userId = res.id;
            admin.active = true;
            admin.email = email;
            admin.lang = 'it';

            $scope.processSaving().then(function(response) {
              $state.go('^.^.user-about', { id: response._id });
            });
          },
          function(err) {
            dialog.error(ErrorsManager.getErrorsAsString(err));
          }
        );
      };

      $scope.cancel = () => $state.go('^.admins');
    }
  ]);
