angular
  .module('pb.base')

  .directive('inputDateValid', [
    '$timeout',

    function($timeout) {
      return {
        restrict: 'A',
        require: 'ngModel',
        replace: true,
        link: function(scope, element, attrs, ngModelCtrl) {
          //FIX ui-bootstrap datepicker bug when set ng-model value from controller

          function fixDate(dateString) {
            if (typeof dateString !== 'string') return [dateString];

            const splitDate = dateString.split('/');
            return splitDate.reverse();
          }

          function setValidity(modelValue) {
            if (!modelValue) {
              ngModelCtrl.$setValidity('date', true);
            } else if (isNaN(new Date(...fixDate(modelValue)))) {
              ngModelCtrl.$setValidity('date', false);
            } else {
              ngModelCtrl.$setValidity('date', true);
            }
          }

          ngModelCtrl.$formatters.unshift(function(modelValue) {
            $timeout(function() {
              setValidity(modelValue);
            }, 1);

            return modelValue;
          });
        }
      };
    }
  ]);
