angular
  .module('workflow')

  .factory('TaskStore', [
    'Store',

    function(Store) {
      'use strict';

      class TaskStore extends Store {}

      return new TaskStore();
    }
  ]);
