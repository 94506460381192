angular
  .module('task')

  .factory('TaskStatusCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class TaskStatusCrud extends Crud {
        constructor() {
          super(null, '@@apiUrl' + '/task-status' + '/:id', { id: '@id' });
        }
      }
      return TaskStatusCrud;
    }
  ]);
