angular
  .module('company')

  .factory('CompanySearch', [
    'Crud',

    function(Crud) {
      'use strict';

      class CompanySearch extends Crud {
        constructor() {
          super('company_search', '@@apiUrl' + '/company-vat/:vat', {
            vat: '@vat'
          });
        }
      }

      return CompanySearch;
    }
  ]);
