angular
  .module('contributor')

  .directive('selectContributorType', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        type: '='
      },
      template:
        '<select ng-model="type" ng-options="key as value for (key, value) in types">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$filter',
        function($scope, $filter) {
          $scope.types = {
            network: $filter('translate')('contributors.NETWORK'),
            not_network: $filter('translate')('contributors.NOT_NETWORK')
          };
        }
      ]
    };
  });
