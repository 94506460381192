angular
  .module('workflow')

  .factory('BatchModel', function() {
    return function() {
      return {
        tasks: [],
        delivered: false
      };
    };
  });
