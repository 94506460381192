angular
  .module('company')

  .directive('selectUserCompany', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        user: '=',
        company: '='
      },
      template:
        '<select chosen ng-model="user" ng-options="u._id as u.first_name for u in companyUser"><option value=""></option></select>',
      controller: [
        '$scope',
        '$controller',
        'CompanyUsersCrud',
        function($scope, $controller, CompanyUsersCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new CompanyUsersCrud(),
            packageName: 'company',
            dataName: 'companyUser',
            filters: {
              page: 1,
              limit: 1000000
            }
          });

          $scope.$watch('company', function(newValue, oldValue) {
            if (newValue) {
              $scope.loadList({ id: newValue }).then(() => {
                $scope.companyUser.unshift({
                  _id: '',
                  first_name: '-'
                });
              });
            }
          });
        }
      ]
    };
  });
