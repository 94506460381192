angular
  .module('library')

  .controller('LibraryPdfCreateCtrl', [
    '$scope',
    '$controller',
    '$filter',
    '$state',
    '$stateParams',
    'PdfCrud',

    function($scope, $controller, $filter, $state, $stateParams, PdfCrud) {
      $controller('SaveBaseCtrl', {
        $scope: $scope,
        crud: new PdfCrud(),
        packageName: 'pdfs',
        dataName: 'pdf',
        formName: 'pdfForm'
      });

      $scope.save = function() {
        $scope.processSaving().then(function() {
          $state.go('root.app.simple.libraries', { table: 'pdf' });
        });
      };
    }
  ]);
