angular
  .module('company')

  .directive('selectCompanyFilter', function() {
    return {
      restrict: 'E',
      scope: {
        company: '=',
        searchCompanyInput: '=',
        isFilter: '<'
      },
      templateUrl: 'custom/company/views/partials/selectCompanyFilter.html',
      controller: 'SelectCompanyCtrl'
    };
  });
