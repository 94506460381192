angular
  .module('pb.base')

  .factory('ChosenService', [
    '$timeout',

    function($timeout) {
      return function(enableChosen, $element) {
        if (enableChosen !== 'true') {
          return;
        }

        $timeout(function() {
          $element.chosen({
            search_contains: true,
            width: '95%'
          });
        });
      };
    }
  ]);
