angular
  .module('pb.base')

  .directive('wizardSteps', [
    function() {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          current: '<',
          steps: '<',
          completed: '<'
        },
        templateUrl: 'modules/base/views/partials/wizardSteps.html',
        link: function(scope) {
          var len = parseInt(scope.steps);

          function refresh() {
            var i,
              temp = [];

            for (i = 0; i < len; i += 1) {
              var isCurrent = parseInt(scope.current || 0) === i + 1,
                isFuture = parseInt(scope.current || 0) < i + 1,
                isCompleted = parseInt(scope.completed || 0) - 1 >= i;

              if (isCurrent) {
                temp.push('danger');
              } else if (isFuture && !isCompleted) {
                temp.push('default');
              } else {
                temp.push('success');
              }
            }

            scope.stepsArray = temp;
          }

          refresh();

          scope.$watch('current + completed', function(newValue) {
            if (newValue) {
              refresh();
            }
          });

          scope.goto = function(step) {
            if (step <= scope.completed + 1 && step !== scope.current) {
              scope.$emit('onGoToStep', step);
            }
          };
        }
      };
    }
  ]);
