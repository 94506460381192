angular
  .module('doorDocumentale')

  .config([
    '$stateProvider',

    function($stateProvider) {
      var path = 'custom/documental/views/';

      $stateProvider.state('root.app.simple.doorDocumentale', {
        url: '/docs/*path',
        views: {
          header: {
            templateUrl: path + 'documental-header.html'
          },
          content: {
            template:
              '<door-documentale bucket-name="bucketName" logged-user="loggedUser" api-url="apiUrl" workflow-api-url="workflowApiUrl" file-url="fileUrl" token="token" xutype="xutype" clipboard="clipboard" data-has-doc-access="hasDocAccess" data-is-pm="isPM"></door-documentale>',
            controller: 'DocumentalController'
          }
        },
        permissions: {
          resources: { documentary: ['read'] }
        }
      });
    }
  ]);
