angular
  .module('library')

  .directive('taskHistory', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        id: '<'
      },
      templateUrl: 'custom/library/views/partials/taskHistory.html',
      controller: [
        '$scope',
        '$controller',
        'HistoryCrud',
        function($scope, $controller, HistoryCrud) {
          $controller('LoadBaseCtrl', {
            $scope: $scope,
            crud: new HistoryCrud(),
            object: null,
            dataName: 'history'
          });
          $scope.$watch('id', function(newValue) {
            newValue ? $scope.processLoading({ id: newValue }) : null;
          });

          $scope.getLatest = function(units) {
            if (units && units.length) {
              var date = units[0].list.dateStart;
              units.forEach(function(unit) {
                date =
                  unit.list && unit.list.dateStart > date
                    ? unit.list.dateStart
                    : date;
              });
              return date;
            }
          };
        }
      ]
    };
  });
