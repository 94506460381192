angular
  .module('pb.base')

  .directive('appFooter', [
    'Utilities',

    function(Utilities) {
      return {
        restrict: 'E',
        replace: true,
        templateUrl: 'modules/base/views/partials/footer.html',
        scope: true,
        link: function(scope, element, attrs) {
          scope.scopedAppVersion = '@@version';
          scope.appWebsiteName = '@@appWebsiteName';
          scope.appWebsite = '@@appWebsite';
          scope.currentYear = Utilities.currentYear();
        }
      };
    }
  ]);
