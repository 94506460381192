angular
  .module('company')

  .controller('ModalFormBillingCtrl', [
    '$scope',
    '$modalInstance',
    'FormValidator',

    function($scope, $modalInstance, FormValidator) {
      $scope.ok = function() {
        if ($scope.billingForm) {
          const validator = new FormValidator($scope.billingForm);
          if (!validator.validate()) {
            return;
          }
        }
        $scope.billingComp.billingCompany = true;
        $modalInstance.close();
      };

      $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
      };
    }
  ]);
