angular
  .module('pb.base')

  .controller('ErrorsCtrl', [
    '$window',
    '$scope',

    function($window, $scope) {
      $scope.back = function() {
        $window.history.back();
      };

      $scope.home = function() {
        $scope.$emit('goToHome');
      };
    }
  ]);
