angular
  .module('company')

  .directive('documentList', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      replace: true,
      scope: {
        documents: '=ngModel',
        editable: '='
      },
      templateUrl: 'custom/company/views/partials/documentList.html',
      link: function(scope) {
        scope.add = function() {
          scope.documents = scope.documents || [];
          scope.documents.push({});
        };

        scope.delete = function(index) {
          scope.documents.splice(index, 1);
        };
      }
    };
  });
