angular
  .module('pb.base')

  .directive('listInfiniteScroll', [
    '$compile',

    function($compile) {
      return {
        restrict: 'E',
        replace: true,
        template:
          '<div infinite-scroll="loadMore()" infinite-scroll-distance="3" infinite-scroll-listen-for-event="scroll.infiniteScrollComplete"></div>',
        scope: false,
        link: function(scope, element, attrs) {
          if (attrs.container) {
            element.attr(
              'infinite-scroll-container',
              "'." + attrs.container + "'"
            );
            $compile(element)(scope);
          }
        }
      };
    }
  ]);
