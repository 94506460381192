angular
  .module('pb.core.module')

  .factory('Module', [
    '$state',
    'MainMenu',
    'UserMenu',

    function($state, MainMenu, UserMenu) {
      var Module = function() {};

      /**
       * Get state by name
       * @param state
       * @returns {Object|Array}
       * @private
       */
      Module.prototype._getState = function(state) {
        if (typeof state !== 'string') {
          throw new TypeError('state must be a string');
        }

        return $state.get(state);
      };

      /**
       * Get needed module's resources
       * @param menu
       * @returns {*}
       * @private
       */
      Module.prototype._getResources = function(menu) {
        var state;

        if (!menu || !menu.link) {
          return {};
        }

        state = this._getState(menu.link);
        return state && state.permissions ? state.permissions : {};
      };

      /**
       * Add requested resources to module object
       * @param menu
       * @returns {*}
       * @private
       */
      Module.prototype._addResourcesToModule = function(menu) {
        if (typeof menu === 'undefined') {
          return menu;
        }

        if (typeof menu !== 'object') {
          throw new TypeError('menu must be an object');
        }

        return angular.extend(menu, this._getResources(menu));
      };

      /**
       * Push module to menus
       * @param module
       * @returns {Module}
       */
      Module.prototype.push = function(module) {
        if (module && module.menu) {
          this._addResourcesToModule(module.menu);
          MainMenu.push(module.menu);
        }

        if (module && module.userMenu) {
          this._addResourcesToModule(module.userMenu);
          UserMenu.push(module.userMenu);
        }

        return this;
      };

      /**
       * Push module to menus
       * @param module
       * @returns {Module}
       */
      Module.prototype.pushSubmenu = function(name, module) {
        if (module && module.menu) {
          this._addResourcesToModule(module.menu);
          MainMenu.pushSubmenu(name, module.menu);
        }

        if (module && module.userMenu) {
          this._addResourcesToModule(module.userMenu);
          UserMenu.pushSubmenu(name, module.userMenu);
        }

        return this;
      };

      return Module;
    }
  ]);
