angular
  .module('components')

  .directive('lineChart', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        lines: '<',
        title: '@'
      },
      template: [
        '<div class="card">',
        '<div class="card-header ch-alt">',
        "<h2>{{title || 'Bar chart'}}</h2>",
        '</div>',

        '<div class="card-body card-padding-sm">',
        '<div class="flot-chart"></div>',
        '<div class="flc-bar"></div>',
        '</div>',
        '</div>'
      ].join(''),
      controller: [
        '$scope',
        '$element',
        function($scope, $element) {
          var colors = [
            '#FFC107',
            '#8BC34A',
            '#00BCD4',
            '#e3e3e3',
            '#009788',
            '#FFED18',
            '#F7412D'
          ];

          function refresh(lines) {
            var lineData = [];

            if (!Array.isArray(lines)) {
              return;
            }

            lines.forEach(function(line, i) {
              var data = line || {};

              lineData.push({
                data: data.data,
                lines: {
                  show: true,
                  fill: 0.98
                },
                label: data.label || '',
                stack: true,
                color: colors[i % 6]
              });
            });

            /* Let's create the chart */
            $.plot($($element).find('.flot-chart'), lineData, {
              series: {
                label: 'Server Process Data',
                lines: {
                  show: true,
                  lineWidth: 0.2,
                  fill: 0.6
                },

                color: '#00BCD4',
                shadowSize: 0
              },
              grid: {
                borderWidth: 1,
                borderColor: '#eee',
                show: true,
                hoverable: true,
                clickable: true
              },

              yaxis: {
                tickColor: '#eee',
                tickDecimals: 0,
                font: {
                  lineHeight: 13,
                  style: 'normal',
                  color: '#9f9f9f'
                },
                shadowSize: 0
              },

              xaxis: {
                tickColor: '#fff',
                tickDecimals: 0,
                font: {
                  lineHeight: 13,
                  style: 'normal',
                  color: '#9f9f9f'
                },
                shadowSize: 0
              },
              legend: {
                container: '.flc-bar',
                backgroundOpacity: 0.5,
                noColumns: 0,
                backgroundColor: 'white',
                lineWidth: 0
              }
            });
          }

          $scope.$watchCollection('lines', refresh);
        }
      ]
    };
  });
