angular
  .module('contributor')

  .factory('RatingsCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class RatingsCrud extends Crud {
        constructor() {
          super(
            'ratings',
            '@@apiUrl' + '/profile-ratings/:id',
            { id: '@id' },
            { get: { method: 'GET', isArray: true } }
          );
        }
      }

      return RatingsCrud;
    }
  ]);
