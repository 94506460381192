angular
  .module('workflow')

  .directive('cardFilter', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      replace: true,
      scope: {
        filtersModel: '=ngModel',
        performSearch: '&search',
        notifyFilter: '&',
        prefix: '@',
        contributor: '=',
        resetPage: '&'
      },
      templateUrl: 'custom/workflow/views/partials/cardFilter.html',
      controller: [
        '$scope',
        '$controller',
        '$stateParams',
        'LoggedUser',
        function($scope, $controller, $stateParams, LoggedUser) {
          $controller('filterController', {
            $scope: $scope
          });

          $scope.isPM = LoggedUser.isPM();

          $scope.searchAndResetPage = () => {
            $scope.search();
            $scope.resetPage();
          };

          $scope.reset = () => {
            $scope.filtersModel = {};
            $scope.contributor = undefined;
            delete $scope.searchCompanyInput;
            $scope.resetPage();
          };

          let firstPMChange = true;
          $scope.$watch('filtersModel.project_manager', project_manager => {
            if (firstPMChange) return (firstPMChange = false);
            if (project_manager === undefined) return;
            if (project_manager === null)
              delete $scope.filtersModel.project_manager;
            $scope.searchAndResetPage();
          });

          let firstAMChange = true;
          $scope.$watch('filtersModel.account_manager', account_manager => {
            if (firstAMChange) return (firstAMChange = false);
            if (account_manager === undefined) return;
            if (account_manager === null)
              delete $scope.filtersModel.account_manager;
            $scope.searchAndResetPage();
          });

          let firstCompanyChange = true;
          $scope.$watch('filtersModel.company', companyId => {
            if (firstCompanyChange) return (firstCompanyChange = false);
            if (companyId === undefined) return;
            if (companyId === null) {
              delete $scope.filtersModel.company;
              delete $scope.filtersModel.customer;
            }
            $scope.searchAndResetPage();
          });

          let firstCustomerChange = true;
          $scope.$watch('filtersModel.customer', customerId => {
            if (firstCustomerChange) return (firstCustomerChange = false);
            if (customerId === undefined) return;
            if (customerId === null) delete $scope.filtersModel.customer;
            $scope.searchAndResetPage();
          });

          let firstDateChange = true;
          $scope.$watch('filtersModel.delivery_date', delivery_date => {
            if (firstDateChange) return (firstDateChange = false);
            delivery_date && $scope.searchAndResetPage();
          });

          let firstNextStepDateChange = true;
          $scope.$watch('filtersModel.next_step_date', next_step_date => {
            if (firstNextStepDateChange)
              return (firstNextStepDateChange = false);
            next_step_date && $scope.searchAndResetPage();
          });

          $scope.$watch('filtersModel.status', status => {
            if (!status) delete $scope.filtersModel.status;
          });

          let firstOrderTypeChange = true;
          $scope.$watch('filtersModel.orderType', orderType => {
            if (firstOrderTypeChange) return (firstOrderTypeChange = false);
            if (orderType === undefined) return;
            if (orderType === '' || orderType === null)
              delete $scope.filtersModel.orderType;
            $scope.searchAndResetPage();
          });
        }
      ]
    };
  });
