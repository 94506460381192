angular
  .module('workflow')

  .factory('WorkflowCrud', [
    'CrudUndoable',

    function(CrudUndoable) {
      'use strict';

      class WorkflowCrud extends CrudUndoable {
        constructor($scope) {
          super(
            $scope,
            null,
            '@@apiUrl' + '/workflows' + '/:id',
            { id: '@id' },
            {
              query: {
                method: 'GET',
                isArray: true
              }
            }
          );
        }
      }
      return WorkflowCrud;
    }
  ]);
