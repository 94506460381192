angular
  .module('contributor')

  .directive('selectTaxCode', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        taxcode: '='
      },
      template: '<select ng-model="taxcode" ng-options="c for c in codes" />',

      controller: [
        '$scope',
        '$filter',
        function($scope, $filter) {
          $scope.codes = [
            $filter('translate')('tribute.tribute_1040'),
            $filter('translate')('tribute.NO_RDA')
          ];
        }
      ]
    };
  });
