angular
  .module('contributor')

  .controller('ContributorTabCtrl', [
    '$scope',
    '$translate',
    '$filter',
    '$timeout',
    'ContributorStore',
    'FormValidator',
    'Dialog',
    'UserLang',
    'LoggedUser',

    function(
      $scope,
      $translate,
      $filter,
      $timeout,
      ContributorStore,
      FormValidator,
      Dialog,
      UserLang,
      LoggedUser
    ) {
      var dialog = new Dialog();
      $scope.store = ContributorStore;

      //On change listener
      ContributorStore.listenTo(function(data) {
        $scope.contributor = data;
      });

      var loggedUser = LoggedUser.getData();

      var currentLang = $translate.proposedLanguage() || $translate.use();

      //Emit save action
      $scope.save = function() {
        if ($scope.contributorForm) {
          var validator = new FormValidator($scope.contributorForm);

          if (!validator.validate()) {
            dialog.error(
              $filter('translate')('crud.VALIDATION_ERROR') +
                '\n' +
                $filter('translate')('crud.VALIDATION_ERROR_VERIFY')
            );
            return;
          }
        }

        ContributorStore.emit('save');

        if (loggedUser && loggedUser._id === $scope.contributor._id) {
          if (
            $scope.contributor.lang &&
            $scope.contributor.lang !== currentLang
          ) {
            $timeout(function() {
              UserLang.set($scope.contributor.lang, true);
            }, 1000);
          }
        }
      };

      $scope.undo = function() {
        $scope.$parent.performUndo();
      };
    }
  ]);
