angular
  .module('pb.base')

  .controller('ForgotCtrl', [
    '$scope',
    '$filter',
    'Auth',
    'AuthForgotPassword',
    'Dialog',

    function($scope, $filter, Auth, AuthForgotPassword, Dialog) {
      var dialog = new Dialog();

      $scope.sendRequest = function() {
        Auth.forgotPassword(new AuthForgotPassword(), $scope.email).then(
          function() {
            dialog.success($filter('translate')('auth.NEW_PASS_SENT'));
          },
          function() {
            dialog.error($filter('translate')('auth.NO_EMAIL_FOUND'));
          }
        );
      };
    }
  ]);
