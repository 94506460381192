angular
  .module('workflow')

  .controller('ModalWorkflowSelectionCtrl', [
    '$scope',
    '$modalInstance',
    'currentRoute',
    'LoggedUser',
    'CleanWorkflow',

    function($scope, $modalInstance, currentRoute, LoggedUser, CleanWorkflow) {
      $scope.currentRoute = currentRoute;
      $scope.isPM = LoggedUser.isPM();

      if (!$scope.isPM) {
        const { company } = LoggedUser.getData() || {};
        $scope.selectedCompany = company;
      }

      $scope.cancel = () => {
        $modalInstance.dismiss('cancel');
      };

      $scope.done = () => {
        const { selectedCompany, selectedWorkflow, type } = $scope;
        $modalInstance.close({
          type,
          selectedCompany,
          selectedWorkflow: CleanWorkflow(selectedWorkflow, type)
        });
      };
    }
  ]);
