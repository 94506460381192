angular
  .module('company', ['pb.core', 'components'])

  .run([
    '$filter',
    'Module',
    function($filter, Module) {
      var module = new Module();

      module.push({
        menu: {
          name: 'company',
          title: $filter('translate')('company.CUSTOMERS'),
          icon: 'zmdi zmdi-accounts-list-alt',
          link: 'root.app.simple.companies'
        }
      });
    }
  ]);
