angular
  .module('pb.base')

  .directive('selectDate', function() {
    return {
      restrict: 'E',
      require: '^ngModel',
      templateUrl: 'modules/base/views/partials/selectDate.html',
      scope: {
        date: '=ngModel'
      }
    };
  });
