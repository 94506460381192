angular
  .module('components')

  .directive('selectDocumentSectorId', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        sector: '=',
        macrosector: '=',
        type: '@'
      },
      template:
        '<select ng-model="sector" ng-options="s._id as s.value for s in documents">' +
        '<option value="">-</option>' +
        '</select>',
      controller: [
        '$scope',
        '$controller',
        'DocumentCrud',
        function($scope, $controller, DocumentCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new DocumentCrud('-list'),
            packageName: 'document',
            dataName: 'documents',
            filters: {
              type: $scope.type
            }
          });

          $scope.$watch('macrosector', function(newValue, oldValue) {
            if (newValue) {
              $scope.loadList({ parent: newValue });
            }
          });
        }
      ]
    };
  });
