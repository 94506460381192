angular
  .module('admin')

  .factory('AdminManagerCache', function() {
    let cache;

    return {
      get: () => cache,
      set: value => (cache = value)
    };
  });
