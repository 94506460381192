angular
  .module('pb.user')

  .directive('userAccountModal', function() {
    return {
      restrict: 'EA',
      require: '^ngModel',
      scope: {
        userId: '=ngModel'
      },
      controller: [
        '$controller',
        '$scope',
        '$attrs',
        function($controller, $scope, $attrs) {
          $controller('ModalBaseCtrl', {
            $scope: $scope,
            controller: 'UserAccountModalCtrl',
            templateUrl: 'modules/user/views/partials/userAccountModal.html',
            content: {},
            childScope: false
          });

          $scope.$on('setUserAccountRequest', function() {
            //Open modal function in ModalBaseCtrl
            $scope.open();
          });

          $scope.$on('onUserAccountEdit', function(e, userId) {
            $scope.userId = userId;
          });
        }
      ]
    };
  })

  .controller('UserAccountModalCtrl', [
    '$controller',
    'UserCrud',
    'UserModel',
    '$scope',
    '$modalInstance',
    'content',

    function(
      $controller,
      UserCrud,
      UserModel,
      $scope,
      $modalInstance,
      content
    ) {
      var crud = new UserCrud($scope);

      $controller('ModalBaseInstanceCtrl', {
        $scope: $scope,
        $modalInstance: $modalInstance,
        content: content
      });

      $controller('EditBaseCtrl', {
        $scope: $scope,
        crud: crud,
        object: UserModel,
        packageName: 'user',
        dataName: 'user',
        formName: 'userForm'
      });

      if ($scope.$parent.userId) {
        $scope.processLoading($scope.$parent.userId);
      }

      $scope.save = function() {
        $scope.processSaving().then(function(response) {
          $scope.$emit('onUserAccountEdit', response._id);
          $scope.ok();
        });
      };
    }
  ]);
