angular
  .module('pb.core.module')

  .factory('MenuManager', [
    '$filter',
    'AbstractUserMenu',

    function($filter, AbstractUserMenu) {
      var MenuManager = function(menus) {
        this.menus = menus || [];
      };

      /**
       * Get menu array
       * @returns {*|Array|MenuManager.menu}
       */
      MenuManager.prototype.get = function() {
        var menus = [];

        angular.forEach(this.menus, function(menu) {
          menus.push(menu.get());
        });

        return menus;
      };

      /**
       * Get menu with items for which user has permissions
       * @param user
       * @returns {Array}
       */
      MenuManager.prototype.getByPermissions = function(user) {
        var menus = [];

        angular.forEach(this.menus, function(menu) {
          var m = menu.getByPermissions(user);
          m ? menus.push(m) : null;
        });

        return menus;
      };

      /**
       * Push a menu item
       * @param data
       * @returns {MenuManager}
       */
      MenuManager.prototype.push = function(data) {
        if (typeof data !== 'object') {
          throw new TypeError('data must be an object');
        }

        this.menus = this.menus || [];
        this.menus.push(new AbstractUserMenu(data));

        return this;
      };

      /**
       * Push a submenu to a previous inserted menu
       * @param data
       * @returns {MenuManager}
       */
      MenuManager.prototype.pushSubmenu = function(name, data) {
        var i;

        if (typeof name !== 'string') {
          throw new TypeError('name must be a string');
        }

        if (typeof data !== 'object') {
          throw new TypeError('data must be an object');
        }

        this.menus = this.menus || [];

        for (i = 0; i < this.menus.length; i += 1) {
          if (this.menus[i].get().name === name) {
            this.menus[i].add(new AbstractUserMenu(data));
            return this;
          }
        }

        throw new Error('Menu "' + name + '" does not exist');
        return this;
      };

      return MenuManager;
    }
  ]);
