angular
  .module('pb.base')

  .directive('signColorInv', function() {
    return {
      restrict: 'A',
      require: '^ngModel',
      scope: false,
      link: function(scope, element, attrs, ngModelCtrl) {
        scope.$watch(
          function() {
            return ngModelCtrl.$viewValue;
          },
          function(newValue) {
            if (newValue) {
              if (newValue < 0) {
                element.addClass('c-blue').removeClass('c-red');
              } else {
                element.addClass('c-red').removeClass('c-blue');
              }
            }
          }
        );
      }
    };
  });
