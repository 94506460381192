angular
  .module('components')

  .directive('selectTasksByService', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        task: '=',
        selectedService: '<'
      },
      template:
        '<select chosen ng-model="task" ng-options="t.value for t in tasks track by t._id" />',
      controller: [
        '$scope',
        'ServiceCrud',
        'UserLang',
        'NameArrayToObject',
        function($scope, ServiceCrud, UserLang, NameArrayToObject) {
          const crud = new ServiceCrud();

          $scope.$watch('selectedService', selectedService => {
            if (!selectedService) return;

            const { service = {} } = selectedService;

            const { _id: id } = service;

            crud
              .get({ id })
              .then((service = {}) => {
                const { tasks = [] } = service;

                const userLang = UserLang.get();

                tasks.forEach((task = {}) => {
                  const {
                    abbreviation = [],
                    description = [],
                    name = []
                  } = task;
                  const foundName = name.find(
                    translatedName => translatedName.lang === userLang
                  );
                  const foundAbbreviation = abbreviation.find(
                    translatedAbbreviation =>
                      translatedAbbreviation.lang === userLang
                  );
                  const { value: nameValue = '' } = foundName;
                  const { value: abbreviationValue = '' } = foundAbbreviation;
                  task.name = NameArrayToObject(name);
                  task.description = NameArrayToObject(description);
                  task.value = nameValue;
                  task.abbreviation = abbreviationValue;
                });

                $scope.tasks = tasks.sort((a, b) =>
                  a.value > b.value ? 1 : -1
                );
              })
              .catch(error => {
                Sentry.captureException(error);
                $scope.tasks = [];
              });
          });
        }
      ]
    };
  });
