angular
  .module('company')

  .directive('selectCompany', function() {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        company: '='
      },
      template:
        '<select chosen ng-model="company" ng-options="c._id as c.company_name for c in companies"><option value=""></option></select>',
      controller: [
        '$scope',
        '$controller',
        'CompanyCrud',
        function($scope, $controller, CompanyCrud) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new CompanyCrud($scope),
            packageName: 'companies',
            dataName: 'companies',
            filters: {
              page: 1,
              limit: 1000000
            }
          });

          $scope.loadList().then(() => {
            $scope.companies.unshift({
              _id: '',
              company_name: '-'
            });
          });
        }
      ]
    };
  });
