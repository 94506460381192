angular
  .module('pb.base', [
    'ngLocale',
    'ngAnimate',
    'ui.router',
    'pascalprecht.translate',
    'ui.bootstrap',
    'infinite-scroll',
    'pb.core',
    'pb.user',
    'ncy-angular-breadcrumb',
    'ui.validate'
  ])

  .config([
    '$compileProvider',
    function($compileProvider) {
      $compileProvider.debugInfoEnabled(false);
    }
  ])

  .config([
    '$translateProvider',
    function($translateProvider) {
      const queriedLang = new URLSearchParams(window.location.search).get(
        'lang'
      );
      let defaultLang;

      if (!queriedLang) {
      } else if ('@@supportedLangs'.split(',').includes(queriedLang))
        defaultLang = queriedLang;
      else if ('@@fallbackToEnLangs'.split(',').includes(queriedLang))
        defaultLang = 'en';

      $translateProvider.translations('en', translations_en);
      $translateProvider.translations('it', translations_it);
      $translateProvider.preferredLanguage(
        defaultLang ||
          localStorage.getItem('@@localStoragePrefix' + 'lang') ||
          'it'
      );
      // Enable escaping of HTML (http://angular-translate.github.io/docs/#/guide/19_security)
      $translateProvider.useSanitizeValueStrategy('escaped');
    }
  ]);
