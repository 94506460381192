angular
  .module('workflow')

  .controller('WorkflowTabCtrl', [
    '$scope',
    '$filter',
    '$timeout',
    'Clipboard',
    'Confirm',
    'Dialog',
    'FormValidator',
    'LoggedUser',
    'LSPrefix',
    'PdfExport',
    'SendOfferMail',
    'WorkflowStore',
    'Project24h',
    'ProjectTemplate',
    'WorkflowBillingDataConfirm',
    'WorkflowTargetZip',
    'ProjectFilesUtils',

    function(
      $scope,
      $filter,
      $timeout,
      Clipboard,
      Confirm,
      Dialog,
      FormValidator,
      LoggedUser,
      LSPrefix,
      PdfExport,
      SendOfferMail,
      WorkflowStore,
      Project24h,
      ProjectTemplate,
      WorkflowBillingDataConfirm,
      WorkflowTargetZip,
      ProjectFilesUtils
    ) {
      const dialog = new Dialog();
      $scope.editable = false;
      $scope.isCustomer = LoggedUser.isCustomer();
      $scope.isPM = LoggedUser.isPM();
      $scope.totals = {};

      let templateSelected;

      //On change listener
      WorkflowStore.listenTo(data => {
        $scope.workflow = data;
        $scope.editable = WorkflowStore.isEditable();
        const { order = {}, quote = {} } = data;
        const { status: orderStatus } = order;
        const { status: quoteStatus } = quote;
        const cancelledNames = ['cancelled', 'canceled'];
        $scope.cancelled =
          cancelledNames.includes(orderStatus) ||
          cancelledNames.includes(quoteStatus);
        $scope.toBill = orderStatus === 'tobill';
        $scope.invoiced = orderStatus === 'invoiced';
        $scope.dataconfirmed = orderStatus === 'dataconfirmed';
      });

      //Emit save action
      $scope.save = function() {
        Object.assign($scope.workflow, { totals: $scope.totals });

        // Se workflow.iso17100 verifico che tutti i progetti abbiano almeno un task "revisione 17100"
        if ($scope.workflow.iso17100) {
          const { projects = [] } = $scope.workflow;
          const has17100 = projects.every(project => {
            const { batches = [], services = [] } = project || {};

            const hasTranslationService = services.some(
              item =>
                item &&
                item.service &&
                item.service.code === '@@translation_service_code'
            );
            if (!hasTranslationService) return true; // Se non c'è un servizio di traduzione, l'applicabilità 17100 va ignorata

            return batches.some(batch => {
              const { tasks = [] } = batch || {};
              return tasks.find(task => {
                const { code = '' } = task || {};
                return code === '@@task_code_17100';
              });
            });
          });
          if (!has17100)
            return dialog.error(
              $filter('translate')('workflow.NO_REVISION_17100_ERR')
            );
        }

        if ($scope.workflowForm) {
          var validator = new FormValidator($scope.workflowForm);

          if (!validator.validate()) {
            dialog.error(
              $filter('translate')('crud.VALIDATION_ERROR') +
                '\n' +
                $filter('translate')('crud.VALIDATION_ERROR_VERIFY')
            );
            return;
          }
        }
        $scope.breadCrumbQuote = $scope.workflow.title;
        $scope.breadCrumbOrder = $scope.workflow.title;
        WorkflowStore.emit('save', $scope.workflow);
      };

      $scope.undo = function() {
        $scope.$parent.performUndo();
      };

      $scope.breadCrumbAbout = $filter('translate')('form.GENERAL_INFO');
      $scope.breadCrumbProjects = $filter('translate')('PROJECTS');
      $scope.breadCrumbBilling = $filter('translate')('company.BILLING');
      $scope.breadCrumbAdmin = $filter('translate')('file.ADMIN');
      $scope.breadCrumbCreateSrv = $filter('translate')(
        'service_detail.ADD_SRV'
      );
      $scope.breadCrumbEditSrv = $filter('translate')('service_detail.SRV_DET');
      $scope.breadCrumbOffer = $filter('translate')('task.OFFER');
      $scope.breadCrumbAdditionalFiles = $filter('translate')(
        'file.ADDITIONALS'
      );

      // function cleanExtraServices() {
      //   if ($scope.workflow && $scope.workflow.offer && $scope.workflow.offer.extraServices) {
      //     $scope.workflow.offer.extraServices.map(es => delete es.edit)
      //     $scope.workflow.offer.extraServices = $scope.workflow.offer.extraServices.filter(es => es.name || es.description)
      //   }
      // }

      const getCustomerTitle = currentLang => {
        if (!currentLang) return '';

        const { workflow = {} } = $scope;
        const { customer = {} } = workflow;
        const { title } = customer;
        const { name = [], value = '' } = title || {};

        if (value) return value;

        return (
          (name.find(({ lang }) => lang === currentLang) || {}).value || ''
        );
      };

      $scope.downloadPdf = () => {
        if (!$scope.workflow.project_manager._id) {
          const message = $filter('translate')('workflow.NO_PM_ASSOCIATED');
          return dialog.error(message);
        }
        const template = angular.copy(templateSelected);
        const { body = {}, lang } = template;
        const { opening = '' } = body;
        body.opening = `${opening} ${getCustomerTitle(lang)}`;
        const workflowCopy = angular.copy($scope.workflow);
        PdfExport.setTasksForOffer(workflowCopy, lang);
        PdfExport.filterEmptyProjects(workflowCopy);
        PdfExport.download(workflowCopy, template);
      };

      $scope.sendMail = () => {
        const legalHeadquarter =
          $scope.workflow &&
          $scope.workflow.company &&
          $scope.workflow.company.headquarters &&
          $scope.workflow.company.headquarters.find(h => h.legal);
        const legalHeadquarterEmail =
          legalHeadquarter &&
          legalHeadquarter.contacts &&
          legalHeadquarter.contacts.email;
        const legalAddressEmail =
          $scope.workflow &&
          $scope.workflow.company &&
          $scope.workflow.company.legal_address &&
          $scope.workflow.company.legal_address.contacts &&
          $scope.workflow.company.legal_address.contacts.email;
        const emailAddress = legalAddressEmail || legalHeadquarterEmail;

        SendOfferMail(emailAddress, $scope.workflow, templateSelected);
      };

      $scope.templateSelected = (template = {}) => {
        $scope.workflow.offer = Object.assign({}, $scope.workflow.offer, {
          template
        });
        templateSelected = template;
      };

      $scope.$on('formAbout:save', () => $scope.save());

      $scope.saveAs24h = () =>
        Project24h.saveAs24h($scope.workflow, $scope.save);
      $scope.saveAsTemplate = () =>
        ProjectTemplate.saveAsTemplate($scope.workflow, $scope.save);

      $scope.sendBillingDataRequest = () => {
        const { _id, customer = {}, project_manager = {} } = $scope.workflow;
        const { active: activeCustomer, email: customerEmail } = customer;
        const { email: pmEmail = '@@pm_default_email' } = project_manager;
        const email = (activeCustomer && customerEmail) || pmEmail;
        const dialog = new Dialog();

        if (!email)
          return dialog.error(
            $filter('translate')('workflow.SEND_BILLING_DATA_REQUEST_NO_EMAIL')
          );

        let showAlertOnError;
        new Confirm()
          .show($filter('translate')('workflow.SEND_BILLING_DATA_REQUEST_MSG'))
          .then(() => {
            showAlertOnError = true;
            const crud = new WorkflowBillingDataConfirm(_id);
            return crud.save({ type: 'manualConfirmBillingData' });
          })
          .then(() => {
            dialog.success(
              $filter('translate')('workflow.SEND_BILLING_DATA_REQUEST_SUCC') +
                email
            );
          })
          .catch(error => {
            if (!showAlertOnError) return;

            Sentry.captureException(error);
            $timeout(
              () =>
                dialog.error(
                  $filter('translate')('workflow.SEND_BILLING_DATA_REQUEST_ERR')
                ),
              250
            );
          });
      };

      $scope.downloadZip = () => {
        WorkflowTargetZip($scope.workflow).catch(err => {
          Sentry.captureException(err);
          const { message } = err;
          const dialog = new Dialog();
          dialog.error(message || $filter('translate')('error.RECEIVE_ERR'));
        });
      };

      $scope.copyS3Path = () => {
        const { workflow = {} } = $scope;
        const humanPath = ProjectFilesUtils.getHumanAdminPath(workflow).replace(
          '@@landoor_network_share_cut',
          ''
        );
        const networkSharePrefix = '@@landoor_network_share_prefix';
        Clipboard.copy(
          `${networkSharePrefix}/${humanPath}`.replace(/\//g, '\\')
        );
      };
    }
  ]);
