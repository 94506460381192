angular
  .module('pb.core.module')

  .factory('AbstractUserMenu', [
    'AbstractMenu',
    'User',

    function(AbstractMenu, User) {
      /**
       * AbstractUserMenu class
       * @param params
       * @constructor
       */
      function AbstractUserMenu(data) {
        AbstractMenu.call(this, data);
      }

      AbstractUserMenu.prototype = new AbstractMenu();
      AbstractUserMenu.prototype.constructor = AbstractUserMenu;

      /**
       * Get AbstractMenu object with submenus for which user has permissions
       * @returns {*|void|Object}
       */
      AbstractUserMenu.prototype.getByPermissions = function(user) {
        var submenus = [],
          userType = this.resource.userType || {};

        if (!(user instanceof User)) {
          throw new Error('user parameter must be instance of User');
        }

        if (!user.hasPermission(this.resource.resources)) {
          return undefined;
        }

        if (!user.isType(userType.type, userType.attr)) {
          return undefined;
        }

        angular.forEach(this._getChildrenResourceByPermissions(user), function(
          submenu
        ) {
          if (submenu && user.hasPermission(submenu.resources)) {
            submenus.push(submenu);
          }
        });

        return angular.extend({}, this.resource, {
          submenus: submenus
        });
      };

      /**
       * Get children resources by permissions (recursive)
       * @returns {Array}
       * @private
       */
      AbstractUserMenu.prototype._getChildrenResourceByPermissions = function(
        user
      ) {
        var submenus = [];

        angular.forEach(this.getChildren(), function(submenu) {
          if (submenu instanceof AbstractMenu) {
            submenus.push(submenu.getByPermissions(user));
          }
        });

        return submenus;
      };

      return AbstractUserMenu;
    }
  ]);
