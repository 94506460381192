angular
  .module('pb.core.crud')

  .factory('ErrorsManager', [
    '$filter',
    function($filter) {
      /**
       * Return errors list as an array of strings
       * @param error
       * @returns {*}
       */
      function getErrors(error) {
        switch (typeof error) {
          case 'string':
            return [error];
          case 'object':
            return getErrorsFromObject(error);
          default:
            return [getError()];
        }
      }

      function getErrorsFromObject(error) {
        if (Array.isArray(error)) {
          return getErrorsFromArray(error);
        }

        if (Array.isArray(error.errors)) {
          return getErrorsFromArray(error.errors);
        }

        return [getError(error)];
      }

      function getErrorsFromArray(error) {
        var errors = [];

        angular.forEach(error, function(err) {
          errors.push(typeof err === 'string' ? err : getError(err));
        });

        return errors;
      }

      function getError(obj) {
        if (!obj) {
          return '';
        }

        return obj.msg || obj.message || '';
      }

      /**
       * Return errors list as a string
       * @param error
       * @returns {*}
       */
      function getErrorsAsString(error) {
        var errorString = $filter('translate')('ERROR') + '!\n\n',
          errors = getErrors(error);

        angular.forEach(errors, function(err) {
          errorString += err + '\n';
        });

        return errorString;
      }

      return {
        getErrors: getErrors,
        getErrorsAsString: getErrorsAsString
      };
    }
  ]);
