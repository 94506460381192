angular.module('statistics').controller('StatisticsCtrl', [
  '$scope',
  'GetToken',
  'GetUTypeToken',
  function($scope, GetToken, GetUTypeToken) {
    $scope.apiUrl = '@@apiUrl';
    $scope.token = GetToken();
    $scope.xutype = GetUTypeToken();
  }
]);
