angular
  .module('pb.base')

  .directive('deleteDialog', [
    '$filter',
    function($filter) {
      return {
        restrict: 'A',
        scope: {
          onConfirm: '&onConfirm'
        },
        link: function(scope, element) {
          function deleteRequest() {
            swal(
              {
                title: '@@appName',
                text: $filter('translate')('dialog.DELETE_ASK'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'btn-danger',
                confirmButtonText: $filter('translate')('DELETE'),
                cancelButtonText: $filter('translate')('CANCEL'),
                closeOnConfirm: false
              },
              function(isConfirm) {
                if (isConfirm) {
                  scope.onConfirm();
                }
              }
            );
          }

          element.on('click', function() {
            deleteRequest();
          });
        }
      };
    }
  ]);
