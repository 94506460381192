angular
  .module('quote')

  .factory('OrderLastDaysCrud', [
    'Crud',

    function(Crud) {
      'use strict';

      class OrderLastDaysCrud extends Crud {
        constructor() {
          super(
            '',
            '@@apiUrl' + '/orders-lastdays' + '/:id',
            { id: '@id' },
            {
              query: {
                method: 'GET',
                isArray: true
              }
            }
          );
        }
      }
      return OrderLastDaysCrud;
    }
  ]);
