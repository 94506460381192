angular
  .module('library')

  .directive('checkboxUnits', function() {
    return {
      restrict: 'E',
      replace: true,
      require: '^ngModel',
      scope: {
        units: '=ngModel'
      },
      templateUrl: 'custom/library/views/partials/units.html',

      controller: [
        '$scope',
        '$controller',
        'UnitCrud',
        '$timeout',
        function($scope, $controller, UnitCrud, $timeout) {
          $controller('ListCtrl', {
            $scope: $scope,
            crud: new UnitCrud('-list'),
            packageName: 'unit',
            dataName: 'items',
            filters: {}
          });

          const loadlist = () =>
            $scope.loadList().then(function() {
              function setSelected(unit) {
                for (var i = 0; i < $scope.items.length; i += 1) {
                  if ($scope.items[i]._id === unit._id) {
                    $scope.items[i].selected = true;
                    return;
                  }
                }
              }

              $timeout(function() {
                angular.forEach($scope.units, function(unit) {
                  setSelected(unit);
                });
              });
            });

          const unwatch = $scope.$watch('units', units => {
            if (!units) return;

            loadlist();

            if (units.length) unwatch();
          });

          $scope.toggleSoftware = function(item) {
            if (
              item.selected &&
              $scope.units &&
              $scope.units.indexOf(item) < 0
            ) {
              $scope.units.push(item);
            } else if (!item.selected) {
              for (var i = 0; i < $scope.units.length; i += 1) {
                if ($scope.units[i]._id === item._id) {
                  $scope.units.splice(i, 1);
                }
              }
            }
          };
        }
      ]
    };
  });
